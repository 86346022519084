import ApplicationController from "../application_controller";

export class HandleFormToastController extends ApplicationController {
  async handleSuccess(e) {
    // TODO: implement way to pass in an optional reflex action and default message
    window.modal.close();
    const [jsonBody, _status, _event] = e.detail;
    if (jsonBody.message) {
      this.stimulate("UI::ToastReflex#trigger_toast", "success", jsonBody.message);
    } else {
      this.stimulate("UI::ToastReflex#trigger_toast", "success", "Success!");
    }
  }

  async handleError(e) {
    const [jsonBody, _status, _event] = e.detail;
    if (jsonBody.message) {
      this.stimulate("UI::ToastReflex#trigger_toast", "error", jsonBody.message);
    } else {
      this.stimulate("UI::ToastReflex#trigger_toast", "error", "Error!");
    }
  }
}
