import ApplicationController from "../application_controller";


export class CopyToClipboard extends ApplicationController {
  async copy(event) {
    event.preventDefault()
    event.stopPropagation()

    try {
      await navigator.clipboard.writeText(event.params.url)

      // Create and append checkmark span
      const checkmark = document.createElement('span')
      checkmark.textContent = '✓'
      checkmark.className = 'ml-2 text-green-500 inline-block transition-opacity duration-1000'

      // Remove any existing checkmarks
      const existingCheckmark = this.element.querySelector('.text-green-500')
      if (existingCheckmark) {
        existingCheckmark.remove()
      }

      // Add new checkmark
      this.element.appendChild(checkmark)

      // Fade out and remove after 2 seconds
      setTimeout(() => {
        checkmark.classList.add('opacity-0')
        setTimeout(() => checkmark.remove(), 1000)
      }, 2000)
    } catch (err) {
      console.error('Failed to copy:', err)
    }
  }
}
