import BaseController from "./base_controller";

export default class extends BaseController {
  static values = {
    cuId: Number,
    orderId: Number,
    orderItemId: Number,
    cuToken: String,
    campaignToken: String,
  };

  static targets = ["checkbox"];

  afterConnected() {
    if (this.isCardSelected()) {
      return this.activateCard();
    }
    return this.deactivateCard();
  }

  isCardSelected() {
    return !!this.base.selectedTokens.find(token => token == this.cuTokenValue);
  }

  mainListController() {
    const main = document.getElementById("campaign-manage-creatives-main");
    return this.getControllerForElement(main, "campaign-production-orders");
  }

  toggle(e) {
    const {
      target: { checked },
    } = e;
    if (checked) {
      return this.selectCard();
    }
    return this.unselectedCard();
  }

  selectCard() {
    this.activateCard();
    this.base.addTokens([this.cuTokenValue]);
    this.mainListController().handleGlobalCheckboxState();
  }

  unselectedCard() {
    this.deactivateCard();
    this.base.removeTokens([this.cuTokenValue]);
    this.mainListController().handleGlobalCheckboxState();
  }

  activateCard() {
    this.checkboxTarget.checked = true;
  }

  deactivateCard() {
    this.checkboxTarget.checked = false;
  }

  onUpdateOrderItem(e) {
    e.target.form.requestSubmit();
  }

  handleFormSuccess(e) {
    const [body, _status, _event] = e.detail;
    return this.handleToaster(body, "success");
  }

  async delete(e) {
    e.preventDefault();
    const payload = {
      production_order_id: this.orderIdValue,
      production_order_item_id: this.orderItemIdValue,
    };
    await this.stimulate("Plus::Campaigns::OrdersReflex#destroy_order_item", payload);
  }
}
