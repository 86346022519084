import { Filter } from "@adquick/map/src/filter";
import { ICON_MAPPING, MAX_BORDER_ZOOM, ZOOM_TO_RADIUS_MAP } from "./constants";
import DeckMap from "@adquick/map";
import Supercluster from "supercluster";

export default class PlusMap extends DeckMap {
  debug = true;
  campaignUnits = [];
  allUnits = [];
  visibleLayer = "campaign_units";
  onClickHandler;
  campaignToken;
  isTooltipExpanded = false;

  constructor(element, onClickHandler, campaignToken) {
    super(element);

    this.showFiltered = true;
    this.details = new Map();
    this.supercluster = new Supercluster({
      maxZoom: 20,
      radius: 50,
      minPoints: 2,
      minZoom: 2,
      generateId: true,
    });
    this.campaignToken = campaignToken;
    this.onClickHandler = onClickHandler;
    this.filter = {};
  }

  toggleLayers(value) {
    this.visibleLayer = value;
    this.updateLayers();
  }

  async loadAllUnits(callback) {
    if (this.allUnits.length) return;
    const locationsRequest = fetch("/plus/units.json");
    const locationsResponse = await (await locationsRequest).json();
    callback();

    const positions = locationsResponse.map(unit => {
      return { id: parseInt(unit.id), bg: parseInt(unit.id), lon: parseFloat(unit.lon), lat: parseFloat(unit.lat) };
    });

    this.allUnits = positions;
  }

  async loadCampaignUnits() {
    if (this.campaignUnits.length) return;
    const locationsRequest = fetch(`/plus/campaigns/${this.campaignToken}/units.json`);
    const locationsResponse = await (await locationsRequest).json();

    const positions = locationsResponse.map(unit => {
      return {
        id: parseInt(unit._id),
        bg: unit.block_group_geoid,
        lon: parseFloat(unit.lon),
        lat: parseFloat(unit.lat),
      };
    });

    this.filter = new Filter(new Map(positions.map(d => [d.id, d])));
    this.updateCampaignUnits(positions);
  }

  getLayers(viewState) {
    let state = viewState;
    if (!this.deck) return [];
    if (!viewState) {
      state = { zoom: this.lastZoom };
    }
    return [this.clusteredLayer(state), this.allUnitsLayer(state), ...this.layers].flat();
  }

  updateCampaignUnits(units) {
    if (!this.deck) return this;
    this.campaignUnits = units;
    this.supercluster.load(
      units.map(u => ({
        geometry: { coordinates: [u.lon, u.lat] },
        properties: u,
      })),
    );
    this.redrawLayers("units updated");
    return this;
  }

  allUnitsLayer(state) {
    const sizes = this.remapZoom(state);
    return new window.deck.ScatterplotLayer({
      id: "all_units_layer",
      visible: this.visibleLayer == "all_units",
      data: this.allUnits,
      pickable: true,
      opacity: 0.95,
      filled: true,
      stroked: state.zoom > MAX_BORDER_ZOOM,
      radiusMinPixels: sizes[0] / 2,
      radiusMaxPixels: sizes[0] / 2,
      lineWidthMinPixels: sizes[1],
      radiusScale: 1,
      getPosition: d => [d.lon, d.lat],
      getFillColor: d => this.getColor(d).fill,
      getLineColor: d => this.getColor(d).border,
      updateTriggers: {
        radiusMaxPixels: [state.zoom],
        stroked: [state.zoom],
        radiusMinPixels: [state.zoom],
        lineWidthMinPixels: [state.zoom],
        getFillColor: [this.details],
        getLineColor: [this.details],
      },
    });
  }

  clusteredLayer(state) {
    this.clusterTooltipController = document.querySelector("#cluster-tooltip").controllerInstance;
    const sizes = this.remapZoom(state);
    const zoom = this.currentZoom(state);
    const bounds = [-180, -85, 180, 85];
    const clusters = this.supercluster.getClusters(bounds, zoom).map(c => {
      console.log({ c_properties: c });
      if (c.properties.cluster) {
        return {
          coordinates: c.geometry.coordinates,
          id: c.id,
          point_count: c.properties.point_count,
          point_count_abbreviated: c.properties.point_count_abbreviated,
        };
      } else {
        return {
          coordinates: c.geometry.coordinates,
          id: c.properties.id,
          point_count: 1,
          point_count_abbreviated: "",
          unit: c.properties,
        };
      }
    });
    return [
      new window.deck.ScatterplotLayer({
        id: "units_layer",
        visible: true,
        data: clusters,
        pickable: true,
        opacity: 1.0,
        filled: true,
        stroked: true,
        radiusUnits: "pixels",
        lineWidthMinPixels: 2,
        radiusScale: 1,
        getPosition: c => c.coordinates,
        getFillColor: d => this.getColor(d).fill,
        getLineColor: d => this.getColor(d).border,
        getRadius: d => this.getPinRadius(d),
        onHover: this.onClusterHover.bind(this),
        onClick: this.onClusterClick.bind(this),
        updateTriggers: {
          getSize: [zoom],
          radiusMaxPixels: [state.zoom],
          radiusMinPixels: [state.zoom],
          lineWidthMinPixels: [state.zoom],
          getFillColor: [this.showFiltered, this.filter.filtered],
          getLineColor: [this.showFiltered, this.filter.filtered],
        },
      }),
      new window.deck.TextLayer({
        id: "text-layer",
        visible: true,
        data: clusters,
        pickable: false,
        getPosition: c => c.coordinates,
        getText: c => c.point_count_abbreviated.toString(),
        getSize: 14,
        getAngle: 0,
        getTextAnchor: "middle",
        getAlignmentBaseline: "center",
        getColor: [255, 255, 255],
        fontWeight: 600,
        fontFamily: "Inter",
      }),
    ];
  }

  onUnitClick({ object }) {
    // Placeholder for Unit click behavior (unit modal)
    console.log("DISPLAY UNIT MODAL", object);
  }

  onClusterHover({ x, y, object }) {
    if (this.isTooltipExpanded) return;
    if (!object) {
      this.setClusterTooltip({});
      return;
    }
    const { point_count, unit } = object;
    let units = [];
    if (unit) {
      units = [unit];
    }
    this.setClusterTooltip({ x, y, units, point_count });
  }

  setClusterTooltip(props) {
    console.log("RENDER TOOLTIP", props);
    this.clusterTooltipController.render(props);
  }

  onClusterClick({ x, y, object }) {
    if (!object) return this.setClusterTooltip({});
    const { point_count, unit } = object;
    let units = [];
    if (!unit) {
      const leaves = this.supercluster.getLeaves(object.id);
      units = leaves.map(l => {
        return l.properties;
      });
      this.isTooltipExpanded = true;
      this.setClusterTooltip({ x, y, units, point_count, onClose: this.onClusterClose, onUnitClick: this.onUnitClick });
    } else {
      this.onUnitClick({ object: unit });
    }
  }

  onClusterClose() {
    this.isTooltipExpanded = false;
    this.setClusterTooltip({});
  }

  getPinRadius(unit) {
    const length = unit.point_count_abbreviated.toString().length;
    switch (length) {
      case 3:
        return 12;
      case 2:
        return 9;
      case 1:
        return 8;
      default:
        return 5;
    }
  }

  onZoomChange(zoom) {
    this.deck.setProps({ layers: this.getLayers({ zoom }) });
  }

  onHover(pick) {
    if (pick.object) {
      const unit = pick.object;
      const details = this.details.get(unit.id);
      this.showTooltip(pick.x, pick.y, details);
    } else {
      this.hideTooltip();
    }
  }

  onClick(pick, event) {
    console.log(pick, event);
    if (pick.object) {
      const unit = pick.object;
      const details = this.details.get(unit.id);
      // console.log(details)
      this.onClickHandler(details);
    }
  }

  updateLayers() {
    if (!this.deck) return this;
    this.redrawLayers("units updated");
    if (this.debug) console.log("updateUnits");
    return this;
  }

  updateDetails(details) {
    if (!this.deck) return this;
    this.details = details;
    this.redrawLayers("unit details updated");
    return this;
  }

  showTooltip(x, y, details) {}

  unitsAsArray() {
    return Array.from(this.details)
      .map(vector => vector[1])
      .flat();
  }

  flyTo(coords) {
    this.deck.setProps({
      initialViewState: {
        zoom: 12,
        pitch: this.deck.viewState.pitch,
        bearing: this.deck.viewState.bearing,
        latitude: coords.lat,
        longitude: coords.lon,
        transitionDuration: 1000,
        transitionInterpolator: new window.deck.FlyToInterpolator(),
      },
    });
  }

  hideTooltip() {}

  currentZoom(viewState) {
    if (!this.deck) return this.lastZoom;
    return Math.floor(viewState.zoom);
  }

  remapZoom(viewState) {
    if (!this.deck) return ZOOM_TO_RADIUS_MAP[this.lastZoom];

    const zoom = Math.floor(viewState.zoom);
    if (zoom < 2) return ZOOM_TO_RADIUS_MAP[2];
    if (zoom > 18) return ZOOM_TO_RADIUS_MAP[18];
    return ZOOM_TO_RADIUS_MAP[zoom];
  }

  getColor(unit) {
    if (!this.filter.filtered.has(unit.id)) {
      return this.showFiltered ? ICON_MAPPING.INACTIVE : ICON_MAPPING.HIDE;
    }

    return ICON_MAPPING.UNIT;
  }
}
