import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static values = { contractToken: String, userId: Number, itemTokens: Array };
  static targets = ["form", "radio", "input", "submitButton"];

  isPriceInputsFilled() {
    return this.inputTargets.filter(el => el.value).length > 0;
  }

  isOptionRadiosChecked() {
    return this.radioTargets.filter(el => el.checked).length > 0;
  }

  allElementInputs() {
    return Array.from(this.element.querySelectorAll("input"));
  }

  connected() {
    this.allElementInputs().forEach(el => el.addEventListener("change", () => this.checkSubmit()));
  }

  disconnect() {
    this.allElementInputs().forEach(el => el.removeEventListener("change", this.checkSubmit));
  }

  checkSubmit() {
    const filled = this.isPriceInputsFilled() && this.isOptionRadiosChecked();
    if (filled) {
      return (this.submitButtonTarget.disabled = false);
    }
    this.submitButtonTarget.disabled = true;
  }

  submit(e) {
    e.preventDefault();
    this.formTarget.submit();
  }
}
