import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  deleteColumn(event) {
    if (confirm("Are you sure you want to delete this column?")) {
      const columnId = event.currentTarget.dataset.columnId;
      this.stimulate("Plus::ExportTemplates::ListReflex#delete_column", columnId);
    }
    return false;
  }
}
