import ApplicationController from "./application_controller";

// How to use:
//
// - Add the `exit-confirmation` controller to a form.
// - Add the `input->exit-confirmation#input` action to the form so it listens to input changes.
// - Add the `submit->exit-confirmation#submit` action to the form to clears unsaved changes.
//
// `input` is a standard javascript event that HTML inputs dispatch when the user changes the value manually:
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/input_event
//
// How it works:
//
// If the user attempts to leave the page without saving, the browser will display a dialog asking for confirmation.
// There are three types of links in the app, and each one requires a different implementation:
//
// - Regular links
// - Turbo links
// - Campaign header links
//
// REGULAR LINKS
//
// When the user clicks on a regular link to visit a different page, the window object dispatches the beforeunload event.
// If we listen to that event and return a non-null value, the browser shows a default dialog asking for confirmation:
// https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes
//
// TURBO LINKS
//
// Turbo doesn't trigger the `beforeunload` event. Instead, it dispatches the `turbo:before-visit` event.
// This controller listens to `turbo:before-visit`, asks for confirmation, then removes the `beforeunload` listener,
// or it would be triggered right after the turbo event, and the user would see a second confirmation dialog.
//
// CAMPAIGN HEADER LINKS
//
// app/views/campaigns/_campaign_header.haml links work differently.
// This controller here adds `data-has-unsaved-changes` on the document body, to indicate unsaved changes.
// _campaign_header will show a confirmation dialog if the document body has `data-has-unsaved-changes`.
// I'm doing this because _campaign_header is incompatible with how "beforeunload" works:
//
// - It assumes that the visit *will* work and it changes some page elements based on that,
//   but "beforeunload" may cancel the visit and leave the page in a broken shape.
// - It contains React Router links, that don't trigger "beforeunload" at all.
//
// If we ever remove React Router links and the custom script from _campaign_header, we can remove this extra complexity.
//
export default class extends ApplicationController {
  initialize() {
    this.message = "Do you want to leave this page? Changes you made won't be saved.";
  }

  connect() {
    // Bind `confirmExit` so `this` references this controller instance when the event listener calls `confirmExit`.
    // If it didn't bind `confirmExit`, `this` would be the `window` object and `this.hasUnsavedChanges` would be undefined.
    this.confirmExit = this.confirmExit.bind(this);
    this.hasUnsavedChanges = false;
    this.addEventListeners();
  }

  disconnect() {
    this.removeEventListeners();
    delete document.body.dataset.hasUnsavedChanges;
  }

  addEventListeners() {
    window.addEventListener("beforeunload", this.confirmExit);
    window.addEventListener("turbo:before-visit", this.confirmExit);
  }

  removeEventListeners() {
    window.removeEventListener("beforeunload", this.confirmExit);
    window.removeEventListener("turbo:before-visit", this.confirmExit);
  }

  input() {
    this.hasUnsavedChanges = true;
    document.body.dataset.hasUnsavedChanges = true;
  }

  submit() {
    this.hasUnsavedChanges = false;
    delete document.body.dataset.hasUnsavedChanges;
  }

  confirmExit(event) {
    if (this.hasUnsavedChanges && !this.isCampaignHeader()) {
      if (event.type === "turbo:before-visit") {
        if (window.confirm(this.message)) {
          this.removeEventListeners();
        } else {
          event.preventDefault();
        }
      } else {
        event.preventDefault();
        return (event.returnValue = this.message);
      }
    }
  }

  isCampaignHeader() {
    const tabBarAnchors = document.querySelectorAll(".tab_bar .tab a");
    const tabBarHrefs = [...tabBarAnchors].map(anchor => anchor.href);
    const destinationHref = document.activeElement.href;
    return tabBarHrefs.includes(destinationHref);
  }
}
