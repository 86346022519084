import { Controller } from "stimulus";
import { get } from "../../../../../adquick-classic/src/marketplace/utils/api";

export class SignedS3Upload extends Controller {
  static targets = ["fileInput", "form", "submitButton", "label", "names", "hiddenFileInput", "explicitInput"];
  static values = {
    directory: String,
    skipHiddenFileInputIdx: Boolean,
    singleFileUpload: Boolean,
    filePath: String,
    fileType: String,
    fileName: String,
    fileSize: String,
  };

  uploading = false;

  connect() {
    this.disableSubmit();
    this.formTarget.addEventListener("submit", () => this.handleSubmit(this));
    const DROP_CLASSES = ["bg-blue"];
    this.labelTarget.addEventListener("dragenter", e => this.labelTarget.classList.add(...DROP_CLASSES));
    this.labelTarget.addEventListener("drop", e => this.labelTarget.classList.remove(...DROP_CLASSES));
    this.labelTarget.addEventListener("dragleave", e => {
      if (!this.labelTarget.contains(e.relatedTarget)) {
        this.labelTarget.classList.remove(...DROP_CLASSES);
      }
    });
  }

  disconnect() {
    this.formTarget.removeEventListener("submit", () => this.handleSubmit(this));
  }

  async handleSubmit(context) {
    context.fileInputTarget.disabled = true;
  }

  async handleInput() {
    const inputName = this.fileInputTarget.name;

    this.disableSubmit();
    this.uploading = true;

    this.toggleLoading();

    if (this.singleFileUploadValue) {
      this.clearFileInputs();
    }

    for (const [i, file] of Array.from(this.fileInputTarget.files).entries()) {
      const { filePath, fullPath, sanitizedFileName } = await this.upload(file);
      this.createHiddenInputs(inputName, filePath, fullPath, this.formTarget, file, sanitizedFileName);
      if (this.hasNamesTarget) {
        this.fileNameSpan(file.name, this.namesTarget);
      }
    }

    this.enableSubmit();
    this.uploading = false;
    this.toggleLoading();
  }

  clearFileInputs() {
    if (this.hiddenFileInputTargets.length == 0) {
      return;
    }

    this.hiddenFileInputTargets.forEach(input => input.remove());
  }

  createHiddenInputs(name, finalFilePath, fullPath, parentElement, file, sanitizedFileName) {
    if (this.hasExplicitInputTarget) {
      const encodedPath = new URL(fullPath);
      this.explicitInputTarget.value = encodedPath.toString();
      return;
    }
    const filePathInput = this.createInput(name, this.filePathValue || "path", finalFilePath, sanitizedFileName);
    parentElement.appendChild(filePathInput);

    const contentTypeInput = this.createInput(name, this.fileTypeValue || "type", file.type, sanitizedFileName);
    parentElement.appendChild(contentTypeInput);

    const originalName = this.createInput(name, this.fileNameValue || "name", file.name, sanitizedFileName);
    parentElement.appendChild(originalName);

    const size = this.createInput(name, this.fileSizeValue || "size", file.size, sanitizedFileName);
    parentElement.appendChild(size);
  }

  fileNameSpan(fileName, parentElement) {
    const span = document.createElement("span");
    span.classList.add("filename");
    span.innerHTML = fileName;
    parentElement.appendChild(span);
  }

  createInput(inputName, paramName, value, index) {
    const input = document.createElement("input");
    input.setAttribute("data-s3-signed-upload-target", "hiddenFileInput");
    input.type = "hidden";

    if (this.skipHiddenFileInputIdxValue) {
      input.name = `${inputName}[${paramName}]`;
    } else {
      input.name = `${inputName}[${index}][${paramName}]`;
    }

    input.value = value;
    return input;
  }

  async upload(file) {
    const params = {
      objectName: file.name,
      contentType: file.type,
      time: Date.now(),
      amzAcl: "private",
      directory: this.directoryValue,
    };
    const myHeaders = new Headers({ "Content-Type": file.type, "x-amz-acl": "private" });
    const url = "/s3/sign";
    const urlParams = new URLSearchParams(params);
    const signResponse = await get(`${url}?${urlParams.toString()}`);

    const uploadResponse = await fetch(signResponse.signedUrl, { body: file, headers: myHeaders, method: "PUT" });

    return { filePath: signResponse.path, fullPath: signResponse.fullPath, sanitizedFileName: signResponse.sanitizedFileName };
  }

  toggleLoading() {
    if (this.uploading) {
      return this.labelTarget.classList.add("show-loading");
    }

    return this.labelTarget.classList.remove("show-loading");
  }

  disableSubmit() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true;
    }
  }

  enableSubmit() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = false;
    }
  }
}
