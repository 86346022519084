import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["previewButton", "icon"];

  isOpen() {
    return this.element.classList.contains("open");
  }

  tooglePreview(e) {
    e.preventDefault();
    this.element.classList.toggle("open");
    this.handleIcon();
  }

  handleIcon() {
    const isOpen = this.isOpen();
    this.iconTarget.classList.toggle("fa-caret-down", !isOpen);
    this.iconTarget.classList.toggle("fa-caret-up", isOpen);
  }
}
