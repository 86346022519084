export default class CustomBoundsLayer {
  constructor(map, layerId, sourceId, beforeLayerId) {
    const mapboxLayer = {
      id: layerId,
      type: "fill",
      source: sourceId,
      layout: {},
      paint: {
        "fill-color": { type: "identity", property: "color" },
        "fill-opacity": { type: "identity", property: "opacity" },
      },
    };

    map.addLayer(mapboxLayer, beforeLayerId);
  }
}
