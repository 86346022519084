import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static values = { showConfirmation: Boolean };

  static targets = ["form"];

  submit(e) {
    e.preventDefault();
    if (!this.showConfirmationValue) {
      return this.formTarget.submit();
    }
    if (
      confirm("Confirmation: This is a legally binding document. Please confirm all data is correct before sending.")
    ) {
      return this.formTarget.submit();
    }
    window.modal.close();
  }
}
