import ApplicationController from "../application_controller";
import BrowseMap from "../../browse-v2/BrowseMap";
import Cookies from "universal-cookie";

export default class extends ApplicationController {
  static values = {
    showDrawTool: Boolean,
    showPrices: Boolean, // show unit prices in general; if false hide all prices
    showUnitPrice: Boolean, // specifically show units.price; if false show units.rate_card_price
    showSupplier: Boolean,
    showFullAccessModal: Boolean,
  };

  static targets = ["listViewButton", "mapViewButton"];


  initialize() {
    this.showFaceIdFilter = true
  }

  connected() {
    new BrowseMap({
      container: "map",
      accessToken: AppConfig.mapboxAccessToken,
      showDrawTool: this.showDrawToolValue,
      showPrices: this.showPricesValue,
      showUnitPrice: this.showUnitPriceValue,
      showSupplier: this.showSupplierValue,
    });
    window.addEventListener("adquick:browsemap:unitselect", this.#handleMapSelectUnits.bind(this));
    this.#showWelcomeModal();
  }

  showUnitsList(event) {
    event.preventDefault();

    this.element.classList.add("show-units-list");
  }

  hideUnitsList(event) {
    event.preventDefault();

    // if the user had scrolled the units list, we need to scroll to the top to show the market overview
    document.querySelector("#browse2 .sidebar").scrollTop = 0;
    this.element.classList.remove("show-units-list");
  }

  async #handleMapSelectUnits(event) {
    const selected_unit_ids = event.detail.unitIds;

    if (!selected_unit_ids.length) return;

    // Store the select_unit_ids in the window object, so the upcoming modal can retrieve them (browse/add_units_controller.js).
    // p.s. We could theoretically send the unit ids as Reflex params, but the user can select a large number of units, so the request would be unnecessarily heavy.
    window.adquick = {
      ...window.adquick,
      browse: { ...window.adquick?.browse, selected_unit_ids },
    };

    const payload = {
      component: "pro/browse/add_units_component",
      component_params: {
        unit_count: selected_unit_ids.length,
      },
    };
    await this.stimulate("DrawerReflex#load_drawer_component", payload);
    return window.drawer.open();
  }

  #showWelcomeModal() {
    if (!this.showFullAccessModalValue) return;

    const cookies = new Cookies();
    if (cookies.get("adquick:browse:welcomemodal")) return;
    cookies.set("adquick:browse:welcomemodal", true);

    setTimeout(async () => {
      await this.stimulate("ModalReflex#load_modal_component", {
        component: "modal/browse/full_access/welcome_modal_component",
      });
      window.modal.open();
    }, 1000);
  }

  async showAiPlannerModal() {
    const payload = {
      component: "modal/browse/ai/planner_prompt_modal_component",
      component_params: {},
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }

  async showAiPlannerCtaModal(e) {
    e.preventDefault();

    const payload = {
      component: "modal/browse/ai/campaign_cta_modal_component",
      component_params: {
        session_id: e.currentTarget.dataset.session,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }
}
