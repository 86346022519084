import ApplicationController from "../application_controller";
import { parsePriceInput } from "../../utils/parsers";

export default class extends ApplicationController {
  static values = { campaignToken: String, contractToken: String, currentUserId: Number, supplierId: Number };

  static targets = [
    "item",
    "totalDisplayContainer",
    "totalDisplay",
    "totalEdit",
    "totalEditInput",
    "nameDisplay",
    "nameEdit",
    "nameEditInput",
  ];

  toggleAll(e) {
    const {
      target: { checked },
    } = e;
    this.itemTargets.forEach(el => (el.checked = checked));
  }

  selectedItemElements() {
    return this.itemTargets.filter(el => el.checked);
  }

  selectedItemTokens() {
    return this.selectedItemElements().map(el => el.dataset.itemToken);
  }

  toggleTotalEdit() {
    this.totalDisplayContainerTarget.style.display = "none";
    this.totalEditTarget.style.display = "grid";
  }

  toggleTotalShow() {
    this.updateTotalDisplayWithInputValue();
    this.totalDisplayContainerTarget.style.display = "grid";
    this.totalEditTarget.style.display = "none";
  }

  toggleNameEdit() {
    this.nameDisplayTarget.style.display = "none";
    this.nameEditTarget.style.display = "grid";
  }

  toggleNameShow() {
    this.nameDisplayTarget.style.display = "grid";
    this.nameEditTarget.style.display = "none";
  }

  updateTotalDisplayWithInputValue() {
    const { value } = this.totalEditInputTarget;
    const formatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
    this.totalDisplayTarget.innerText = formatter.format(parsePriceInput(value));
  }

  async toggleEditing() {
    await this.stimulate("Plus::Campaigns::VendorContractsReflex#toggleSupplierCounterofferEdit");
  }

  async cancelEditing() {
    await this.stimulate("Plus::Campaigns::VendorContractsReflex#cancelSupplierCounteroffer");
  }

  async openUnitModal(e) {
    const payload = {
      component: "modal/vendor_contract_unit_modal_component",
      component_params: {
        supplier_id: this.supplierIdValue,
        campaign_token: this.campaignTokenValue,
        current_user_id: this.currentUserIdValue,
        contract_token: this.contractTokenValue,
        reflex_action: "add_units",
        filters: {},
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async openContractModal(e) {
    const {
      target: { dataset },
    } = e;
    const payload = {
      component: "modal/vendor_send_contract_modal",
      component_params: {
        current_user_id: this.currentUserIdValue,
        contract_token: this.contractTokenValue,
        campaign_token: this.campaignTokenValue,
        proposal: dataset.target == "proposal",
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async openResetPriceModal(e) {
    const {
      target: { dataset },
    } = e;
    const { submitUrl } = dataset;
    const payload = {
      component: "modal/vendor_confirmation",
      component_params: {
        campaign_token: this.campaignTokenValue,
        current_user_id: this.currentUserIdValue,
        contract_token: this.contractTokenValue,
        item_tokens: this.selectedItemTokens(),
        message: "This action will reset the respective values for the original values.",
        submit_route: submitUrl,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async openConfirmMassUpdateModal(e) {
    const {
      target: { dataset },
    } = e;
    const { submitUrl } = dataset;
    const payload = {
      component: "modal/vendor_confirmation",
      component_params: {
        campaign_token: this.campaignTokenValue,
        current_user_id: this.currentUserIdValue,
        contract_token: this.contractTokenValue,
        total: parsePriceInput(this.totalEditInputTarget.value),
        item_tokens: [],
        message:
          "Editing the total will proportionally add or substract from the cost of all units to meet your desired total.",
        submit_route: submitUrl,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
    this.toggleTotalShow();
  }

  async openUploadModal() {
    const payload = {
      component: "modal/vendor_upload_contract",
      component_params: {
        current_user_id: this.currentUserIdValue,
        contract_token: this.contractTokenValue,
        campaign_token: this.campaignTokenValue,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async openPriceAdjustModal() {
    const payload = {
      component: "modal/vendor_price_adjust",
      component_params: {
        campaign_token: this.campaignTokenValue,
        current_user_id: this.currentUserIdValue,
        contract_token: this.contractTokenValue,
        item_tokens: this.selectedItemTokens(),
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async confirmToggleVendorProduced() {
    const payload = {
      component: "modal/vendor_confirmation",
      component_params: {
        campaign_token: this.campaignTokenValue,
        current_user_id: this.currentUserIdValue,
        contract_token: this.contractTokenValue,
        item_tokens: this.selectedItemTokens(),
        message: `Are you sure you want to toggle vendor produced for ${this.selectedItemTokens().length} items?`,
        submit_route: `/api/v1/campaigns/${this.campaignTokenValue}/supplier_contracts/${this.contractTokenValue}/bulk_toggle_vendor_produce`,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async confirmMassDelete() {
    const payload = {
      component: "modal/vendor_confirmation",
      component_params: {
        campaign_token: this.campaignTokenValue,
        current_user_id: this.currentUserIdValue,
        contract_token: this.contractTokenValue,
        item_tokens: this.selectedItemTokens(),
        message: `Are you sure you want to delete ${this.selectedItemTokens().length} items?`,
        submit_route: `/api/v1/campaigns/${this.campaignTokenValue}/supplier_contracts/${this.contractTokenValue}/bulk_remove_item`,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async updateContractName() {
    const payload = { name: this.nameEditInputTarget.value };
    await this.stimulate("Plus::Campaigns::VendorContractsReflex#update", payload);
  }

  openPreview() {
    const campaign_token = this.campaignTokenValue;
    const contract_token = this.contractTokenValue;
    const url = `/api/v1/campaigns/${campaign_token}/supplier_contracts/${contract_token}/contracts_preview/`;
    window.open(url, "_blank");
  }
}
