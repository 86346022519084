class UnitLabelsFeature {
  static RECOMMENDED_IMAGE = 'recommended-unit';
  static AVAILABLE_IMAGE = 'available-unit';
  static CART_IMAGE = 'cart-unit';
  static FAVORITED_IMAGE = 'favorited-unit';

  static RECOMMENDED_LAYER = 'units-recommended-layer';
  static CART_LAYER = 'units-cart-layer';
  static FAVORITED_LAYER = 'units-favorited-layer';

  constructor(map, sourceId) {
    this.map = map;
    this.sourceId = sourceId;
    
    this.#initializeLayers();
  }

  #initializeLayers() {
    this.#updateRecommendedLayer();
    this.#updateCartLayer();
    this.#updateFavoritedLayer();
  }

  #updateRecommendedLayer() {
    this.#updateLayer(
      UnitLabelsFeature.RECOMMENDED_LAYER,
      ["get", "is_recommended"],
      UnitLabelsFeature.RECOMMENDED_IMAGE,
      30,
      -12
    );
  }

  #updateCartLayer() {
    this.#updateLayer(
      UnitLabelsFeature.CART_LAYER,
      ["==", ["get", "status_badge"], "cart"],
      UnitLabelsFeature.CART_IMAGE,
      30,
      -12
    );
  }

  #updateFavoritedLayer() {
    this.#updateLayer(
      UnitLabelsFeature.FAVORITED_LAYER,
      ["get", "is_favorited"],
      UnitLabelsFeature.FAVORITED_IMAGE,
      30,
      12
    );
  }

  #updateLayer(id, filter, image, x, y) {
    if (!this.map.getSource(this.sourceId)) return;
    if (this.map.getLayer(id)) this.map.removeLayer(id);

    this.map.addLayer({
      id: id,
      type: "symbol",
      source: this.sourceId,
      filter: filter,
      layout: {
        "icon-image": image,
        "icon-offset": [
          "case",
          ["==", ["get", "direction"], "E"], ["literal", [-1 * x, y]],
          ["==", ["get", "direction"], "NE"], ["literal", [-1 * x, y]],
          ["==", ["get", "direction"], "SE"], ["literal", [-1 * x, y]],
          ["literal", [x, y]]
        ],
        "icon-rotation-alignment": "map",
        "icon-size": 0.4,
        "icon-allow-overlap": true,
        "text-allow-overlap": true
      }
    });
  }
}

export default UnitLabelsFeature; 