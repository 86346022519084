import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static values = {
    campaignToken: String,
  };

  async openReplaceCreativeModal(e) {
    e.preventDefault();

    const campaignUnitToken = e.currentTarget.dataset.campaignUnitToken;
    const designAssetProofId = e.currentTarget.dataset.designAssetProofId;

    const payload = {
      component: "modal/campaigns/replace_proof_creative_file",
      component_params: {
        campaign_token: this.campaignTokenValue,
        campaign_unit_token: campaignUnitToken,
        design_asset_proof_id: designAssetProofId
      },
    };

    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }
}
