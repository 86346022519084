import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static targets = ["holdUntil"];

  onHoldUntilChange(e) {
    const {
      target: { value },
    } = e;
    this.holdUntilTarget.value = !!value ? value : null;
  }
}
