import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  connected() {
    document.querySelector('html').classList.add('overflow-hidden');
  }

  disconnect() {
    document.querySelector('html').classList.remove('overflow-hidden');
  }

  closeModal() {
    this.element.remove();
  }
}
