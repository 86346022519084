import BaseController from "./base_controller";

export default class extends BaseController {
  static values = {
    campaignToken: String,
    allFilteredTokens: Array,
    nextPage: Number,
    previousPage: Number,
  };

  static targets = ["unit", "actions", "bulkListContainer", "globalCheckbox", "selectedCount", "selectAllFiltered"];

  isAllCurrentTokensSelected() {
    if (!this.base.hasSelectedTokens()) {
      return false;
    }
    return this.allCurrentTokens().every(token => this.base.selectedTokens.includes(token));
  }

  allCurrentTokens() {
    return this.unitTargets.map(el => el.dataset.creativeCardCuTokenValue);
  }

  afterConnected() {
    this.handleGlobalCheckboxState();
  }

  handleGlobalCheckboxState() {
    this.handleSelectedDetailsState();
    if (this.isAllCurrentTokensSelected()) {
      return (this.globalCheckboxTarget.checked = true);
    }
    return (this.globalCheckboxTarget.checked = false);
  }

  handleSelectedDetailsState() {
    if (!this.base.hasSelectedTokens()) {
      return this.hideSelectedDetails();
    }
    return this.showSelectedDetails();
  }

  hideSelectedDetails() {
    this.actionsTarget.classList.remove("show-selected");
  }

  showSelectedDetails() {
    this.actionsTarget.classList.add("show-selected");
    this.buildCurrentSelectedState();
  }

  buildCurrentSelectedState() {
    const content = `${this.base.selectedTokens.length} current units selected`;
    this.selectedCountTarget.innerHTML = content;
  }

  selectAllFiltered() {
    this.base.selectedTokens = this.allFilteredTokensValue;
    this.unitTargets.forEach(el => this.activateCard(el));
    this.handleGlobalCheckboxState();
  }

  clearSelection() {
    this.base.selectedTokens = [];
    this.unitTargets.forEach(el => this.deactivateCard(el));
    this.handleGlobalCheckboxState();
  }

  toggleAll(e) {
    const {
      target: { checked },
    } = e;
    if (checked) {
      return this.selectAll();
    }
    return this.unselectAll();
  }

  unselectAll() {
    this.unitTargets.forEach(el => this.deactivateCard(el));
    this.base.removeTokens(this.allCurrentTokens());
    this.handleSelectedDetailsState();
  }

  selectAll() {
    this.unitTargets.forEach(el => this.activateCard(el));
    this.base.addTokens(this.allCurrentTokens());
    this.handleSelectedDetailsState();
  }

  activateCard(card) {
    const cardController = this.getCardController(card);
    return cardController.activateCard();
  }

  deactivateCard(card) {
    const cardController = this.getCardController(card);
    return cardController.deactivateCard();
  }

  getCardController(card) {
    return this.getControllerForElement(card, "creative-card");
  }

  noSelectionError() {
    return alert("You need to have selected cards");
  }

  openUploadModal() {
    const payload = {
      component: "modal/creatives/upload_asset",
      component_params: {
        campaign_token: this.campaignTokenValue,
        campaign_unit_ids: this.base.selectedTokens,
      },
    };
    this.stimulateModal(payload);
  }

  async openStartProductionModal(e) {
    e.preventDefault();

    const payload = {
      component: "modal/cancel_continue_modal_component",
      component_params: {
        title: 'Are you ready to start production?',
        text: 'Please make sure all the creative files you’ve uploaded are built to the correct specifications. After you submit creatives for production, proofs will be generated for your review before printing.',
        link: e.target.dataset.modalLink
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);

    return window.modal.open();
  }

  async downloadSpecSheets() {
    const payload = { campaign_unit_tokens: this.base.selectedTokens };
    const headers = { "Content-Type": "application/json" };
    const options = {
      method: "POST",
      body: JSON.stringify(payload),
      headers,
    };
    const response = await fetch(
      `/api/v1/campaigns/${this.campaignTokenValue}/design_assets/bulk_download_spec_sheets`,
      options,
    );
    return this.processResponseSuccess(response);
  }

  async downloadCreatives() {
    const payload = { campaign_unit_tokens: this.base.selectedTokens };
    const headers = { "Content-Type": "application/json" };
    const options = {
      method: "POST",
      body: JSON.stringify(payload),
      headers,
    };
    const response = await fetch(`/api/v1/campaigns/${this.campaignTokenValue}/design_assets/download_zip`, options);
    return this.processResponseSuccess(response);
  }

  async addPostingNote(e) {
    const payload = {
      component: "modal/creatives/add_posting_note",
      component_params: {
        campaign_token: this.campaignTokenValue,
        campaign_unit_ids: this.base.selectedTokens,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async removePostingNotes() {
    const payload = { campaign_unit_ids: this.base.selectedTokens };
    const headers = { "Content-Type": "application/json" };
    const options = {
      method: "POST",
      body: JSON.stringify(payload),
      headers,
    };
    const response = await fetch(
      `/api/v1/campaigns/${this.campaignTokenValue}/design_assets/bulk_remove_posting_note`,
      options,
    );
    if (response.status > 400) {
      return this.processResponseError(response);
    }
    return this.processResponseSuccess(response);
  }

  async exportToProductionGrid() {
    const payload = { campaign_unit_ids: this.base.selectedTokens };
    const headers = { "Content-Type": "application/json" };
    const options = {
      method: "POST",
      body: JSON.stringify(payload),
      headers,
    };
    const response = await fetch(
      `/api/v1/campaigns/${this.campaignTokenValue}/design_assets/export_to_production_grid`,
      options,
    );
    return this.processResponseSuccess(response);
  }

  async rejectCreatives() {
    const payload = {
      component: "modal/creatives/reject_creative",
      component_params: {
        campaign_token: this.campaignTokenValue,
        campaign_unit_ids: this.base.selectedTokens,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async updateShippingAddress() {
    const payload = {
      component: "modal/creatives/supplier_address",
      component_params: {
        campaign_token: this.campaignTokenValue,
        supplier_address_id: null,
        campaign_unit_ids: this.base.selectedTokens,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async updateInstaller() {
    const payload = {
      component: "modal/creatives/bulk_update_installer",
      component_params: {
        campaign_unit_tokens: this.base.selectedTokens,
        campaign_token: this.campaignTokenValue,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async createProductionOrder() {
    const payload = { campaign_token: this.campaignTokenValue, campaign_unit_ids: this.base.selectedTokens };
    const headers = { "Content-Type": "application/json" };
    const options = {
      method: "POST",
      body: JSON.stringify(payload),
      headers,
    };
    const response = await fetch(`/api/v1/production_orders`, options);
    if (response.status > 400) {
      return this.processResponseError(response);
    }
    return this.processResponseSuccess(response);
  }

  async sendToInstaller() {
    const payload = { campaign_unit_tokens: this.base.selectedTokens };
    const headers = { "Content-Type": "application/json" };
    const options = {
      method: "POST",
      body: JSON.stringify(payload),
      headers,
    };
    const response = await fetch(`/api/v1/campaigns/${this.campaignTokenValue}/installers`, options);
    if (response.status > 400) {
      return this.processResponseError(response);
    }
    return this.processResponseSuccess(response);
  }

  async exportPostingNotes() {
    const payload = { campaign_unit_tokens: this.base.selectedTokens };
    const headers = { "Content-Type": "application/json" };
    const options = {
      method: "POST",
      body: JSON.stringify(payload),
      headers,
    };
    const response = await fetch(
      `/api/v1/campaigns/${this.campaignTokenValue}/campaign_units/posting_instruction`,
      options,
    );
    if (response.status > 400) {
      return this.processResponseError(response);
    }
    return this.processResponseSuccess(response);
  }

  async submitProductionOrder(e) {
    const {
      target: {
        dataset: { productionOrderId },
      },
    } = e;
    e.preventDefault();
    const headers = { "Content-Type": "application/json" };
    const options = {
      method: "POST",
      body: JSON.stringify({}),
      headers,
    };
    const response = await fetch(`/api/v1/production_orders/${productionOrderId}/submit`, options);
    if (response.status > 400) {
      return this.processResponseError(response);
    }
    return this.processResponseSuccess(response);
  }

  async onGoToPage(e) {
    const {
      target: {
        dataset: { page },
      },
    } = e;
    if (page == "next" || page == "previous") {
      return this.handlePaginationButton(page);
    }
    this.paramsAdd("page", page);
    await this.stimulate("Plus::Campaigns::CreativesReflex#index");
  }

  async handlePaginationButton(action) {
    const buttons = { next: this.nextPageValue, previous: this.previousPageValue };
    const page = buttons[action];
    if (!page) {
      return;
    }
    this.paramsAdd("page", page);
    await this.stimulate("Plus::Campaigns::CreativesReflex#index");
  }

  async stimulateModal(payload, skipToggle = false) {
    if (!this.base.hasSelectedTokens()) {
      return this.noSelectionError();
    }
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }
}
