import ApplicationController from "../application_controller";
import dayjs from "dayjs";

export default class extends ApplicationController {
  static targets = [
    "form",
    "collection",
    "accountManager",
    "salesUserId",
    "createdBy",
    "mvUnitStatus",
    "startDate",
    "endDate",
    "status",
    "sortBy",
    "starredCampaigns"
  ];

  async submitForm(e) {
    e.preventDefault();
    const mvu_unit_status = this.hasMvUnitStatusTarget ? this.mvUnitStatusTarget.value : "";
    const account_manager = this.hasAccountManagerTarget ? this.accountManagerTarget.value : "";
    const sales_user_id = this.hasSalesUserIdTarget ? this.salesUserIdTarget.value : "";
    const start_date = this.hasStartDateTarget ? this.startDateTarget.value : "";
    const created_by = this.hasCreatedByTarget ? this.createdByTarget.value : "";
    const advertiser = document.getElementById("advertiser_id").value || "";
    const agency_id = document.getElementById("agency_id").value || "";
    const collection = this.hasCollectionTarget ? this.collectionTarget.value : "all";
    const end_date = this.hasEndDateTarget ? this.endDateTarget.value : "";
    const sort_by = this.hasSortByTarget ? this.sortByTarget.value : "";
    const status = this.hasStatusTarget ? this.statusTarget.value : "";
    const starred_campaigns = this.hasStarredCampaignsTarget ? this.starredCampaignsTarget.value : "";
    const q = document.getElementById('dashboard-campaign-search-input').value;
    let data = {
      collection,
      account_manager,
      sales_user_id,
      created_by,
      mvu_unit_status,
      advertiser,
      agency_id,
      start_date,
      end_date,
      sort_by,
      status,
      starred_campaigns,
      q
    };

    let new_data = this.removeEmptyParams(data);
    let queryString = new URLSearchParams(new_data);

    const market_ids = _.values(document.querySelectorAll('#markets-container .selected')).map(x => x.dataset.id) || [];
    market_ids.forEach(id => {
      queryString.append("market_ids[]", id);
    });
    window.location.search = queryString.toString();
  }

  removeEmptyParams(data) {
    return Object.keys(data).reduce((acc, key) => {
      if (data[key] !== "") {
        acc[key] = data[key];
      }
      return acc;
    }, {});
  }

  onMarketRemove(e) {
    e.currentTarget.parentElement.remove();
    this.submitForm(e);
  }

  clearFilters() {
    window.location.search = "";
  }

  clearFilter(e) {
    const url = new URL(location);
    const paramName = e.currentTarget.dataset.paramName;
    url.searchParams.delete(paramName);
    window.location.search = url.searchParams.toString();
  }

  onDateRangeChange(e) {
    const dates = JSON.parse(e.currentTarget.dataset.value);
    this.buildDates(dates);
    if (this.startDateTarget.value != "" && this.endDateTarget.value != "") {
      this.submitForm(e);
    }
  }

  buildDates(dates) {
    const startDate = dates[0];
    const endDate = dates[1];
    this.startDateTarget.value = !!startDate ? this.formatDate(startDate) : null;
    this.endDateTarget.value = !!endDate ? this.formatDate(endDate) : null;
  }

  formatDate(dateString) {
    return dayjs(dateString).format("M/D/YYYY");
  }

  buildDates(dates) {
    const startDate = dates[0];
    const endDate = dates[1];
    this.startDateTarget.value = !!startDate ? this.formatDate(startDate) : null;
    this.endDateTarget.value = !!endDate ? this.formatDate(endDate) : null;
  }
}
