import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  get base() {
    return this._base;
  }

  getControllerForElement(el, identifier) {
    return this.application.getControllerForElementAndIdentifier(el, identifier);
  }

  connected() {
    const baseElement = document.getElementById("manage-units");
    const baseKey = "campaign-manage-units-base";
    this._base = this.application.getControllerForElementAndIdentifier(baseElement, baseKey);
    this.afterConnected();
  }

  afterConnected() {}

  resetPagination() {
    if (this.hasFormValue) return;

    this.paramsAdd("page", 1);
  }
}
