export default class UnitMediaTypeIconsLayer {
  constructor(map, source, minzoom) {
    this.#addUnitIconLayer(map, source, minzoom);
  }

  #addUnitIconLayer(map, source, minzoom) {
    map.addLayer({
      id: "unit-icons",
      type: "symbol",
      source: source,
      minzoom: minzoom,
      filter: ["!has", "point_count"],
      layout: {
        "icon-image": "{unit_type}",
        "icon-size": 0.4,
        "icon-allow-overlap": true,
        "text-allow-overlap": true,
      },
    });
  }
}
