import ApplicationController from "../../application_controller";
import dayjs from "dayjs";

export default class extends ApplicationController {
  static targets = ["startDate", "endDate"];

  onDateRangeChange(e) {
    const dates = JSON.parse(e.currentTarget.dataset.value);
    this.buildDates(dates);
  }

  buildDates(dates) {
    const startDate = dates[0];
    const endDate = dates[1];
    this.startDateTarget.value = !!startDate ? this.formatDate(startDate) : null;
    this.endDateTarget.value = !!endDate ? this.formatDate(endDate) : null;
  }

  formatDate(dateString) {
    return dayjs(dateString).format("YYYY-MM-DD");
  }
}
