import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static values = {
    selectedTokens: Array,
    campaignToken: String,
    polygon: Array,
  };

  async openFlightModal() {
    const payload = {
      component: "modal/add_flights_bulk_component",
      component_params: {
        campaign_token: this.campaignTokenValue,
        campaign_unit_tokens: this.selectedTokensValue,
        polygon: this.polygonValue,
        turbo: true
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }
}
