import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import * as turf from "@turf/turf";
import QueryString from "query-string";
import {
  paramsGet,
  paramsAdd,
  paramsRemove,
  hasParam,
} from "../../../../../adquick-classic/src/marketplace/utils/QueryString";
import * as MapFeature from "../map/features/browse";
import AiPlannerCta from "../controls/AiPlannerCta";

class BrowseMap {
  // map parameters
  static DEFAULT_MAP_STYLE = "mapbox://styles/fahimf/cjy0g4rbh0y1d1cledi9pf3nk";

  // the units will be loaded at most once every these milliseconds
  static THROTTLE_INTERVAL = 500;
  static CLUSTER_MAX_ZOOM = 22;
  // unit highlight (View On Map)
  static UNIT_HIGHLIGHT_DURATION = 5000;
  static MAX_FLY_TO_DURATION = 1000;

  // map layers' and sources' ids
  static MARKET_SOURCE_ID = "market";
  static MARKET_NAME_LAYER_ID = "market-name-layer";
  static MARKET_CIRCLE_LAYER_ID = "market-circle-layer";
  static UNIT_SOURCE_ID = "unit";
  static UNIT_NAME_LAYER_ID = "unit-name-layer";
  static UNIT_CIRCLE_LAYER_ID = "unit-circle-layer";
  static UNIT_CLUSTER_LAYER_ID = "unit-cluster-layer";
  static AUDIENCE_HEATMAP_LAYER_ID = "audience-heatmap";

  // ui params
  static SIDEBAR_WIDTH = 475;

  constructor({ container, accessToken, showDrawTool, showPrices, showUnitPrice, showSupplier }) {
    this.showDrawTool = showDrawTool;
    this.showPrices = showPrices;
    this.showUnitPrice = showUnitPrice;
    this.showSupplier = showSupplier;
    mapboxgl.accessToken = accessToken;
    this.settings = this.#getMapSettings({ container: container, accessToken: accessToken, mapboxgl: mapboxgl });
  }

  /*** CONFIGURE MAP ***/

  async #getMapSettings({ container, accessToken, mapboxgl }) {
    const settingsUrl = new URL(`/browse/map_settings${window.location.search}`, window.location);
    const response = await fetch(settingsUrl);
    const json = await response.json();
    this.unitMinZooom = json.unit_min_zoom;
    this.drawToolsMinZoom = json.draw_tools_min_zoom;
    this.marketMaxZoom = json.market_max_zoom;
    this.clusterMaxZoom = json.cluster_max_zoom;
    this.defaultMapZoom = json.default_map_zoom;
    this.defaultMapCenter = json.default_map_center;
    this.lnglatStep = json.lnglat_step;
    this.lnglatDecimals = json.lnglat_decimals;
    this.largeMarkets = json.large_markets;
    this.hasAiUnits = json.has_ai_units;
    this.aiButtonControl = new AiPlannerCta(json.has_ai_units);

    // Temporary SEO experiment: https://app.asana.com/0/1199187629498590/1205733729765815/f
    const { lng, lat, zoom } = document.getElementById("browse2").dataset["lat"]
      ? document.getElementById("browse2").dataset
      : paramsGet();

    const geocoder = new MapboxGeocoder({
      accessToken,
      placeholder: "Search for a location",
      flyTo: { curve: 2 },
      mapboxgl: mapboxgl,
      marker: false,
    });

    this.map = new mapboxgl.Map({
      container: container, // id of the html element that contains the map
      style: BrowseMap.DEFAULT_MAP_STYLE,
      center: lng && lat ? [lng, lat] : this.defaultMapCenter,
      zoom: zoom || this.defaultMapZoom,
      attributionControl: false,
    })
      .on("load", this.#handleMapLoad.bind(this))
      .addControl(geocoder, "top-left");
    window.map = this.map;
  }

  #handleMapLoad() {
    this.#createUnitSource();
    if (!this.hasAiUnits) {
      this.#createMarketSource();
      this.#preloadLargeMarkets();
      new MapFeature.MarketLayer(
        this.map,
        BrowseMap.MARKET_SOURCE_ID,
        BrowseMap.MARKET_CIRCLE_LAYER_ID,
        BrowseMap.MARKET_NAME_LAYER_ID,
        this.marketMaxZoom,
      );
    }
    this.#updateSources();

    new MapFeature.UnitLayer(
      this.map,
      BrowseMap.UNIT_SOURCE_ID,
      BrowseMap.UNIT_CLUSTER_LAYER_ID,
      BrowseMap.UNIT_CIRCLE_LAYER_ID,
      BrowseMap.UNIT_NAME_LAYER_ID,
      this.unitMinZooom,
    );
    new MapFeature.MousePointer(this.map, BrowseMap.MARKET_NAME_LAYER_ID);
    new MapFeature.LayerLoadingIndicator(this.map, BrowseMap.UNIT_SOURCE_ID);
    new MapFeature.LayerLoadingIndicator(this.map, BrowseMap.MARKET_SOURCE_ID);
    this.audienceHeatmapLayer = new MapFeature.AudienceHeatmapLayer(
      this.map,
      BrowseMap.AUDIENCE_HEATMAP_LAYER_ID
    );

    const unitTooltipController = new MapFeature.UnitTooltipController(
      this.map,
      this.showPrices,
      this.showUnitPrice,
      this.showSupplier,
    );
    new MapFeature.UnitLayerTooltip(this.map, BrowseMap.UNIT_CIRCLE_LAYER_ID, unitTooltipController);
    new MapFeature.ClusterSpiderifier(
      this.map,
      BrowseMap.UNIT_CLUSTER_LAYER_ID,
      unitTooltipController,
      BrowseMap.UNIT_SOURCE_ID,
      BrowseMap.SIDEBAR_WIDTH,
    );

    if (this.showDrawTool) {
      this.mapDraw = new MapFeature.DrawTool(this.map, 0);
    }
    this.map.addControl(this.aiButtonControl, "top-left");
    this.map.addControl(new mapboxgl.NavigationControl(), "bottom-left");
    this.map.on("moveend", this.#handleMapMoveEnd.bind(this));
    this.map.on("zoomend", this.#handleMapMoveEnd.bind(this));
    this.map.on("click", BrowseMap.MARKET_NAME_LAYER_ID, this.#handleMarketClick.bind(this));
    this.map.on("click", BrowseMap.UNIT_CIRCLE_LAYER_ID, this.#handleUnitClick.bind(this));
    this.map.on("draw.create", this.#handleDrawCreate.bind(this));

    window.addEventListener(
      "adquick:history:replacestate",
      _.debounce(this.#handleHistoryReplaceStateDebounced.bind(this), 1000, { leading: true }),
    );
    window.addEventListener("adquick:browseunitmodal:viewonmap", this.#handleViewOnMapClick.bind(this));
    window.addEventListener("adquick:modal:close", this.#handleCloseUnitModal.bind(this));
    this.audienceHeatmapLayer.addEventListeners();

    // Dispatch event because the event listeners may need to perform additional configuration once the map is ready.
    this.#dispatchMoveEndEvent();
  }

  #dispatchMoveEndEvent() {
    window.dispatchEvent(new CustomEvent("adquick:map:moveend", { detail: { map: this.map } }));
  }

  #createUnitSource() {
    this.map.addSource(BrowseMap.UNIT_SOURCE_ID, {
      type: "geojson",
      cluster: true,
      clusterMaxZoom: this.clusterMaxZoom, // TODO: This option is ignored in mapboxgl 2.0. Upgrade mapboxgl once the Source.getClusterLeaves bug is fixed.
      maxzoom: this.clusterMaxZoom, // units are clustered until the user zooms all the way in
      clusterRadius: 15,
      promoteId: "id",
      data: { type: "FeatureCollection", features: [] },
    });
  }

  #createMarketSource() {
    this.map.addSource(BrowseMap.MARKET_SOURCE_ID, {
      type: "geojson",
      data: this.#marketDataUrl(),
    });
  }

  #preloadLargeMarkets() {
    for (const lngLat of Object.values(this.largeMarkets)) {
      fetch(this.#unitDataUrlForLngLat(...lngLat));
    }
  }

  #updateSources() {
    this.#updateUnitsSource();
    this.#updateMarketsSource();
  }

  #handleHistoryReplaceState() {
    this.#updateSources();
  }

  #handleHistoryReplaceStateDebounced = _.debounce(this.#handleHistoryReplaceState, BrowseMap.THROTTLE_INTERVAL).bind(
    this,
  );

  /*** MAP MOVE ***/

  #handleMapMoveEnd() {
    this.#dispatchMoveEndEvent();
    this.#updateMapParams();
  }

  #updateMapParams() {
    const zoom = this.map.getZoom();
    const { lng, lat } = this.map.getBounds().getCenter();
    paramsAdd("zoom", zoom);
    paramsAdd("lng", lng);
    paramsAdd("lat", lat);
  }

  #handleMarketClick(event) {
    this.map.flyTo({
      center: event.features[0].geometry.coordinates,
      zoom: this.marketMaxZoom + 0.3,
      curve: 2,
      padding: { left: BrowseMap.SIDEBAR_WIDTH },
    });
  }

  #handleUnitClick(event) {
    const unitId = event.features[0].properties.id;
    if (!unitId) return false; // return unless it's a single unit (a "circle", not a cluster)
    window.dispatchEvent(new CustomEvent("adquick:browsemap:unitclick", { detail: { unitId } }));
    window.dispatchEvent(new CustomEvent("adquick:browsemap:unitview", { detail: { unitId } }));
  }

  #handleViewOnMapClick(event) {
    let { lng, lat } = event.detail;
    window.modal.close();
    this.map.flyTo({
      center: [lng, lat],
      zoom: BrowseMap.CLUSTER_MAX_ZOOM + 1, // Force clusters to break apart and show individual units
      curve: 2,
      padding: { left: BrowseMap.SIDEBAR_WIDTH },
    });

    setTimeout(() => {
      const features = this.#getUniqueFeatures(lat, lng);
      if (features.length) {
        this.#highlightUnit(features[0].id);
        setTimeout(() => {
          this.#removeHighlightFromUnit(features[0].id);
        }, BrowseMap.UNIT_HIGHLIGHT_DURATION);
      } else {
        console.error("Couldn't find unit on map. Either it's not loaded yet or it's still a cluster.");
      }
    }, BrowseMap.MAX_FLY_TO_DURATION + 100); // Wait until the map is fully zoomed in before highlighting the unit
  }

  #highlightUnit(unitId) {
    this.map.setFeatureState({ source: BrowseMap.UNIT_SOURCE_ID, id: unitId }, { highlighted: true });
  }

  #removeHighlightFromUnit(unitId) {
    this.map.setFeatureState({ source: BrowseMap.UNIT_SOURCE_ID, id: unitId }, { highlighted: false });
  }

  /*** DRAW CREATE ***/

  async #handleDrawCreate(event) {
    const polygonId = event.features[0].id;
    const polygonCoordinates = event.features[0].geometry.coordinates;

    this.mapDraw.delete(polygonId);
    const unitIds = await this.#getUnitIdsWithinPolygon(polygonCoordinates);

    window.dispatchEvent(new CustomEvent("adquick:browsemap:unitselect", { detail: { unitIds } }));
  }

  async #getUnitIdsWithinPolygon(polygonCoordinates) {
    // This fetch doesn't cause a network delay, since it hits a browser cache from the last time the map was moved/zoomed.
    const geojson = await fetch(this.#unitDataUrl(this.map)).then(res => res.json());
    const polygon = turf.polygon(polygonCoordinates);

    const reduceToUnitIdsWithinPolygon = (unitIds, feature) => {
      const selected = turf.booleanPointInPolygon(feature.geometry.coordinates, polygon);
      return selected ? [...unitIds, feature.properties.id] : unitIds;
    };

    const unitIds = geojson.features.reduce(reduceToUnitIdsWithinPolygon, []);
    return unitIds;
  }

  /*** UNITS SOURCE ***/

  #updateUnitsSource() {
    if (this.hasAiUnits) {
      return this.map.getSource(BrowseMap.UNIT_SOURCE_ID).setData(this.#unitDataUrl());
    }
    if (this.map.getZoom() > this.marketMaxZoom) {
      this.map.getSource(BrowseMap.UNIT_SOURCE_ID).setData(this.#unitDataUrl());
    }
  }

  #unitDataUrl() {
    const { lng, lat } = this.map.getBounds().getCenter();
    return this.#unitDataUrlForLngLat(lng, lat);
  }

  #unitDataUrlForLngLat(lng, lat) {
    // Round the coordinates to get more cache hits
    const roundedLng = this.#roundBy(lng, this.lnglatStep).toFixed(this.lnglatDecimals);
    const roundedLat = this.#roundBy(lat, this.lnglatStep).toFixed(this.lnglatDecimals);

    // Remove the zoom param to get more cache hits
    const { zoom, ...params } = paramsGet();

    const queryString = QueryString.stringify(
      { ...params, lng: roundedLng, lat: roundedLat },
      { arrayFormat: "bracket" },
    );
    return `/browse/units?${queryString}`;
  }

  #roundBy(n, increment) {
    return Math.round(n / increment) * increment;
  }

  /*** MARKETS SOURCE ***/

  #updateMarketsSource() {
    const marketSource = this.map.getSource(BrowseMap.MARKET_SOURCE_ID);
    if (marketSource) {
      marketSource.setData(this.#marketDataUrl());
    }
  }

  #marketDataUrl() {
    const { lng, lat, zoom, sort, ...params } = paramsGet();
    const queryStringWithoutMapParams = QueryString.stringify(params, { arrayFormat: "bracket" });
    return `/browse/markets?${queryStringWithoutMapParams}`;
  }

  #getUniqueFeatures(lat, lng) {
    const projectedPoint = this.map.project([lng, lat]);
    const features = this.map.queryRenderedFeatures(projectedPoint, { layers: [BrowseMap.UNIT_CIRCLE_LAYER_ID] });
    const uniqueIds = new Set();
    const uniqueFeatures = [];
    for (const feature of features) {
      const unitId = feature.properties.id;
      if (!unitId) continue; // continue unless it's a unit "circle" (e.g. not a cluster)
      if (!uniqueIds.has(unitId)) {
        uniqueIds.add(unitId);
        uniqueFeatures.push(feature);
      }
    }
    return uniqueFeatures;
  }

  /*** UNIT MODAL HISTORY EVENTS ***/

  #handleCloseUnitModal(event) {
    const element = event.detail.element;
    if (element.querySelector("#browse-unit-modal") == null) return false;
    if (hasParam("unit_id")) paramsRemove("unit_id", false);
  }
  e;
}

export default BrowseMap;
