import AudiencesController from "../shared/audiences_controller";

export default class extends AudiencesController {
  async handleHeatmapTurnedOn(event) {
    const {
      detail: { scorePersonaIds, token },
    } = event;
    const personaIdsParam = scorePersonaIds.join(",");
    const response = await fetch(
      `/campaigns/${token}/audience_scores?` +
        new URLSearchParams({
          score_persona_ids: personaIdsParam,
        }),
    );
    const scores = (await response?.json()) || [];
    this.showReferences();
    window.dispatchEvent(new CustomEvent("adquick:audiences:showheatmap", { detail: { scores } }));
  }
}
