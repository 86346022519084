import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { isAdmin: Boolean };
  static targets = ["query", "submit"];

  initialize() {
    this.buffer = [];
    this.lastKeyTime = Date.now();
  }

  async keyup(event) {
    if (!event.key) return;

    const key = event.key.toLowerCase();
    if (!this.isAdminValue || key != "f") return;

    const currentTime = Date.now();
    if (currentTime - this.lastKeyTime > 500) this.buffer = [];
    this.buffer.push(key);
    this.lastKeyTime = currentTime;
    if (this.buffer.join("") == "fff") {
      await this.stimulate("ModalReflex#load_modal_component", { component: "modal/fast_find_component" });
      return window.modal.open();
    }
  }

  onEnterText() {
    const key = event.key.toLowerCase();
    if (this.queryTarget.value.length >= 3) {
      this.submitTarget.disabled = false;
      if (key == "enter") this.find();
    } else {
      this.submitTarget.disabled = true;
    }
  }

  find() {
    this.submitTarget.disabled = true;
    window.location.href = `/admin/users?q=${this.queryTarget.value}&fast_find=true`;
  }

  cancel() {
    return window.modal.close();
  }
}
