export default class UnitLayerTooltip {
  constructor(map, layer, tooltipController, { leafIdentifier = "unitId" } = {}) {
    this.tooltipController = tooltipController;
    this.leafIdentifier = leafIdentifier || "unitId";

    const handleMouseEnter = this.#handleMouseEnter.bind(this);
    const handleMouseLeave = this.#handleMouseLeave.bind(this);

    map.on("mouseenter", layer, handleMouseEnter);
    map.on("mouseleave", layer, handleMouseLeave);
    map.on("move", layer, handleMouseLeave);
    map.on("zoom", layer, handleMouseLeave);
  }

  #handleMouseEnter(event) {
    const map = event.target;
    const unitId = event.features[0]?.properties?.id;
    const coordinates = event.features[0]?.geometry?.coordinates;

    if (map && unitId && coordinates) {
      map.getCanvas().style.cursor = "pointer";
      this.tooltipController.showUnitTooltip({ [this.leafIdentifier]: unitId, coordinates });
    }
  }

  #handleMouseLeave(event) {
    const map = event.target;
    map.getCanvas().style.cursor = "";
    this.tooltipController.hideUnitTooltip();
  }
}
