export const PRIMARY_COLOR = [212, 35, 193, 230];
export const NEUTRO_COLOR = [133, 133, 133, 230];
export const ACCENT_COLOR = [145, 24, 132, 230];
export const BORDER_COLOR = [255, 255, 255, 255];
export const HIDE_COLOR = [255, 255, 255, 0];

export const ICON_MAPPING = {
  UNIT: { fill: ACCENT_COLOR, border: BORDER_COLOR },
  REGULAR: { fill: PRIMARY_COLOR, border: BORDER_COLOR },
  INACTIVE: { fill: NEUTRO_COLOR, border: BORDER_COLOR },
  HIDE: { fill: HIDE_COLOR, border: HIDE_COLOR },
};

export const MAX_BORDER_ZOOM = 10;
export const MAX_RADIUS_SIZE = 2000;

export const ZOOM_TO_RADIUS_MAP = {
  2: [1, 0],
  3: [1, 0],
  4: [1, 1],
  5: [2, 1],
  6: [2, 2],
  7: [2, 2],
  8: [3, 2],
  9: [3, 2],
  10: [5, 2],
  11: [8, 2],
  12: [10, 2],
  13: [10, 2],
  14: [10, 2],
  15: [10, 2],
  16: [12, 2],
  17: [12, 2],
  18: [30, 1],
};
