import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  get defaultIndexAction() {
    return this._defaultIndexReflexAction;
  }

  get base() {
    return this._base;
  }

  getControllerForElement(el, identifier) {
    return this.application.getControllerForElementAndIdentifier(el, identifier);
  }

  connected() {
    const baseElement = document.getElementById("creatives-page");
    const baseKey = "campaign-creatives-base";
    this._base = this.application.getControllerForElementAndIdentifier(baseElement, baseKey);
    this._defaultIndexReflexAction = baseElement.dataset.defaultIndexAction || "Plus::Campaigns::CreativesReflex";
    this.afterConnected();
  }

  afterConnected() {}

  resetPagination() {
    if (this.hasFormValue) return;

    this.paramsAdd("page", 1);
  }

  async processResponseSuccess(response) {
    const body = await response.json();
    return this.handleToaster(body, "success");
  }

  async processResponseError(response) {
    const body = await response.json();
    return this.handleToaster(body, "error");
  }

  async handleToaster({ message }, toasterType) {
    if (!message) {
      return;
    }
    await this.stimulate("UI::ToastReflex#show", toasterType, message);
    return await this.stimulate(`${this.defaultIndexAction}#index`);
  }
}
