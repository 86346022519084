import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  submit(event) {
    event.preventDefault();
    const form = event.target;
    const campaignId = form.campaign_id.value;
    const unitIds = window.adquick?.browse?.selected_unit_ids;

    if (campaignId && unitIds) {
      this.stimulate("Pro::Browse::AddUnitsReflex#submit_modal", campaignId, unitIds);

      // Clear the selected_unit_ids set from browse_controller.js to start clean on the next time the user selects some units
      window.adquick.browse.selected_unit_ids = null;
    }
  }

  handleAddSingleUnit(event) {
    const { unitId, campaignId } = event.detail;
    this.stimulate("Pro::Browse::AddUnitsReflex#submit_modal", campaignId, [unitId]);
  }

  handleCreateNewCampaign() {
    this.stimulate("Pro::Browse::AddUnitsReflex#create_campaign");
  }
}
