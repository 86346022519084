import ApplicationController from "./application_controller";

/*
How to use:
- Add a 'expandable' controller on the parent div.
- Inside the parent div, you can have regular divs that are shown regardless of the element being expanded or collapsed.
- Add a 'expandedContent' target on elements that are shown only when the state is expanded.
- Add a 'collapsedContent' target on elements that are shown only when the state is collapsed.
- Add a `toggle` action on buttons or elements that toggle the content.
*/
export default class extends ApplicationController {
  static targets = ["collapsedContent", "expandedContent"];

  static values = {
    expanded: Boolean,
  };

  connect() {
    this.expanded = this.expandedValue ? true : false;
    this.changeVisibility();
  }

  toggle() {
    this.expanded = !this.expanded;
    this.changeVisibility();
  }

  changeVisibility() {
    this.collapsedContentTargets.forEach(target => (target.hidden = this.expanded));
    this.expandedContentTargets.forEach(target => (target.hidden = !this.expanded));
  }
}
