import ApplicationController from "../application_controller";

// TODO: this isn't browse-specific, so move to a different folder
export default class extends ApplicationController {
  static values = {
    reflex: String,
    campaignId: Number,
    campaignBooked: Boolean,
  };

  static targets = ["popup", "search"];

  initialize() {
    this.showFaceIdFilter = true;
  }

  connected() {
    this.#handleBookedCampaigns();
    this.setDefaultSearchInput();
  }

  #handleBookedCampaigns() {
    const filter = "booking_status";
    const filterIsntBeingUsed = this.params[filter] == undefined;
    const option = "all_booked";

    if (this.campaignBookedValue && filterIsntBeingUsed) {
      _.debounce(this.#applySingleSelectOption.bind(this), 1000)(filter, option);
    }
  }

  handleMouseDown(event) {
    if (this.#isClickOutside(event)) this.#hideAllPopups();
  }

  handleClearAll(event) {
    event.preventDefault();
    this.paramsClear();
    this.#hideAllPopups();
    this.#refreshUI();
  }

  // The target element must have an attribute data-filter-key that names the corresponding URL param to be added/removed.
  // It can be a single key or multiple, e.g.: "media_types" or "min_price,max_price".
  handleClearFilter(event) {
    event.preventDefault();
    const { filterKey } = event.currentTarget.dataset;
    filterKey.split(",").forEach(filterKey => this.paramsRemove(filterKey));
    this.#refreshUI(); // server-side-render the un-highlighted buttons
  }

  handleClickButton(event) {
    const { popupKey } = event.currentTarget.dataset;
    this.#togglePopupVisibility(popupKey);
  }

  handleOptionSearchInput(event) {
    this.searchTerm = event.currentTarget.value;
    this.#refreshUIDebounced(); // debounce to wait for additional key strokes
  }

  handleExcludeInclude(event) {
    const toggleValue = event.currentTarget.value === "true";
    const filterKey = event.currentTarget.dataset.filterKey;
    this.#applyCheckboxDebounced(filterKey, toggleValue);
  }

  handleAndOr(event) {
    const toggleValue = event.currentTarget.value === "true";
    const filterKey = event.currentTarget.dataset.filterKey;
    this.#applyCheckboxDebounced(filterKey, toggleValue);
  }

  handleInput(event) {
    const {
      value,
      dataset: { filterKey },
    } = event.currentTarget;
    this.#applyInputDebounced(filterKey, value); // debounce to wait for additional key strokes
  }

  handleChangeCheckbox(event) {
    const {
      checked,
      dataset: { filterKey, lastInteraction },
    } = event.currentTarget;
    this.#applyCheckboxDebounced(filterKey, checked, lastInteraction); // debounce to wait for additional check boxes
  }

  handleClickSingleSelectOption(event) {
    const { filterKey, optionKey } = event.currentTarget.dataset;
    this.#applySingleSelectOption(filterKey, optionKey);
  }

  handleClickMultiSelectOption(event) {
    const { filterKey, optionKey } = event.currentTarget.dataset;
    this.#applyMultiSelectOption(filterKey, optionKey);
  }

  handleClickBooleanSelectOption(event) {
    const { filterKey, optionKey } = event.currentTarget.dataset;
    this.#applyCheckboxDebounced(filterKey, optionKey == "true");
  }

  handleClickSearchableMultiSelectOption(event) {
    const { filterKey, optionKey } = event.currentTarget.dataset;
    this.searchTerm = this.#findSearchTarget(filterKey)?.value;
    this.#applyMultiSelectOption(filterKey, optionKey);
  }

  handleClickMultiTagOption(event) {
    const { filterKey, optionKey } = event.currentTarget.dataset;
    this.#applyMultiSelectOption(filterKey, optionKey, false); // false to not morph filters bar
  }

  handleSearchInputChange(event) {
    if (this.showFaceIdFilter) {
      this.hideFaceIdFilter();
      this.showFaceIdFilter = false;
    } else {
      this.hideTabPanelFilter();
      this.showFaceIdFilter = true;
    }
  }

  hideFaceIdFilter() {
    document.getElementById('face_id_filter').style.display = 'none';
    document.getElementById('geopath_filter').style.display = 'block';
    this.showFaceIdFilter = false;
  }

  hideTabPanelFilter() {
    document.getElementById('face_id_filter').style.display = 'block';
    document.getElementById('geopath_filter').style.display = 'none';
    this.showFaceIdFilter = true;
  }

  setDefaultSearchInput() {
    const parentInputChildren = document.getElementById('search_select');
    const faceInput = Array.from(parentInputChildren).find(x=> x.value == 'face_id');
    if (faceInput && faceInput.selected) {
      this.hideTabPanelFilter();
    } else {
      this.hideFaceIdFilter();
    }
  }

  afterReflex(e) {
    this.setDefaultSearchInput();
  }

  #togglePopupVisibility(popupKey) {
    this.searchTerm = null;
    const popup = this.popupTargets.find(p => p.dataset.popupKey == popupKey);
    if (popup) {
      this.openedPopupKey = null;
      popup.remove();
    } else {
      this.openedPopupKey = popupKey;
      this.#refreshUI();
    }
  }

  #hideAllPopups() {
    this.searchTerm = null;
    this.openedPopupKey = null;
    this.popupTargets.forEach(popup => {
      popup.remove();
    });
  }

  #isClickOutside(event) {
    const isClickOutsidePopup = !event.target.closest(".filter_popup");
    const isClickOutsideButton = !event.target.closest(".filter_button");
    return isClickOutsidePopup && isClickOutsideButton;
  }

  #applyInput(filterKey, value) {
    if (value) {
      this.paramsAdd(filterKey, value);
    } else {
      this.paramsRemove(filterKey);
    }
    this.#refreshUI();
  }

  #applyInputDebounced = _.debounce(this.#applyInput.bind(this), 500);

  #applyCheckbox(filterKey, checked, lastInteraction) {
    if (checked) {
      this.paramsAdd(filterKey, true);
    } else {
      this.paramsRemove(filterKey);
    }
    this.#refreshUI(lastInteraction);
  }

  #applyCheckboxDebounced = _.debounce(this.#applyCheckbox.bind(this), 500);

  #applySingleSelectOption(filterKey, optionKey) {
    if (this.params[filterKey] == optionKey) {
      this.paramsRemove(filterKey);
    } else {
      this.paramsAdd(filterKey, optionKey);
    }
    this.#refreshUI();
  }

  #applyMultiSelectOption(filterKey, optionKey, refreshUI = true) {
    if (this.params[filterKey]?.includes(optionKey)) {
      this.paramsRemoveFromArray(filterKey, optionKey);
    } else {
      this.paramsAddToArray(filterKey, optionKey);
    }
    if (refreshUI) this.#refreshUI();
  }

  // TODO: implement clear button for option search
  #clearOptionSearch(filterKey) {
    const searchTarget = this.#findSearchTarget(filterKey);
    if (searchTarget) searchTarget.value = null;
    this.searchTerm = null;
  }

  #findSearchTarget(filterKey) {
    return this.searchTargets.find(t => t.dataset.filterKey == filterKey);
  }

  // server-side-render the selected option/button
  #refreshUI(lastInteraction = null) {
    this.stimulate(this.reflexValue, {
      campaign_id: this.campaignIdValue,
      opened_popup: this.openedPopupKey,
      search_term: this.searchTerm,
      last_interaction: lastInteraction,
    });
  }

  #refreshUIDebounced = _.debounce(this.#refreshUI.bind(this), 500);
}
