import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["form"];

  static values = {
    campaignToken: String,
    shouldSignAuth: Boolean,
    payment: String,
  };

  connected() {}

  async medaiAuthSubmit() {
    try {
      const response = await $.ajax({
        type: "POST",
        url: `/api/v1/media_authorizations`,
      });
      const { signature_request_url } = response;
      this.closeLoadingModal();
      this.openMediaAuthHelloSign(signature_request_url);
    } catch (error) {
      this.closeLoadingModal();
      alert("Error submitting media auth");
      throw error;
    }
  }

  openMediaAuthHelloSign(signature_request_url) {
    const localFormTarget = this.formTarget;
    HelloSign.init(AppConfig.helloSignClientId);
    HelloSign.open({
      skipDomainVerification: AppConfig.helloSignTestMode,
      url: signature_request_url,
      allowCancel: true,
      container: this.helloSignRef,
      messageListener(data) {
        if (data.event == "signature_request_signed") {
          HelloSign.close();
          localFormTarget.submit();
        }
      },
    });
  }

  async onBillingSubmit(e) {
    e.preventDefault();
    if (!this.formTarget.checkValidity()) {
      this.formTarget.reportValidity();
      return;
    }
    const should_sign = this.shouldSignAuthValue;
    if (should_sign) {
      this.openLoadingModal();
      return this.medaiAuthSubmit();
    } else {
      this.closeLoadingModal();
      return this.formTarget.submit();
    }
  }

  openLoadingModal() {
    var element = document.getElementById("loading-modal");
    element.classList.remove("hide");
    element.classList.add("open");
  }

  closeLoadingModal() {
    var element = document.getElementById("loading-modal");
    element.classList.remove("open");
    element.classList.add("hide");
  }
}
