export default class PoliticalHeatmapLayer {
  constructor(map, layerId, beforeLayerId) {
    this.map = map;
    this.layerId = layerId;
    this.source = "2020-elections";
    this.sourceLayer = "precints";
    this.addLayer(map, layerId, beforeLayerId);
    this.hideLayers();
  }

  addLayer(map, layerId, beforeLayerId) {
    map.addSource(this.source, {
      type: "vector",
      url: "mapbox://fahimf.2020_elections_v1",
    });

    map.addLayer(
      {
        id: layerId,
        type: "fill",
        source: this.source,
        "source-layer": this.sourceLayer,
        paint: {
          "fill-color": [
            "case",
            ["<", ["get", "pct_dem_lead"], 0], "red",
            [">", ["get", "pct_dem_lead"], 0], "blue",
            "gray"
          ],
          // Fill opacity is a function of the absolute value of the percentage lead
          "fill-opacity": ["+",
            0.2,
            ["*",
              0.5,
              ["/",
                ["abs", ["coalesce", ["get", "pct_dem_lead"], 0]],
                100
              ]
            ]
          ]
        },
      },
      beforeLayerId,
    );

    // Create a tooltip element
    const tooltip = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    // Show tooltip on hover
    map.on('mousemove', layerId, function (e) {
      map.getCanvas().style.cursor = 'pointer'; // Change cursor to pointer

      // Get the feature properties
      const properties = e.features[0].properties;
      const content = `
          <div class="map-tooltip">
              <strong>Precint</strong>: ${properties['GEOID']}<br>
              <strong>Total Votes</strong>: ${properties['votes_total']}<br>
              <strong>Rep Votes</strong>: ${properties['votes_rep']}<br>
              <strong>Dem Votes</strong>: ${properties['votes_dem']}<br>
              <strong>Dem lead by</strong>: ${properties['pct_dem_lead']}%<br>
          </div>
      `;

      // Set the tooltip location and content
      tooltip.setLngLat(e.lngLat).setHTML(content).addTo(map);
    });

    // Hide tooltip when not hovering
    map.on('mouseleave', layerId, function () {
      map.getCanvas().style.cursor = ''; // Reset cursor
      tooltip.remove(); // Remove the tooltip
    });
  }

  addEventListeners() {
    window.addEventListener("adquick:audiences:showheatmap", this.#handleShowAudiencesHeatmap.bind(this));
    window.addEventListener("adquick:audiences:hideheatmap", this.#handleHideAudiencesHeatmap.bind(this));
  }

  #handleShowAudiencesHeatmap(event) {
    const {
      detail: { scores },
    } = event;
    this.updateLayer(scores);
  }

  #handleHideAudiencesHeatmap(_e) {
    this.hideLayers();
  }

  hideLayers() {
    this.map.setLayoutProperty(`${this.layerId}`, "visibility", "none");
  }

  showLayers() {
    this.map.setLayoutProperty(`${this.layerId}`, "visibility", "visible");
  }
}
