import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static values = {
    campaignToken: String,
    contractToken: String,
    note: String,
    currentUserId: Number,
    contractSignerIds: Array,
    contractTemplateId: Number,
    templateId: Number,
    redirect: String,
  };

  static targets = ["mediaOffer", "totalEditInput"];

  parseDefaultNumberValue(value) {
    if (value == 0) {
      return null;
    }
    return value;
  }

  connected() {
    this.negotiationController = this.application.getControllerForElementAndIdentifier(
      this.element,
      "vendor-negotiation",
    );
  }

  allFilledCounterOfferElements() {
    return this.mediaOfferTargets.filter(el => el.value != "");
  }

  getItemTokenFromElement({ dataset }) {
    return dataset.itemToken;
  }

  getMediaOfferFromElement({ value }) {
    return value;
  }

  buildSupplierProposalsPayload() {
    return this.allFilledCounterOfferElements().reduce((acc, el) => {
      acc.push({
        token: this.getItemTokenFromElement(el),
        media_offer: this.getMediaOfferFromElement(el),
      });
      return acc;
    }, []);
  }

  modalPayload() {
    return {
      component: "modal/vendor_counteroffer",
      component_params: {
        campaign_token: this.campaignTokenValue,
        current_user_id: this.currentUserIdValue,
        contract_token: this.contractTokenValue,
        supplier_proposals: this.buildSupplierProposalsPayload(),
      },
    };
  }

  async openSendCounterofferModal() {
    await this.stimulate("ModalReflex#load_modal_component", this.modalPayload());
    window.modal.open();
  }

  async openConfirmMassUpdateModal(e) {
    const {
      target: { dataset },
    } = e;
    const { submitUrl } = dataset;
    const payload = {
      component: "modal/vendor_confirmation",
      component_params: {
        campaign_token: this.campaignTokenValue,
        current_user_id: this.currentUserIdValue,
        contract_token: this.contractTokenValue,
        total: this.totalEditInputTarget.value,
        item_tokens: [],
        message:
          "Editing the total will proportionally add or substract from the cost of all units to meet your desired total.",
        skip_form_submit: true,
        submit_route: submitUrl,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
    this.negotiationController.toggleTotalShow();
  }

  async approveNegotiation() {
    const payload = {
      token: this.contractTokenValue,
      signer_ids: this.contractSignerIdsValue,
      contract_template_id: this.parseDefaultNumberValue(this.contractTemplateIdValue),
      template_id: this.parseDefaultNumberValue(this.templateIdValue),
      note: this.noteValue,
    };
    await this.stimulate(`Plus::Campaigns::VendorContractsReflex#send_contract`, payload);
    window.modal.close();
    location.assign(this.redirectValue);
  }
}
