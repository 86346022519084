import ApplicationController from "../application_controller";
import { del } from "../../../../../../adquick-classic/src/marketplace/utils/api";
import dayjs from "dayjs";

export default class extends ApplicationController {
  static targets = ["form", "startDate", "endDate", "addOnId"];

  async connected() {
    $(this.formTarget).on("ajax:success", function(event, data, status, xhr) {
      document.location.reload();
    });
  }

  onDateRangeChange(e) {
    const dates = JSON.parse(e.currentTarget.dataset.value);
    this.buildDates(dates);
  }

  buildDates(dates) {
    const startDate = dates[0];
    const endDate = dates[1];
    this.startDateTarget.value = !!startDate ? this.formatDate(startDate) : null;
    this.endDateTarget.value = !!endDate ? this.formatDate(endDate) : null;
  }

  formatDate(dateString) {
    return dayjs(dateString).format("M/D/YYYY");
  }

  async onDeleteAddOn(e) {
    const addOnId = e.currentTarget.dataset.id;
    const campaignId = e.currentTarget.dataset.campaignId;
    const check = confirm("Are you sure you want to delete this add-on?");

    if (!check) {
      return window.modal.close();
    }
    try {
      const response = await del(`/campaigns/${campaignId}/add_ons/${addOnId}`);
      return document.location.reload();
    } catch (error) {
      await this.stimulate("UI::ToastReflex#show", "error", "We were unable to delete this add-on. Please try again.");
      return window.modal.close();
    }
  }
}
