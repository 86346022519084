import ApplicationController from "./../../application_controller";
import dayjs from "dayjs";
import Handsontable from "handsontable";
import _ from "lodash";

export default class extends ApplicationController {
  static targets = ["form", "unitDateRange", "startDate", "endDate", "lat", "lon", "grid", "search"];

  static values = { unitId: String, campaignUnitToken: String, templateId: Number };

  initialize() {
    this.removedRows = [];
  }

  async connected() {
    const event = new Event("packageModalOpened");
    document.dispatchEvent(event);
    // TODO use friendly_id instead of the ID for consistency with other unit endpoints
    try {
      const unitId = this.gridTarget.dataset["packageModalUnitIdValue"];
      const campaignId = this.gridTarget.dataset["packageModalCampaignIdValue"];
      const campaignUnitToken = this.campaignUnitTokenValue;
      this.grid = new Handsontable(this.gridTarget, this.tableProps());
      this.setupSearch(this.searchTarget, this.grid);
      this.grid.addHook("beforeRemoveRow", (index, amount, rows) => {
        this.beforeRemoveHook(rows);
      });
      this.grid.addHook("beforeKeyDown", event => {
        this.onBeforeKeyDown(event);
      });
      if (campaignUnitToken !== undefined) {
        await this.loadGridData(this.grid, campaignUnitToken, campaignId, this.templateIdValue);
      }
    } catch (error) {
      console.log("Children functionality is disabled.");
      console.error(error);
    }
    window.addEventListener("tsc:tab-change", () => {
      this.grid.validateCells();
      this.grid.render();
    });
  }

  beforeRemoveHook(rows) {
    rows.map(row => {
      const rowData = this.grid.getDataAtRowProp(row, "id");
      if (rowData == null) return;
      this.removedRows.push(rowData);
    });
  }

  onBeforeKeyDown(event) {
    if (event.keyCode !== 46) return;

    const selectedRange = this.grid.getSelectedRange();
    // Only triggers the row deletion if the user selected all the columns, otherwise simply run the normal `delete` event and just remove the selected cells contents
    const rowsToRemove = selectedRange
      .map(r => {
        const firstCol = r.from.col;
        const lastCol = r.to.col;
        const totalColumnSize = this.tableProps().columns.length;
        if (lastCol + 1 - firstCol == totalColumnSize) {
          // check to see if the selected columns match the total columns on the grid
          return { start: r.from.row, end: r.to.row };
        }
      })
      .filter(r => r !== undefined);
    if (rowsToRemove.length > 0) {
      const ranges = rowsToRemove.map(r => this.range(r.start, r.end));
      if (window.confirm("Are you sure you want to remove the highlighted rows?")) {
        event.stopImmediatePropagation();
        event.preventDefault();
        // [r, 1] means starting from row `r` delete 1 row
        // this is to make the handling of non contiguous selections easier
        const reverseOrdered = ranges
          .flatMap(r => r)
          .reverse()
          .map(r => [r, 1]);
        this.grid.alter("remove_row", reverseOrdered);
        this.grid.deselectCell();
      }
    }
  }

  range(start, end) {
    return Array.from(new Array(end - start + 1).keys()).map(num => {
      return num + start;
    });
  }

  async loadGridData(grid, campaignUnitToken, campaignId, templateId) {
    if (!campaignId && !templateId) {
      return;
    }

    let response;

    if (campaignId) {
      response = await fetch(`/api/v1/campaigns/${campaignId}/package_units/${campaignUnitToken}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
    }

    if (templateId) {
      response = await fetch(`/api/v1/package_templates/${templateId}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
    }
    const body = await response.json();
    await grid.loadData(body.unit.campaign_package_children);
    return await grid.alter("insert_row");
  }

  numberFields() {
    return [
      "unit[price]",
      "unit[rate_card_price]",
      "unit[installation_cost]",
      "unit[production_cost]",
      "unit[total_spots]",
      "unit[spot_length]",
      "unit[impressions]",
      "unit[lat]",
      "unit[lon]",
      "unit[poi_distance_in_miles]",
    ];
  }

  appendRemovedToForm(removedIds, formData) {
    removedIds.map(id => {
      formData.append(`unit[removed_children][]`, id);
    });
  }

  async onSubmit() {
    var formData = new FormData(this.formTarget);
    try {
      const data = this.grid.getSourceData();
      const dataWithoutEmptyRows = data.filter(d => !Object.values(d).every(d => d == null));
      dataWithoutEmptyRows.map((data, index) => {
        Object.entries(data).map(entry => {
          formData.append(`unit[package_child][][${entry[0]}]`, entry[1]);
        });
        formData.append(`unit[package_child][][row_number]`, index);
      });
      this.appendRemovedToForm(this.removedRows, formData);
      if (!this.validateFields()) {
        return alert("Validation error on overview section, please navigate to Package Overview to check the errors.");
      }
    } catch (error) {
      console.log("Children grid is disabled, submiting only the overview form");
      console.error(error);
    }
    const response = await fetch(this.formTarget.action, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "text/html,application/xhtml+xml,application/xml",
      },
    });

    if (response.status == 200) {
      document.location.reload();
      return;
    }
    const responseBody = await response.json();
    const commentsPlugin = this.grid.getPlugin("comments");
    const errors = responseBody.children_errors;
    errors.map(e => {
      Object.entries(e.errors).map(entry => {
        this.grid.setCellMeta(e.row, this.grid.propToCol(entry[0]), "valid", false);
        commentsPlugin.setCommentAtCell(e.row, this.grid.propToCol(entry[0]), entry[1]);
      });
    });
    this.grid.render();
    alert("Error updating package, check package data an grid data");
  }

  setupSearch(searchInput, grid) {
    Handsontable.dom.addEvent(searchInput, "keyup", function(event) {
      var search = grid.getPlugin("search");
      var queryResult = search.query(this.value);
      grid.render();
    });
  }

  tableProps() {
    const type = this.gridTarget.dataset["packageModalTypeValue"];
    let columns;
    let headers;
    if (type === "rfp") {
      columns = this.rfpColumns();
      headers = this.rfpHeaders();
    } else {
      columns = this.campaignColumns();
      headers = this.campaignHeaders();
    }

    const schema = columns.reduce((acc, curr) => {
      const schemaField = { [curr.data]: null };
      return Object.assign({}, acc, schemaField);
    }, {});
    return {
      colHeaders: headers,
      minRows: 5,
      minSpareRows: 1,
      width: this.element.offsetWidth,
      colWidths: 200,
      width: "100%",
      height: 420,
      columnSorting: false,
      autoWrapRow: false,
      autoWrapCol: false,
      rowHeaders: true,
      comments: true,
      renderAllRows: false,
      stretchH: "all",
      contextMenu: true,
      columns: columns,
      dataSchema: schema,
      filters: true,
      startRows: 10,
      dropdownMenu: true,
      search: true,
      contextMenu: ["cut", "copy", "row_below", "remove_row"],
    };
  }

  campaignColumns() {
    return [
      {
        data: "face_id",
      },
      {
        data: "tab_panel_id",
      },
      {
        data: "lat",
        type: "numeric",
      },
      {
        data: "lon",
        type: "numeric",
      },
      {
        data: "poi_distance_in_miles",
        type: "numeric",
      },
      {
        data: "address",
      },
      {
        data: "vendor",
      },
      {
        data: "size",
      },
      {
        data: "screen_type",
        type: "dropdown",
        source: ["digital", "static"],
      },
      {
        data: "media_type",
        type: "dropdown",
        source: [
          "billboard",
          "street_furniture",
          "transit",
          "wallscape",
          "wildposting",
          "retail",
          "alternative",
          "airport",
          "retail/venues",
        ],
      },
      {
        data: "impressions",
        type: "numeric",
      },
      {
        data: "start_date",
        type: "date",
        dateFormat: "MM/DD/YYYY",
      },
      {
        data: "end_date",
        type: "date",
        dateFormat: "MM/DD/YYYY",
      },
      {
        data: "post_date",
        type: "date",
        dateFormat: "MM/DD/YYYY",
      },
      {
        data: "takedown_date",
        type: "date",
        dateFormat: "MM/DD/YYYY",
      },
      {
        data: "id",
        readOnly: true,
      },
    ];
  }

  campaignHeaders() {
    return [
      "Face Id",
      "Geopath Id",
      "Lat",
      "Lon",
      "Distance from POI (in miles)",
      "Address",
      "Vendor",
      "Size",
      "Screen Type",
      "Media Type",
      "Impressions",
      "Start Date",
      "End Date",
      "Post Date",
      "Takedown Date",
      "Id",
    ];
  }

  rfpColumns() {
    return [
      {
        data: "face_id",
      },
      {
        data: "tab_panel_id",
      },
      {
        data: "lat",
        type: "numeric",
      },
      {
        data: "lon",
        type: "numeric",
      },
      {
        data: "size",
      },
      {
        data: "screen_type",
        type: "dropdown",
        source: ["digital", "static"],
      },
      {
        data: "media_type",
        type: "dropdown",
        source: [
          "billboard",
          "street_furniture",
          "transit",
          "wallscape",
          "wildposting",
          "retail",
          "alternative",
          "airport",
          "retail/venues",
        ],
      },
      {
        data: "id",
        readOnly: true,
      },
    ];
  }

  rfpHeaders() {
    return ["Face Id", "Geopath Id", "Lat", "Lon", "Size", "Screen Type", "Media Type", "Id"];
  }

  validateFields() {
    const fields = [...this.formTarget.elements];
    fields.forEach(el => {
      this.validateEmptyField(el);
      this.validateLatLon(el);
      this.validateNumbers(el);
    });
    return fields.filter(el => el.classList.contains("has-error")).length == 0;
  }

  validateEmptyField(field) {
    if (!field.required) {
      return;
    }
    const isEmpty = !field.value;
    isEmpty && this.handleElementErrorModifier(field, "add", ["has-error", "has-required-error"]);
    !isEmpty && this.handleElementErrorModifier(field, "remove", ["has-error", "has-required-error"]);
  }

  validateLatLon(el) {
    const isLat = el.name == "unit[lat]";
    const isLon = el.name == "unit[lon]";
    if (isLat) {
      return this.validateLat(el);
    }
    if (isLon) {
      return this.validateLon(el);
    }
  }

  validateLat(el) {
    const float = parseFloat(el.value);
    if (float < -90 || float > 90) {
      return this.handleElementErrorModifier(el, "add", ["has-error", "has-boundary-error"]);
    }
    if (float == 0) {
      return this.handleElementErrorModifier(el, "add", ["has-error", "has-zero-error"]);
    }
    return this.handleElementErrorModifier(el, "remove", ["has-error", "has-boundary-error", "has-zero-error"]);
  }

  validateLon(el) {
    const float = parseFloat(el.value);
    if (float < -180 || float > 180) {
      return this.handleElementErrorModifier(el, "add", ["has-error", "has-boundary-error"]);
    }
    if (float == 0) {
      return this.handleElementErrorModifier(el, "add", ["has-error", "has-zero-error"]);
    }
    return this.handleElementErrorModifier(el, "remove", ["has-error", "has-boundary-error", "has-zero-error"]);
  }

  validateNumbers(el) {
    if (!this.numberFields().includes(el.name)) {
      return;
    }
    const float = parseFloat(el.value);
    const errorList = ["has-error", "has-number-error"];
    if (!el.value && !el.required) {
      return this.handleElementErrorModifier(el, "remove", errorList);
    }
    if (Number.isNaN(float)) {
      return this.handleElementErrorModifier(el, "add", errorList);
    }
    return this.handleElementErrorModifier(el, "remove", errorList);
  }

  handleElementErrorModifier(el, action, modifiers) {
    const actions = {
      add: el.classList.add,
      remove: el.classList.remove,
    };
    this.handleFormGroupModifier(el.parentElement, action, modifiers);
    actions[action].call(el.classList, ...modifiers);
  }

  handleFormGroupModifier(el, action, modifiers) {
    if (!el.classList.contains("form-group")) {
      return;
    }
    const withModifiers = modifiers.map(m => m.replace("has", "with"));
    this.handleElementErrorModifier(el, action, withModifiers);
  }

  onDateRangeChange(e) {
    const dates = JSON.parse(e.currentTarget.dataset.value);
    this.buildDates(dates);
  }

  buildDates(dates) {
    const startDate = dates[0];
    const endDate = dates[1];
    this.startDateTarget.value = !!startDate ? this.formatDate(startDate) : null;
    this.endDateTarget.value = !!endDate ? this.formatDate(endDate) : null;
  }

  formatDate(dateString) {
    return dayjs(dateString).format("M/D/YYYY");
  }

  onMarketChange(e) {
    const selectedMarketId = e.target.value;
    const selectedMarketOption = [...e.target.options].find(el => el.value == selectedMarketId);
    this.latTarget.value = selectedMarketOption.dataset["centerLat"];
    this.lonTarget.value = selectedMarketOption.dataset["centerLon"];
  }

  async requestChildrenUnits(e) {
    const target = e.target;
    target.classList.toggle("loading-shine");
    await this.stimulate("Pro::Campaigns::PackagesModalReflex#request_children_units", {
      campaign_unit_token: this.campaignUnitTokenValue,
      element_id: target.id,
    });
  }

  async sumPackageChildrenImpressions(e) {
    const {
      target: { checked },
    } = e;
    await this.stimulate("Pro::Campaigns::PackagesModalReflex#update_sum_impressions", {
      campaign_unit_token: this.campaignUnitTokenValue,
      sum_impressions: checked,
    });
  }

  async onUpdateData(e) {
    await this.stimulate("Pro::Campaigns::PackagesModalReflex#update_data", {
      campaign_unit_token: this.campaignUnitTokenValue,
    });
  }
}
