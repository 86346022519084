import ApplicationController from "../application_controller";
import numeral from "numeral";
import Chart from "chart.js/auto";

const CONVERSIONS_TRACK_ITEMS = {
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
        callback: function(value, index, array) {
          return numeral(value).format("0a");
        },
      },
    },
    x: {
      time: {
        // Luxon format string
        tooltipFormat: "DD T",
      },
    },
  },
  plugins: {
    title: {
      display: true,
      text: "History",
      align: "start",
      font: {
        size: 16,
      },
    },
    legend: {
      display: false,
    },
  },
  responsive: true,
};

export default class extends ApplicationController {
  static targets = ["chart", "filterType"];
  static values = {
    webConversions: Array,
    appConversions: Array,
    footfallConversions: Array,
    webDates: Array,
    appDates: Array,
    footfallDates: Array,
  };

  connect() {
    this.setChart();
    console.log("hello! connection made");
  }

  getData() {
    const consersionData = this.getConserionsValue();
    return {
      labels: consersionData.dates,
      datasets: [
        {
          label: consersionData.label,
          backgroundColor: "transparent",
          borderColor: consersionData.color,
          data: consersionData.data,
          legend: { display: false },
          tension: 0.4,
        },
      ],
    };
  }

  onFilterTypeChange(event) {
    this.setChart();
  }

  getConserionsValue() {
    if (this.filterTypeTarget.value == "web") {
      return {
        label: "Web Visits",
        data: this.webConversionsValue,
        dates: this.webDatesValue,
        color: "#29A495",
      };
    } else if (this.filterTypeTarget.value == "app") {
      return { label: "App Visits", data: this.appConversionsValue, dates: this.appDatesValue, color: "#462FD0" };
    } else if (this.filterTypeTarget.value == "footfall") {
      return {
        label: "Footfall Visits",
        data: this.footfallConversionsValue,
        dates: this.footfallDatesValue,
        color: "#3394D4",
      };
    }
  }

  setChart() {
    if (this.chart != null) {
      this.chart.destroy();
    }
    this.chart = new Chart(this.chartTarget.getContext("2d"), {
      type: "line",
      data: this.getData(),
      options: CONVERSIONS_TRACK_ITEMS,
    });
  }
}
