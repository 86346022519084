import { Controller } from "stimulus";
import { post } from "../../../../../adquick-classic/src/marketplace/utils/api";
import { get } from "../../../../../adquick-classic/src/marketplace/utils/api";
export class CampaignPopBulkActionController extends Controller {
  static targets = ["checkbox"];
  static values = {
    campaignToken: String,
    minEndDate: String,
  };
  connect() {
    StimulusReflex.register(this);
  }

  async triggerMissingPOPs(e) {
    e.preventDefault();
    if (this.campaignUnitTokens.length == 0) {
      alert("Please select at least one unit");
      return;
    }

    const campaignId = this.campaignTokenValue;

    const url = `/api/v1/campaigns/${campaignId}/proof_of_postings/trigger_missing_pop`;
    const payload = JSON.stringify({
      campaign_unit_tokens: this.campaignUnitTokens,
    });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const response = await fetch(url, { method: "POST", body: payload, headers: headers });
    if (response.ok) {
      this.stimulate("UI::ToastReflex#trigger_toast", "success", "Email sent");
    } else {
      const { message } = await response.json();
      this.stimulate(
        "UI::ToastReflex#trigger_toast",
        "error",
        `Error sending email: ${message}. Please contact the support team.`,
      );
    }
  }

  async triggerMissingTakeDownDates(e) {
    e.preventDefault();
    if (this.campaignUnitTokens.length == 0) {
      alert("Please select at least one unit");
      return;
    }

    const campaignId = this.campaignTokenValue;

    const payload = {
      campaign_unit_tokens: this.campaignUnitTokens,
    };
    try {
      await post(`/api/v1/campaigns/${campaignId}/proof_of_postings/trigger_missing_takedown_dates`, payload);
      this.stimulate(
        "UI::ToastReflex#trigger_toast",
        "success",
        "A missing take down notes email was trigger for the selected contacts",
      );
    } catch (error) {
      console.error(error);
      this.stimulate("UI::ToastReflex#trigger_toast", "error", "Error sending email, please contact the support team");
    }
  }

  async showTakedownDateModal(e) {
    e.preventDefault();
    if (this.campaignUnitTokens.length == 0) {
      alert("Please select at least one unit");
      return;
    }

    const payload = {
      component: "pro/unit/update_takedown_date_component",
      component_params: {
        campaign_id: this.campaignTokenValue,
        campaign_unit_tokens: this.campaignUnitTokens,
        min_end_date: this.minEndDateValue,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }

  async onTakeDownDateSuccess(e) {
    window.modal.close();
    const [jsonBody, _status, _event] = e.detail;
    // we must rely on the tokens replied by the api since on this instance of the controller we dont have the checkboxTargets
    // if we want dont want to rely on the backend response we can add data attributes to the inputs on the form app/components/pro/unit/update_takedown_date_component.haml
    // of just use the e.target to get the form data from the event we are handling
    const tokens = jsonBody.campaign_unit_ids;
    tokens.map(token => {
      this.stimulate("Pro::Unit::PopCardReflex#render_card", { campaign_unit_token: token });
    });
  }

  async displayError(e) {
    window.modal.close();
    this.stimulate("UI::ToastReflex#show", "error", "Error when performing action, please reach for support");
  }

  async downloadPops(e) {
    e.preventDefault();
    const campaignId = this.campaignTokenValue;

    const url = `/api/v1/campaigns/${campaignId}/proof_of_postings/download_zip`;
    const params = new URLSearchParams({ ids: this.popIds });
    const response = await get(`${url}?${params.toString()}`);
    const jobId = response.job_id;

    this.poolJob(
      jobId,
      false,
      "POP download finished",
      "POP download in progress",
      "POP download failed, please reach for support",
    );
    return jobId;
  }

  async downloadPostedUnitDetails(e) {
    e.preventDefault();
    if (this.campaignUnitTokens.length == 0) {
      alert("Please select at least one unit");
      return;
    }

    const endPoint = `/api/v1/campaigns/${this.campaignTokenValue}/campaign_units/export_posted_details`;
    const payload = {
      campaign_unit_tokens: this.campaignUnitTokens,
    };
    const { job_id } = await post(endPoint, payload);
    this.poolJob(job_id);
  }

  async downloadPopsFromApi(e) {
    e.preventDefault();
    e.target.classList.add("loading-shine");
    const endPoint = `/api/v1/campaigns/${this.campaignTokenValue}/design_assets/import_lamar_pops`;

    const { job_id, lamar_contracts, cco_contracts } = await post(endPoint);
    if (lamar_contracts.length == 0) {
      alert("No Lamar contracts found");
    }

    if (cco_contracts.length == 0) {
      alert("No CCO contracts found");
    }
    if (job_id == undefined) {
      return;
    }
    this.poolJob(
      job_id,
      true,
      "POP import API call finished",
      "POP import API call in progress",
      "POP API call failed, please reach for support",
    );
  }

  async poolJob(
    jobId,
    stimulateList = false,
    toastSuccessMessage = null,
    toastProgressMessage = null,
    toastErrorMessage = null,
  ) {
    this.stimulate("UI::ToastReflex#trigger_toast", "success", toastProgressMessage);
    const url = `/api/v1/job_progress/${jobId}`;
    // {"id":143,"job_id":"d9328ea6f286040e24427a1e","total_tasks":1,"completed_tasks":0,"failed_tasks":0,"data":null,"error":null,"is_completed":false}

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const response = await fetch(url, { headers: headers });
    const body = await response.json();
    if (!response.ok) {
      console.error("Something wrong when polling job");
      if (toastErrorMessage) {
        this.stimulate("UI::ToastReflex#trigger_toast", "error", toastErrorMessage);
      }
      return;
    }
    if (body == undefined) {
      setTimeout(() => {
        this.poolJob(jobId, stimulateList, toastSuccessMessage, toastProgressMessage, toastErrorMessage);
      }, 1000);
      return;
    }
    if (body.total_tasks == body.completed_tasks) {
      if (body.data.file_url) {
        window.open(body.data.file_url);
      }

      if (body.data.url) {
        window.open(body.data.url);
      }

      if (stimulateList) {
        await this.stimulate("Pro::Unit::PopCardReflex#render_card_list", {});
      }

      if (toastSuccessMessage) {
        this.stimulate("UI::ToastReflex#trigger_toast", "success", toastSuccessMessage);
      }
    }

    if (body.total_tasks > body.completed_tasks) {
      setTimeout(() => {
        this.poolJob(jobId, stimulateList, toastSuccessMessage, toastProgressMessage, toastErrorMessage);
      }, 1000);
    }
  }

  get popIds() {
    return this.checkboxTargets.filter(e => e.checked).map(e => e.dataset.popIds);
  }

  get campaignUnitids() {
    return this.checkboxTargets.filter(e => e.checked).map(e => e.value);
  }

  get campaignUnitTokens() {
    return this.checkboxTargets.filter(e => e.checked).map(e => e.dataset.token);
  }
}
