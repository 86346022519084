import ApplicationController from "../application_controller";
export default class extends ApplicationController {
  static targets = ["campaignToken", "unitId", "removalReason", "campaignUnitTokens"];

  static values = {
    useStimulate: Boolean,
    campaignUnitTokens: Array,
    refreshFilter: Boolean,
  };

  async connected() {}

  restoreZIndexAndClose() {
    const unitModal = document.getElementById("modal");
    if (unitModal) {
      unitModal.style.zIndex = "";
    }
    return window.modal.close();
  }

  async onRemoveUnit(e) {
    if (this.useStimulateValue) {
      e.preventDefault();
      return this.runStimulate();
    } else {
      e.preventDefault();
      const payload = {
        removal_reason: this.removalReasonTarget.value,
      };
      const campaignId = this.campaignTokenTarget.value;
      const unitId = this.unitIdTarget.value;
      // when calling api, need slugs
      const response = await $.ajax({
        type: "DELETE",
        url: `/api/v1/campaigns/${campaignId}/remove_unit/${unitId}`,
        data: payload,
      });
      if (response.errors) {
        alert("There was problem processing this request.");
      } else {
        // alert(`${response.removed_count} unit(s) successfully removed from this campaign.`);
        await this.stimulate("RemoveUnitsReflex#show_toast", response);
        this.restoreZIndexAndClose();
        window.afterRemoveUnit && window.afterRemoveUnit(campaignId);
        window.hideModal && window.hideModal();
        if (this.refreshFilterValue) {
          Rails.fire(document.getElementById("campaign_grid_data_search"), "submit");
        }
      }
    }
  }

  async runStimulate() {
    const payload = { campaign_unit_tokens: Array.from(this.campaignUnitTokensTargets).map(element => element.value) };
    await this.stimulate("Plus::Campaigns::ManageUnitsReflex#bulk_destroy", payload);
    window.modal.close();
  }
}
