import ApplicationController from "../application_controller";
import CampaignMap from "../../campaigns/CampaignMap";

export default class extends ApplicationController {
  static values = {
    showPrices: Boolean, // show unit prices in general; if false hide all prices
    showUnitPrice: Boolean, // specifically show units.price; if false show units.rate_card_price
    showSupplier: Boolean,
    showFullAccessModal: Boolean,
    hasPackageChildren: Boolean,
    campaignId: String,
    bounds: Array,
  };

  static targets = ["listViewButton", "mapViewButton"];

  connected() {
    this.campaignMap = new CampaignMap({
      campaignId: this.campaignIdValue,
      container: "map",
      accessToken: AppConfig.mapboxAccessToken,
      showPrices: this.showPricesValue,
      showUnitPrice: this.showUnitPriceValue,
      showSupplier: this.showSupplierValue,
      hasPackageChildren: this.hasPackageChildrenValue,
      bounds: this.boundsValue,
    });
    window.addEventListener("adquick:campaignmap:unitselect", this.#handleMapSelectUnits.bind(this));
  }

  showUnitsList(event) {
    event.preventDefault();

    this.element.classList.add("show-units-list");
  }

  hideUnitsList(event) {
    event.preventDefault();

    // if the user had scrolled the units list, we need to scroll to the top to show the market overview
    document.querySelector("#campaigns2 .sidebar").scrollTop = 0;
    this.element.classList.remove("show-units-list");
  }

  updateDataSources(event) {
    this.campaignMap.updatePoisSource()
    this.campaignMap.updateCustomBoundsSource()
  }

  handleCampaignUnitsUpdated(event) {
    this.campaignMap.updateUnitFeatures(event.detail)
  }

  updateUnitsSource(_event) {
    this.campaignMap.updateUnitsSource()
  }

  updatePoiSource(event) {
    this.campaignMap.updatePoisSource()
  }

  async #handleMapSelectUnits(event) {
    const selected_unit_ids = event.detail.unitIds;
    const selected_polygon = event.detail.polygonCoordinates;

    // if (!selected_unit_ids.length) return;

    // Store the select_unit_ids in the window object, so the upcoming modal can retrieve them (browse/add_units_controller.js).
    // p.s. We could theoretically send the unit ids as Reflex params, but the user can select a large number of units, so the request would be unnecessarily heavy.
    window.adquick = {
      ...window.adquick,
      browse: { ...window.adquick?.browse, selected_unit_ids },
    };

    const payload = {
      component: "pro/campaigns/drawer/menu_component",
      component_params: {
        campaign_id: this.campaignIdValue,
        unit_count: selected_unit_ids.length,
        selected_polygon: selected_polygon,
        selected_unit_slugs: selected_unit_ids
      },
    };
    await this.stimulate("DrawerReflex#load_drawer_component", payload);
    return window.drawer.open();
  }
}
