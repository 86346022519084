import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  toggleScreenType(_e) {
    const selectedScreenTypes = this.element.querySelectorAll('.screen-type-checkboxes:checked')
    let screenTypeValue;

    if (selectedScreenTypes.length === 2) {
      screenTypeValue = 'both';
    } else if (selectedScreenTypes.length === 1) {
      screenTypeValue = selectedScreenTypes[0].value
    } else {
      screenTypeValue = '';
    }

    this.element.querySelector('#campaign-screen-type-field').value = screenTypeValue;
  }

  toggleMediaType(e) {
    const checked = e.target.checked;
    const mediaTypeWrapper = e.target.closest('.media-type')

    if (checked) {
      mediaTypeWrapper.classList.add('selected');
      this.#selectSubtypes(mediaTypeWrapper);
    } else {
      mediaTypeWrapper.classList.remove('selected');
      this.#unselectSubtypes(mediaTypeWrapper);
    }
  }

  toggleMediaSubtype(e) {
    const checked = e.target.checked;
    const mediaSubtypeWrapper = e.target.parentElement;

    if (checked) {
      mediaSubtypeWrapper.classList.add('selected');
    } else {
      mediaSubtypeWrapper.classList.remove('selected');
    }
  }

  #selectSubtypes(mediaTypeWrapper) {
    mediaTypeWrapper.querySelectorAll('label').forEach(subtypeLabel => {
      subtypeLabel.classList.add('selected');
    });
    mediaTypeWrapper.querySelectorAll('.subtypes input').forEach(subtypeCheckbox => {
      subtypeCheckbox.checked = true;
    });
  }

  #unselectSubtypes(mediaTypeWrapper) {
    mediaTypeWrapper.querySelectorAll('label.selected').forEach(subtypeLabel => {
      subtypeLabel.classList.remove('selected');
    });
    mediaTypeWrapper.querySelectorAll('.subtypes input').forEach(subtypeCheckbox => {
      subtypeCheckbox.checked = false;
    });
  }
}
