import ApplicationController from "./application_controller";
import Swiper from "swiper/bundle";

export default class extends ApplicationController {
  connected() {
    this.swiper = new Swiper(this.element, this.defaultOptions);
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }

  get defaultOptions() {
    return {
      autoplay: {
        delay: 8000,
      },
      pagination: {
        el: ".swiper-pagination",
        renderBullet: this.renderPaginationBullet,
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };
  }

  renderPaginationBullet(index, className) {
    return `<span class=${className}></span>`;
  }
}
