import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["form", "startDate", "endDate", "unitDateRange"];

  checkDatesValidity() {
    const elements = [this.startDateTarget, this.endDateTarget];
    const invalid = elements.filter(el => !this.isDateValid(el.value)).length > 0;
    if (invalid) {
      return this.toggleInvalidInputElement(this.unitDateRangeTarget);
    }
    return this.clearInvalidInputElement(this.unitDateRangeTarget);
  }

  isDateValid(date) {
    return !!date;
  }

  requiredFormElements() {
    return Array.from(this.formTarget.querySelectorAll("input[required]"));
  }

  invalidElements() {
    const requiredElements = this.requiredFormElements();
    return requiredElements.filter(el => !el.reportValidity());
  }

  elementFormGroup(el) {
    return el.closest(".form-group");
  }

  toggleInvalidInputElement(el) {
    const parent = this.elementFormGroup(el);
    parent.classList.add("with-required-error");
    el.classList.add("has-error");
  }

  clearInvalidInputElement(el) {
    const parent = this.elementFormGroup(el);
    parent.classList.remove("with-required-error");
    el.classList.remove("has-error");
  }

  clearFormErrors() {
    const elements = this.requiredFormElements();
    return elements.forEach(el => this.clearInvalidInputElement(el));
  }

  onDateRangeChange(e) {
    const dates = JSON.parse(e.currentTarget.dataset.value);
    this.buildDates(dates);
  }

  buildDates(dates) {
    const startDate = dates[0];
    const endDate = dates[1];
    this.startDateTarget.value = !!startDate ? startDate : null;
    this.endDateTarget.value = !!endDate ? endDate : null;
  }

  validateForm() {
    this.checkDatesValidity();
    const invalidElements = this.invalidElements();
    return invalidElements.forEach(el => this.toggleInvalidInputElement(el));
  }

  submit(e) {
    e.preventDefault();
    this.clearFormErrors();
    if (!this.formTarget.reportValidity()) {
      return this.validateForm();
    }

    this.formTarget.requestSubmit();
    window.modal.close();
    window.drawer.close();
  }
}
