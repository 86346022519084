import BaseController from "./base_controller";

export default class extends BaseController {
  static targets = ["item", "filter", "hiddenInputs"];
  static values = { hasForm: Boolean, reflexAction: String };

  submitForm() {
    if (!this.hasFormValue) return;
    // Must handle both grid and pop filter forms
    const gridForm = document.getElementById("campaign_grid_data_search")
    const popForm = document.getElementById("pop-filter-form")
    if (gridForm) {
      Rails.fire(gridForm, "submit");
    } else if (popForm) {
      Rails.fire(popForm, "submit");
    }
  }

  getFilterData() {
    return this.element.closest("li");
  }

  getElementType(el) {
    return el.nodeName.toLowerCase();
  }

  clear() {
    this.itemTargets.forEach(el => this.clearElementValue(el, false));
    this.submitForm();
    this.stimulateFilter();
  }

  dispatchInputEvent(el) {
    el.dispatchEvent(new Event("input", { bubbles: true }));
  }

  clearElementValue(el, submitForm = true) {
    const isInput = this.getElementType(el) == "input";
    if (isInput && el.type == "text") {
      el.value = null;
    }
    if (isInput && el.type == "checkbox") {
      el.checked = false;
    }
    this.dispatchInputEvent(el);
    if (submitForm) {
      this.submitForm();
    }
    this.removeKeyHandler(el);
  }

  removeKeyHandler(el) {
    if (this.hasFormValue) return;

    const key = el.name || el.dataset.filter;
    this.paramsRemove(key);
  }

  removeKeyFromArrayHandler(el) {
    const key = el.dataset.filterParsed;
    const value = el.dataset.filterValue;
    this.paramsRemoveFromArray(key, value);
  }

  apply() {
    this.itemTargets.forEach(el => this.addKeyHandler(el));
    this.resetPagination();
    this.submitForm();
    this.stimulateFilter();
  }

  async stimulateFilter() {
    if (this.hasFormValue) return;

    const filterElement = this.getFilterData();
    const payload = {
      morph_id: filterElement.id,
      filter: filterElement.dataset.filter,
      custom_class: filterElement.dataset.customClass,
      reflex_action: this.reflexActionValue,
      has_form: this.hasFormValue,
    };
    await this.stimulate("Plus::Campaigns::FilterReflex#show", payload);
    await this.stimulate(this.reflexActionValue);
  }

  toggleActive(e) {
    const { target } = e;
    const {
      dataset: { filterParsed, filterValue },
    } = target;
    // const isActive = this.hasArrayParam(filterParsed, filterValue)
    const isActive = this.activeStatus(target, filterParsed, filterValue);
    this.resetPagination();
    if (isActive) {
      return this.handleElementDeactivation(target);
    }
    return this.handleElementActivation(target);
  }

  createHiddenInput(name, value, array = false) {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    if (array) {
      input.setAttribute("name", `${name}[]`);
    } else {
      input.setAttribute("name", name);
    }
    input.setAttribute("value", value);
    return input;
  }

  activeStatus(el, filterParsed, filterValue) {
    if (this.hasFormValue) return el.classList.contains("active");

    return this.hasArrayParam(filterParsed, filterValue);
  }

  handleElementDeactivation(el) {
    if (this.hasFormValue) return this.handleElementDeactivationForm(el);

    el.classList.remove("active");
    this.removeKeyFromArrayHandler(el);
    return this.stimulateFilter();
  }

  handleElementDeactivationForm(el) {
    el.classList.remove("active");
    const value = el.value || el.dataset.filterValue;
    this.hiddenInputsTarget.querySelectorAll(`input[value="${CSS.escape(value)}"]`).forEach(e => e.remove());
    this.dispatchInputEvent(el);
    this.submitForm();
  }

  handleElementActivation(el) {
    if (this.hasFormValue) return this.handleElementActivationForm(el);
    el.classList.add("active");
    this.addKeyHandler(el);
    return this.stimulateFilter();
  }

  handleElementActivationForm(el) {
    el.classList.add("active");
    const key = el.dataset.filterParsed;
    const value = el.dataset.filterValue;
    this.addHiddenInput(key, value);
  }

  addHiddenInput(key, value) {
    const input = this.createHiddenInput(key, value, true);
    if (this.hiddenInputsTarget.querySelectorAll(`input[value="${CSS.escape(value)}"]`).length > 0) {
      return;
    }
    this.hiddenInputsTarget.appendChild(input);
    this.dispatchInputEvent(input);
    this.submitForm();
  }

  addKeyHandler(el) {
    if (this.hasFormValue) return;

    const key = el.name || el.dataset.filter;
    const value = el.value || el.dataset.filterValue;
    this.paramsAdd(key, value);
  }

  checkActive(e) {
    const { target } = e;
    const isActive = target.checked;
    this.resetPagination();
    if (isActive) {
      return this.handleCheckActivation(target);
    }
    return this.handleCheckDeactivation(target);
  }

  handleCheckActivation(el) {
    el.classList.add("active");
    if (this.hasFormValue) {
      return;
    }
    this.addKeyHandler(el);
    return this.stimulateFilter();
  }

  handleCheckDeactivation(el) {
    el.classList.remove("active");
    if (this.hasFormValue) {
      return;
    }

    this.removeKeyHandler(el);
    return this.stimulateFilter();
  }

  setAllInactive() {
    const elements = document.querySelectorAll("#filter--availability li");
    elements.forEach(element => {
      element.classList.remove("active");
    });
  }

  activateOption(e) {
    const { target } = e;

    if (this.hasFormValue) {
      this.setAllInactive();
      const value = target.dataset.filterValue;
      const targetName = target.dataset.filter;
      // hidden input with dynamic name on sort selector
      if (target.dataset.replaceName == "true") {
        this.filterTarget.name = targetName;
      }
      this.filterTarget.value = value;
      this.dispatchInputEvent(this.filterTarget);
      this.submitForm();
    }

    this.itemTargets.forEach(el => this.removeKeyHandler(el));
    this.handleItemState(target);
    this.resetPagination();
    return this.stimulateFilter();
  }

  toggleActiveSingleParam(e) {
    const { target } = e;

    if (this.hasFormValue) {
      const value = target.dataset.filterValue;
      if (value == this.filterTarget.value) {
        this.filterTarget.removeAttribute("value");
      } else {
        this.filterTarget.value = value;
      }
      this.dispatchInputEvent(this.filterTarget);
      this.submitForm();
    }

    this.itemTargets.forEach(el => this.removeKeyHandler(el));
    this.handleItemState(target);
    this.resetPagination();
    return this.stimulateFilter();
  }

  handleItemState(target) {
    const isActive = target.classList.contains("active");
    if (isActive) {
      return this.handleCheckDeactivation(target);
    }
    return this.handleCheckActivation(target);
  }
}
