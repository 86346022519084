import ApplicationController from "./application_controller";
import JobProgress from "../utils/JobProgress";

export default class extends ApplicationController {
  filters = {
    group_by: "market",
    source: "geopath",
    favorited: false,
    recommended: false,
  };
  data_refreshing = false;
  job_polling = new JobProgress();

  toggleFilter(e) {
    e.preventDefault();
    const target = e.currentTarget;
    const filterName = target.dataset.name;
    this.filters[filterName] = !this.filters[filterName];
    this.toggleCheckedClass(target, this.filters[filterName]);
    this.filter(target);
  }

  filter(target) {
    this.stimulate("Pro::Campaigns::ReachAndFrequencyReflex#filter", target, {
      campaign_id: this.campaignToken(),
      filters: this.filters,
    });
  }

  toggleCheckedClass(target, checked) {
    if (checked) {
      target.classList.add("checked");
    } else {
      target.classList.remove("checked");
    }
  }

  changeGroupBy(e) {
    const target = e.currentTarget;
    const newGroupBy = target.value;

    this.filters["group_by"] = newGroupBy;
    this.filter(target);
  }

  changeSource(e) {
    const target = e.currentTarget;
    const newSource = target.value;

    this.filters["source"] = newSource;
    this.filter(target);
  }

  onJobComplete() {
    document.getElementById("refresh_data_button").disabled = false;
    this.data_refreshing = false;
    this.stimulate("Pro::Campaigns::ReachAndFrequencyReflex#filter", {
      campaign_id: this.campaignToken(),
      filters: this.filters,
    });
  }

  loadData(e) {
    if (this.data_refreshing === true) {
      return;
    }
    e.target.disabled = true;
    this.stimulate("Pro::Campaigns::ReachAndFrequencyReflex#load_data", { campaign_id: this.campaignToken() });
  }

  refreshData(e) {
    if (this.data_refreshing === true) {
      return;
    }
    e.target.disabled = true;
    this.stimulate("Pro::Campaigns::ReachAndFrequencyReflex#refresh_data", { campaign_id: this.campaignToken() });
  }

  oldRefreshData(e) {
    if (this.data_refreshing === true) {
      return;
    }
    e.target.disabled = true;
    const campaignToken = this.campaignToken();
    const url = `/plus/campaigns/${campaignToken}/geopath/refresh_data`;
    this.data_refreshing = true;
    $.get(url).then(response => {
      const { job_id } = response;
      this.job_polling.startPolling(job_id, this.onJobComplete.bind(this));
    });
  }

  campaignToken() {
    return window.location.pathname.split("campaigns/")[1].split("/reach_frequency")[0];
  }
}
