import ApplicationController from "../application_controller";
import Cookies from "js-cookie";

export default class extends ApplicationController {
  static targets = ["cardsViewBtn", "tableViewBtn"];

  exportTemplateRedirect(e) {
    const {
      target: { value },
    } = e;
    if (!value) {
      return;
    }

    return window.open(value, "_blank");
  }

  cardView() {
    Cookies.set("dashboards_view_as", "cards", { expires: 365 });
    this.cardsViewBtnTarget.classList.add("active");
    this.tableViewBtnTarget.classList.remove("active");
    this.stimulate("Plus::Campaigns::Dashboards::ListReflex#update", "cards", this.queryParams());
  }

  tableView() {
    Cookies.set("dashboards_view_as", "table", { expires: 365 });
    this.tableViewBtnTarget.classList.add("active");
    this.cardsViewBtnTarget.classList.remove("active");
    this.stimulate("Plus::Campaigns::Dashboards::ListReflex#update", "table", this.queryParams());
  }

  queryParams(resetPageNumber = true) {
    if (resetPageNumber) {
      // reset page number after changing views
      const url = new URL(window.location);
      url.searchParams.set("page", "1");
      window.history.pushState({}, "", url);
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
  }
}
