export default class AudienceHeatmapLayer {
  constructor(map, layerId) {
    this.map = map;
    this.layerId = layerId;
    this.blockGroupSource = "block-groups";
    this.blockGroupSourceLayer = "usa_tbgs";
    this.zipCodeSource = "zip-codes";
    this.zipCodeSourceLayer = "usa_zip_codes";

    // this layer is set in mapbox's UI: https://studio.mapbox.com/styles/fahimf/cjy0g4rbh0y1d1cledi9pf3nk/edit/#14.6/30.31801/-81.65663
    const beforeLayerId = 'road-label';
    this.addTigerBlockGroupLayer(map, layerId, beforeLayerId);
    this.addZipCodeLayer(map, layerId, beforeLayerId);
    this.hideLayers();
  }

  addTigerBlockGroupLayer(map, layerId, beforeLayerId) {
    map.addSource(this.blockGroupSource, {
      type: "vector",
      url: "mapbox://fahimf.usa_tbgs_v2",
    });

    map.addLayer(
      {
        id: `${layerId}-${this.blockGroupSource}`,
        type: "fill",
        source: this.blockGroupSource,
        "source-layer": this.blockGroupSourceLayer,
        paint: {
          "fill-color": ["feature-state", "color"],
          "fill-opacity": 0.4,
        },
      },
      beforeLayerId,
    );
  }

  addZipCodeLayer(map, layerId, beforeLayerId) {
    map.addSource(this.zipCodeSource, {
      type: "vector",
      url: "mapbox://fahimf.usa_zip_codes_v1",
    });

    map.addLayer(
      {
        id: `${layerId}-${this.zipCodeSource}`,
        type: "fill",
        source: this.zipCodeSource,
        "source-layer": this.zipCodeSourceLayer,
        paint: {
          "fill-color": ["feature-state", "color"],
          "fill-opacity": 0.4,
        },
      },
      beforeLayerId,
    );
  }

  addEventListeners() {
    window.addEventListener("adquick:audiences:showheatmap", this.#handleShowAudiencesHeatmap.bind(this));
    window.addEventListener("adquick:audiences:hideheatmap", this.#handleHideAudiencesHeatmap.bind(this));
  }

  #handleShowAudiencesHeatmap(event) {
    const {
      detail: { scores },
    } = event;
    this.updateLayer(scores);
  }

  #handleHideAudiencesHeatmap(_e) {
    this.hideLayers();
  }

  hideLayers() {
    this.map.setLayoutProperty(`${this.layerId}-${this.blockGroupSource}`, "visibility", "none");
    this.map.setLayoutProperty(`${this.layerId}-${this.zipCodeSource}`, "visibility", "none");
  }

  updateLayer(scores) {
    const tigerBlockKey = "tiger_block_group_id";
    const zipCodeKey = "zip_code_id";
    let source;
    let sourceLayer;
    let geoIds;
    let geoKey;
    let layerId;

    // Decide which source to use depending on what's in the scores array
    if (scores[0].hasOwnProperty(tigerBlockKey)) {
      geoKey = tigerBlockKey;
      geoIds = scores.map(d => d.tiger_block_group_id);
      source = this.blockGroupSource;
      sourceLayer = this.blockGroupSourceLayer;
    } else {
      geoKey = zipCodeKey;
      geoIds = scores.map(d => d.zip_code_id);
      source = this.zipCodeSource;
      sourceLayer = this.zipCodeSourceLayer;
    }
    layerId = `${this.layerId}-${source}`;

    this.map.setFilter(layerId, ["in", ["id"], ["literal", geoIds]]);
    const colorCallback = this.loadColorRange(scores);

    for (const item of scores) {
      this.map.setFeatureState(
        {
          source: source,
          sourceLayer: sourceLayer,
          id: item[geoKey],
        },
        {
          color: colorCallback(item.score),
        },
      );
    }

    this.hideLayers();
    this.map.setLayoutProperty(layerId, "visibility", "visible");
  }

  loadColorRange(scores) {
    const grey = "#D2D2D3";
    const lightYellow = "#FFFBA1";
    const darkYellow = "#FFB826";
    const orange = "#FC8234";
    const lightRed = "#EF1F1F";
    const darkRed = "#9B001D";
    const colorRange = [lightYellow, darkYellow, orange, lightRed, darkRed];
    this.colorRange = colorRange;

    this.weights = scores
      .map(d => d.score)
      .filter(s => s > 0)
      .sort((a, b) => a - b);
    this.edges = [];
    this.size = colorRange.length;

    for (let i = 1; i < this.size; i++) {
      this.edges.push(this.weights[i * Math.floor(this.weights.length / this.size)]);
    }

    return weight => {
      if (weight === 0) {
        return grey;
      }

      for (let i = this.size - 1; i >= 0; i--) {
        if (weight >= this.edges[i - 1]) return colorRange[i];
      }
      return colorRange[0];
    };
  }
}
