import MapSidebarController from "../shared/map_sidebar_controller";

export default class extends MapSidebarController {
  static values = {
    reflex: String,
  };

  connected() {
    this.marketMaxZoom = this.marketMaxZoomValue;
    super.connected();
  }

  render(page = 1) {
    if (this.bounds == null) {
      // wait for map to be ready
      _.delay(this.render.bind(this), 1000);
    } else {
      if (this.currentTab() === MapSidebarController.UNITS_TAB) {
        const isVisible = this.toggleVisibility();
        if (!isVisible) return;
      }
      this.updateSidebar(this.currentTab(), page);
      this.element.scrollTo(0, 0);
    }
  }

  async openModal(unitId, pushState) {
    const payload = {
      component: "modal/browse_unit_modal_component",
      component_params: {
        unit_id: unitId,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    if (pushState) this.paramsAdd("unit_id", unitId, false);
    window.modal.open();
  }

  handleBrowseUnitView(_event) {
    this.stimulate("Pro::Browse::MapsReflex#track_unit_view");
  }
}
