import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("csv box connected");
  }

  import(e) {
    const sheetId = e.target.dataset.sheetId;
    const userId = e.target.dataset.userId;
    const submitUrl = e.target.dataset.submitUrl;
    const authToken = e.target.dataset.authToken;
    const customFields = JSON.parse(e.target.dataset.customFields);
    const dynamicColumns = JSON.parse(e.target.dataset.dynamicColumns);

    const importer = new CSVBoxImporter(sheetId, {}, r => console.log(r));
    importer.setUser({ ...customFields, ...{ user_id: userId, auth_token: authToken } });
    if (dynamicColumns) {
      importer.setDynamicColumns(dynamicColumns);
    }
    importer.openModal();
    if (submitUrl) {
      importer.listen("onSubmit", function(data) {
        fetch(submitUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...data, ...{ jwt: authToken } }),
        })
          .then(response => response.json())
          .then(response_data => console.log(response_data))
          .catch(error => console.log(error));
      });
    }
  }
}
