import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static values = {
    campaignId: String,
    unitSlugs: Array,
  };

  static targets = [
    "addTagForm",
    "addTagButton",
    "addTagInput",
    "addToPackageUnitSlugs"
  ];

  // Called when the Drawer gets opened
  connected() {
    this.unitSlugsValue = window.adquick?.browse?.selected_unit_ids;
  }

  async handleFavorite() {
    const unitSlugs = this.unitSlugsValue;

    if (this.campaignIdValue && unitSlugs) {
      await this.stimulate("Pro::Campaigns::FavoriteUnitsReflex#favorite_bulk", this.campaignIdValue, unitSlugs);
      await this.stimulate("UI::ToastReflex#show", "success", "Units marked as favorited");
      this.#clearSelectedUnits();
      window.drawer.close();
    }
  }

  async handleUnfavorite() {
    const unitSlugs = this.unitSlugsValue;

    if (this.campaignIdValue && unitSlugs) {
      await this.stimulate("Pro::Campaigns::FavoriteUnitsReflex#unfavorite_bulk", this.campaignIdValue, unitSlugs);
      await this.stimulate("UI::ToastReflex#show", "success", "Units unmarked as favorited");
      this.#clearSelectedUnits();
      window.drawer.close();
    }
  }

  async handleRecommend() {
    const unitSlugs = this.unitSlugsValue;

    if (this.campaignIdValue && unitSlugs) {
      await this.stimulate("Pro::Campaigns::RecommendUnitsReflex#recommend_bulk", this.campaignIdValue, unitSlugs);
      await this.stimulate("UI::ToastReflex#show", "success", "Units marked as recommended");
      this.#clearSelectedUnits();
      window.drawer.close();
    }
  }

  async handleUnrecommend() {
    const unitSlugs = this.unitSlugsValue;

    if (this.campaignIdValue && unitSlugs) {
      await this.stimulate("Pro::Campaigns::RecommendUnitsReflex#unrecommend_bulk", this.campaignIdValue, unitSlugs);
      await this.stimulate("UI::ToastReflex#show", "success", "Units unmarked as recommended");
      this.#clearSelectedUnits();
      window.drawer.close();
    }
  }

  handleAddTagFormToggle() {
    this.addTagFormTarget.classList.toggle("hide");
    this.addTagButtonTarget.classList.toggle("active");
    if (this.addTagButtonTarget.classList.contains("active")) {
      this.addTagInputTarget.focus();
    } else {
      this.#resetAddTagForm();
    }
  }

  async handleAddTagSubmit() {
    const unitSlugs = this.unitSlugsValue;

    if (this.campaignIdValue && unitSlugs) {
      await this.stimulate(
        "Pro::Campaigns::TagUnitsReflex#tag_bulk",
        this.campaignIdValue,
        unitSlugs,
        this.addTagInputTarget.value,
      );
      await this.stimulate("UI::ToastReflex#show", "success", "Units tagged with " + this.addTagInputTarget.value);
      this.#clearSelectedUnits();
      window.drawer.close();
    }
  }

  async handleClearTags() {
    const unitSlugs = this.unitSlugsValue;

    if (this.campaignIdValue && unitSlugs) {
      await this.stimulate("Pro::Campaigns::TagUnitsReflex#untag_bulk", this.campaignIdValue, unitSlugs);
      await this.stimulate("UI::ToastReflex#show", "success", "Units successfully untagged");
      this.#clearSelectedUnits();
      window.drawer.close();
    }
  }

  async removeUnits() {
    const unitSlugs = this.unitSlugsValue;

    await this.stimulate('Pro::Campaigns::RemoveUnitsReflex#remove', this.campaignIdValue, unitSlugs);
    window.dispatchEvent(new CustomEvent("adquick:browse:updatesidebar"));
    window.dispatchEvent(new CustomEvent("adquick:browse:reloadmapunits"));
    await this.stimulate('UI::ToastReflex#show', 'success', 'Units successfully removed');

    window.drawer.close();
  }

  populateAddToPackageUnitSlugs() {
    this.addToPackageUnitSlugsTarget.value = this.unitSlugsValue;
  }

  #resetAddTagForm() {
    this.addTagFormTarget.classList.add("hide");
    this.addTagButtonTarget.classList.remove("active");
    this.addTagInputTarget.value = "";
  }

  #clearSelectedUnits() {
    window.adquick.browse.selected_unit_ids = null;
  }
}
