export default class VendorSearchResults {
  constructor(searchResults) {
    this.searchResults = searchResults;
  }

  findSupplierOnMarketById = (market, supplierId) => {
    return market.suppliers.find(s => s.supplier_id == supplierId);
  };

  findSupplierContactById = (supplier, contactId) => {
    if (!supplier) {
      return;
    }
    return supplier.contacts.find(c => c.id == contactId);
  };

  updateSupplierContactsWithContact(supplier, contact) {
    return [...supplier.contacts.filter(c => c.id != contact.id), contact];
  }

  updateSupplierContactsWithToggledContacts(supplier, toggledContacts) {
    const toggledContactsIds = toggledContacts.map(c => c.id);
    return [...supplier.contacts.filter(c => !toggledContactsIds.includes(c.id)), ...toggledContacts];
  }

  updateMarketSuppliersWithSupplier(market, supplier) {
    return [...market.suppliers.filter(s => s.supplier_id != supplier.supplier_id), supplier];
  }

  toggleContacts(contacts, checked) {
    return contacts.reduce((toggledContacts, contact) => {
      contact["checked"] = checked;
      toggledContacts.push(contact);
      return toggledContacts;
    }, []);
  }

  toggleSuppliersContactsById(suppliers, contactIds, checked) {
    const parsedContactIds = contactIds.map(id => Number.parseInt(id));
    return suppliers.reduce((acc, supplier) => {
      const contacts = supplier.contacts.filter(c => parsedContactIds.includes(c.id));
      const toggledContacts = this.toggleContacts(contacts, checked);
      supplier.contacts = this.updateSupplierContactsWithToggledContacts(supplier, toggledContacts);
      acc.push(supplier);
      return acc;
    }, []);
  }

  toggleFavoritedSupplier(suppliers, supplierId, favorited) {
    return suppliers.reduce((acc, supplier) => {
      const match = supplier.supplier_id == supplierId;
      if (match) {
        supplier.favorited = favorited;
      }
      acc.push(supplier);
      return acc;
    }, []);
  }

  updateSearchResultWithCheckedContactBySupplier(contactId, supplierId, checked) {
    return this.searchResults.reduce((searchResult, market) => {
      const supplier = this.findSupplierOnMarketById(market, Number.parseInt(supplierId));
      const contact = this.findSupplierContactById(supplier, Number.parseInt(contactId));
      if (!supplier || !contact) {
        searchResult.push(market);
        return searchResult;
      }
      contact["checked"] = checked;
      supplier.contacts = this.updateSupplierContactsWithContact(supplier, contact);
      market.suppliers = this.updateMarketSuppliersWithSupplier(market, supplier);
      searchResult.push(market);
      return searchResult;
    }, []);
  }

  updateSearchResultWithCheckedContactIds(contactIds, checked) {
    return this.searchResults.reduce((searchResult, market) => {
      const suppliers = this.toggleSuppliersContactsById(market.suppliers, contactIds, checked);
      market.suppliers = suppliers;
      searchResult.push(market);
      return searchResult;
    }, []);
  }

  updatedSearchResultWithFavoritedSupplierChange(supplierId, favorited) {
    return this.searchResults.reduce((searchResult, market) => {
      const suppliers = this.toggleFavoritedSupplier(market.suppliers, Number.parseInt(supplierId), favorited);
      market.suppliers = suppliers;
      searchResult.push(market);
      return searchResult;
    }, []);
  }
}
