import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static values = { unitIds: Array, campaignId: String };

  static targets = ["input"];

  async submit(e) {
    try {
      await this.stimulateProductionCostUpdate();
      this.handleSuccess();
    } catch (payload) {
      const { event } = payload;
      const {
        detail: {
          stimulusReflex: { serverMessage },
        },
      } = event;
      this.handleError(serverMessage);
    }
  }

  async stimulateProductionCostUpdate() {
    const payload = {
      production_cost_per_square_feet: this.inputTarget.value,
      unit_ids: this.unitIdsValue,
      campaign_id: this.campaignIdValue,
    };
    await this.stimulate("CampaignUnitReflex#mass_update_production_cost", payload);
  }

  async handleError({ body }) {
    await this.stimulate("UI::ToastReflex#show", "warning", body);
    window.modal.close();
  }

  async handleSuccess() {
    await this.stimulate("UI::ToastReflex#show", "success", "Your production cost change is being process");
    window.location.reload();
  }
}
