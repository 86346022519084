export const viewLink = (campaignToken, unitSlug) => {
  return /*html*/ `
    <a href='/campaigns/${campaignToken}/units/${unitSlug}' target='_blank' title="View Unit" class="view-unit-action">
      <i class="fa-solid fa-eye"></i>
    </a>
  `;
};

export const removalReasonLink = (campaignToken, campaignUnitToken, unitSlug) => {
  return /*html*/ `
    <a data-controller='remove-units'
    data-remove-units-campaign-unit-token-value='${campaignUnitToken}'
    data-remove-units-campaign-id-value='${campaignToken}'
    data-remove-units-unit-id-value='${unitSlug}'
    data-remove-units-refresh-filter-value='true'
    data-action='click->remove-units#openRemovalReasonModal'
    title="Remove unit from campaign"
    >
    <i class="fa-solid fa-trash"></i>
  </a>
  `;
};

export const removeLink = () => {
  return /*html*/ `
    <a class='remove-units'>
      <i class="fa-solid fa-trash"></i>
    </a>
  `;
};

export const favoriteIcon = favIconStyle => {
  return /*html*/ `
    <a title="Favorite unit">
      <i class="fa fa-heart" aria-hidden="true" style="${favIconStyle}"></i>
    </a>
  `;
};

export const recommendIcon = recSpanStyle => {
  return /*html*/ `
    <span class="recommended--badge recommended-action flex flex-justify-center" style="${recSpanStyle}" title="Recommend Unit">
      <span class="badge_recommended" >
        R
      </span>
    </span>
  `;
};
