import ApplicationController from "./application_controller";

export class ToggleHideElementController extends ApplicationController {
  static values = {
    query: String,
  };

  hide() {
    const elements = document.querySelectorAll(this.queryValue);
    Array.from(elements).map(el => {
      if (this.elementStatus()) {
        el.setAttribute("disabled", true);
        el.classList.add("hide");
      } else {
        el.removeAttribute("disabled");
        el.classList.remove("hide");
      }
    });
  }

  elementStatus() {
    if (this.element.tagName == "INPUT") {
      if (this.element.type == "checkbox") {
        return this.element.checked;
      }
      return this.element.value;
    }
  }
}
