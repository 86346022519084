import consumer from "./consumer";

const start = () => {
  const adquick_request_supplier_task_element = document.querySelector("input#adquick_requests_supplier_task_id");

  if (!adquick_request_supplier_task_element) {
    return setTimeout(() => start(), 300);
  }

  const user_id = document.querySelector("input#adquick_requests_user_id").value;
  const supplier_task_id = adquick_request_supplier_task_element.value;

  consumer.subscriptions.create(
    {
      channel: "AdquickRequestsChannel",
      user_id,
      supplier_task_id,
    },
    {
      connected() {
        console.info("[ActionCable] Connected to AdquickRequestsChannel.");
      },
      received(data) {
        const parsed = JSON.parse(data);
        console.log(`redirecting to ${parsed["redirect_to"]}`);
        window.location.assign(parsed["redirect_to"]);
      },
    },
  );
};

const AdquickRequestsChannel = { start };
export default AdquickRequestsChannel;
