import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  static targets = ["submitButton"];

  set skip_suspected(s) {
    return (this._skip_suspected = s);
  }

  get skip_suspected() {
    return this._skip_suspected;
  }

  connected() {
    document.addEventListener("validated", this.resetSubmitButton);
  }

  disconnect() {
    document.removeEventListener("validated", this.resetSubmitButton);
  }

  resetSubmitButton = ({ detail }) => {
    const { suspected } = detail;
    if (suspected) {
      this.skip_suspected = true;
    }
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.removeAttribute("disabled");
      this.submitButtonTarget.innerText = "Save and continue";
    }
  };

  isSpecUploadStep() {
    return this.data.get("page") == "spec_sheet_specs";
  }

  isUploadImageStep() {
    return this.data.get("page") == "images_specs";
  }

  goToPageHandler(e) {
    if (!e.target.hasAttribute("disabled")) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    return;
  }

  pageAction() {
    const actions = {
      review: "process_review",
      add_inventory: "process_limited_grid",
      unit_details: "process_full_grid",
    };
    return actions[this.data.get("page")];
  }

  submitPayload(skipReplacements = false) {
    const actions = {
      add_inventory: {
        grid_file: this.parseInventoryFile(),
        confirm_no_replacements: skipReplacements,
        force_render_replacements: this.data.has("forceReplacementsPage"),
        grid_columns: window.gridColumns
      },
      unit_details: { grid_file: this.parseInventoryFile(), skip_suspected: this.skip_suspected || false, grid_columns: window.gridColumns },
    };
    return actions[this.data.get("page")] || {};
  }

  parseInventoryFile() {
    try {
      const supplierTaskId = this.data.get("supplierTaskId");
      const file = window.sessionStorage.getItem(`task_${supplierTaskId}_data`);
      return JSON.parse(file);
    } catch (error) {
      return [];
    }
  }

  isInventoryFileEmpty() {
    return this.parseInventoryFile().length == 0;
  }

  inventoryHasErrors() {
    const isOptionalStep = this.data.get("supplierTaskType") == "update";
    if (isOptionalStep) {
      return false;
    }
    const inventory = this.parseInventoryFile();
    return inventory.filter(d => d["has_error"] && d["has_error"].length > 0).length > 0;
  }

  stepHasInventoryToSubmit() {
    const currentStep = this.data.get("page");
    return currentStep == "add_inventory" || currentStep == "unit_details";
  }

  checkReplacementsStatus() {
    return this.data.get("page") == "add_inventory";
  }

  async confirmNoInventory() {
    const payload = { component: "modal/rfp_confirm_no_inventory_component" };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async submit() {
    const currencySelector = document.querySelector('#adquick_requests .currency');

    if (this.stepHasInventoryToSubmit()) {
      return this.handleInventorySubmit();
    } else if (this.isUploadImageStep()) {
      return this.handleImageStepSubmit();
    } else if (this.isSpecUploadStep()) {
      return this.handleSpecSheetStepSubmit();
    } else if (this.pageAction() === 'process_review' && currencySelector) {
      return this.handleRefiewRfpWithCurrencySelector();
    } else {
      this.toggleLoading();
      await this.stimulate(`Plus::AdquickRequestsReflex#${this.pageAction()}`, this.submitPayload());
    }
  }

  hasMissingReplacement() {
    const supplierTaskId = this.data.get("supplierTaskId");
    return window.localStorage.getItem(`task_${supplierTaskId}_has_missing_replacements`);
  }

  async handleInventorySubmit() {
    if (this.isInventoryFileEmpty()) {
      return alert("You can't submit an empty grid");
    }
    let skipReplacements = false;
    if (this.hasMissingReplacement() == "true") {
      if (this.data.has("options-confirm-replacements")) {
        skipReplacements = confirm("Some units dont have replacement, continue?");
      }
    } else {
      skipReplacements = true;
    }

    this.toggleLoading();
    await this.stimulate(`Plus::AdquickRequestsReflex#${this.pageAction()}`, this.submitPayload(skipReplacements));
  }

  async handleImageStepSubmit() {
    if (!this.data.has("allImagesSubmitted")) {
      return this.missingImageError();
    }
    this.toggleLoading();
    await this.stimulate("Plus::AdquickRequestsReflex#process_images", {});
  }

  async handleRefiewRfpWithCurrencySelector() {
    const selectedCurrency = document.querySelector('#adquick_requests .currency .selected-option').dataset['id'];

    if (selectedCurrency) {
      this.toggleLoading();
      await this.stimulate(`Plus::AdquickRequestsReflex#process_review`, { currency: selectedCurrency });
    } else {
      // TODO: Show error message
    }
  }

  handleSpecSheetStepSubmit() {
    const isStepRequired = this.data.get("supplierTaskType") == "hold";
    const showMissingSpecError = isStepRequired && !this.data.has("allSpecsSubmitted");
    const showMissingVerifiedError = isStepRequired && !this.data.has("allSpecsVerified");
    if (showMissingSpecError) {
      return this.missingSpecSheetError();
    }
    if (showMissingVerifiedError) {
      return this.missingVerifiedSpecSheetError();
    }
    this.openConfirmDetailsModal();
  }

  async openConfirmDetailsModal() {
    const payload = { component: "modal/rfp_confirm_component" };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  toggleLoading() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.setAttribute("disabled", true);
      this.submitButtonTarget.innerText = "Processing...";
    }
  }

  missingImageError() {
    return alert("There are units with missing images. Please upload images for them.");
  }

  missingSpecSheetError() {
    return alert("There are units with missing spec sheets. Please upload them before submitting.");
  }

  missingVerifiedSpecSheetError() {
    return alert("There are units with missing verified spec sheets. Please verify the spec sheets before submitting.");
  }
}
