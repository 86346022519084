import ApplicationController from "../application_controller";

export class TurboDialog extends ApplicationController {
  initialize() {
    const existingActions = this.element.getAttribute("data-action")
    const newActions = `${existingActions} close->turbo-dialog#remove turbo:frame-load->turbo-dialog#open click->turbo-dialog#maybeClose`
    this.element.setAttribute("data-action", newActions)
  }

  maybeClose(e) {
    // Closes modal if user clicks outside of it
    // Maybe add a option if we dont want the modal to close when clicking outside of it
    const dialogDimensions = this.element.getBoundingClientRect()
    if (
      e.clientX < dialogDimensions.left ||
      e.clientX > dialogDimensions.right ||
      e.clientY < dialogDimensions.top ||
      e.clientY > dialogDimensions.bottom
    ) {
      this.element.close()
    }
  }

  open() {
    this.element.showModal()
  }

  close() {
    this.element.close()
  }

  remove() {
    this.element.querySelector("turbo-frame").innerHTML = ""
  }

  // Workaround for strange interactions with dialog + form
  // Even with require inputs the browser dispatched a dialog `close` event when pressing enter
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog#usage_notes
  // Mentions that the dialog will close if method="dialog" but even with my form having method="post" it still closes
  interceptEnter(e) {
    const dialogForm = this.element.querySelector("form")
    if (dialogForm) {
      e.preventDefault()
      e.stopPropagation()
      dialogForm.requestSubmit()
    }
  }
}
