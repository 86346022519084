import ApplicationController from "./../../application_controller";

export default class extends ApplicationController {
  static targets = ["replacement", "faceIdString"];

  static values = {
    supplierTaskId: String,
    campaignUnitId: String,
    unitId: String,
  };

  async searchByFaceId() {
    const ids = this.faceIdStringTarget.value;
    const payload = {
      face_ids: ids,
      supplier_task_id: this.supplierTaskIdValue,
      campaign_unit_id: this.campaignUnitIdValue,
      unit_id: this.unitIdValue,
    };
    await this.stimulate("Plus::ReplacementsReflex#search_by_face_id", payload);
  }

  async submitReplacements() {
    const r_array = this.replacementTargets.filter(r => r.checked).map(r => r.id);
    if (r_array.length > 0) {
      const payload = {
        replacement_ids: r_array,
        supplier_task_id: this.supplierTaskIdValue,
        campaign_unit_id: this.campaignUnitIdValue,
        unit_id: this.unitIdValue,
      };
      await this.stimulate("Plus::ReplacementsReflex#update_inventory_file", payload);

      // Morph header to update attributes that handles navigation
      await this.stimulate("Plus::AdquickRequestsReflex#morph_header", {
        page: "review",
        supplier_task_id: this.supplierTaskIdValue,
      });
      return window.modal.close();
    }
  }
}
