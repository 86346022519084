import ApplicationController from "./application_controller";

export class ToggleElementController extends ApplicationController {
  static values = {
    attr: Array,
    class: String,
    focus: Boolean,
  };

  static targets = ["attr", "class"];

  call() {
    this.attrTargets.forEach(element => {
      this.focus(element);
      this.attrValue.map(attr => {
        element.toggleAttribute(attr);
      });
    });
    this.classTargets.forEach(element => {
      this.focus(element);
      element.classList.toggle(this.classValue);
    });
  }

  // Use this like 'toggle-element-focus-value': 'true' if you want to focus the first non hidden input present on the target
  // TODO handle cases when the target is already an input
  focus(element) {
    if (this.focusValue) {
      setTimeout(() => {
        element.querySelector("input:not([type=hidden])").focus();
      }, 0);
    }
  }
}
