export default class UnitTooltipController {
  constructor(map, campaignToken, showPrices, showUnitPrice, showSupplier) {
    this.map = map;
    this.campaignToken = campaignToken;
    this.showPrices = showPrices;
    this.showUnitPrice = showUnitPrice;
    this.showSupplier = showSupplier;

    this.showTooltip = true;

    map.on("draw.render", this.#handleDrawRender.bind(this));
  }

  // Avoid showing the tooltip while the user is drawing on the map.
  #handleDrawRender() {
    this.showTooltip = false;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.showTooltip = true;
    }, 1000);
  }

  showUnitTooltip({ unitId, coordinates, offset = 10 }) {
    if (unitId && coordinates && this.showTooltip) {
      fetch(`/campaigns/${this.campaignToken}/unit_tooltip/${unitId}`)
        .then(res => res.text())
        .then(html => {
          this.unitTooltip?.remove();
          this.unitTooltip = new mapboxgl.Popup({ closeButton: false, offset })
            .setLngLat(coordinates)
            .setHTML(html)
            .addTo(this.map);
        });
    }
  }

  hideUnitTooltip() {
    this.unitTooltip?.remove();
  }
}
