export default class PackageChildrenLayer {
  constructor(map, source, clusterLayerId, circleLayerId) {
    this.map = map;
    this.source = source;
    this.clusterLayerId = clusterLayerId;
    this.circleLayerId = circleLayerId;
    this.clusterCountLayerId = `${clusterLayerId}-count`;
    this.circleColor = "#0d93ea";

    this.addChildPackagesLayer();

    window.addEventListener("adquick:map:highlightpackage", this.handleHighlightPackage.bind(this));
    window.addEventListener("adquick:map:unhighlightpackage", this.handleUnhighlightPackage.bind(this));
  }

  addChildPackagesLayer() {
    this.createClusterLayer();
    this.createClusterCountLayer();
    this.createCircleLayer();
    this.addChildClickEvent();
    this.highlightParentOnChildHover();

    // this.packageChildrenSpider = new PackageChildrenSpider(
    //   this.map,
    //   this.props.onUnitClick,
    //   this.highlightPackageParent.bind(this),
    //   this.removeUnitPopup.bind(this),
    //   this.highlightChildrenUnits.bind(this),
    // );
    // const spider = this.packageChildrenSpider;
    // this.map.on("click", "package_children", e => {
    //   const features = this.map.queryRenderedFeatures(e.point, {
    //     layers: ["package_children"],
    //   });
    //   spider.close();
    //   if (!features.length) {
    //     return;
    //   } else {
    //     const source = this.map.getSource("package_children_source");
    //     if (!features[0].properties) return;
    //     source.getClusterLeaves(features[0].properties.cluster_id, 100, 0, function(err, leafFeatures) {
    //       if (err) {
    //         return console.error("error while getting leaves of a cluster", err);
    //       }
    //       const markers = _.map(leafFeatures, function(leafFeature) {
    //         return leafFeature.properties;
    //       });
    //       spider.spiderfy(features[0].geometry.coordinates, markers);
    //     });
    //   }
    // });
  }

  highlightParentOnChildHover() {
    // // highlight the whole package layer and its parent on mouse over
    let unitId;
    this.map.on("mouseenter", this.circleLayerId, e => {
      if (e.features) {
        if (!e.features[0].properties) return;
        unitId = e.features[0].properties.parent_id;
        window.dispatchEvent(new CustomEvent("adquick:map:highlightunit", { detail: { unitId } }));
      }
    });

    this.map.on("mouseleave", this.circleLayerId, e => {
      window.dispatchEvent(new CustomEvent("adquick:map:unhighlightunit", { detail: { unitId } }));
    });
  }

  addChildClickEvent() {
    this.map.on("click", this.circleLayerId, this.handleClick.bind(this));
  }

  createClusterCountLayer() {
    if (this.map.getLayer(this.clusterCountLayerId)) this.map.removeLayer(this.clusterCountLayerId);

    this.map.addLayer({
      id: this.clusterCountLayerId,
      type: "symbol",
      source: this.source,
      filter: ["has", "point_count"],
      minzoom: 7,
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 10,
      },
      paint: {
        "text-color": "#FFFFFF",
      },
    });
  }

  createCircleLayer() {
    if (this.map.getLayer(this.circleLayerId)) this.map.removeLayer(this.circleLayerId);

    this.map.addLayer({
      id: this.circleLayerId,
      type: "circle",
      source: this.source,
      minzoom: 7,
      filter: ["!has", "point_count"],
      paint: {
        "circle-opacity": 0.95,
        "circle-radius": 4,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#FFFFFF",
        "circle-color": this.circleColor,
      },
    });
  }

  createClusterLayer() {
    if (this.map.getLayer(this.clusterLayerId)) this.map.removeLayer(this.clusterLayerId);

    this.map.addLayer({
      id: this.clusterLayerId,
      type: "circle",
      source: this.source,
      minzoom: 7,
      filter: ["has", "point_count"],
      paint: {
        "circle-color": this.circleColor,
        "circle-opacity": 0.8,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#FFFFFF",
        "circle-radius": ["step", ["get", "point_count"], 7, 40, 10, 60, 12],
      },
    });
  }

  handleClick(event) {
    const unitId = event.features[0].properties.parent_id;
    if (!unitId) return false;
    // FIX: this event name should be changed as it isn't coming from "browse" but from the "campaign map"
    window.dispatchEvent(new CustomEvent("adquick:browsemap:unitclick", { detail: { unitId } }));
  }

  handleHighlightPackage(event) {
    const unitId = event.detail.unitId;
    if (unitId) {
      this.removeHighlightedChildrenLayer();
      this.createHighlightedChildrenLayer(unitId);
    }
  }

  handleUnhighlightPackage(event) {
    this.removeHighlightedChildrenLayer();
  }

  createHighlightedChildrenLayer(unitId) {
    this.map.addLayer({
      id: "highlighted-children",
      type: "circle",
      source: this.source,
      minzoom: 7,
      filter: ["==", "parent_id", unitId],
      paint: {
        "circle-color": {type: "identity", property: "color"},
        "circle-opacity": 0,
        "circle-stroke-width": 3,
        "circle-stroke-color": "#FEC700",
        "circle-radius": 4,
      },
    });
  }

  removeHighlightedChildrenLayer() {
    if (this.map.getLayer("highlighted-children")) this.map.removeLayer("highlighted-children");
  }
}
