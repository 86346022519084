import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  set showingRfp(showing) {
    this._showingRfp = showing;
  }

  get showingRfp() {
    return this._showingRfp;
  }

  connected() {
    this.showingRfp = false;
  }

  getGridData() {
    try {
      const supplierTaskId = this.data.get("supplierTaskId");
      const file = window.sessionStorage.getItem(`task_${supplierTaskId}_data`);
      return JSON.parse(file);
    } catch (error) {
      return [];
    }
  }

  async openPackageModal() {
    const payload = {
      component: "modal/rfp_package_component",
      component_params: {
        supplier_task_id: this.data.get("supplierTaskId"),
        grid_data: this.getGridData(),
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }

  async openTemplateModal(e) {
    e.preventDefault();
    console.log(e.target.value);
    const payload = {
      component: "modal/rfp_package_component",
      component_params: {
        supplier_task_id: this.data.get("supplierTaskId"),
        grid_data: this.getGridData(),
        package_template: e.target.value,
      },
    };
    await this.stimulate("PackageTemplateSearchReflex#search", {
      element_id: "select-template",
      action: "requests-actions#openTemplateModal toggle-element#call",
    });
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  toggleRfpDetails() {
    if (!this.showingRfp) {
      return this.showRfpDetails();
    }
    return this.hideRfpDetails();
  }

  showRfpDetails() {
    this.showingRfp = true;
    this.stimulate("Plus::AdquickRequestsReflex#morph_request_details");
  }

  hideRfpDetails() {
    this.showingRfp = false;
    document.getElementById("request_details").innerHTML = "";
  }
}
