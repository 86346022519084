import ApplicationController from "./application_controller";

export class CampaignAvailsBatch extends ApplicationController {
  static values = {
    url: String,
  };

  static targets = ["progress", "text"];

  async initialize() {
    this.intervalId = setInterval(() => this.updateValues(), 2000);
  }

  async updateValues() {
    if (!this.hasProgressTarget) {
      return;
    }

    const response = await fetch(this.urlValue, { method: "get" });
    const jsonBody = await response.json();
    this.progressTarget.value = jsonBody.ran;
    this.progressTarget.max = jsonBody.total;
    if (jsonBody.ran == jsonBody.total) {
      clearInterval(this.intervalId);
    }
    this.textTarget.innerHTML = `${this.progressTarget.value}/${this.progressTarget.max}`;
  }
}
