import ApplicationController from "../application_controller";

export class AutoOpenDialog extends ApplicationController {
  initialize() {
    this.element.showModal()
  }

  close() {
    this.element.close()
  }
}
