import MapboxDraw from "@mapbox/mapbox-gl-draw";
import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
import { RulerControl } from "mapbox-gl-controls";
import SquareControl from "./../../../../../../../packages/adquick-classic/src/marketplace/components/NewMap/SquareControl";
import CustomPointOfInterestControl from "./controls/CustomPointOfInterestControl";
import CustomPointOfInterestMode from "./controls/CustomPointOfInterestMode";
export default class DrawTool {
  constructor(map, maxZoom, campaignToken) {
    this.maxZoom = maxZoom;
    this.campaignToken = campaignToken;
    const mapboxDraw = this.#addMapboxDraw(map);
    this.#addTooltips();
    this.#toggleVisibility(map);
    map.on("zoomend", this.#handleMapZoomEnd.bind(this));
    return mapboxDraw;
  }

  #addMapboxDraw(map) {
    const mapboxDraw = new MapboxDraw({
      modes: {
        draw_rectangle: DrawRectangle,
        custom_point_of_interest: CustomPointOfInterestMode,
        ...MapboxDraw.modes,
      },
      displayControlsDefault: false,
      controls: { polygon: true },
    });

    const squareControl = new SquareControl(mapboxDraw);

    const rulerControl = new RulerControl({
      units: "miles",
      labelFormat: n => `${n.toFixed(2)} miles`,
    });

    map.addControl(mapboxDraw, "top-left");
    map.addControl(squareControl, "top-left");
    map.addControl(rulerControl, "top-left");
    if (this.campaignToken) {
      map.addControl(new CustomPointOfInterestControl(mapboxDraw, this.campaignToken), "top-left")
    }

    return mapboxDraw;
  }

  #addTooltips() {
    const tooltips = [
      {
        selector: ".mapbox-gl-draw_polygon",
        content: "Polygon tool: select units from the map and add them to a campaign",
      },
      {
        selector: ".mapbox-ctrl-square-control",
        content: "Square tool: select units from the map and add them to a campaign",
      },
      { selector: ".mapbox-control-ruler", content: "Ruler: measure distances on the map" },
    ];

    tooltips.forEach(tooltip => {
      document.querySelector(tooltip.selector).setAttribute("title", tooltip.content);
    });
  }

  #handleMapZoomEnd(event) {
    this.#toggleVisibility(event.target);
  }

  #toggleVisibility(map) {
    const isZoomedOut = map.getZoom() < this.maxZoom;
    document.querySelectorAll(".mapboxgl-ctrl-top-left .mapboxgl-ctrl-group").forEach(ctrl => {
      ctrl.hidden = isZoomedOut;
    });
  }
}
