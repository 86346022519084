import BaseController from "./base_controller";

export default class extends BaseController {
  static values = {
    cuId: Number,
    cuToken: String,
    campaignToken: String,
    supplierAddressId: Number,
  };

  static targets = ["checkbox"];

  afterConnected() {
    if (this.isCardSelected()) {
      return this.activateCard();
    }
    return this.deactivateCard();
  }

  isCardSelected() {
    return !!this.base.selectedTokens.find(token => token == this.cuTokenValue);
  }

  mainListController() {
    const main = document.getElementById("campaign-manage-creatives-main");
    return this.getControllerForElement(main, "campaign-creatives");
  }

  toggle(e) {
    const {
      target: { checked },
    } = e;
    if (checked) {
      return this.selectCard();
    }
    return this.unselectedCard();
  }

  selectCard() {
    this.activateCard();
    this.base.addTokens([this.cuTokenValue]);
    this.mainListController().handleGlobalCheckboxState();
  }

  unselectedCard() {
    this.deactivateCard();
    this.base.removeTokens([this.cuTokenValue]);
    this.mainListController().handleGlobalCheckboxState();
  }

  activateCard() {
    this.checkboxTarget.checked = true;
  }

  deactivateCard() {
    this.checkboxTarget.checked = false;
  }

  async uploadAsset(_e) {
    const payload = {
      component: "modal/creatives/upload_asset",
      component_params: {
        campaign_token: this.campaignTokenValue,
        campaign_unit_ids: [this.cuTokenValue],
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async updateInstaller(e) {
    e.preventDefault();
    const payload = {
      component: "modal/creatives/update_installer",
      component_params: {
        campaign_unit_token: this.cuTokenValue,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async updateOverage(e) {
    e.preventDefault();
    const payload = {
      component: "modal/creatives/update_overage",
      component_params: {
        campaign_unit_token: this.cuTokenValue,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async assignSpecSheet(e) {
    e.preventDefault();
    const payload = {
      component: "modal/creatives/assign_spec_sheet",
      component_params: {
        campaign_unit_token: this.cuTokenValue,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async handleSuccess(e) {
    await this.stimulate("Plus::Campaigns::CreativesReflex#index");
    window.modal.close();
  }

  async addPostingNote(e) {
    const payload = {
      component: "modal/creatives/add_posting_note",
      component_params: {
        campaign_token: this.campaignTokenValue,
        campaign_unit_ids: [this.cuTokenValue],
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async deleteAsset(e) {
    const { target } = e;
    const { creativeCardAssetId } = target.closest(".card-asset").dataset;

    const response = await fetch(`/api/v1/campaigns/${this.campaignTokenValue}/design_assets/${creativeCardAssetId}`, {
      method: "DELETE",
    });
    if (response.status > 400) {
      return this.processResponseError(response);
    }
    return this.processResponseSuccess(response);
  }

  async rejectAsset(e) {
    e.preventDefault();
    const { target } = e;
    const { creativeCardAssetId } = target.closest(".card-asset").dataset;

    const payload = {
      component: "modal/creatives/reject_creative",
      component_params: {
        campaign_token: this.campaignTokenValue,
        design_assets_ids: [creativeCardAssetId],
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async undoRejection(e) {
    e.preventDefault();
    const { target } = e;
    const { creativeCardAssetId } = target.closest(".card-asset").dataset;

    await this.stimulate("Plus::Campaigns::CreativesReflex#undo_rejection", creativeCardAssetId);
  }

  async ShowShippingAddressForm(e) {
    e.preventDefault();
    // auto checks the card checkbox so we can reuse the bulk action for both single edits and bulk edits
    const checkbox = this.element.querySelector("input[type=checkbox]");
    const checkboxState = checkbox.checked;
    if (!checkboxState) {
      // triggering click instead of setting .checked = true so this works with checkbox selector controller
      checkbox.click();
    }
    console.log(this.element);

    const payload = {
      component: "modal/creatives/supplier_address",
      component_params: {
        campaign_token: this.campaignTokenValue,
        supplier_address_id: this.supplierAddressIdValue,
        campaign_unit_ids: [this.cuTokenValue],
      },
    };
    console.log(payload);
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }
}
