import ApplicationController from "./application_controller";

export class ClonePackageController extends ApplicationController {
  static targets = ["form", "name"];
  static values = {
    directory: String,
  };

  saveAndClone() {
    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", "clone");
    hiddenField.setAttribute("value", true);
    this.formTarget.appendChild(hiddenField);
    Rails.fire(this.formTarget, "submit");
  }

  handleSuccess(e) {
    const [data, _status, _xhr] = e.detail;
    this.rerenderModal(data.clone_token, data.campaign_id, data.unit_id);
    // not sure why this closes the modal, gonna leave that out for now
    // this.stimulate('UI::ToastReflex#trigger_toast', 'success', 'Package cloned')
  }

  async rerenderModal(clone_token, campaign_id, unit_id) {
    const payload = {
      component: "modal/campaign_package_component",
      morph_target_dom: "#package_modal",
      component_params: {
        campaign_id: campaign_id,
        unit_id: unit_id,
        campaign_unit_token: clone_token,
      },
    };
    await this.stimulate("MorphComponentReflex#morph_component", payload);
  }
}
