import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ['dueDateHiddenField'];

  onDueDateChange(e) {
    const selectedDate = e.target.value;
    this.dueDateHiddenFieldTarget.value = !!selectedDate ? this.#formatDate(selectedDate) : null;
  }

  #formatDate(dateString) {
    return new Date(dateString).toUTCString();
  }
}
