import ApplicationController from "../application_controller";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "file-saver";

export class FileDownloadController extends ApplicationController {
  static targets = ["source", "checkbox"];
  static values = {
    fileName: String,
  };

  urlToPromise(url) {
    return new Promise(function(resolve, reject) {
      JSZipUtils.getBinaryContent(url, function(err, data) {
        if (err) {
          console.log(`Download failed for ${url}`);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  downloadSelected(e) {
    e.preventDefault();
    const checkedTargets = Array.from(this.sourceTargets).filter(
      e => e.querySelector('input[data-file-download-target="checkbox"]').checked,
    );
    const targetUrls = checkedTargets.map(e => e.dataset["zipDownloadUrl"]);
    let zip = new JSZip();
    targetUrls.map(url => {
      let filename = url.replace(/.*\//g, "");
      zip.file(filename, this.urlToPromise(url), { binary: true });
    });

    zip.generateAsync({ type: "blob" }).then(
      content => {
        saveAs(content, this.fileNameValue);
      },
      err => {
        console.log(err);
        this.stimulate(
          "UI::ToastReflex#show",
          "error",
          "Error downloading some images, please reach out for support or try again in a few minutes",
        );
      },
    );
  }
}
