import BaseController from "./base_controller";
import debounce from "lodash/debounce";
import { filterMapping } from "../../utils/filter_mapping";

export default class extends BaseController {
  static targets = ["item", "filterDate", "popup", "filter", "searchSelect", "faceIdInput",
  "geopathIdInput"];
  static values = { hasForm: Boolean, reflexAction: String };

  connected() {
    document.addEventListener("click", this.onToggle.bind(this));
    this.filterByFaceId = debounce(this.filterByFaceId.bind(this), 300);
    this.debouncedSubmit = debounce(this.submitForm.bind(this), 300);
    this.setDefaultSearchInput();
  }

  disconnect() {
    document.removeEventListener("click", this.onToggle);
  }

  onToggle(e) {
    const { target } = e;
    const clickedWithin = this.element.contains(target);
    if (clickedWithin) {
      return;
    }
    this.checkActiveFilters();
    return this.hideAllPopups();
  }

  submitForm() {
    if (!this.hasFormValue) return;

    Rails.fire(document.getElementById("campaign_grid_data_search"), "submit");
  }

  handleSearchInputChange() {
    const searchInput = this.searchSelectTarget.value;
    switch (searchInput) {
      case "face_id":
        this.hideTabPanelFilter();
        break;
      case "geopath_id":
        this.hideFaceIdFilter();
        break;
    }
  }

  setDefaultSearchInput() {
    this.geopathIdInputTarget.value
    const searchInput = this.searchSelectTarget.value;
    switch (searchInput) {
      case "face_id":
        this.hideTabPanelFilter();
        break;
      case "geopath_id":
        this.hideFaceIdFilter();
        break;
    }
  }

  hideFaceIdFilter() {
    document.getElementById('face_id_input').style.display = 'none';
    document.getElementById('geopath_id_input').style.display = 'block';
  }

  hideTabPanelFilter() {
    document.getElementById('face_id_input').style.display = 'block';
    document.getElementById('geopath_id_input').style.display = 'none';
  }

  // TODO: besides hiding the popup, we possibly also need to remove the "active" status of the filter button
  hideAllPopups() {
    this.popupTargets.forEach(el => el.classList.remove("show"));
  }

  checkActiveFilters() {
    this.itemTargets.forEach(el => this.manageFilterState(el));
  }

  manageFilterState(el) {
    const {
      dataset: { filter },
    } = el;

    if (this.hasFormValue) {
      if (this.isFilterActive(filter)) {
        return;
      }
      this.deactiveFilterElement(el);
      return;
    }

    if (filter == "sort") {
      return;
    }
    if (this.isFilterActive(filter)) {
      return this.activateFilterElement(el);
    }
    return this.deactiveFilterElement(el);
  }

  isFilterActive(filter) {
    return !!Object.keys(this.params).find(k => filterMapping[filter]?.includes(k));
  }

  activateFilterElement(el) {
    return el.classList.add("active");
  }

  deactiveFilterElement(el) {
    return el.classList.remove("active");
  }

  openFilterPopup(e) {
    const { target } = e;
    const popup = this.findPopupForElement(target);
    if (!popup) {
      return;
    }
    this.hideAllPopups();
    this.activateFilterElement(target);
    return this.togglePopup(popup);
  }

  findPopupForElement(element) {
    return element.querySelector(".filter--popup");
  }

  togglePopup(popup) {
    const isOpen = popup.classList.contains("show");
    if (isOpen) {
      return popup.classList.remove("show");
    }
    return popup.classList.add("show");
  }

  filterByFaceId(e) {
    const {
      target: { value },
    } = e;
    this.resetPagination();
    if (!value) {
      return this.clearFaceIdSearch();
    }
    this.paramsAdd("face_id", value);
    this.stimulateResult();
  }

  clearFaceIdSearch() {
    this.paramsRemove("face_id");
    this.stimulateResult();
  }

  applyFilterFromButton(e) {
    // targeting closest `li` because it has the needed data attributes
    // when the event was triggered by clicking the li icon the target icon didnt had the necessary attributes
    // and the filter failed
    const closestListItem = e.target.closest("li");
    const {
      dataset: { filter },
    } = closestListItem;
    if (!filter) {
      return;
    }
    if (this.hasFormValue) {
      this.handleBooleanFilters(closestListItem.id);
      this.stimulateFilter(closestListItem);
      return;
    }

    const hasFilter = this.hasParam(filter);
    if (hasFilter) {
      this.paramsRemove(filter);
    }
    if (!hasFilter) {
      this.paramsAdd(filter, true);
    }
    this.resetPagination();
    return this.stimulateFilter(closestListItem);
  }

  handleBooleanFilters(eventTargetId) {
    switch (eventTargetId) {
      case "filter--favorite":
        this.toggleBooleanFilter(this.filterTargets.find(t => t.id == "favorite"));
        break;
      case "filter--recommended":
        this.toggleBooleanFilter(this.filterTargets.find(t => t.id == "recommended"));
        break;
      case "filter--in-cart":
        this.toggleBooleanFilter(this.filterTargets.find(t => t.id == "in_cart"));
        break;
      default:
        break;
    }
  }

  toggleBooleanFilter(element) {
    if (element.value) {
      element.removeAttribute("value");
    } else {
      element.value = true;
    }
    this.dispatchInputEvent(element);
    this.submitForm();
  }

  openCalendar(e) {
    const { target } = e;
    if (target.id !== "filter--date") {
      return;
    }
    this.activateFilterElement(target);
    const pickerController = this.application.getControllerForElementAndIdentifier(target, "datepicker-range");
    pickerController.datepicker.open();
  }

  onDateRangeChange(e) {
    e.preventDefault();
    const {
      dataset: { value },
    } = e.target;
    const dates = JSON.parse(value);
    this.saveDates(dates);
  }

  async clearDateRange() {
    if (this.hasFormValue) {
      this.resetHiddenInputs();
      return;
    }

    this.paramsRemove("date[start_date]");
    this.paramsRemove("date[end_date]");
    await this.stimulateFilter(this.filterDateTarget);
    this.restartDatePicker();
  }

  async saveDates(dates) {
    const start_date = !!dates[0] ? this.formatDate(dates[0]) : null;
    const end_date = !!dates[1] ? this.formatDate(dates[1]) : null;
    if (!start_date || !end_date) {
      return;
    }
    if (this.hasFormValue) {
      this.handleHiddenInputs(start_date, end_date);
      return;
    }
    this.paramsAdd("date[start_date]", start_date);
    this.paramsAdd("date[end_date]", end_date);
    this.resetPagination();
    await this.stimulateFilter(this.filterDateTarget);
    this.restartDatePicker();
  }

  handleHiddenInputs(start_date, end_date) {
    document.getElementById("start_date_gteq").value = new Date(start_date).toISOString();
    document.getElementById("end_date_lteq").value = new Date(end_date).toISOString();
    this.dispatchInputEvent(document.getElementById("end_date_lteq"));
    this.submitForm(); // how to improve this? how to pass the form as param to the other controllers?
  }

  resetHiddenInputs() {
    document.getElementById("start_date_gteq").value = null;
    document.getElementById("end_date_lteq").value = null;
    this.dispatchInputEvent(document.getElementById("end_date_lteq"));
    this.restartDatePicker();
    this.submitForm();
  }

  dispatchInputEvent(el) {
    el.dispatchEvent(new Event("input", { bubbles: true }));
  }

  formatDate(dateString) {
    return new Date(dateString).toUTCString();
  }

  restartDatePicker() {
    return this.application.getControllerForElementAndIdentifier(this.filterDateTarget, "datepicker-range").reload();
  }

  async stimulateFilter(element) {
    if (this.hasFormValue) {
      return;
    }

    const payload = {
      morph_id: element.id,
      filter: element.dataset.filter,
      custom_class: element.dataset.customClass,
      title: element.dataset.title,
      reflex_action: this.reflexActionValue,
      has_form: this.hasFormValue,
    };
    await this.stimulate("Plus::Campaigns::FilterReflex#show", payload);
    this.stimulateResult();
  }

  async stimulateResult() {
    await this.stimulate(this.reflexActionValue);
  }
}
