import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["declineReasonsField", "requestedChangesField"];

  async openRequestChangesModal(e) {
    e.preventDefault();

    const campaignProposalId = e.target.dataset.campaignProposalId;
    const payload = {
      component: "modal/campaign_proposal_request_change_component",
      component_params: { campaign_proposal_id: campaignProposalId },
    };

    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }

  async openDeclineModal(e) {
    e.preventDefault();

    const campaignProposalId = e.target.dataset.campaignProposalId;
    const payload = {
      component: "modal/campaign_proposal_decline_component",
      component_params: { campaign_proposal_id: campaignProposalId },
    };

    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }

  closeModal(e) {
    e.preventDefault();

    window.modal.close();
  }

  // Calling via Stimulus instead of directly using Reflex in the view because the reflex was being triggered twice per click
  approveCampaignProposal(e) {
    e.preventDefault();

    const campaignProposalId = e.target.dataset.campaignProposalId;
    this.stimulate("CampaignOverview#approve_campaign_proposal", campaignProposalId);
  }

  sendChangeRequest(e) {
    e.preventDefault();

    const campaignProposalId = e.target.dataset.campaignProposalId;
    const requestedChanges = this.requestedChangesFieldTarget.value;

    this.stimulate("CampaignOverview#request_changes_campaign_proposal", campaignProposalId, requestedChanges);
    window.modal.close();
  }

  sendDecline(e) {
    e.preventDefault();

    const campaignProposalId = e.target.dataset.campaignProposalId;
    const reason = this.declineReasonsFieldTarget.value;

    this.stimulate("CampaignOverview#decline_campaign_proposal", campaignProposalId, reason);
    window.modal.close();
  }
}
