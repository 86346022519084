import BaseController from "./base_controller";

export default class extends BaseController {
  static values = {
    campaignUnitToken: String,
    campaignToken: String,
  };

  static targets = ["cardListContainer", "checkbox"];

  get campaignUnitToken() {
    return this.element.dataset.campaignUnitToken;
  }

  mainListController() {
    const main = document.getElementById("campaign-manage-units-main");
    return this.getControllerForElement(main, "campaign-manage-units");
  }

  isCardSelected() {
    return !!this.base.selectedTokens.find(token => token == this.campaignUnitTokenValue);
  }

  afterConnected() {
    if (this.isCardSelected()) {
      return this.activateCard();
    }
    return this.deactivateCard();
  }

  activateCard() {
    this.checkboxTarget.checked = true;
    this.element.classList.add("active");
  }

  deactivateCard() {
    this.checkboxTarget.checked = false;
    this.element.classList.remove("active");
  }

  addTokenToGlobal() {
    return this.globalSelectedTokens().concat(this.campaignUnitToken);
  }

  removeTokenFromGlobal() {
    return this.globalSelectedTokens().filter(token => token != this.campaignUnitToken);
  }

  toggle(e) {
    const {
      target: { checked },
    } = e;
    if (checked) {
      return this.selectCard();
    }
    return this.unselectedCard();
  }

  selectCard() {
    this.activateCard();
    this.base.addTokens([this.campaignUnitTokenValue]);
    this.mainListController().handleGlobalCheckboxState();
  }

  unselectedCard() {
    this.deactivateCard();
    this.base.removeTokens([this.campaignUnitTokenValue]);
    this.mainListController().handleGlobalCheckboxState();
  }

  toggleCardList() {
    this.cardListContainerTarget.classList.toggle("open");
  }

  onPriceChange(e) {
    const { name, value } = e.target;
    const payload = { [name]: this.parsePriceValue(value) };
    return this.stimulateCampaignUnitUpdate(payload);
  }

  onSupplierUserChange(e) {
    const { value } = e.target;
    const payload = { supplier_user_id: value };
    return this.stimulateCampaignUnitUpdate(payload);
  }

  parsePriceValue(value) {
    if (!value) {
      return null;
    }
    const regex = new RegExp(/[$\,]/, "gm");
    return Number.parseFloat(value.replace(regex, ""));
  }

  onDateRangeChange(e) {
    const {
      dataset: { value },
    } = e.target;
    const dates = JSON.parse(value);
    this.saveDates(dates);
  }

  saveDates(dates) {
    const start_date = !!dates[0] ? this.formatDate(dates[0]) : null;
    const end_date = !!dates[1] ? this.formatDate(dates[1]) : null;
    if (!start_date || !end_date) {
      return;
    }
    const payload = { start_date, end_date };
    return this.stimulateCampaignUnitUpdate(payload);
  }

  formatDate(dateString) {
    return new Date(dateString).toUTCString();
  }

  destroy() {
    if (confirm(`Are you sure you want to remove this unit?`)) {
      return this.stimulatecampaignUnitDestroy();
    }
  }

  openSupplierRequestModal(e) {
    const {
      target: { dataset },
    } = e;
    const payload = {
      component: "modal/supplier_request_component",
      component_params: {
        campaign_unit_tokens: [this.campaignUnitTokenValue],
        campaign_token: this.campaignTokenValue,
        request_type: dataset.modalType,
      },
    };
    this.stimulateModal(payload);
  }

  openProductionCostModal() {
    const payload = {
      component: "modal/normalize_production_cost_component",
      component_params: {
        campaign_unit_tokens: [this.campaignUnitTokenValue],
        campaign_token: this.campaignTokenValue,
      },
    };
    this.stimulateModal(payload);
  }

  async revertProductionCosts() {
    const payload = {
      unit_ids: [this.campaignUnitTokenValue],
      campaign_id: this.campaignTokenValue,
    };
    try {
      await fetch("/api/v1/campaign_units/revert_production_cost", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      window.location.reload();
    } catch (error) {
      alert(error);
    }
  }

  async stimulateCampaignUnitUpdate(data) {
    const payload = {
      data,
      campaign_unit_token: this.campaignUnitTokenValue,
      morph_to: `#campaign--kht4r2zuu5 #manage-units--cards #${this.element.id}`,
    };
    await this.stimulate("Plus::Campaigns::ManageUnitsReflex#update", payload);
    this.afterConnected();
    this.restartDatepicker();
  }

  restartDatepicker() {
    const el = document.getElementById(`${this.element.id}_range_container`);
    return this.getControllerForElement(el, "datepicker-range").reload();
  }

  async stimulatecampaignUnitDestroy() {
    const payload = { campaign_unit_token: this.campaignUnitTokenValue };
    await this.stimulate("Plus::Campaigns::ManageUnitsReflex#destroy", payload);
  }

  async stimulateModal(payload) {
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
    this.toggleCardList();
  }
}
