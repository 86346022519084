import ApplicationController from "./application_controller";
import Quill from "quill";

export default class extends ApplicationController {
  static targets = [
    "goal",
    "type",
    "notes",
    "analyticsRec",
    "image_url",
    "heading",
    "body",
    "ready",
    "manual_check",
    "manual_input",
    "marketsForm",
    "overallMarketingBudget",
    "yearlyOohBudget",
    "campaignBudget",
    "targetDemographics",
  ];

  connected() {
    StimulusReflex.register(this);
    this.setupNotesWysiwyg();
  }

  gatherSelectInputs() {
    // Don't remove these variables, they are being accessed via metaprogramming with `eval`
    const account_executive = document.getElementById("campaign_strategy_account_executive_id").value;
    const account_manager = document.getElementById("campaign_strategy_account_manager_id").value;
    const sdr = document.getElementById("campaign_strategy_sdr_id").value;
    const creative_team = document.getElementById("campaign_strategy_creative_team_id").value;
    const analytics_team = document.getElementById("campaign_strategy_analytics_team_id").value;
    const manualInputs = this.manual_inputTargets.map(x => ({ role: x.name, name: x.value }));
    const data = this.manual_checkTargets.map(x => ({
      id: x.id,
      role: x.value,
      manual_entry: x.checked,
      user_id: eval(x.value),
      name: manualInputs.find(c => c.role === x.value).name,
    }));
    return data;
  }

  async checkErrors(data_goals, user_info) {
    // check if at least 2 acceptable users for requirements
    // check if at least one selected goal
    const error_array = [];
    const goals_completed = data_goals.find(x => x.selected === true);
    const ae_completed = user_info.find(
      x => (x.role === "account_executive" && !x.manual_entry && x.user_id) || (x.manual_entry && x.name),
    );
    const am_completed = user_info.find(
      x => (x.role === "account_manager" && !x.manual_entry && x.user_id) || (x.manual_entry && x.name),
    );
    if (!goals_completed) {
      error_array.push("- At least one goal needs to be checked\n");
    }
    if (!ae_completed) {
      error_array.push("- The account manager must be defined\n");
    }
    if (!am_completed) {
      error_array.push("- The account manager must be defined\n");
    }
    if (error_array.length > 0) {
      alert(
        `Your update has been saved, however in order to publish the following must be updated:\n${error_array.join(
          "",
        )} `,
      );
      await this.stimulate(`Plus::Campaigns::StrategyReflex#update_strategy_ready`, { ready: false });
    } else {
      alert(`Your update has been saved!`);
    }
  }

  async publishStrategy(_event) {
    const data = { ready: this.readyTarget.checked };
    await this.stimulate(`Plus::Campaigns::StrategyReflex#update_strategy_ready`, data);
    window.location.reload();
  }

  async saveStrategy(_event) {
    await Rails.fire(this.marketsFormTarget, "submit");
    const user_info = this.gatherSelectInputs();
    const notes = this.notesTarget.querySelector(".ql-editor").innerHTML;
    const data_info = {
      image_url: this.image_urlTarget.value,
      notes: notes,
      body: this.bodyTarget.value,
      heading: this.headingTarget.value,
      overall_marketing_budget: this.overallMarketingBudgetTarget.value,
      yearly_ooh_budget: this.yearlyOohBudgetTarget.value,
      campaign_budget: this.campaignBudgetTarget.value,
      target_demographics: this.targetDemographicsTarget.value.split(",").map(item => item.trim()),
    };
    const data_goals = this.goalTargets.map(x => ({ id: x.value, selected: x.checked }));
    const data_types = this.typeTargets.map(x => ({ id: x.value, selected: x.checked }));
    const data_analytics_recs = this.analyticsRecTargets.map(x => ({ id: x.value, selected: x.checked }));

    await this.stimulate(`Plus::Campaigns::StrategyReflex#update_info_item`, data_info);
    await this.stimulate(`Plus::Campaigns::StrategyReflex#update_strategy_goals`, data_goals);
    await this.stimulate(`Plus::Campaigns::StrategyReflex#update_strategy_types`, data_types);
    await this.stimulate(
      `Plus::Campaigns::StrategyReflex#update_strategy_analytics_recommendations`,
      data_analytics_recs,
    );
    await this.stimulate(`Plus::Campaigns::StrategyReflex#update_strategy_users`, user_info);
    this.checkErrors(data_goals, user_info);
  }

  async deleteGoal(event) {
    const delete_target = event.target.value;
    await this.stimulate(`Plus::Campaigns::StrategyReflex#delete_strategy_goal`, delete_target);
    alert(`Goal Removed!`);
  }

  removeDraftGoal() {
    this.closeClientGoalForm();
  }

  removeDraftType() {
    this.closeClientTypeForm();
  }

  async submitGoal(event) {
    event.preventDefault();
    const goal_to_send = { name: event.target["goal_name"].value, is_default_type: false };
    await this.stimulate(`Plus::Campaigns::StrategyReflex#create_strategy_goal`, goal_to_send);
    alert(`Goal Saved!`);
    this.closeClientGoalForm();
  }

  closeClientGoalForm() {
    this.closeForm("goal_form", "add_form");
  }

  opencClientGoalsForm() {
    this.openForm("goal_form", "add_form");
  }

  closeClientTypeForm() {
    this.closeForm("type_form", "add_type_form");
  }

  openClientTypeForm() {
    this.openForm("type_form", "add_type_form");
  }

  openForm(target, add) {
    const target_form = document.getElementById(target);
    const add_form = document.getElementById(add);
    target_form.classList.remove("hidden");
    add_form.disabled = true;
  }

  closeForm(target, add) {
    const target_form = document.getElementById(target);
    const add_form = document.getElementById(add);
    target_form.classList.add("hidden");
    add_form.disabled = false;
  }

  async submitType(event) {
    event.preventDefault();
    const title = event.target["type_title"].value;
    const description = event.target["type_description"].value;
    const type_to_send = { title: title, description: description, is_default_type: false };
    await this.stimulate(`Plus::Campaigns::StrategyReflex#create_strategy_type`, type_to_send);
    alert(`Type Saved!`);
    this.closeClientTypeForm();
  }

  async deleteType(event) {
    const delete_target = event.target.value;
    await this.stimulate(`Plus::Campaigns::StrategyReflex#delete_strategy_type`, delete_target);
    alert(`Goal Removed!`);
  }

  setupNotesWysiwyg() {
    const editorElement = document.getElementById("notes-wysisyg");
    const quillSettings = {
      modules: {
        toolbar: [
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          ["link"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
        ],
      },
      scrollingContainer: "#notes-wysisyg-wrapper",
      placeholder: "Write the strategy notes here.",
      theme: "snow",
    };

    this.editor = new Quill(editorElement, quillSettings);

    if (!editorElement.dataset.content) {
      return;
    }
    this.editor.clipboard.dangerouslyPasteHTML(editorElement.dataset.content, "api");
  }
}
