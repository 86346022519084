export default class UnitDirectionsLayer {
  constructor(map, source, minzoom) {
    this.#addUnitDirectionsLayer(map, source, minzoom);
  }

  #addUnitDirectionsLayer(map, source, minzoom) {
    [
      { name: "north", code: "N", offset: [1, -30] },
      { name: "south", code: "S", offset: [1, 30] },
      { name: "east", code: "E", offset: [30, 1] },
      { name: "west", code: "W", offset: [-30, 1] },
      { name: "northeast", code: "NE", offset: [23, -23] },
      { name: "northwest", code: "NW", offset: [-23, -23] },
      { name: "southeast", code: "SE", offset: [23, 23] },
      { name: "southwest", code: "SW", offset: [-23, 23] },
    ].forEach(direction =>
      map.addLayer({
        id: `unit-direction-${direction.name}`,
        type: "symbol",
        source: source,
        minzoom: minzoom,
        filter: ["==", ["get", "direction"], direction.code],
        layout: {
          "icon-offset": direction.offset,
          "icon-image": direction.name,
          "icon-size": 0.4,
          "icon-rotation-alignment": "map",
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
        },
      }),
    );
  }
}
