import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [];

  get requestStatus() {
    return this.data.get("status");
  }

  get requestType() {
    return this.data.get("type");
  }

  get currentPage() {
    return this.data.get("currentPage");
  }

  get nextPage() {
    return this.data.get("nextPage");
  }

  get previousPage() {
    return this.data.get("previousPage");
  }

  pages(page) {
    const pages = {
      next: this.nextPage,
      previous: this.previousPage,
    };
    return pages[page] || page;
  }

  buildStimulatePagePayload(page) {
    return {
      request_type: this.requestType,
      status: this.requestStatus,
      parent: `#${this.requestType}`,
      page,
    };
  }

  goToPage(e) {
    const { page } = e.currentTarget.dataset;
    const goToPage = Number.parseInt(this.pages(page));
    if (isNaN(goToPage)) {
      return;
    }
    const payload = this.buildStimulatePagePayload(goToPage);
    this.stimulatePage(payload);
  }

  async stimulatePage(payload) {
    await this.stimulate("Plus::AdquickRequestsReflex#show", payload);
  }
}
