import ApplicationController from "./../application_controller";
import { post, destroy } from "@rails/request.js";

export default class extends ApplicationController {
  static targets = ["fileInput"];

  openFileLibrary(e) {
    e.preventDefault();
    this.fileInputTarget.click();
  }

  uploadFileFromLibrary(e) {
    const file = e.target.files[0];

    if (e.target.files.length === 0) {
      return
    }

    this.#showLoading();

    const formData = new FormData();
    formData.append('file', file);

    const url = this.fileInputTarget.dataset.url;
    post(url, { body: formData, responseKind: "turbo-stream" });
  }

  deleteAttachment(e) {
    e.preventDefault();
    e.target.parentElement.parentElement.classList.add('hidden');

    const url = e.currentTarget.dataset.url;
    destroy(url, { responseKind: "turbo-stream" });
  }

  #showLoading() {
    const loadingElement = document.createElement('li');
    loadingElement.id = 'campaign-attachments-attachments-list-loading';

    const iconSpanElement = document.createElement('span');
    iconSpanElement.className = 'file icon';
    const iconElement = document.createElement('i');
    iconElement.className = 'fal fa-spinner';
    iconSpanElement.appendChild(iconElement);
    loadingElement.appendChild(iconSpanElement);

    const loadingTextNode = document.createTextNode('Uploading...');
    loadingElement.appendChild(loadingTextNode);

    this.element.querySelector('#campaign-attachments-attachments-list').append(loadingElement);
  }
}
