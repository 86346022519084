import "babel-polyfill";
import StimulusReflex from "stimulus_reflex";
import { Application } from "stimulus";
import consumer from "./channels/consumer";
import AdquickRequestsChannel from "./channels/adquick_requests_channel";
import CampaignSupplierContractsChannel from "./channels/campaign_supplier_contract_channel";
import CreativesChannel from "./channels/creatives_channel";
import BrowseChannel from "./channels/browse_channel";

import {
  audiences,
  select,
  multi_select,
  multi_select_async,
  datepicker,
  quill,
  handsontable,
  toast,
  tabs,
  drag_sortable_list,
  dropdown,
  sortable,
  csvbox,
  upload_file,
  read_more,
  single_select,
} from "../../shared/plus";

import tippy_controller from "./controllers/tippy_controller.js";
import adquick_requests_controller from "./controllers/adquick_requests_controller.js";
import campaigns_controller from "./controllers/campaigns/campaigns_controller";
import campaigns_sidebar_controller from "./controllers/campaigns/sidebar_controller.js";
import campaigns_audiences_controller from "./controllers/campaigns/audiences_controller.js";
import campaigns_filter_controller from "./controllers/campaigns/filter_controller";
import campaigns_export_dropdown_controller from "./controllers/campaigns/export_dropdown_controller";
import campaigns_drawer_controller from "./controllers/campaigns/drawer_controller";
import campaigns_modal_actions_controller from "./controllers/campaigns/modal_actions_controller.js";
import browse_controller from "./controllers/browse/browse_controller.js";
import browse_add_units_controller from "./controllers/browse/add_units_controller.js";
import browse_sidebar_controller from "./controllers/browse/sidebar_controller.js";
import browse_modal_actions_controller from "./controllers/browse/modal_actions_controller.js";
import browse_filter_controller from "./controllers/browse/filter_controller";
import browse_full_access_modal_controller from "./controllers/browse/full_access_modal_controller";
import browse_audiences_controller from "./controllers/browse/audiences_controller";
import { ExternalApiTaskDownloadController } from "./controllers/external_api_task_download_controller";
import image_card_controller from "./controllers/adquick_requests/image_card_controller.js";
import confirm_modal_controller from "./controllers/adquick_requests/modal/confirm_modal_controller.js";
import confirm_no_inventory_modal_controller from "./controllers/adquick_requests/modal/confirm_no_inventory_modal_controller.js";
import image_modal_controller from "./controllers/adquick_requests/modal/image_modal_controller.js";
import package_modal_controller from "./controllers/adquick_requests/modal/package_modal_controller.js";
import auto_recommend_units_modal_controller from "./controllers/adquick_requests/modal/auto_recommend_units_modal_controller.js";
import find_supplier_replacements_modal_controller from "./controllers/adquick_requests/modal/find_supplier_replacements_modal_controller.js";
import addon_modal_controller from "./controllers/campaigns/addon_modal_controller.js";
import removal_reason_modal_controller from "./controllers/campaigns/removal_reason_modal_controller.js";
import remove_units_controller from "./controllers/remove_units_controller.js";
import spec_modal_controller from "./controllers/adquick_requests/modal/spec_modal_controller.js";
import package_card_controller from "./controllers/adquick_requests/package_card_controller.js";
import requests_actions_controller from "./controllers/adquick_requests/requests_actions_controller.js";
import requests_attachment_controller from "./controllers/adquick_requests/requests_attachment_controller.js";
import requests_downloads_controller from "./controllers/adquick_requests/requests_downloads_controller.js";
import requests_header_controller from "./controllers/adquick_requests/requests_header_controller.js";
import view_sugestions_controller from "./controllers/adquick_requests/view_sugestions_controller";
import requests_images_controller from "./controllers/adquick_requests/requests_images_controller.js";
import requests_specs_controller from "./controllers/adquick_requests/requests_specs_controller.js";
import spec_card_controller from "./controllers/adquick_requests/spec_card_controller.js";
import application_controller from "./controllers/application_controller.js";
import campaign_header_controller from "./controllers/campaign_header_controller.js";
import cluster_tooltip_controller from "./controllers/cluster-tooltip_controller.js";
import custom_language_modal_controller from "./controllers/custom_language_modal_controller.js";
import card_context_menu_controller from "./controllers/dashboards/card_context_menu_controller.js";
import account_dashboard_controller from "./controllers/dashboards/account_dashboard_controller.js";
import impressions_tracker_controller from "./controllers/dashboards/impressions_tracker_controller.js";
import conversions_tracker_controller from "./controllers/dashboards/conversions_tracker_controller.js";
import pop_gallery_controller from "./controllers/dashboards/pop_gallery_controller.js";
import dashboard_filters_controller from "./controllers/dashboards/dashboard_filters_controller.js";
import price_loader_controller from "./controllers/dashboards/price_loader_controller.js";
import export_templates_controller from "./controllers/export_templates/export_templates_controller.js";
import contract_templates_controller from "./controllers/contract_templates/contract_templates_controller.js";
import color_picker_controller from "./controllers/export_templates/color_picker_controller.js";
import dashboard_view_options_controller from "./controllers/dashboards/dashboard_view_options_controller.js";
import checkouts_controller from "./controllers/campaigns/checkouts_controller.js";
import payment_controller from "./controllers/campaigns/checkout/payment_controller.js";
import checkout_success_controller from "./controllers/campaigns/checkout/success_controller";
import inventory_grid_controller from "./controllers/inventory_grid_controller.js";
import map_controller from "./controllers/map_controller.js";
import new_campaign_controller from "./controllers/new_campaign_controller.js";
import campaign_attachments_controller from "./controllers/campaigns/campaign_attachments_controller.js";
import new_campaign_component_controller from "./controllers/campaigns/new_campaign_component_controller.js";
import campaign_screen_and_media_types_component_controller from "./controllers/campaigns/campaign_screen_and_media_types_component_controller.js";
import campaign_due_date_component_controller from "./controllers/campaigns/campaign_due_date_component_controller.js";
import campaign_markets_component_controller from "./controllers/campaigns/campaign_markets_component_controller.js";
import campaign_date_types from "./controllers/campaigns/date_types_controller.js";
import campaign_branch_use_office_as_poi from "./controllers/campaigns/branch_use_office_as_poi_controller.js";
import reach_frequency_controller from "./controllers/reach_frequency_controller.js";
import rfp_preview_controller from "./controllers/rfp_preview_controller.js";
import send_rfp_controller from "./controllers/send_rfp_controller.js";
import supplier_attachment_controller from "./controllers/supplier_attachment_controller.js";
import vendor_search_controller from "./controllers/vendor_search_controller.js";
import impersonate_controller from "./controllers/impersonate_controller.js";
import campaign_manage_units_base from "./controllers/campaign_manage_units_controller";
import campaign_strategy_controller from "./controllers/campaign_strategy_controller";
import campaign_filter from "./controllers/manage_units/campaign_filter_controller";
import campaign_filter_popup from "./controllers/manage_units/campaign_filter_popup_controller";
import campaign_manage_units from "./controllers/manage_units/campaign_manage_units_controller";
import campaign_manage_unit_card from "./controllers/manage_units/campaign_manage_unit_card_controller";
import draw_tool_modal_controller from "./controllers/campaigns/draw_tool_modal_controller";
import proofs_controller from "./controllers/campaigns/proofs_controller";
import add_flights_bulk_modal_controller from "./controllers/campaigns/add_flights_bulk_modal_controller";
import overview_settings_controller from "./controllers/campaigns/overview_settings_controller";
import full_page_modal_controller from "./controllers/components/aq/full_page_modal_controller";
import campaign_overview_controller from "./controllers/campaigns/campaign_overview_controller";
import update_dates_modal from "./controllers/manage_units/modal/update_dates_controller";
import add_contract_modal from "./controllers/manage_units/modal/add_contract_modal_controller";
import request_holds_modal from "./controllers/manage_units/modal/request_holds_modal_controller";
import production_cost_modal from "./controllers/manage_units/modal/production_cost_modal_controller";
import { CampaignAvailsBatch } from "./controllers/campaign_avails_batch_controller";
import vendor_contracts_page from "./controllers/vendor_contracts/page_controller";
import vendor_contracts_filters from "./controllers/vendor_contracts/filters_controller";
import vendor_contract_unit_modal from "./controllers/vendor_contracts/modal/unit_modal_controller";
import vendor_negotiation from "./controllers/vendor_contracts/negotiation_controller";
import vendor_negotiation_item from "./controllers/vendor_contracts/negotiation_item_controller";
import vendor_send_contract_modal from "./controllers/vendor_contracts/modal/send_contract_modal_controller";
import vendor_adjust_contract_item_price_modal from "./controllers/vendor_contracts/modal/adjust_price_modal_controller";
import vendor_confirmation_modal from "./controllers/vendor_contracts/modal/vendor_confirmation_controller";
import vendor_upload_contract_modal from "./controllers/vendor_contracts/modal/upload_contract_controller";
import vendor_proposal from "./controllers/vendor_contracts/proposal_controller";
import vendor_price_proposal_modal from "./controllers/vendor_contracts/modal/send_price_proposal_modal_controller";
import expandable_controller from "./controllers/expandable_controller";
import expandable_checkbox_controller from "./controllers/expandable_checkbox_controller";
import exit_confirmation_controller from "./controllers/exit_confirmation_controller";
import carousel_controller from "./controllers/carousel_controller";
import { NewCampaignGridController } from "./controllers/new_campaign_grid_controller";
import { ListSearchController } from "./controllers/list_search_controller";
import { QueryStringTrackController } from "./controllers/query_string_track_controller";
import CampaignsMarketSearchController from "./controllers/campaigns/market_search_controller";
import CampaignsMarketsResultsController from "./controllers/campaigns/markets_results_controller";
import { PopCardController } from "./controllers/pop_card_controller";
import { SelectTargetsController } from "./controllers/select_targets_controller";
import { CampaignPopFilterController } from "./controllers/campaign_pop_filter_controller";
import { DropdownMenuController } from "./controllers/dropdown_menu_controller";
import { CampaignPopBulkActionController } from "./controllers/campaign_pop_bulk_actions_controller";
import { SignedS3Upload } from "./controllers/signed_s3_upload_controller";
import { ClonePackageController } from "./controllers/clone_package_controller";
import campaign_creatives_base from "./controllers/campaign_creatives_base_controller";
import campaign_creatives from "./controllers/creatives/creatives_controller";
import campaign_production_orders from "./controllers/creatives/production_orders_controller";
import creative_card_controller from "./controllers/creatives/creative_card_controller";
import order_card_controller from "./controllers/creatives/order_card_controller";
import { ToggleHideElementController } from "./controllers/toggle_hide_element_controller";
import { ToggleElementController } from "./controllers/toggle_element_controller";
import { GalleryImageModalController } from "./controllers/image_modal_controller";
import { FileDownloadController } from "./controllers/shared/file_download_controller";
import { SupplierAddressModalController } from "./controllers/creatives/supplier_address_modal_controller";
import { HandleFormToastController } from "./controllers/shared/handle_form_toast_controller";
import dsp_billing_modal_controller from './controllers/programmatic/dsp-billing-modal_controller';
import { TurboDialog } from "./controllers/shared/turbo_dialog_controller.js";
import { AutoOpenDialog } from "./controllers/shared/auto_open_dialog_controller.js";
import { CopyToClipboard } from "./controllers/shared/copy_to_clipboard_controller.js";
const application = Application.start();

const allControllers = {
  audiences,
  select,
  multi_select,
  multi_select_async,
  datepicker,
  quill,
  handsontable,
  toast,
  drag_sortable_list,
  dropdown,
  sortable,
  csvbox,
  upload_file,
  read_more,
  single_select
};

// add reflex on the global scope
window.StimulusReflex = StimulusReflex;
window.application = application;

for (let key in allControllers) {
  const controller = allControllers[key]?.default || allControllers[key];
  key = key.replaceAll("_", "-");
  application.register(key, controller);
}

application.register("tippy", tippy_controller);
application.register("adquick-requests", adquick_requests_controller);
application.register("campaigns", campaigns_controller);
application.register("campaigns-sidebar", campaigns_sidebar_controller);
application.register("campaigns-audiences", campaigns_audiences_controller);
application.register("campaigns-filter", campaigns_filter_controller);
application.register("campaigns-export-dropdown", campaigns_export_dropdown_controller);
application.register("campaigns-drawer", campaigns_drawer_controller);
application.register("campaigns-modal-actions", campaigns_modal_actions_controller);
application.register("browse", browse_controller);
application.register("browse-add-units", browse_add_units_controller);
application.register("browse-sidebar", browse_sidebar_controller);
application.register("browse-modal-actions", browse_modal_actions_controller);
application.register("browse-filter", browse_filter_controller);
application.register("browse-full-access-modal", browse_full_access_modal_controller);
application.register("browse-audiences", browse_audiences_controller);
application.register("external-api-task-download", ExternalApiTaskDownloadController);
application.register("image-card", image_card_controller);
application.register("confirm-modal", confirm_modal_controller);
application.register("confirm-no-inventory-modal", confirm_no_inventory_modal_controller);
application.register("image-modal", image_modal_controller);
application.register("package-modal", package_modal_controller);
application.register("auto-recommend-units-modal", auto_recommend_units_modal_controller);
application.register("find-supplier-replacements-modal", find_supplier_replacements_modal_controller);
application.register("addon-modal", addon_modal_controller);
application.register("removal-reason-modal", removal_reason_modal_controller);
application.register("remove-units", remove_units_controller);
application.register("spec-modal", spec_modal_controller);
application.register("package-card", package_card_controller);
application.register("requests-actions", requests_actions_controller);
application.register("requests-attachment", requests_attachment_controller);
application.register("requests-downloads", requests_downloads_controller);
application.register("requests-header", requests_header_controller);
application.register("view-sugestions", view_sugestions_controller);
application.register("requests-images", requests_images_controller);
application.register("requests-specs", requests_specs_controller);
application.register("spec-card", spec_card_controller);
application.register("application", application_controller);
application.register("campaign-header", campaign_header_controller);
application.register("cluster-tooltip", cluster_tooltip_controller);
application.register("custom-language-modal", custom_language_modal_controller);
application.register("card-context-menu", card_context_menu_controller);
application.register("dashboard-account", account_dashboard_controller);
application.register("dashboard-filters", dashboard_filters_controller);
application.register("impressions-tracker", impressions_tracker_controller);
application.register("conversions-tracker", conversions_tracker_controller);
application.register("pop-gallery", pop_gallery_controller);
application.register("price-loader", price_loader_controller);
application.register("export-templates", export_templates_controller);
application.register("contract-templates", contract_templates_controller);
application.register("color-picker", color_picker_controller);
application.register("dashboard-view-options", dashboard_view_options_controller);
application.register("checkouts", checkouts_controller);
application.register("payment", payment_controller);
application.register("checkout-success", checkout_success_controller);
application.register("inventory-grid", inventory_grid_controller);
application.register("map", map_controller);
application.register("new-campaign", new_campaign_controller);
application.register("campaign-attachments", campaign_attachments_controller);
application.register("new-campaign-component", new_campaign_component_controller);
application.register("campaign-screen-and-media-types-component", campaign_screen_and_media_types_component_controller);
application.register("campaign-due-date-component", campaign_due_date_component_controller);
application.register("campaign-markets-component", campaign_markets_component_controller);
application.register("campaign-date-types", campaign_date_types);
application.register("campaign-use-office-as-poi", campaign_branch_use_office_as_poi);
application.register("reach-frequency", reach_frequency_controller);
application.register("rfp-preview", rfp_preview_controller);
application.register("send-rfp", send_rfp_controller);
application.register("supplier-attachment", supplier_attachment_controller);
application.register("vendor-search", vendor_search_controller);
application.register("impersonate", impersonate_controller);
application.register("tabs", tabs);
application.register("campaign-manage-units-base", campaign_manage_units_base);
application.register("campaign-strategy", campaign_strategy_controller);
application.register("campaign-filter", campaign_filter);
application.register("campaign-filter-popup", campaign_filter_popup);
application.register("campaign-manage-units", campaign_manage_units);
application.register("campaign-manage-unit-card", campaign_manage_unit_card);
application.register("draw-tool-modal", draw_tool_modal_controller);
application.register("campaigns-proofs", proofs_controller);
application.register("add-flights-bulk-modal", add_flights_bulk_modal_controller);
application.register("overview-settings", overview_settings_controller);
application.register("full-page-modal-component", full_page_modal_controller);
application.register("campaign-overview", campaign_overview_controller);
application.register("update-dates-modal", update_dates_modal);
application.register("add-contract-modal", add_contract_modal);
application.register("request-holds-modal", request_holds_modal);
application.register("production-cost-modal", production_cost_modal);
application.register("campaign-avails-batch", CampaignAvailsBatch);
application.register("vendor-contracts", vendor_contracts_page);
application.register("vendor-contracts-filters", vendor_contracts_filters);
application.register("vendor-contract-unit-modal", vendor_contract_unit_modal);
application.register("vendor-negotiation", vendor_negotiation);
application.register("vendor-negotiation-item", vendor_negotiation_item);
application.register("vendor-send-contract-modal", vendor_send_contract_modal);
application.register("vendor-adjust-contract-item-price-modal", vendor_adjust_contract_item_price_modal);
application.register("vendor-confirmation-modal", vendor_confirmation_modal);
application.register("vendor-upload-contract-modal", vendor_upload_contract_modal);
application.register("vendor-proposal", vendor_proposal);
application.register("vendor-price-proposal-modal", vendor_price_proposal_modal);
application.register("expandable", expandable_controller);
application.register("expandable-checkbox", expandable_checkbox_controller);
application.register("exit-confirmation", exit_confirmation_controller);
application.register("carousel", carousel_controller);
application.register("new-campaign-grid", NewCampaignGridController);
application.register("list-search", ListSearchController);
application.register("query-string-track", QueryStringTrackController);
application.register("campaigns-market-search", CampaignsMarketSearchController);
application.register("campaigns-markets-results", CampaignsMarketsResultsController);
application.register("pop-card", PopCardController);
application.register("select-all", SelectTargetsController);
application.register("campaign-pop-filter", CampaignPopFilterController);
application.register("dropdown-menu", DropdownMenuController);
application.register("campaign-pop-bulk-actions", CampaignPopBulkActionController);
application.register("s3-signed-upload", SignedS3Upload);
application.register("clone-package", ClonePackageController);
application.register("campaign-creatives-base", campaign_creatives_base);
application.register("campaign-creatives", campaign_creatives);
application.register("campaign-production-orders", campaign_production_orders);
application.register("creative-card", creative_card_controller);
application.register("creative-supplier-address-modal", SupplierAddressModalController);
application.register("order-card", order_card_controller);
application.register("toggle-hide-element", ToggleHideElementController);
application.register("toggle-element", ToggleElementController);
application.register("gallery-image-modal", GalleryImageModalController);
application.register("file-download", FileDownloadController);
application.register("handle-form-toast", HandleFormToastController);
application.register("dsp-billing-modal", dsp_billing_modal_controller);
application.register("turbo-dialog", TurboDialog);
application.register("auto-open-dialog", AutoOpenDialog);
application.register("copy-to-clipboard", CopyToClipboard);
StimulusReflex.initialize(application, {
  consumer,
  isolate: true,
});
StimulusReflex.debug = process.env.NODE_ENV === "development";
AdquickRequestsChannel.start();
CampaignSupplierContractsChannel.start();
CreativesChannel.start();
BrowseChannel.start();
