import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  static values = {
    supplierTaskId: String,
    campaignUnitId: String,
    unitId: String,
    replacementId: Number,
  };
  async showModal(e) {
    const payload = {
      supplier_task_id: this.supplierTaskIdValue,
      unit_id: this.unitIdValue,
      campaign_unit_id: this.campaignUnitIdValue,
    };
    const modalPayload = {
      component: "modal/find_supplier_replacements_component",
      component_params: {
        replacement_for_id: this.campaignUnitIdValue,
        supplier_task_id: this.supplierTaskIdValue,
        unit_id: this.unitIdValue,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", modalPayload);
    return window.modal.open();
  }

  async showFaceIdModal(e) {
    const payload = {
      supplier_task_id: this.supplierTaskIdValue,
      unit_id: this.unitIdValue,
      campaign_unit_id: this.campaignUnitIdValue,
    };
    const modalPayload = {
      component: "modal/find_supplier_replacements_by_face_id_component",
      component_params: {
        replacement_for_id: this.campaignUnitIdValue,
        supplier_task_id: this.supplierTaskIdValue,
        unit_id: this.unitIdValue,
        unit_ids: [],
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", modalPayload);
    return window.modal.open();
  }

  async removeReplacement() {
    confirm("Are you sure you want to remove this replacement?");

    await this.stimulate("Plus::ReplacementsReflex#remove", {
      replacement_id: this.replacementIdValue,
      supplier_task_id: this.supplierTaskIdValue,
    });
  }
}
