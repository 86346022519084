import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["form"];

  set selectedTokens(tokens) {
    this._selectedTokens = [...new Set(tokens)];
  }

  get selectedTokens() {
    return this._selectedTokens;
  }

  connected() {
    this._selectedTokens = [];
  }

  addTokens(tokens) {
    this.selectedTokens = [...tokens, ...this.selectedTokens];
  }

  removeTokens(tokens) {
    this.selectedTokens = this.selectedTokens.filter(token => !tokens.includes(token));
  }

  hasSelectedTokens() {
    return this.selectedTokens.length > 0;
  }
}
