export default class ClusterHighlightLayer {
  static HIGHLIGHTED_CLUSTER_SOURCE_ID = "highlighted-clusters";
  static HIGHLIGHTED_CLUSTER_LAYER_ID = "highlighted-cluster-markers";

  constructor(map, source, clusterLayerId, circleLayerId) {
    this.map = map;
    this.source = source;
    this.clusterLayerId = clusterLayerId;
    this.circleLayerId = circleLayerId;

    this.#createHighlightedClusterSource();
  }

  async highlightCluster(unitId) {
    if (!this.map.getLayer(this.clusterLayerId)) {
      return;
    }

    const cluster_filter = this.map
      .queryRenderedFeatures(undefined, { layers: [this.clusterLayerId] })
      .map(async cluster => {
        const leaves = await this.#findLeaves(cluster.properties);
        if (leaves.find(u => unitId === u.properties.id)) {
          return cluster;
        }
      });

    const highlighted_clusters = (await Promise.all(cluster_filter)).filter(c => c);

    if (!highlighted_clusters.length) return;

    this.#removeLayerIfExists();
    this.map
      .getSource(ClusterHighlightLayer.HIGHLIGHTED_CLUSTER_SOURCE_ID)
      .setData({ type: "FeatureCollection", features: highlighted_clusters });
    this.#addHighlightedClustersLayer();
  }

  unhighlightCluster() {
    this.#removeLayerIfExists();
  }

  #createHighlightedClusterSource() {
    this.map.addSource(ClusterHighlightLayer.HIGHLIGHTED_CLUSTER_SOURCE_ID, {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
      buffer: 1,
    });
  }

  #removeLayerIfExists() {
    if (this.map.getLayer(ClusterHighlightLayer.HIGHLIGHTED_CLUSTER_LAYER_ID))
      this.map.removeLayer(ClusterHighlightLayer.HIGHLIGHTED_CLUSTER_LAYER_ID);
  }

  #addHighlightedClustersLayer() {
    this.map.addLayer(
      {
        id: ClusterHighlightLayer.HIGHLIGHTED_CLUSTER_LAYER_ID,
        type: "circle",
        source: ClusterHighlightLayer.HIGHLIGHTED_CLUSTER_SOURCE_ID,
        paint: {
          "circle-color": "#FFF",
          "circle-opacity": 0,
          "circle-stroke-width": 3,
          "circle-stroke-color": "#FEC700",
          "circle-radius": ["step", ["get", "point_count"], 8, 10, 10, 100, 12],
        },
      },
      this.circleLayerId,
    );
  }

  async #findLeaves(cluster) {
    const source = this.map.getSource(this.source);
    return new Promise((resolve, reject) =>
      source.getClusterLeaves(cluster.cluster_id, 1000, 0, (error, features) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(features);
      }),
    );
  }
}
