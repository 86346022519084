import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static values = { token: String, isVendor: Boolean };

  clone() {
    // TODO: Use a reflex rather than an api request
    if (confirm("Are you sure you want to duplicate this campaign?")) {
      $.post(`/api/v1/campaigns/${this.tokenValue}/clone?vendor_clone=${this.isVendorValue}`).then(response => {
        if (response.status === 200)
          this.stimulate("UI::ToastReflex#trigger_toast", "success", "Success cloning the campaign");
        else {
          console.info(`couldn't clone campaign ${this.tokenValue}, ${response.status}`);
        }
      });
    }
  }

  archive() {
    // TODO: Use a reflex rather than an api request
    if (confirm("Are you sure you want to archive this campaign?")) {
      $.ajax(`/api/v1/campaigns/${this.tokenValue}.json`, { method: "DELETE" }).then(response => {
        if (response.status === 200)
          this.stimulate("UI::ToastReflex#trigger_toast", "success", "Successfully archived the campaign");
        else {
          console.info(`couldn't delete campaign ${this.tokenValue}, ${response.status}`);
        }
      });
    }
  }
}
