import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  async openImageModal() {
    const payload = {
      component: "modal/rfp_image_component",
      component_params: {
        unit_id: this.data.get("unitId"),
        redirect_to: this.data.get("currentPath"),
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async deleteUnit() {
    const payload = {
      campaign_unit_id: this.data.get("campaignUnitId"),
      row_id: this.data.get("rowId"),
    };
    await this.stimulate("Plus::AdquickRequestsReflex#remove_unit_from_image_page", payload);
  }
}
