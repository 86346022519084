import { Filter } from "./filter";
import Point from "@mapbox/point-geometry";
import { attempt, map } from "lodash";

const ACCESS_TOKEN = "pk.eyJ1IjoiZmFoaW1mIiwiYSI6ImNpeTNhMXFmYTAwM3kyd21udWc4dzEzeWIifQ.-UQVnwzTeUjjOVjrBNgS1g";
const DEFAULT_STYLE = "mapbox://styles/fahimf/cjy0g4rbh0y1d1cledi9pf3nk";
const MINIMO_STYLE = "mapbox://styles/fahimf/cjkvl6f3409cg2rpd5oa5k3ut";
const SATELLITE_STYLE = "mapbox://styles/mapbox/satellite-v9";

declare global {
  interface Window {
    deck: {
      [key: string]: any;
    };
  }
}

type Layer = any;
type ViewState = any;
type HammerInput = any;

export default class Map {
  deck: any; // deck.gl look for doc: https://github.com/visgl/deck.gl/blob/master/docs/get-started/using-standalone.md
  mapbox: any; // mapbox object
  lastZoom: number;
  layers: Layer[];
  filter: Filter;
  element: HTMLElement;

  constructor(element: string, layers = [], viewState = null, getTooltip = null, style = null) {
    console.log("map init", element, layers, viewState);
    this.lastZoom = (viewState && viewState.zoom) || 3.3;
    this.layers = layers;
    this.element = element;

    const initialViewState = viewState || {
      longitude: -89,
      latitude: 35.5,
      zoom: this.lastZoom,
      maxZoom: 18,
    };

    this.deck = new window.deck.DeckGL({
      container: element,
      mapboxApiAccessToken: ACCESS_TOKEN,
      mapStyle: this.getMapStyles(style),
      onViewStateChange: this.onViewStateChange.bind(this),
      onHover: this.onHover.bind(this),
      glOptions: {
        preserveDrawingBuffer: false, // set true if you want to rasterize the canvas -- canvas.drawImage()
      },
      initialViewState,
      controller: true,
      layers: this.getLayers({ zoom: this.lastZoom }),
      getTooltip: getTooltip || (() => {}),
    });

    this.mapbox = this.deck.getMapboxMap();
    this.mapbox.on("statechange", this.onStateChange.bind(this));

    return this;
  }

  getMapStyles(style: string) {
    const styles = {
      minimo: MINIMO_STYLE,
      default: DEFAULT_STYLE,
      satellite: SATELLITE_STYLE,
    };
    return styles[style] || styles["default"];
  }

  getLayers(viewState?: ViewState): Layer[] {
    return [...this.layers];
  }

  getLayer(layerId: string): Layer {
    return this.deck.layerManager.layers.find(layer => layer.id === layerId);
  }

  getViewPortRadius(): number {
    const x = this.element.clientWidth;
    const y = this.element.clientHeight;
    const point = this.mapbox.unproject(new Point(x, y));
    const center = this.mapbox.getCenter();
    return turf.distance(turf.point([point.lng, point.lat]), turf.point([center.lng, center.lat]), { units: "meters" });
  }

  hasLayer(layerId: string): boolean {
    return !!this.getLayer(layerId);
  }

  onStateChange(): void {
    // do something
  }

  onRedraw(motive: string): void {
    // when redraw a layer do this
  }

  onHover(pick: any, input: HammerInput): void {
    // do onHover stuff
  }

  onStyleChange(newStyle: string) {
    // do onStylechange stuff
  }

  async setStyle(mapStyle: string) {
    this.mapbox.setStyle(mapStyle);
    await this.waitFor(() => this.mapbox.isStyleLoaded() && this.mapbox.getStyle().name === mapStyle);
    this.onStyleChange(mapStyle);
  }

  setSatelliteStyle(): void {
    this.setStyle(SATELLITE_STYLE);
  }

  setDefaultStyle(): void {
    this.setStyle(DEFAULT_STYLE);
  }

  onZoomChange(newZoom: number): void {
    // add zoom change actions
  }

  onViewStateChange({ viewState }): void {
    if (viewState.zoom !== this.lastZoom) {
      this.lastZoom = viewState.zoom;
      this.onZoomChange(viewState.zoom);
    }

    this.mapbox.fire("statechange");
    // add view change actions
  }

  addLayers(layersToAdd: (Layer | null)[]): Map {
    layersToAdd = layersToAdd.filter(l => l);
    const newIDs = new Set(layersToAdd.map(l => l.id));
    this.layers = [...this.layers.filter(l => !newIDs.has(l.id)), ...layersToAdd];
    this.redrawLayers(`new layers ADDED: ${layersToAdd.map(l => l.id).join(",")}`);
    return this;
  }

  async addUnderLayer(layer: any, visible: boolean) {
    if (this.mapbox.getLayer(layer.id)) {
      this.mapbox.removeLayer(layer.id);
      await this.waitFor(() => !this.mapbox.getLayer(layer.id));
    }
 
    this.mapbox.addLayer(layer, await this.getFirstLabelLayerId());
    this.changeVisibility(layer.id, visible);
  }

  changeVisibility(layerID: String, visible: boolean) {
    if (!this.mapbox.getLayer(layerID)) return;

    if (visible) {
      this.mapbox.setLayoutProperty(layerID, "visibility", "visible");
    } else {
      this.mapbox.setLayoutProperty(layerID, "visibility", "none");
    }
  }

  async getFirstLabelLayerId() {
    const style = await this.getStyle();
    if (style.name === "PRODUCTION") return "road-primary";
    if (style.name === "Mapbox Satellite") return undefined;

    const layers = style.layers;
    // Find the index of the first symbol (i.e. label) layer in the map style
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].type === "symbol") {
        return layers[i].id;
      }
    }
    return undefined;
  }

  async getStyle() {
    await this.waitFor(() => this.mapbox.isStyleLoaded());
    return this.mapbox.getStyle();
  }

  async waitFor(callback: any) {
    await this.sleep(0);

    let attempts = 0;
    while (attempts++ < 40) {
      if (callback()) return;

      await this.sleep(50);
    }
  }

  async sleep(time: Number) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  removeLayer(layerId: string): Map {
    if (this.layers) {
      this.layers = this.layers.filter(layer => layer.id !== layerId);
    }
    this.redrawLayers(`layer REMOVED: ${layerId}`);
    return this;
  }

  redrawLayers(motive: string, details?: any): void {
    if (window.DEBUG_MAP) {
      console.info(`%cMap redraw: ${motive}`, "color: #1f7faf");

      if (details) {
        console.info("redraw", motive, details);
      }
    }
    this.deck.setProps({ layers: this.getLayers() });
    this.onRedraw(motive, details);
  }

  onFilter(s: Set<number>): void {
    // on filter
  }

  // bounds format: [[min_lat, min_lon],[max_lat, max_lon]]
  flyToBounds(bounds: [[Number, Number], [Number, Number]]) {
    this.mapbox.fitBounds(bounds, {
      easing: () => 1,
      padding: { top: 60, bottom: 10, left: 200, right: 10 },
    });

    setTimeout(() => {
      this.flyTo({
        lat: this.mapbox.getCenter().lat,
        lon: this.mapbox.getCenter().lng,
        zoom: this.mapbox.getZoom(),
        duration: 1,
        speed: 10,
      });
    }, 100);
  }

  flyTo({ lat, lon, zoom, duration, speed }) {
    this.deck.setProps({
      initialViewState: {
        zoom: zoom || 12,
        pitch: this.deck.viewState.pitch,
        bearing: this.deck.viewState.bearing,
        latitude: lat,
        longitude: lon,
        transitionDuration: duration || 1000,
        transitionInterpolator: new window.deck.FlyToInterpolator({ speed: speed || 2 }),
      },
    });
  }

  setLoading(): Map {
    this.element.classList.add("is_loading");
    return this;
  }

  unsetLoading(): Map {
    this.element.classList.remove("is_loading");
    return this;
  }

  waitReflexLoading(eventName = "stimulus-reflex:after", selector = null) {
    this.setLoading();

    const eventHandler = event => {
      if (!selector || selector === event?.detail?.selector?.trim()) {
        this.unsetLoading();
        document.removeEventListener(eventName, eventHandler);
      }
    };

    document.addEventListener(eventName, eventHandler);
  }

  _debug(flag = false): void {
    if (flag === true) {
      window.DEBUG_MAP = true;
      this.redrawLayers("Debug mode: ON");
    } else {
      window.DEBUG_MAP = false;
      this.redrawLayers("Debug mode: OFF");
    }
  }
}
