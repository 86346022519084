import ApplicationController from "./application_controller";

export class ExternalApiTaskDownloadController extends ApplicationController {
  connect() {
    super.connect();
    console.log("connected");
    window.addEventListener("download:finished", this.downloadDone);
  }

  disconnect() {
    window.removeEventListener("download:finished", this.downloadDone);
  }

  downloadDone = e => {
    const url = e.detail.url;
    window.open(url, "_blank");
  };

  call(e) {
    const id = e.target.dataset.taskId;
    const campaignToken = e.target.dataset.campaignToken;
    const supplierId = e.target.dataset.supplierId;

    if (id) {
      this.stimulate("ExternalApiTaskDownloadReflex#call", { id: id });
    } else {
      this.stimulate("ExternalApiTaskDownloadReflex#call", { campaignToken: campaignToken, supplierId: supplierId });
    }
  }

  beforeReflex() {
    Array.prototype.map.call(document.getElementsByClassName("download"), e => {
      e.classList.add("wait");
      e.disabled = true;
    });
  }

  afterReflex() {
    Array.prototype.map.call(document.getElementsByClassName("download"), e => {
      e.classList.remove("wait");
      e.disabled = false;
    });
  }
}
