import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["rightIcon", "searchField"];

  search() {
    const query = this.searchFieldTarget.value;
    this.stimulateSearch(query);
  }

  clearSearch(e) {
    if (this.isLoading()) {
      return;
    }
    this.searchFieldTarget.value = "";
    this.stimulateSearch("");
  }

  async stimulateSearch(query) {
    this.startLoading();
    await this.stimulate("Campaigns::Manage::Strategy::MarketsReflex#search", { query });
    this.finishLoading();
  }

  isLoading() {
    return this.rightIconTarget.classList.contains("loading-spin");
  }

  removeIconClasses() {
    return ["fal", "fa-times-circle", "pointer"];
  }

  spinnerIconClasses() {
    return ["fad", "fa-spinner-third", "loading-spin"];
  }

  toggleRemoveIcon() {
    const classes = this.removeIconClasses();
    classes.forEach(c => this.rightIconTarget.classList.toggle(c));
  }

  toggleSpinnerIcon() {
    const classes = this.spinnerIconClasses();
    classes.forEach(c => this.rightIconTarget.classList.toggle(c));
  }

  startLoading() {
    this.toggleRemoveIcon();
    this.toggleSpinnerIcon();
  }

  finishLoading() {
    this.toggleSpinnerIcon();
    this.toggleRemoveIcon();
  }
}
