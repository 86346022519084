export default class MousePointer {
  constructor(map, layer) {
    map.on("mouseenter", layer, this.#handleMouseEnterLayer.bind(this));
    map.on("mouseleave", layer, this.#handleMouseLeaveLayer.bind(this));
  }

  #handleMouseEnterLayer(event) {
    const map = event.target;
    map.getCanvas().style.cursor = "pointer";
  }

  #handleMouseLeaveLayer(event) {
    const map = event.target;
    map.getCanvas().style.cursor = "";
  }
}
