import ApplicationController from "./application_controller";
import PlusMap from "../map/index";
import { Units } from "../map/units";
import { Swal } from "../../../shared/plus";

export default class extends ApplicationController {
  static targets = ["map", "data", "toggleAllUnits", "allUnitsTooltip", "tag", "audiencesAddTagContainer"];

  async connected() {
    this.campaignToken = this.data.get("campaignToken");
    this.map = new PlusMap(this.mapTarget, this.onClick.bind(this), this.campaignToken);
    window.map = this.map;

    this.units = new Units(this.map, this.dataTarget).connect();

    this.map.updateLayers();
    this.disableAllUnitsButton();
  }

  disconnect() {
    this.units.disconnect();
  }

  enableAllUnitsButton() {
    this.toggleAllUnitsTarget.classList.remove("loading");
    this.allUnitsTooltipTarget.innerHTML = "All Units";
  }

  disableAllUnitsButton() {
    this.toggleAllUnitsTarget.classList.add("loading");
    this.allUnitsTooltipTarget.innerHTML = "Loading...";
  }

  onClickAllUnits(e) {
    if (this.toggleAllUnitsTarget.classList.contains("loading")) return;
    this.paramsAdd("mode", "all");
    this.stimulate();
    this.map.toggleLayers("all_units");
  }

  onClickCampaignUnits(e) {
    this.paramsAdd("mode", "campaign");
    this.stimulate();
    this.map.toggleLayers("campaign_units");
  }

  async onClickTagUnits(e) {
    try {
      const tag = this.tagTarget.value;
      if (!tag) return;

      const unit_ids = this.map.filter.filtered;
      this.audiencesAddTagContainerTarget.classList.add("loading");
      await this.stimulate("Plus::CampaignUnitTagsReflex#add", { tag, unit_ids });
      this.tagTarget.value = "";
      Swal.mixin({
        toast: true,
        position: "bottom-start",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      }).fire({
        icon: "success",
        title: "Tag successfully added to campaign",
      });
    } catch (e) {
      Swal.mixin({
        toast: true,
        position: "bottom-start",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      }).fire({
        icon: "error",
        title: "Could not create tag.",
      });
      console.error(e.toString());
      if (window.Rollbar) Rollbar.error("Could not create tag from audiences filter.", e.toString());
    } finally {
      this.audiencesAddTagContainerTarget.classList.remove("loading");
    }
  }

  onClick(unit) {
    const event = new CustomEvent("unitClick", { detail: unit.id });
    this.unitPanelTarget.dispatchEvent(event);
  }

  flyToGeo(event) {
    const lat = parseFloat(event.currentTarget.dataset.lat);
    const lon = parseFloat(event.currentTarget.dataset.lon);
    this.map.flyTo({ lat, lon });
  }
}
