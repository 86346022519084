import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  static targets = ["card"];

  selectedCards() {
    return this.cardTargets.filter(el => el.checked);
  }

  selectedCardsIds() {
    return this.selectedCards().map(el => el.dataset.cardUnitId);
  }

  toggleAll(e) {
    const { checked } = e.target;
    this.cardTargets.forEach(el => {
      if (checked) {
        return el.setAttribute("checked", checked);
      }
      return el.removeAttribute("checked");
    });
  }

  massUploadSelected() {
    const unitIds = this.selectedCardsIds();
    if (unitIds.length == 0) {
      return alert("Please select at least one card");
    }
    this.openImageModal(unitIds);
  }

  massDeleteSelected() {
    const selectedCards = this.selectedCards();
    if (selectedCards.length == 0) {
      return alert("Please select at least one card");
    }
    const campaign_unit_ids = selectedCards.map(el => el.dataset.campaignUnitId);
    const row_ids = selectedCards.map(el => el.dataset.rowId);
    const payload = {
      campaign_unit_ids,
      row_ids,
      page: "images",
    };
    this.stimulate("Plus::AdquickRequestsReflex#bulk_remove_units_from_rfp", payload);
  }

  async openImageModal(unitIds) {
    const payload = {
      component: "modal/rfp_image_component",
      component_params: {
        unit_ids: unitIds,
        redirect_to: this.data.get("currentPath"),
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }
}
