export default class LayerLoadingIndicator {
  constructor(map, sourceId) {
    this.sourceId = sourceId;
    map.on("sourcedataloading", this.#handleMapSourceDataLoading.bind(this));
    map.on("sourcedata", this.#handleMapSourceData.bind(this));
  }

  #handleMapSourceDataLoading(event) {
    if (!event.tile && event.sourceId == this.sourceId && event.source.data != this.data) {
      this.data = event.source.data;
      this.#showLoadingIndicator();
    }
  }

  #handleMapSourceData(event) {
    if (
      !event.tile &&
      event.sourceDataType == "content" &&
      event.sourceId == this.sourceId &&
      event.source.data == this.data
    ) {
      this.#hideLoadingIndicator();
    }
  }

  #showLoadingIndicator() {
    if (this.#getLoadingIndicator()) return;
    document.querySelector(".mapboxgl-ctrl-top-left")?.appendChild(this.#loadingIndicator());
  }

  #hideLoadingIndicator() {
    clearTimeout(this.timeout);
    this.#getLoadingIndicator()?.remove();
  }

  #getLoadingIndicator() {
    return document.querySelector(".loading-indicator");
  }

  #loadingIndicator() {
    const placeholder = document.createElement("div");
    placeholder.innerHTML = `
      <div class='loading-indicator'>
        <i class="fal fa-spinner fa-spin-pulse" style="font-size: 18px; padding: 9px; margin: 0;"></i>
      </div>
    `
      .replace(/\s{2,}/g, " ")
      .trim();
    return placeholder.firstElementChild;
  }
}
