import ApplicationController from "./application_controller";
export class PopCardController extends ApplicationController {
  static values = {
    campaignUnitId: Number,
    campaignUnitToken: String,
    campaignUnitEndDate: String,
    unitSlug: String,
    campaignToken: String,
    showPopForm: String,
  };

  async connect() {
    StimulusReflex.register(this);
  }

  async openUnitModal(e) {
    e.preventDefault();
    const payload = {
      component: "modal/browse_unit_modal_component",
      component_params: {
        unit_id: this.unitSlugValue,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  async showPostedDateModal(e) {
    const showPopForm = e.target.dataset.showPopForm;
    e.preventDefault();
    const payload = {
      component: "pro/unit/update_posted_date_component",
      component_params: { campaign_unit_id: this.campaignUnitIdValue, show_pop_form: showPopForm },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }

  async showTakedownDateModal(e) {
    e.preventDefault();
    const payload = {
      component: "pro/unit/update_takedown_date_component",
      component_params: {
        campaign_unit_tokens: [this.campaignUnitTokenValue],
        campaign_id: this.campaignTokenValue,
        min_end_date: this.campaignUnitEndDateValue,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }

  async showDeliveredImpressionsModal(e) {
    e.preventDefault();
    const payload = {
      component: "pro/unit/update_delivered_impressions_component",
      component_params: { campaign_unit_id: this.campaignUnitIdValue },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }

  async openUploadModal(e, popType) {
    e.preventDefault();

    const payload = {
      component: "pro/unit/upload_pop_component",
      component_params: { campaign_unit_id: this.campaignUnitIdValue, pop_type: popType },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }
  async showPopUploadModal(e) {
    return this.openUploadModal(e, "pop");
  }

  async showGlamourPopUploadModal(e) {
    return this.openUploadModal(e, "glamour_pop");
  }

  async deletePop(e) {
    e.preventDefault();
    const url = e.currentTarget.href;
    e.target.classList.toggle("loading-shine");
    const response = await fetch(url, { method: "DELETE" });
    if (response.ok) {
      e.target.classList.toggle("loading-shine");
      this.stimulate("Pro::Unit::PopCardReflex#render_card", { campaign_unit_token: this.campaignUnitTokenValue });
    }
  }

  async toggleShowOnOverview(e) {
    e.preventDefault();
    const url = e.target.dataset.href;
    const value = e.currentTarget.checked;
    const payload = JSON.stringify({ show_pop_on_overview: value });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    e.target.classList.toggle("loading-shine");

    const response = await fetch(url, { method: "POST", body: payload, headers: headers });
    if (response.ok) {
      e.target.classList.toggle("loading-shine");
      this.stimulate("Pro::Unit::PopCardReflex#render_card", { campaign_unit_token: this.campaignUnitTokenValue });
    }
  }

  async onFormResponse(e) {
    switch (this.showPopFormValue) {
      case "pop":
        return this.showPopUploadModal(e);
      case "glamour_pop":
        return this.showGlamourPopUploadModal(e);
      default:
        await window.modal.close();
        this.stimulate("Pro::Unit::PopCardReflex#render_card", { campaign_unit_token: this.campaignUnitTokenValue });
    }
  }
}
