import ApplicationController from "./application_controller";
export class GalleryImageModalController extends ApplicationController {
  static values = {
    url: String,
  };

  async open(e) {
    e.preventDefault();
    const payload = {
      component: "modal/image_modal_component",
      component_params: {
        url: this.urlValue,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", e.target, payload);
    window.modal.open();
  }
}
