import BaseController from "./base_controller";

export default class extends BaseController {
  static values = {
    campaignToken: String,
    allFilteredTokens: Array,
    nextPage: Number,
    previousPage: Number,
  };

  static targets = ["unit", "actions", "bulkListContainer", "globalCheckbox", "selectedCount", "selectAllFiltered"];

  isAllCurrentTokensSelected() {
    if (!this.base.hasSelectedTokens()) {
      return false;
    }
    return this.allCurrentTokens().every(token => this.base.selectedTokens.includes(token));
  }

  allCurrentTokens() {
    return this.unitTargets.map(el => el.dataset.orderCardCuTokenValue);
  }

  itemIdFromChecked() {
    const checked = this.unitTargets.filter(el => el.querySelector('input[data-order-card-target="checkbox"]').checked);
    return checked.map(c => c.dataset.orderCardOrderItemIdValue);
  }

  afterConnected() {
    this.handleGlobalCheckboxState();
  }

  handleGlobalCheckboxState() {
    this.handleSelectedDetailsState();
    if (this.isAllCurrentTokensSelected()) {
      return (this.globalCheckboxTarget.checked = true);
    }
    return (this.globalCheckboxTarget.checked = false);
  }

  handleSelectedDetailsState() {
    if (!this.base.hasSelectedTokens()) {
      return this.hideSelectedDetails();
    }
    return this.showSelectedDetails();
  }

  hideSelectedDetails() {
    this.actionsTarget.classList.remove("show-selected");
  }

  showSelectedDetails() {
    this.actionsTarget.classList.add("show-selected");
    this.buildCurrentSelectedState();
  }

  buildCurrentSelectedState() {
    const content = `${this.base.selectedTokens.length} current units selected`;
    this.selectedCountTarget.innerHTML = content;
  }

  selectAllFiltered() {
    this.base.selectedTokens = this.allFilteredTokensValue;
    this.unitTargets.forEach(el => this.activateCard(el));
    this.handleGlobalCheckboxState();
  }

  clearSelection() {
    this.base.selectedTokens = [];
    this.unitTargets.forEach(el => this.deactivateCard(el));
    this.handleGlobalCheckboxState();
  }

  toggleAll(e) {
    const {
      target: { checked },
    } = e;
    if (checked) {
      return this.selectAll();
    }
    return this.unselectAll();
  }

  unselectAll() {
    this.unitTargets.forEach(el => this.deactivateCard(el));
    this.base.removeTokens(this.allCurrentTokens());
    this.handleSelectedDetailsState();
  }

  selectAll() {
    this.unitTargets.forEach(el => this.activateCard(el));
    this.base.addTokens(this.allCurrentTokens());
    this.handleSelectedDetailsState();
  }

  activateCard(card) {
    const cardController = this.getCardController(card);
    return cardController.activateCard();
  }

  deactivateCard(card) {
    const cardController = this.getCardController(card);
    return cardController.deactivateCard();
  }

  getCardController(card) {
    return this.getControllerForElement(card, "order-card");
  }

  noSelectionError() {
    return alert("You need to have selected cards");
  }

  openShippingModal() {
    const payload = {
      component: "modal/orders/shipping_form",
      component_params: {
        campaign_token: this.campaignTokenValue,
        campaign_unit_ids: this.base.selectedTokens,
        production_order_item_ids: this.itemIdFromChecked(),
      },
    };
    this.stimulateModal(payload);
  }

  async handleSuccess(e) {
    window.modal.close();
    await this.stimulate("Plus::Campaigns::OrdersReflex#index");
  }

  async submitProductionOrder(e) {
    const {
      target: {
        dataset: { productionOrderId },
      },
    } = e;
    e.preventDefault();
    const headers = { "Content-Type": "application/json" };
    const options = {
      method: "POST",
      body: JSON.stringify({}),
      headers,
    };
    const response = await fetch(`/api/v1/production_orders/${productionOrderId}/submit`, options);
    if (response.status > 400) {
      return this.processResponseError(response);
    }
    return this.processResponseSuccess(response);
  }

  async onGoToPage(e) {
    const {
      target: {
        dataset: { page },
      },
    } = e;
    if (page == "next" || page == "previous") {
      return this.handlePaginationButton(page);
    }
    this.paramsAdd("page", page);
    await this.stimulate("Plus::Campaigns::CreativesReflex#index");
  }

  async handlePaginationButton(action) {
    const buttons = { next: this.nextPageValue, previous: this.previousPageValue };
    const page = buttons[action];
    if (!page) {
      return;
    }
    this.paramsAdd("page", page);
    await this.stimulate("Plus::Campaigns::CreativesReflex#index");
  }

  async stimulateModal(payload, skipToggle = false) {
    if (!this.base.hasSelectedTokens()) {
      return this.noSelectionError();
    }
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }
}
