import ApplicationController from "./../../application_controller";

export default class extends ApplicationController {
  static targets = ["submitButton"];

  onCheckboxChange(e) {
    if (e.target.checked) {
      return this.enableSubmit();
    }
    this.disableSumit();
  }

  enableSubmit() {
    this.submitButtonTarget.removeAttribute("disabled");
  }

  disableSumit() {
    this.submitButtonTarget.setAttribute("disabled", true);
  }

  onSubmit() {
    this.stimulate("Plus::AdquickRequestsReflex#process_confirm");
  }
}
