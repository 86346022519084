import MapboxglSpiderifier from "mapboxgl-spiderifier";

export default class ClusterSpiderifier {
  constructor(
    map,
    clusterLayerId,
    tooltipController,
    unitSourceId,
    sidebarWidth,
    { leafIdentifier = "unitId", customizeSpiderLegFn = null } = {},
  ) {
    this.map = map;
    this.clusterLayerId = clusterLayerId;
    this.tooltipController = tooltipController;
    this.unitSourceId = unitSourceId;
    this.sidebarWidth = sidebarWidth;
    this.leafIdentifier = leafIdentifier || "unitId";
    this.customizeSpiderLegFn = customizeSpiderLegFn;

    this.spiderifier = new MapboxglSpiderifier(map, {
      customPin: true,
      markerWidth: 13,
      markerHeight: 13,
      initializeLeg: this.#initializeLeg.bind(this),
      onClick: this.#handleClickOnSpiderLeg.bind(this),
    });

    map.on("move", this.spiderifier.unspiderfy);
    map.on("zoom", this.spiderifier.unspiderfy);
    map.on("click", this.spiderifier.unspiderfy);
    map.on("click", this.clusterLayerId, this.#handleClickOnUnitCluster.bind(this));
  }

  #initializeLeg(spiderLeg) {
    const { container, pin } = spiderLeg.elements;

    container.style.width = "15px";
    container.style.height = "15px";

    pin.style.width = "15px";
    pin.style.height = "15px";
    pin.style.backgroundColor = "#c11fb1";
    pin.style.borderRadius = "50%";
    pin.style.border = "2px solid white";
    pin.style.cursor = "pointer";

    const unitId = spiderLeg.feature.id;
    const coordinates = spiderLeg.mapboxMarker._lngLat;
    const offset = MapboxglSpiderifier.popupOffsetForSpiderLeg(spiderLeg);

    offset["top"][1] += 10;
    offset["top-right"][1] += 10;
    offset["top-left"][1] += 10;
    offset["bottom"][1] -= 10;
    offset["bottom-right"][1] -= 10;
    offset["bottom-left"][1] -= 10;

    spiderLeg.elements.pin.addEventListener("mouseenter", () => {
      this.tooltipController.showUnitTooltip({ [this.leafIdentifier]: unitId, coordinates, offset });
    });

    spiderLeg.elements.pin.addEventListener("mouseleave", () => {
      this.tooltipController.hideUnitTooltip();
    });

    if (this.customizeSpiderLegFn) {
      this.customizeSpiderLegFn({ spiderLeg, map: this.map });
    }
  }

  #handleClickOnSpiderLeg(event, spiderLeg) {
    const unitId = spiderLeg.feature.id;
    if (!unitId) return false; // return unless it's a unit "circle" (e.g. not a cluster)
    window.dispatchEvent(new CustomEvent("adquick:browsemap:unitclick", { detail: { [this.leafIdentifier]: unitId } }));
  }

  #handleClickOnUnitCluster(event) {
    const features = this.map.queryRenderedFeatures(event.point, { layers: [this.clusterLayerId] });

    if (!features.length) return;

    const clusterId = features[0].properties.cluster_id;
    const pointCount = features[0].properties.point_count;

    if (!clusterId) return;

    const zoom = this.map.getZoom();
    const source = this.map.getSource(this.unitSourceId);

    let shouldOpenSpider =
      (zoom > 13.6 && pointCount <= 10) || (zoom > 12 && pointCount <= 5) || pointCount <= 3 || zoom > 15.5;

    if (shouldOpenSpider) {
      this.#openSpiderOnCluster(source, clusterId, pointCount, features);
    } else {
      this.#incrementallyFlyTo(event);
    }
  }

  #incrementallyFlyTo(event) {
    const increment = this.map.getZoom() < 14 ? 3.5 : 1;
    this.map.flyTo({
      center: event.lngLat,
      zoom: this.map.getZoom() + increment,
      padding: { left: this.sidebarWidth },
    });
  }

  #openSpiderOnCluster(source, clusterId, pointCount, features) {
    source.getClusterLeaves(clusterId, pointCount, 0, (error, leafFeatures) => {
      if (error) {
        return console.error(error);
      }
      const markers = leafFeatures.map(lf => lf.properties);
      this.spiderifier.spiderfy(features[0].geometry.coordinates, markers);
    });
  }
}
