import ApplicationController from "./../../application_controller";

export default class extends ApplicationController {
  static targets = ["uploadSection", "imageSection", "fileInput", "submitButton", "uploadForm"];

  openFileLibrary() {
    this.fileInputTarget.click();
  }

  validateUrl(e) {
    try {
      new URL(e.target.value);
      return this.enableSubmit();
    } catch (error) {
      return this.disableSumit();
    }
  }

  getFileFromLibrary(e) {
    const file = e.target.files[0];
    if (!file.type.includes("image/")) {
      return this.buildErrorState();
    }
    const blob = new Blob([file], { type: file.type });
    const blobUrl = URL.createObjectURL(blob);
    this.appendImageWithUrl(blobUrl);
  }

  appendImageWithUrl(url) {
    const image = document.createElement("img");
    image.setAttribute("src", url);
    this.imageSectionTarget.appendChild(image);
    this.uploadSectionTarget.classList.remove("show-error");
    this.uploadSectionTarget.classList.add("show-images");
    this.enableSubmit();
  }

  buildErrorState() {
    this.uploadSectionTarget.classList.add("show-error");
  }

  enableSubmit() {
    this.submitButtonTarget.removeAttribute("disabled");
  }

  disableSumit() {
    this.submitButtonTarget.setAttribute("disabled", true);
  }

  onSubmit() {
    this.disableSumit();
    this.uploadFormTarget.submit();
  }

  async delete(event) {
    const element = event.target;
    const imageId = element.parentElement.dataset.imageId;
    try {
      await this.stimulate("UnitImageReflex#delete", imageId);
      element.parentElement.parentElement.remove();
    } catch (error) {
      console.log("Error: " + error);
    }
  }
}
