import ApplicationController from "../application_controller";

// This is a class that is not supposed to be used by itself, it is meant to be extended by other controllers
// This is why some functions are being called but not implemented here
class MapSidebarController extends ApplicationController {
  static targets = ["tab"];

  static values = {
    marketMaxZoom: Number,
  };

  static AUDIENCES_TAB = "audiences";
  static UNITS_TAB = "units";

  connected() {
    this.page = 1;
    this.bounds = null;
    this.render();
    this.#openUnitModalFromURL();
    window.addEventListener("adquick:history:replacestate", this.#handleHistoryReplaceStateDebounced);
    window.addEventListener("adquick:browse:updatesidebar", this.handleUpdateSidebar.bind(this));
    this.handleMapMoveEnd = _.debounce(this.handleMapMoveEnd.bind(this), 500, { leading: false });
  }

  handleSidebarUnitClick(event) {
    const {
      currentTarget: { dataset },
    } = event;
    const { unitId, pushState } = dataset;
    this.openModal(unitId, typeof pushState !== "undefined");
  }

  handleMapUnitClick(event) {
    const unitId = event.detail.unitId;
    this.openModal(unitId, true);
  }

  async handleChangeTab(event) {
    const { currentTarget } = event;
    const {
      dataset: { tab },
    } = currentTarget;
    if (currentTarget.classList.contains("active")) return;
    this.#markItemAsActive(currentTarget, this.tabTargets);
    if (tab === MapSidebarController.AUDIENCES_TAB) {
      await this.stimulate(`${this.reflexValue}#render_audiences`, {});
    }
    this.updateSidebar(tab);
  }

  handleSidebarUnitMouseEnter(event) {
    const {
      currentTarget: { dataset },
    } = event;
    const { unitId } = dataset;
    window.dispatchEvent(new CustomEvent("adquick:map:highlightunit", { detail: { unitId } }));
  }

  handleSidebarUnitMouseLeave(event) {
    const {
      currentTarget: { dataset },
    } = event;
    const { unitId } = dataset;
    window.dispatchEvent(new CustomEvent("adquick:map:unhighlightunit", { detail: { unitId } }));
  }

  handleUpdateSidebar(_event) {
    // Implement in subclass
  }

  async openModal(_unitId, _pushState) {
    console.error('This function is supposed to be implemented by the class that extends MapSidebarController');
  }

  #markItemAsActive(selected, allItems) {
    allItems.forEach(item => item.classList.remove("active"));
    selected.classList.add("active");
  }

  #openUnitModalFromURL() {
    if (this.hasParam("unit_id")) {
      // Workaround because sometimes this would be called before the action cable connection is established
      // so the `this.stimulate` wont work. ApplicationController#waitForConnection didn't prevent this from happening
      // Their docs say we are doing this wrong, we should use another approach: https://docs.stimulusreflex.com/appendices/troubleshooting.html#don-t-initiate-a-reflex-when-your-page-has-finished-loading
      // Other users said that changing to AnyCable fixed similar issues for them
      setTimeout(() => {
        const unitId = this.params["unit_id"];
        this.openModal(unitId);
      }, 500);
    }
  }

  handleMapMoveEnd(event) {
    const { map } = event.detail;
    this.bounds = map.getBounds();
    this.zoom = map.getZoom();
  }

  handleHistoryReplaceState() {
    this.render();
  }

  #handleHistoryReplaceStateDebounced = _.debounce(this.handleHistoryReplaceState.bind(this), 1000, { leading: false });

  handleClickNextPage(event) {
    event.preventDefault();
    this.render(++this.page);
  }

  handleClickPrevPage(event) {
    event.preventDefault();
    this.page = Math.max(1, --this.page);
    this.render(this.page);
  }

  render(page = 1) {
    // implement in subclass
  }

  updateSidebar(tab, page = 1) {
    const min_lng = this.bounds.getWest();
    const max_lng = this.bounds.getEast();
    const min_lat = this.bounds.getSouth();
    const max_lat = this.bounds.getNorth();
    const mixpanel_distinct_id = window.mixpanel.get_distinct_id();
    const options = { page, tab, mixpanel_distinct_id, bounds: { min_lng, max_lng, min_lat, max_lat } };
    this.stimulate(`${this.reflexValue}#update_sidebar`, options);
  }

  toggleVisibility() {
    let isVisible;
    const mapboxglControlContainer = document.querySelector(".mapboxgl-control-container");
    const listViewButton = document.querySelector(".list-view-button");

    if (!this.bounds || !this.zoom || this.zoom < this.marketMaxZoom) {
      isVisible = false;
      this.element.style.overflowY = "hidden";
      this.element.style.left = `${-this.element.clientWidth}px`;
      mapboxglControlContainer.style.left = "0";
      mapboxglControlContainer.style.right = "0";
      listViewButton.classList.add("hidden");
    } else {
      isVisible = true;
      this.element.style.overflowY = "auto";
      this.element.style.left = "0";
      mapboxglControlContainer.style.left = `${this.element.clientWidth}px`;
      mapboxglControlContainer.style.right = "0";
      listViewButton.classList.remove("hidden");
    }

    return isVisible;
  }

  // StimulusReflex callback
  beforeUpdateSidebar() {
    if (this.currentTab() === MapSidebarController.UNITS_TAB) {
      this.element.classList.add("wait");
    }
  }

  // StimulusReflex callback
  afterUpdateSidebar() {
    if (this.currentTab() === MapSidebarController.UNITS_TAB) {
      this.element.classList.remove("wait");
    }
  }

  currentTab() {
    return document.querySelector("#current-tab").value;
  }
}

export default MapSidebarController;
