import MapSidebarController from "../shared/map_sidebar_controller";

export default class extends MapSidebarController {
  static values = {
    campaignId: Number,
    reflex: String,
  };

  render(page = 1) {
    this.updateSidebar(this.currentTab(), page);
  }

  updateSidebar(tab, page = 1) {
    if (this.bounds == null) return;

    const min_lng = this.bounds.getWest();
    const max_lng = this.bounds.getEast();
    const min_lat = this.bounds.getSouth();
    const max_lat = this.bounds.getNorth();
    const options = { campaign_id: this.campaignIdValue, page, tab, bounds: { min_lng, max_lng, min_lat, max_lat } };
    this.stimulate(`${this.reflexValue}#update_sidebar`, options);
  }

  async openModal(unitId, pushState) {
    const payload = {
      component: "modal/campaigns_unit_modal_component",
      component_params: {
        campaign_id: this.campaignIdValue,
        unit_id: unitId,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    if (pushState) this.paramsAdd("unit_id", unitId, false);
    window.modal.open();
  }

  handleMapMoveEnd(event) {
    const { map } = event.detail;
    this.bounds = map.getBounds();
    this.zoom = map.getZoom();
    this.render();
  }

  handleAddOnToggle(_event) {
    const header = document.getElementById("add-on-header-node");
    const list = document.getElementById("add-on-list-node");
    header.classList.toggle("hide");
    list.classList.toggle("hide");
  }

  handleUpdateSidebar() {
    const page = 1;
    this.updateSidebar(this.currentTab(), page);
  }

  handleFavoriteClick(event){
    window.dispatchEvent(new CustomEvent("adquick:map:unit:favorite", {}));
  }

  handleRecommendClick(event) {
    window.dispatchEvent(new CustomEvent("adquick:map:unit:recommend", {}));
  }
}
