import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  async openSpecModal() {
    const payload = {
      component: "modal/rfp_spec_component",
      component_params: {
        unit_id: this.data.get("unitId"),
        redirect_to: this.data.get("currentPath"),
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  onVerifyChange(e) {
    const { checked } = e.target;
    const payload = {
      verified: checked,
      unit_id: this.data.get("unitId"),
    };
    this.stimulate("Plus::AdquickRequestsReflex#toggle_spec_sheet_verification", payload);
  }
}
