import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  useOfficeAsPoiToggle(e) {
    const checked = e.target.checked;
    const poisMilesRadiusWrapper = this.element.querySelector('#pois-miles-radius-wrapper');

    if (checked === true) {
      poisMilesRadiusWrapper.classList.remove('hidden');
    } else {
      poisMilesRadiusWrapper.classList.add('hidden');
      poisMilesRadiusWrapper.querySelector('input').value = '';
    }
  }
}
