import ApplicationController from "../application_controller";
import debounce from "lodash/debounce";

export default class AudiencesController extends ApplicationController {
  static values = {
    filterEnabled: Boolean,
    audienceMode: String,
  };
  connected() {
    this.filterEnabled = this.filterEnabledValue;
    this.useOr = this.audienceModeValue === "or";
    window.addEventListener("adquick:audiences:audienceadded", this.#handleAudienceAdded);
    window.addEventListener("adquick:audiences:scorechanged", this.#handleScoreChanged);
    window.addEventListener("adquick:audiences:audienceremoved", this.#handleAudienceRemoved);
    window.addEventListener("adquick:audiences:audiencefiltertoggled", this.#handleAudienceFilterToggled);
    window.addEventListener("adquick:audiences:audiencemodetoggled", this.#handleAudienceModeToggled);

    window.addEventListener("adquick:audiences:heatmapturnedon", this.#handleHeatmapTurnedOn);
    window.addEventListener("adquick:audiences:heatmapturnedoff", this.#handleHeatmapTurnedOff);
  }

  disconnect() {
    window.removeEventListener("adquick:audiences:audienceadded", this.#handleAudienceAdded);
    window.removeEventListener("adquick:audiences:scorechanged", this.#handleScoreChanged);
    window.removeEventListener("adquick:audiences:audienceremoved", this.#handleAudienceRemoved);
    window.removeEventListener("adquick:audiences:audiencefiltertoggled", this.#handleAudienceFilterToggled);
    window.removeEventListener("adquick:audiences:audiencemodetoggled", this.#handleAudienceModeToggled);

    window.removeEventListener("adquick:audiences:heatmapturnedon", this.#handleHeatmapTurnedOn);
    window.removeEventListener("adquick:audiences:heatmapturnedoff", this.#handleHeatmapTurnedOff);

    super.disconnect();
  }

  #handleAudienceAdded = _.debounce(this.handleAudienceAdded.bind(this), 500, { leading: true });
  #handleScoreChanged = _.debounce(this.handleScoreChanged.bind(this), 500, { leading: true });
  #handleAudienceRemoved = _.debounce(this.handleAudienceRemoved.bind(this), 500, { leading: true });
  #handleAudienceFilterToggled = _.debounce(this.handleAudienceFilterToggled.bind(this), 500, { leading: true });
  #handleAudienceModeToggled = _.debounce(this.handleAudienceModeToggled.bind(this), 500, { leading: true });
  #handleHeatmapTurnedOn = debounce(this.handleHeatmapTurnedOn.bind(this), 500, { leading: true });
  #handleHeatmapTurnedOff = debounce(this.handleHeatmapTurnedOff.bind(this), 500, { leading: true });

  handleAudienceAdded(event) {
    const {
      detail: { scorePersonaId },
    } = event;
    this.paramsAdd(this.#scorePersonaParamName(scorePersonaId), 0);
  }

  handleScoreChanged(event) {
    const {
      detail: { scorePersonaId, score },
    } = event;
    if (this.filterEnabled) {
      this.paramsAdd(this.#scorePersonaParamName(scorePersonaId), score);
    }
  }

  handleAudienceRemoved(event) {
    const {
      detail: { scorePersonaId },
    } = event;
    if (this.filterEnabled) {
      this.paramsRemove(this.#scorePersonaParamName(scorePersonaId));
    }
  }

  handleAudienceFilterToggled(event) {
    const {
      detail: { audienceFilterEnabled },
    } = event;
    this.filterEnabled = audienceFilterEnabled;
    if (this.filterEnabled) {
      this.#scorePersonas().forEach(scorePersona => {
        this.paramsAdd(this.#scorePersonaParamName(scorePersona.id), scorePersona.score, false);
      });
      if (this.useOr) {
        this.paramsAdd("audiencesUseOr", true);
      }
      this.paramsAdd("audiences", true);
    } else {
      this.paramsRemoveAllStartingWith("score_persona_", false);
      this.paramsRemove("audiences");
      this.paramsRemove("audiencesUseOr");
    }
  }

  handleAudienceModeToggled(event) {
    const {
      detail: { audiencesUseOr },
    } = event;
    this.useOr = audiencesUseOr;

    if (!this.filterEnabled) {
      return;
    }

    if (this.useOr) {
      this.paramsAdd("audiencesUseOr", true);
    } else {
      this.paramsRemove("audiencesUseOr");
    }
  }

  async handleHeatmapTurnedOn(event) {
    // implemented in subclasses
  }

  handleHeatmapTurnedOff(event) {
    this.hideReferences();
    window.dispatchEvent(new CustomEvent("adquick:audiences:hideheatmap", { detail: {} }));
  }

  showReferences() {
    const references = this.referencesElement();
    references.style.display = "block";
  }

  hideReferences() {
    const references = this.referencesElement();
    references.style.display = "none";
  }

  referencesElement() {
    return document.getElementById("heatmap-references");
  }

  #scorePersonaParamName(id) {
    return `score_persona_${id}`;
  }

  #scorePersonas() {
    const scorePersonas = [];
    document.querySelectorAll(".score-persona").forEach(element => {
      const id = element.dataset.audience;
      const score = element.textContent;
      scorePersonas.push({ id, score });
    });

    return scorePersonas;
  }
}
