import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  static targets = [
    "bookByDate",
  ];

  selectDateType(e) {
    const dateType = e.target.value;
    const campaignDatesWrapper = document.querySelector(".campaign-date-types-component");

    campaignDatesWrapper.classList.remove("strict");
    campaignDatesWrapper.classList.remove("multidate");
    campaignDatesWrapper.classList.remove("flexible");
    campaignDatesWrapper.classList.add(dateType);

    // Flatpickr cannot properly calculate the width of the calendar element when it is inside a hidden element but it needs to be inside it so its data-action
    // attributes work properly. So this is the workaround for it
    let correctWidth = 0;
    const calendars = Array.from(document.querySelectorAll(".campaign-date-types-component .flatpickr-calendar"));

    calendars.forEach(calendar => {
      const currentElementWidth = Number(calendar.style.width.replace("px", ""));
      correctWidth = Math.max(correctWidth, currentElementWidth);
    });

    const correctWidthInPx = `${correctWidth}px`;

    calendars.forEach(calendar => {
      calendar.style.width = correctWidthInPx;
      calendar.querySelector(".flatpickr-days").style.width = correctWidthInPx;
    });
  }

  onDateRangeChange(e) {
    const dates = JSON.parse(e.target.dataset.value);
    const datePickerFormGroup = e.target.closest(".datepicker-form-group");
    const startDateElement = datePickerFormGroup.querySelector(".start-date");
    const endDateElement = datePickerFormGroup.querySelector(".end-date");

    this.#buildDates(dates, startDateElement, endDateElement);
  }

  onBookByDateChange(e) {
    const { target: { value } } = e;
    this.bookByDateTarget.value = !!value ? this.#formatDate(value) : null;
  }

  addFlight(e) {
    e.preventDefault();

    // TODO: refactor this into a target to avoid using querySelector
    const flightCounterNumber = document.querySelector(
      "#multidate-date-type-wrapper .date-range-container:last-of-type",
    ).dataset["counter"];
    const newFlightCounterNumber = parseInt(flightCounterNumber) + 1;

    // Clone from the last datepicker
    // TODO: refactor this into a target to avoid using querySelector
    const existingDatePickerWrapper = document.querySelector(
      "#multidate-date-type-wrapper .date-range-container:last-of-type",
    );
    const clonedDatePickerWrapper = existingDatePickerWrapper.cloneNode(true);
    clonedDatePickerWrapper.dataset["counter"] = newFlightCounterNumber;
    clonedDatePickerWrapper.dataset["customElement"] = `flight_${newFlightCounterNumber}_date_range_input`;
    const newDatePickerInput = clonedDatePickerWrapper.querySelector(".date-range-input");
    newDatePickerInput.id = `flight_${newFlightCounterNumber}_date_range_input`;
    newDatePickerInput.value = "";

    // If this flight was cloned from a flight that was marked to be destroyed, it would be hidden, so we show it again
    clonedDatePickerWrapper.classList.remove("hide");

    // Update flight counter in the label
    const newDatePickerLabel = clonedDatePickerWrapper.querySelector("label");
    newDatePickerLabel.textContent = newDatePickerLabel.textContent.replace(/\d+/, newFlightCounterNumber);

    // Reset date fields
    const newDatePickerStartDate = clonedDatePickerWrapper.querySelector(".start-date");
    newDatePickerStartDate.name = "campaign[campaign_dates_attributes][][start_date]";
    newDatePickerStartDate.value = null;
    const newDatePickerEndDate = clonedDatePickerWrapper.querySelector(".end-date");
    newDatePickerEndDate.name = "campaign[campaign_dates_attributes][][end_date]";
    newDatePickerEndDate.value = null;

    // As this will be a new flight, not persisted, it shouldn't have the ID field
    const idInput = clonedDatePickerWrapper.querySelector(".id-input");
    if (idInput) {
      idInput.remove();
    }

    // As this will be a new flight, not persisted, it shouldn't have the "_destroy" field
    const destroyInput = clonedDatePickerWrapper.querySelector(".destroy-input");
    if (destroyInput) {
      destroyInput.remove();
    }

    document.querySelector(".add-flight-button").before(clonedDatePickerWrapper);
  }

  removeFlight(e) {
    e.preventDefault();

    const wrapper = e.target.parentElement.parentElement;
    let destroyInput = wrapper.querySelector(".destroy-input");

    // It means that the Flight being removed had not been persisted yet, so we can directly remove it from the DOM
    if (destroyInput === null) {
      wrapper.remove();
    } else {
      destroyInput.value = "1";
      wrapper.classList.add("hide");
    }
  }

  #buildDates(dates, startDateElement, endDateElement) {
    const startDate = dates[0];
    const endDate = dates[1];

    startDateElement.value = !!startDate ? this.#formatDate(startDate) : null;
    endDateElement.value = !!endDate ? this.#formatDate(endDate) : null;
  }

  #formatDate(dateString) {
    return new Date(dateString).toUTCString();
  }
}
