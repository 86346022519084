import ApplicationController from "../application_controller";
import StimulusReflex from "stimulus_reflex";
import Inputmask from "inputmask";
import validator from "validator";

export default class extends ApplicationController {
  connect() {
    StimulusReflex.register(this);
    this.overlayId = "overlay-id";
    this.createOverlay();
    this.initializeMasks();
    this.initializeValidation();
    this.addInputListeners();
  }

  initializeMasks() {
    this.applyMask("phone", "(999) 999-9999", "(___) ___-____");
    this.applyMask("zip_code", "99999", "_____");
    this.applyMask("currency", "AAA", "USD");
  }

  applyMask(elementId, maskPattern, placeholder = null, defaultValue = null) {
    const element = document.getElementById(elementId);
    if (!element) return;

    const inputmask = new Inputmask({
      mask: maskPattern,
      placeholder: placeholder || maskPattern.replace(/9/g, '_')
    });
    inputmask.mask(element);

    if (defaultValue && element.value === "") {
      element.value = defaultValue;
    }
  }

  initializeValidation() {
    this.validate(); // Initial validation check
  }

  addInputListeners() {
    // Add event listeners for input validation
    const elementsToValidate = ["email", "phone", "zip_code", "currency"];
    elementsToValidate.forEach(id => {
      const element = document.getElementById(id);
      if (element) {
        element.addEventListener("input", () => this.validate());
      }
    });

    document.querySelectorAll(".form-control").forEach(element => {
      element.addEventListener("input", () => this.validate());
    });
  }

  validate() {
    const fields = {
      businessName: "business_name",
      business: "business",
      businessType: "business_type",
      currency: "currency",
      address: "address",
      city: "city",
      state: "state",
      zipCode: "zip_code",
      contactName: "contact_name",
      email: "email",
      phone: "phone"
    };

    const formValues = {};
    let allFilled = true;

    for (const [key, id] of Object.entries(fields)) {
      const element = document.getElementById(id);
      if (element) {
        formValues[key] = element.value.trim();
        if (!formValues[key]) {
          allFilled = false;
        }
      }
    }

    const emailValid = validator.isEmail(formValues.email);
    const zipCodeValid = validator.isPostalCode(formValues.zipCode, 'US');
    const currencyValid = /^[A-Z]{3}$/.test(formValues.currency);
    const phoneValid = formValues.phone.replace(/\D/g, '').length === 10;

    const allValid = allFilled && emailValid && zipCodeValid && currencyValid && phoneValid;
    document.getElementById("submit_button").disabled = !allValid;
  }

  createOverlay() {
    const overlay = document.createElement("div");
    Object.assign(overlay.style, {
      position: "fixed",
      display: "none",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: "20"
    });
    overlay.id = this.overlayId;
    document.body.appendChild(overlay);
  }

  hideOverlay() {
    const overlay = document.getElementById(this.overlayId);
    if (overlay) {
      overlay.style.display = "none";
    }
  }

  showOverlay() {
    let overlay = document.getElementById(this.overlayId);
    if (!overlay) {
      this.createOverlay();
      overlay = document.getElementById(this.overlayId);
    }
    overlay.style.display = "block";
  }

  save(event) {
    event.preventDefault();
    document.getElementById("submit_button").disabled = true;
    this.stimulate("Programmatic::DspBillingReflex#save", this.getFormData());
  }

  getFormData() {
    return {
      business_name: document.getElementById("business_name").value,
      business: document.getElementById("business").value,
      address: document.getElementById("address").value,
      city_state_zip: `${document.getElementById("city").value}, ${document.getElementById("state").value}, ${document.getElementById("zip_code").value}`,
      business_type: document.getElementById("business_type").value,
      accounts_receivable_contact_name: document.getElementById("contact_name").value,
      receivable_contact_email: document.getElementById("email").value,
      accounts_receivable_contact_phone: document.getElementById("phone").value,
      currency: document.getElementById("currency").value,
    };
  }

  reflexSuccess(event) {
    this.hideOverlay();
    document.getElementById("submit_button").disabled = false;
  }

  reflexError(event) {
    this.hideOverlay();
    document.getElementById("submit_button").disabled = false;
  }

  reflexHalted(event) {
    // this.hideOverlay();
    document.getElementById("submit_button").disabled = false;
  }
}
