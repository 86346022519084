import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static values = {
    contractToken: String,
    contractType: String,
    templateId: Number,
    indexRedirect: String,
    sendProposal: Boolean,
  };
  static targets = ["contractTemplate", "contractSigner", "note", "customTerms", "form"];

  connected() {
    console.log("send contract modal connected");
  }

  addSigner(e) {
    // for v0 just do one signer for now
  }

  selectedTemplateOption() {
    const template = this.contractTemplateTargets.find(el => el.selected);
    return (template && template.value) || null;
  }

  selectedSignerOption() {
    return this.contractSignerTargets.find(el => el.selected).value;
  }

  addselected(targetOption) {
    const targets = {
      template: this.selectedTemplateOption(),
      signer: this.selectedSignerOption(),
    };
    return targets[targetOption];
  }

  determineTemplateTag() {
    const templateTag = {};
    const contract_type = this.contractTypeValue;
    const template_id = this.addselected("template");

    if (contract_type === "custom") {
      templateTag["contract_template_id"] = template_id;
    }
    if (contract_type === "talon") {
      templateTag["template_id"] = template_id;
    }

    return templateTag;
  }

  async submit(e) {
    e.preventDefault();
    const payload = {
      token: this.contractTokenValue,
      signer_ids: [this.addselected("signer")],
      contract_template_id: null,
      template_id: null,
      note: this.noteTarget.value,
      ...this.determineTemplateTag(),
    };
    // send contract is sent with the form data while proposals are send with the stimulus
    await this.stimulate(`Plus::Campaigns::VendorContractsReflex#${this.submitReflexMethod()}`, payload);

    window.modal.close();
    location.assign(this.indexRedirectValue);
  }

  async submitWithTerms(e) {
    e.preventDefault();
    this.formTarget.submit();
  }

  submitReflexMethod() {
    if (this.sendProposalValue) {
      return "send_proposal";
    }
    return "send_contract";
  }
}
