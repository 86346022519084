import ApplicationController from "./../../application_controller";
import { post } from "../../../../../../../adquick-classic/src/marketplace/utils/api";
import JobProgress from "../../../utils/JobProgress.js";

export default class extends ApplicationController {
  static targets = [
    "modal",
    "stepMetrics",
    "stepRestrictions",
    "metric",
    "reach",
    "frequency",
    "poiDistance",
    "continueButton",
    "costMax",
    "cpmMax",
    "budgetMax",
    "tag",
    "keepExisting",
  ];

  job_polling = new JobProgress();

  closeModal() {
    // This uses the shared modal JS lib
    window.modal.close();
  }

  enableContinue() {
    this.continueButtonTarget.disabled = false;
  }

  back() {
    this.stepRestrictionsTarget.classList.add("hidden");
    this.stepMetricsTarget.classList.remove("hidden");

    const audienceSelect = document.querySelector("#auto_recommend_units_modal #audience_wrapper .metric_filter");
    audienceSelect.classList.remove("error");
    this.budgetMaxTarget.classList.remove("error");
    this.poiDistanceTarget.classList.remove("error");
    document.getElementById("auto_recommend_units_modal_market").classList.remove("error");

    document
      .querySelectorAll("#auto_recommend_units_modal .optional")
      .forEach(element => element.classList.remove("hidden"));
    document
      .querySelectorAll("#auto_recommend_units_modal .required")
      .forEach(element => element.classList.add("hidden"));
  }

  continueToRestrictions() {
    const metric = this.selectedMetric();
    const poiDistance = this.poiDistanceTarget.value;
    const audienceSelect = document.querySelector("#auto_recommend_units_modal #audience_wrapper .metric_filter");
    const selectedAudience = document.querySelector("#auto_recommend_units_modal #auto_recommend_units_audience").value;

    if (metric === "poi" && (poiDistance.length == 0 || poiDistance == 0)) {
      this.poiDistanceTarget.classList.add("error");
    } else if (metric === "audience" && selectedAudience === "") {
      audienceSelect.classList.add("error");
    } else if (metric === "audience" || metric === "unit_score") {
      const budgetParent = this.budgetMaxTarget.closest(".restriction");

      budgetParent.getElementsByClassName("optional")[0].classList.add("hidden");
      budgetParent.getElementsByClassName("required")[0].classList.remove("hidden");

      this.showSecondStep();
    } else if (metric === "reach_and_frequency") {
      if (this.reachTarget.value === "" || this.frequencyTarget.value === "") {
        if (this.reachTarget.value === "") {
          this.reachTarget.classList.add("error");
        }
        if (this.frequencyTarget.value === "") {
          this.frequencyTarget.classList.add("error");
        }
      } else {
        const marketParent = document.getElementById("auto_recommend_units_modal_market").closest(".restriction");

        marketParent.getElementsByClassName("optional")[0].classList.add("hidden");
        marketParent.getElementsByClassName("required")[0].classList.remove("hidden");

        this.showSecondStep();

        this.reachTarget.classList.remove("error");
        this.frequencyTarget.classList.remove("error");
      }
    } else {
      this.showSecondStep();
    }
  }

  showSecondStep() {
    const audienceSelect = document.querySelector("#auto_recommend_units_modal #audience_wrapper .metric_filter");

    audienceSelect.classList.remove("error");
    this.poiDistanceTarget.classList.remove("error");

    this.stepMetricsTarget.classList.add("hidden");
    this.stepRestrictionsTarget.classList.remove("hidden");
  }

  selectedMetric() {
    return this.metricTargets.find(radio => radio.checked).value;
  }

  showResultMessage(response) {
    const error = response.data.error;
    const success = response.data.success;

    if (error) {
      this.stimulate("UI::ToastReflex#show", "error", error);
    } else {
      this.stimulate("UI::ToastReflex#show", "success", success);
    }
  }

  async validateAndSubmit() {
    const metric = this.selectedMetric();
    const budgetValue = this.budgetMaxTarget.value;
    const marketValue = document.getElementById("auto_recommend_units_modal_market").value;

    if ((metric === "audience" || metric === "unit_score") && budgetValue === "") {
      this.budgetMaxTarget.classList.add("error");
    } else if (metric === "reach_and_frequency" && marketValue === "") {
      document.getElementById("auto_recommend_units_modal_market").classList.add("error");
    } else {
      this.submit();
    }
  }

  async submit() {
    this.closeModal();
    this.stimulate("UI::ToastReflex#show", "info", "Finding units to auto-recommend in the background...");

    const campaignToken = this.modalTarget.dataset["campaignToken"];
    const metric = this.selectedMetric();
    const reach = this.reachTarget.value;
    const frequency = this.frequencyTarget.value;
    const poi_distance = this.poiDistanceTarget.value;
    const audience = document.querySelector("#auto_recommend_units_modal #auto_recommend_units_audience").value;

    const market = document.getElementById("auto_recommend_units_modal_market").value;
    const cost_max = this.costMaxTarget.value;
    const cpm_max = this.cpmMaxTarget.value;
    const budget_max = this.budgetMaxTarget.value;
    const tag = this.tagTarget.value;
    const keep_existing = this.keepExistingTarget.checked;

    const params = {
      metric,
      reach,
      frequency,
      poi_distance,
      cost_max,
      cpm_max,
      budget_max,
      tag,
      keep_existing,
      market,
      audience,
    };

    const { job_id } = await post(`/api/v1/campaigns/${campaignToken}/auto_recommend_units`, params);
    this.job_polling.startPolling(job_id, this.showResultMessage.bind(this));
  }
}
