export default class UnitFaceIdLayer {
  constructor(map, source, layerId) {
    this.map = map;
    this.source = source;

    this.#addUnitFaceIdLayer(map, source, layerId);
  }

  #addUnitFaceIdLayer(map, source, layerId) {
    map.addLayer({
      id: layerId,
      type: "symbol",
      source: source,
      layout: {
        "visibility": "none",
        "icon-allow-overlap": true,
        "text-allow-overlap": true,
        "text-field": ["get", "face_id"],
        "text-font": ["Inter Semi Bold"],
        "text-size": 10,
        "text-justify": "center",
        "text-offset": [0.0, 1.1],
        "text-anchor": "top",
      },
      paint: {
        "text-color": "#000000",
        "text-halo-color": "#FFFFFF",
        "text-halo-width": 2,
        "text-halo-blur": 1,
      },
    });
  }
}
