import ClusterHighlightLayer from "./ClusterHighlightLayer";

export default class UnitLayer {
  constructor(
    map,
    source,
    clusterLayerId,
    circleLayerId,
    symbolLayerId,
    minzoom,
    { circleLayerProperties = null, clusterLayerProperties = null } = {},
  ) {
    this.map = map;
    this.source = source;
    this.circleLayerProperties = circleLayerProperties;
    this.clusterLayerProperties = clusterLayerProperties;

    this.clusterHighlight = new ClusterHighlightLayer(map, source, clusterLayerId, circleLayerId);
    this.#addUnitClusterLayer(map, source, clusterLayerId, minzoom);
    this.#addUnitCircleLayer(map, source, circleLayerId, minzoom);
    this.#addClusterUnitCountLayer(map, source, symbolLayerId, minzoom);
    window.addEventListener("adquick:map:highlightunit", this.#handleHighlightUnit.bind(this));
    window.addEventListener("adquick:map:unhighlightunit", this.#handleUnhighlightUnit.bind(this));
  }

  #addUnitClusterLayer(map, source, layerId, minzoom) {
    map.addLayer({
      id: layerId,
      type: "circle",
      source: source,
      minzoom: minzoom,
      filter: ["has", "point_count"],
      paint: {
        "circle-color": this.#getClusterColor(),
        "circle-opacity": 0.8,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#FFFFFF",
        "circle-radius": ["step", ["get", "point_count"], 8, 10, 10, 100, 12],
      },
      layout: {
        "circle-sort-key": ["to-number", ["get", "point_count"]],
      },
    });
  }

  #addUnitCircleLayer(map, source, id, minzoom) {
    map.addLayer({
      id: id,
      type: "circle",
      source: source,
      minzoom: minzoom,
      filter: ["!has", "point_count"],
      paint: {
        "circle-color": this.#getCircleColor(),
        "circle-opacity": 0.8,
        "circle-stroke-width": ["case", ["boolean", ["feature-state", "highlighted"], false], 3, 2],
        "circle-stroke-color": [
          "case",
          ["boolean", ["feature-state", "highlighted"], false],
          "#FEC700", // yellow when highlighted
          "#FFFFFF", // white otherwise
        ],
        "circle-radius": 9,
      },
    });
  }

  #addClusterUnitCountLayer(map, source, id, minzoom) {
    map.addLayer({
      id: id,
      type: "symbol",
      source: source,
      minzoom: minzoom,
      layout: {
        "symbol-sort-key": ["to-number", ["-", ["get", "point_count"]]],
        "text-field": "{point_count_abbreviated}",
        "text-font": ["Inter Bold"],
        "text-size": 10,
      },
      paint: {
        "text-color": "#ffffff",
      },
    });
  }

  #handleHighlightUnit(event) {
    const unitId = event.detail.unitId;
    if (unitId) {
      this.map.setFeatureState({ source: this.source, id: unitId }, { highlighted: true });
      this.clusterHighlight.highlightCluster(unitId);
      window.dispatchEvent(new CustomEvent("adquick:map:highlightpackage", { detail: { unitId } }));
    }
  }

  #handleUnhighlightUnit(event) {
    const unitId = event.detail.unitId;
    if (unitId) {
      this.map.setFeatureState({ source: this.source, id: unitId }, { highlighted: false });
      this.clusterHighlight.unhighlightCluster();
      window.dispatchEvent(new CustomEvent("adquick:map:unhighlightpackage", { detail: { unitId } }));
    }
  }

  #getClusterColor() {
    const { paint = null } = this.clusterLayerProperties || {};
    const { "circle-color": color = "#911884" } = paint || {};
    return color;
  }

  #getCircleColor() {
    const { paint = null } = this.circleLayerProperties || {};
    const { "circle-color": color = "#911884" } = paint || {};
    return color;
  }
}
