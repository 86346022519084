import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = [
    "tabContent",
    "tab",
    "subTab",
    "demographicsTab",
    "economicsTab",
    "educationTab",
    "flagUnitForm",
    "flagUnitButton",
    "flagUnitInput",
    "flagConfirmationMessage",
  ];

  static values = {
    unitId: String, // unit's slug
    unitLat: Number,
    unitLng: Number,
  };

  initialize() {
    this.campaignId = null;
  }

  handleAddToCampaign(e) {
    this.stimulate("Pro::Browse::ModalActionsReflex#display_add_to_campaign_form", this.unitIdValue);
  }

  handleCancelClick(event) {
    this.stimulate("Pro::Browse::ModalActionsReflex#render_original_footer", this.unitIdValue);
  }

  handleAddToCampaignSave(e) {
    if (!this.unitIdValue && !this.campaignId) alert("Invalid unit or campaign ID"); // return unless we have both required values
    document.dispatchEvent(
      new CustomEvent("adquick:browseunitmodal:addunittocampaign", {
        detail: { unitId: this.unitIdValue, campaignId: this.campaignId },
      }),
    );
  }

  handleCampaignSelect(event) {
    const { id } = JSON.parse(event.currentTarget.dataset.value);
    this.campaignId = id;
  }

  handleChangeTab(event) {
    const { currentTarget } = event;
    const {
      dataset: { tabId },
    } = currentTarget;
    if (currentTarget.classList.contains("active")) return;
    this.#markItemAsActive(currentTarget, this.tabTargets);
    this.stimulate("Pro::Browse::ModalActionsReflex#change_tab", { component_name: tabId, unit_id: this.unitIdValue });
  }

  handleAudiencesSubTabChange(event) {
    const { currentTarget } = event;
    const {
      dataset: { target },
    } = currentTarget;
    if (currentTarget.classList.contains("active")) return;
    this.#markItemAsActive(currentTarget, this.subTabTargets);
    this.#markItemAsActive(this[target], [
      this.demographicsTabTarget,
      this.economicsTabTarget,
      this.educationTabTarget,
    ]);
  }

  handleFlagUnitFormToggle(event) {
    this.flagUnitFormTarget.classList.toggle("hide");
    this.flagUnitButtonTarget.classList.toggle("active");
    if (this.flagUnitButtonTarget.classList.contains("active")) {
      this.flagUnitInputTarget.focus();
    } else {
      this.#resetFlagUnitForm();
    }
  }

  handleCancelFlaggingUnit() {
    this.#resetFlagUnitForm();
  }

  async handleFlaggingUnitSubmit() {
    await this.stimulate("Pro::Browse::ModalActionsReflex#flag_unit", {
      note: this.flagUnitInputTarget.value,
      unit_id: this.unitIdValue,
    });
    this.flagConfirmationMessageTarget.classList.remove("hide");
    this.#resetFlagUnitForm();
  }

  handleViewOnMap(e) {
    window.dispatchEvent(
      new CustomEvent("adquick:browseunitmodal:viewonmap", {
        detail: {
          lat: this.unitLatValue,
          lng: this.unitLngValue,
        },
      }),
    );
  }

  closeModal(event) {
    event.preventDefault();
    window.modal.close();
  }

  // StimulusReflex callbacks

  beforeChangeTab() {
    this.tabContentTarget.classList.add("wait");
  }

  afterChangeTab() {
    this.tabContentTarget.classList.remove("wait");
  }

  // Private

  #markItemAsActive(selected, allItems) {
    allItems.forEach(item => item.classList.remove("active"));
    selected.classList.add("active");
  }

  #resetFlagUnitForm() {
    this.flagUnitFormTarget.classList.add("hide");
    this.flagUnitButtonTarget.classList.remove("active");
    this.flagUnitInputTarget.value = "";
  }
}
