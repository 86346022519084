import ApplicationController from "../../application_controller";
import { delay } from "lodash";

export default class extends ApplicationController {
  static values = {
    campaignToken: String,
    jobId: String,
  };

  connected() {
    this.checkCheckoutJob();
  }

  checkCheckoutJob() {
    const jobIdPresent = this.jobIdValue != null && this.jobIdValue !== "";
    if (jobIdPresent) {
      // this.stimulate("UI::BackgroundJobProgressReflex#wait_for_job", { job_id: this.jobIdValue });
      this.waitForJobSuccess();
    } else {
      this.stimulate("Pro::Campaigns::CheckoutReflex#wait_for_checkout");
    }
  }

  waitForJobSuccess(e) {
    document.getElementById("processing").style.display = "none";
    document.getElementById("done").style.display = "flex";
  }

  waitForCheckoutSuccess(e) {
    document.getElementById("processing").style.display = "none";
    document.getElementById("done").style.display = "flex";
  }
}
