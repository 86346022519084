import consumer from "./consumer";
import CableReady from "cable_ready";

const start = () => {
  consumer.subscriptions.create("BrowseChannel", {
    connected() {
      console.info("[ActionCable] Connected to BrowseChannel.");
    },
    received(data) {
      if (data.cableReady) CableReady.perform(data.operations);
    },
  });
};

const BrowseChannel = { start };
export default BrowseChannel;
