import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static values = { contractToken: String, userId: Number, itemTokens: Array, skipFormSubmit: Boolean };
  static targets = ["form", "submitButton"];

  formElements() {
    return Array.from(this.formTarget.querySelectorAll("input")).filter(el => el.name != "authenticity_token");
  }

  submit(e) {
    e.preventDefault();
    if (this.skipFormSubmitValue) {
      return this.submitReflex();
    }
    this.formTarget.submit();
  }

  elementKeyMapToValue(key, value) {
    const mapping = {
      "changes[total]": { changes: { total: value } },
    };
    return mapping[key];
  }

  submitReflexPayload() {
    const dataset = this.formElements().map(el => Object.assign({}, { [el.name]: el.value }));
    return dataset.reduce((acc, value) => {
      const keys = Object.keys(value);
      acc = { ...acc, ...this.elementKeyMapToValue(keys[0], value[keys[0]]) };
      return acc;
    }, {});
  }

  async submitReflex() {
    const payload = { ...this.submitReflexPayload(), contract_token: this.contractTokenValue };
    await this.stimulate("Plus::Campaigns::VendorContractsItemReflex#change_proposal_item_totals", payload);
    window.modal.close();
  }
}
