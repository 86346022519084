import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [];
  static values = {
    unitId: String,
    campaignId: String,
    useStimulate: Boolean,
    campaignUnitTokens: Array,
    refreshFilter: Boolean,
  };

  // connected() {
  //   console.log(this.useStimulateValue);
  //   console.log(this.campaignUnitTokensValue);
  // }

  openRemovalReasonModal() {
    const payload = {
      component: "modal/removal_reason_component",
      component_params: {
        campaign_id: this.campaignIdValue,
        unit_id: this.unitIdValue,
        use_stimulate: this.useStimulateValue,
        campaign_unit_tokens: this.campaignUnitTokensValue,
        refresh_filter: this.refreshFilterValue,
      },
    };

    this.stimulate("ModalReflex#load_modal_component", payload);

    const unitModal = document.getElementById("modal");
    if (unitModal) {
      unitModal.style.zIndex = 1055;
    }
    return window.modal.open();
  }
}
