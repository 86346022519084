import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  static targets = ["card"];

  selectedCards() {
    return this.cardTargets.filter(el => el.checked);
  }

  selectedCardsIds() {
    return this.selectedCards().map(el => el.dataset.cardUnitId);
  }

  toggleAll(e) {
    const { checked } = e.target;
    this.cardTargets.forEach(el => {
      if (checked) {
        return el.setAttribute("checked", checked);
      }
      return el.removeAttribute("checked");
    });
  }

  allSelectedCardsHaveSpecs() {
    const elements = this.selectedCards();
    return elements.filter(el => !Object.keys(el.dataset).includes("cardHasSpec")).length == 0;
  }

  massUploadSelected() {
    const unitIds = this.selectedCardsIds();
    if (unitIds.length == 0) {
      return alert("Please select at least one card");
    }
    this.openSpecModal(unitIds);
  }

  massVerifySelected() {
    if (this.selectedCards().length == 0) {
      return alert("Please select at least one card");
    }
    if (!this.allSelectedCardsHaveSpecs()) {
      return alert("Some selected units are missing specs. Please upload them before verifying. ");
    }
    return this.stimulateMassVerification();
  }

  async stimulateMassVerification() {
    const payload = { unit_ids: this.selectedCardsIds() };
    await this.stimulate("Plus::AdquickRequestsReflex#mass_verify_unit_specs", payload);
  }

  massDeleteSelected() {
    const selectedCards = this.selectedCards();
    if (selectedCards.length == 0) {
      return alert("Please select at least one card");
    }
    const campaign_unit_ids = selectedCards.map(el => el.dataset.campaignUnitId);
    const row_ids = selectedCards.map(el => el.dataset.rowId);
    const payload = {
      campaign_unit_ids,
      row_ids,
      page: "spec_sheets",
    };
    this.stimulate("Plus::AdquickRequestsReflex#bulk_remove_units_from_rfp", payload);
  }

  async openSpecModal(unitIds) {
    const payload = {
      component: "modal/rfp_spec_component",
      component_params: {
        unit_ids: unitIds,
        redirect_to: this.data.get("currentPath"),
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }
}
