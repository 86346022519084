import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [];

  connected() {
    this.setSupplierUpscopeUser();
  }

  async openPackageModal() {
    const payload = {
      component: "modal/campaign_package_component",
      component_params: {
        campaign_id: this.data.get("campaignToken"),
        unit_id: this.data.get("unitId"),
        campaign_unit_token: this.data.get("campaignUnitToken"),
        user_id: this.data.get("userId"),
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);

    window.modal.open();
    // packages/adquick-classic/src/marketplace/components/UnitInfo/index.js adds this to the window object
    // manually close the edit unit modal because some focus bug prevents the grid from being interactive
    // on smaller resolutions
    if (window.hideModal) { window.hideModal() }
  }

  async openAutoRecommendUnitsModal() {
    const payload = {
      component: "modal/auto_recommend_units_component",
      component_params: {
        campaign_token: this.data.get("campaignToken"),
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }

  async openAddonModal() {
    const payload = {
      component: "modal/campaign_addon_component",
      component_params: {
        campaign_token: this.data.get("campaignToken"),
        add_on_id: this.data.get("addOnId"),
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }

  async setSupplierUpscopeUser() {
    const email = this.data.get("user-email");
    const supplierId = this.data.get("supplierId");

    if (supplierId != null) {
      Upscope("updateConnection", {
        // Set the user ID below. If you don't have one, set to undefined.
        uniqueId: email,
        identities: [email || null],
        tags: ["supplier-id-" + supplierId],
      });
    }
  }
}
