export const filterMapping = {
  date: ["date[start_date]", "date[end_date]"],
  availability: ["availability"],
  favorite: ["favorite"],
  recommended: ["recommended"],
  price: ["price[max]", "price[min]"],
  poi_distance: ["poi_distance[max]", "poi_distance[min]"],
  cpm: ["cpm[max]", "cpm[min]"],
  media_type: ["media_type"],
  screen_type: ["screen_type"],
  size: ["size"],
  orientation: ["orientation"],
  supplier: ["supplier"],
  market: ["market"],
  tag: ["tag"],
  booked_status: ["booked_status"],
  in_cart: ["in_cart"],
  more: [
    "with_pricing",
    "without_pricing",
    "with_picture",
    "without_picture",
    "packages_only",
    "exclude_packages",
    "managed_supplier",
    "with_margin_agreement",
    "broker_supplier",
    "not_broker_supplier",
    "without_tags",
    "cannabis_friendly",
    "on_hold_only",
    "without_restrictions",
    "with_rate_card_price",
    "exclude_favorites",
    "exclude_recommended",
  ],
};
