export default class ShowLayersControl {
  constructor(campaignMap) {
    this.active = false;
    this.campaignMap = campaignMap;
  }

  onAdd(map) {
    this._map = map;
    this._container = document.createElement("div");
    this._button = document.createElement("button");
    this._button.className = "map-control-show";
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group full-width";
    this._button.textContent = "Show";
    this._container.appendChild(this._button);
    this._container.onclick = this.onClick.bind(this);
    this.toggleMapCluster = this.toggleMapCluster.bind(this);
    this.toggleShowTraffic = this.toggleShowTraffic.bind(this);
    this.toggleShowFaceIDLayer = this.toggleShowFaceIDLayer.bind(this);
    this.toggleShowPoiLayer = this.toggleShowPoiLayer.bind(this);
    this._dropdown = this.renderLayerButtons();
    return this._container;
  }
  onClick() {
    this._dropdown.classList.toggle("hidden");
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }

  layerOptions() {
    return [
      { label: "Show FaceID", on_click: this.toggleShowFaceIDLayer, active: this.campaignMap.show_face_id},
      { label: "Show POIs", on_click: this.toggleShowPoiLayer, active: this.campaignMap.show_pois},
      { label: "Show Traffic", on_click: this.toggleShowTraffic, active: this.campaignMap.show_traffic },
      { label: "Show Cluster", on_click: this.toggleMapCluster, active: this.campaignMap.show_clusters}
    ]
  }

  // Re-render the dropdown after each toggle to reflect the new config from campaignMap
  // so that the active class is toggled correctly

  toggleMapCluster(e) {
    e.preventDefault()
    this.campaignMap.toggleShowCluster()
    this._dropdown = this.renderLayerButtons();
  }

  toggleShowFaceIDLayer(e) {
    e.preventDefault()
    this.campaignMap.toggleShowFaceIDLayer()
    this._dropdown = this.renderLayerButtons();
  }
  toggleShowPoiLayer(e) {
    e.preventDefault()
    this.campaignMap.toggleShowPoiLayer()
    this._dropdown = this.renderLayerButtons()
  }

  toggleShowTraffic(e) {
    e.preventDefault()
    this.campaignMap.toggleShowTraffic()
    this._dropdown = this.renderLayerButtons();
  }

  renderLayerButtons() {
    this._container.querySelector(".map-control-dropdown")?.remove();

    const dropDownContainer = document.createElement("div");
    dropDownContainer.className = "map-control-dropdown hidden";

    this.layerOptions().forEach(layer => {
      const button = document.createElement("button");
      button.className = `dropdown-option ${layer.active ? "active" : ""}`;
      button.textContent = layer.label;
      button.addEventListener("click", layer.on_click);
      dropDownContainer.appendChild(button);
    });
    this._container.appendChild(dropDownContainer);
    return dropDownContainer
  }
}
