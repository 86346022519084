import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import * as turf from "@turf/turf";
import QueryString from "query-string";
import {
  paramsGet,
  paramsRemove,
  hasParam,
} from "../../../../../adquick-classic/src/marketplace/utils/QueryString";
import { get } from "../../../../../adquick-classic/src/marketplace/utils/api";
import ShowLayersControl from "./controls/ShowLayersControl";
import * as MapFeature from "../map/features/campaigns";

class CampaignMap {
  // map parameters
  static DEFAULT_MAP_STYLE = "mapbox://styles/fahimf/cjy0g4rbh0y1d1cledi9pf3nk";
  static TRAFFIC_MAP_STYLE = "mapbox://styles/fahimf/cizq3knvy004t2sp6tvkpbuk1";
  static UNIT_MIN_ZOOM = 0;
  static CLUSTER_MAX_ZOOM = 22;
  static DEFAULT_MAP_ZOOM = 3;
  static DEFAULT_MAP_CENTER = [-95, 37];

  // the units will be loaded at most once every these milliseconds
  static THROTTLE_INTERVAL = 500;

  // make sure it matches Pro::Browse::UnitsController::LNGLAT_STEP
  static LNGLAT_STEP = 1;

  // make sure it mathes Pro::Browse::UnitsController::LNGLAT_DECIMALS
  static LNGLAT_DECIMALS = 0;

  // unit highlight (View On Map)
  static UNIT_HIGHLIGHT_DURATION = 5000;
  static MAX_FLY_TO_DURATION = 1000;

  // map layers' and sources' ids
  static UNIT_SOURCE_ID = "unit";
  static UNIT_FACE_ID_LAYER_ID = "unit-faceid";
  static UNIT_NAME_LAYER_ID = "unit-name-layer";
  static UNIT_CIRCLE_LAYER_ID = "unit-circle-layer";
  static UNIT_CLUSTER_LAYER_ID = "unit-cluster-layer";
  static PACKAGE_CHILDREN_SOURCE_ID = "package-children";
  static PACKAGE_CHILDREN_CIRCLE_LAYER_ID = "package-children-circle-layer";
  static PACKAGE_CHILDREN_CLUSTER_LAYER_ID = "package-children-cluster-layer";
  static AUDIENCE_HEATMAP_LAYER_ID = "audience-heatmap";
  static POLITICAL_HEATMAP_LAYER_ID = "political-heatmap";
  static POI_SOURCE_ID = "poi-source";
  static POI_LAYER_ID = "poi-layer";
  static CUSTOM_BOUNDS_SOURCE_ID = "custom-bounds-source";
  static CUSTOM_BOUNDS_LAYER_ID = "custom-bounds-layer";

  // ui params
  static SIDEBAR_WIDTH = 475;

  constructor({ campaignId, container, accessToken, showPrices, showUnitPrice, showSupplier, hasPackageChildren, bounds }) {
    this.campaignId = campaignId;
    this.showPrices = showPrices;
    this.showUnitPrice = showUnitPrice;
    this.showSupplier = showSupplier;
    this.hasPackageChildren = hasPackageChildren;
    this.bounds = bounds;
    this.show_clusters = true
    this.show_traffic = false
    this.show_pois = true
    this.show_face_id = false

    mapboxgl.accessToken = accessToken;

    const { lng, lat, zoom } = paramsGet();

    const geocoder = new MapboxGeocoder({
      accessToken,
      placeholder: "Search for a location",
      flyTo: { curve: 2 },
      mapboxgl: mapboxgl,
      marker: false,
    });

    this.map = new mapboxgl.Map({
      container: container, // id of the html element that contains the map
      style: CampaignMap.DEFAULT_MAP_STYLE,
      center: lng && lat ? [lng, lat] : CampaignMap.DEFAULT_MAP_CENTER,
      zoom: zoom || CampaignMap.DEFAULT_MAP_ZOOM,
      attributionControl: false,
    })
      .on("style.load", this.#handleMapLoad.bind(this))
      .addControl(geocoder, "top-left")
      .addControl(new mapboxgl.NavigationControl({ showZoom: true, showCompass: false }), "bottom-left");
    this.mapDraw = new MapFeature.DrawTool(this.map, 0, this.campaignId);
    this.map.addControl(new ShowLayersControl(this), "top-left");

    window.map = this.map;
  }

  /*** CONFIGURE MAP ***/

  #handleMapLoad() {
    this.#createUnitSource();
    this.#createPackageChildrenSource();
    this.#createPoiSource();
    this.#createCustomBoundsSource();

    this.#updateSources();
    this.updatePoisSource();
    this.updateCustomBoundsSource();

    this.#initializeMapFeatures();
    this.updatePoisSource.bind(this);
    this.map.on("moveend", this.#handleMapMoveEnd.bind(this));
    this.map.on("zoomend", this.#handleMapMoveEnd.bind(this));
    this.map.on("click", CampaignMap.UNIT_CIRCLE_LAYER_ID, this.#handleUnitClick.bind(this));
    this.map.on("draw.create", this.#handleDrawCreate.bind(this));

    window.addEventListener(
      "adquick:history:replacestate",
      _.debounce(this.#handleHistoryReplaceStateDebounced.bind(this), 1000, { leading: true }),
    );
    window.addEventListener("adquick:browseunitmodal:viewonmap", this.#handleViewOnMapClick.bind(this));
    window.addEventListener("adquick:browse:reloadmapunits", this.#updateSources.bind(this));
    window.addEventListener("adquick:modal:close", this.#handleCloseUnitModal.bind(this));
    window.addEventListener("adquick:map:unit:favorite", this.#handleUnitFavorite.bind(this));
    window.addEventListener("adquick:map:unit:recommend", this.#handleUnitRecommend.bind(this));
    this.audienceHeatmapLayer.addEventListeners();

    if (paramsGet()["political"] === "true") {
      this.politicalHeatmapLayer.showLayers();
    }

    // Dispatch event because the event listeners may need to perform additional configuration once the map is ready.
    this.#dispatchMoveEndEvent();

    Object.keys(window.UNIT_TYPE_ICON_MAP).forEach(iconId => this.#addIconToMap(iconId));
    Object.keys(window.UNIT_AVAILABILITY_MAP).forEach(iconId => this.#addIconToMap(iconId));
  }

  #initializeMapFeatures() {
    const unitTooltipController = new MapFeature.CampaignUnitTooltipController(
      this.map,
      this.campaignId,
      this.showPrices,
      this.showUnitPrice,
      this.showSupplier,
    );

    new MapFeature.UnitLayer(
      this.map,
      CampaignMap.UNIT_SOURCE_ID,
      CampaignMap.UNIT_CLUSTER_LAYER_ID,
      CampaignMap.UNIT_CIRCLE_LAYER_ID,
      CampaignMap.UNIT_NAME_LAYER_ID,
      CampaignMap.UNIT_MIN_ZOOM,
    );
    new MapFeature.UnitFaceIdLayer(map, CampaignMap.UNIT_SOURCE_ID, CampaignMap.UNIT_FACE_ID_LAYER_ID);
    new MapFeature.UnitMediaTypeIconsLayer(this.map, CampaignMap.UNIT_SOURCE_ID, CampaignMap.UNIT_MIN_ZOOM);
    new MapFeature.UnitDirectionsLayer(this.map, CampaignMap.UNIT_SOURCE_ID, CampaignMap.UNIT_MIN_ZOOM);
    new MapFeature.LayerLoadingIndicator(this.map, CampaignMap.UNIT_SOURCE_ID);
    new MapFeature.PoiLayer(
      this.map,
      CampaignMap.POI_LAYER_ID,
      CampaignMap.POI_SOURCE_ID,
      CampaignMap.UNIT_MIN_ZOOM,
      CampaignMap.UNIT_CLUSTER_LAYER_ID,
    );
    new MapFeature.UnitLayerTooltip(this.map, CampaignMap.UNIT_CIRCLE_LAYER_ID, unitTooltipController);
    new MapFeature.ClusterSpiderifier(
      this.map,
      CampaignMap.UNIT_CLUSTER_LAYER_ID,
      unitTooltipController,
      CampaignMap.UNIT_SOURCE_ID,
      CampaignMap.SIDEBAR_WIDTH,
    );
    if (this.hasPackageChildren) {
      new MapFeature.PackageChildrenLayer(
        this.map,
        CampaignMap.PACKAGE_CHILDREN_SOURCE_ID,
        CampaignMap.PACKAGE_CHILDREN_CLUSTER_LAYER_ID,
        CampaignMap.PACKAGE_CHILDREN_CIRCLE_LAYER_ID
      );
    }
    this.audienceHeatmapLayer = new MapFeature.AudienceHeatmapLayer(
      this.map,
      CampaignMap.AUDIENCE_HEATMAP_LAYER_ID,
    );
    this.politicalHeatmapLayer = new MapFeature.PoliticalHeatmapLayer(
      this.map,
      CampaignMap.POLITICAL_HEATMAP_LAYER_ID,
      CampaignMap.UNIT_CIRCLE_LAYER_ID,
    );
    new MapFeature.CustomBoundsLayer(
      this.map,
      CampaignMap.CUSTOM_BOUNDS_LAYER_ID,
      CampaignMap.CUSTOM_BOUNDS_SOURCE_ID,
      CampaignMap.UNIT_CIRCLE_LAYER_ID,
    );
    new MapFeature.UnitLabelsFeature(
      this.map,
      CampaignMap.UNIT_SOURCE_ID
    );
  }

  #dispatchMoveEndEvent() {
    window.dispatchEvent(new CustomEvent("adquick:map:moveend", { detail: { map: this.map } }));
  }

  #updateSources() {
    this.updateUnitsSource();
    this.#updatePackageChildrenSource();
  }

  #handleHistoryReplaceState() {
    this.#updateSources();
  }

  toggleShowCluster() {
    const currentStyle = this.map.getStyle()
    const negate = !this.show_clusters
    currentStyle.sources.unit.cluster = negate
    this.show_clusters = negate
    this.map.setStyle(currentStyle)
  }

  toggleShowFaceIDLayer() {
    const negate = !this.show_face_id
    this.show_face_id = negate
    if (negate) {
      this.map.setLayoutProperty(CampaignMap.UNIT_FACE_ID_LAYER_ID, 'visibility', 'visible');
    } else {
      this.map.setLayoutProperty(CampaignMap.UNIT_FACE_ID_LAYER_ID, 'visibility', 'none');
    }
  }

  toggleShowPoiLayer() {
    const negate = !this.show_pois
    this.show_pois = negate
    if (negate) {
      this.map.setLayoutProperty(CampaignMap.POI_LAYER_ID, 'visibility', 'visible');
    } else {
      this.map.setLayoutProperty(CampaignMap.POI_LAYER_ID, 'visibility', 'none');
    }
  }

  toggleShowTraffic() {
    const negate = !this.show_traffic
    this.show_traffic = negate
    if (negate) {
      this.map.setStyle(CampaignMap.TRAFFIC_MAP_STYLE);
    } else {
      this.map.setStyle(CampaignMap.DEFAULT_MAP_STYLE);
    }
  }

  #handleHistoryReplaceStateDebounced = _.debounce(this.#handleHistoryReplaceState, CampaignMap.THROTTLE_INTERVAL).bind(
    this,
  );

  /*** MAP MOVE ***/

  #handleMapMoveEnd() {
    this.#dispatchMoveEndEvent();
  }

  /*** UNIT CLICK ***/

  #handleUnitClick(event) {
    const unitId = event.features[0].properties.id;
    if (!unitId) return false; // return unless it's a unit "circle" (e.g. not a cluster)
    // FIX: this event name should be changed as it isn't coming from "browse" but from the "campaign map"
    window.dispatchEvent(new CustomEvent("adquick:browsemap:unitclick", { detail: { unitId } }));
  }

  /*** VIEW ON MAP CLICK ***/

  #handleViewOnMapClick(event) {
    let { lng, lat } = event.detail;
    window.modal.close();
    this.map.flyTo({
      center: [lng, lat],
      zoom: CampaignMap.CLUSTER_MAX_ZOOM + 1, // Force clusters to break apart and show individual units
      curve: 2,
      padding: { left: CampaignMap.SIDEBAR_WIDTH },
    });

    setTimeout(() => {
      const features = this.#getUniqueFeatures(lat, lng);
      if (features.length) {
        let unitId = features[0].id;
        window.dispatchEvent(new CustomEvent("adquick:map:highlightunit", { detail: { unitId: unitId } }));
        setTimeout(() => {
          window.dispatchEvent(new CustomEvent("adquick:map:unhighlightunit", { detail: { unitId: unitId } }));
        }, CampaignMap.UNIT_HIGHLIGHT_DURATION);
      } else {
        console.error("Couldn't find unit on map. Either it's not loaded yet or it's still a cluster.");
      }
    }, CampaignMap.MAX_FLY_TO_DURATION + 100); // Wait until the map is fully zoomed in before highlighting the unit
  }

  /*** DRAW CREATE ***/

  async #handleDrawCreate(event) {
    const polygonId = event.features[0].id;
    const polygonCoordinates = event.features[0].geometry.coordinates;

    this.mapDraw.delete(polygonId);
    const unitIds = await this.#getUnitIdsWithinPolygon(polygonCoordinates);

    window.dispatchEvent(new CustomEvent("adquick:campaignmap:unitselect", { detail: { unitIds, polygonCoordinates } }));
  }

  async #getUnitIdsWithinPolygon(polygonCoordinates) {
    // This fetch doesn't cause a network delay, since it hits a browser cache from the last time the map was moved/zoomed.
    const bounds = this.#getPolygonBounds(polygonCoordinates[0]);
    const geojson = await fetch(this.#unitDataUrl(bounds)).then(res => res.json());
    const polygon = turf.polygon(polygonCoordinates);

    const reduceToUnitIdsWithinPolygon = (unitIds, feature) => {
      const selected = turf.booleanPointInPolygon(feature.geometry.coordinates, polygon);
      return selected ? [...unitIds, feature.properties.id] : unitIds;
    };

    const unitIds = geojson.features.reduce(reduceToUnitIdsWithinPolygon, []);
    return unitIds;
  }

  #getPolygonBounds(coordinates) {
    // Initialize with first coordinate values
    let bounds = {
      max_lon: coordinates[0][0],
      min_lon: coordinates[0][0],
      max_lat: coordinates[0][1],
      min_lat: coordinates[0][1]
    };

    // Compare each coordinate to find max/min values
    coordinates.forEach(([lon, lat]) => {
      bounds.max_lon = Math.max(bounds.max_lon, lon);
      bounds.min_lon = Math.min(bounds.min_lon, lon);
      bounds.max_lat = Math.max(bounds.max_lat, lat);
      bounds.min_lat = Math.min(bounds.min_lat, lat);
    });

    return bounds;
  }

  /*** UNITS SOURCE ***/

  #createUnitSource() {
    this.map.addSource(CampaignMap.UNIT_SOURCE_ID, {
      type: "geojson",
      cluster: true,
      clusterMaxZoom: CampaignMap.CLUSTER_MAX_ZOOM, // TODO: This option is ignored in mapboxgl 2.0. Upgrade mapboxgl once the Source.getClusterLeaves bug is fixed.
      maxzoom: CampaignMap.CLUSTER_MAX_ZOOM, // units are clustered until the user zooms all the way in
      clusterRadius: 15,
      promoteId: "id",
      data: { type: "FeatureCollection", features: [] },
    });
  }

  async updateUnitsSource(fitBounds = true) {
    const dataUrl = this.#unitDataUrl();
    const sourceData = await get(dataUrl);

    this.map.getSource(CampaignMap.UNIT_SOURCE_ID).setData(sourceData);

    if (sourceData.features.length) {
      if (fitBounds) this.#fitToCampaignUnits(sourceData.features);
    }
  }

  #unitDataUrl(bounds = {}) {
    return this.#dataUrlWithParams("units_v2", bounds);
  }

  /*** PACKAGE CHILDREN SOURCE ***/

  #createPackageChildrenSource() {
    if (!this.hasPackageChildren) return;

    this.map.addSource(CampaignMap.PACKAGE_CHILDREN_SOURCE_ID, {
      type: "geojson",
      cluster: true,
      clusterMaxZoom: CampaignMap.CLUSTER_MAX_ZOOM,
      maxzoom: CampaignMap.CLUSTER_MAX_ZOOM, // units are clustered until the user zooms all the way in
      clusterRadius: 15,
      promoteId: "id",
      data: { type: "FeatureCollection", features: [] },
    });
  }

  #updatePackageChildrenSource() {
    if (!this.hasPackageChildren) return;

    const dataUrl = this.#packageChildrenDataUrl()
    this.map.getSource(CampaignMap.PACKAGE_CHILDREN_SOURCE_ID).setData(dataUrl);
  }

  #packageChildrenDataUrl() {
    return this.#dataUrlWithParams('package_children');
  }

  #dataUrlWithParams(endpoint, bounds = {}) {
    // Remove the zoom param to get more cache hits
    const { zoom, ...params } = paramsGet();

    const queryString = QueryString.stringify(
      { ...params, ...bounds },
      { arrayFormat: "bracket" },
    );
    return `/campaigns/${this.campaignId}/${endpoint}?${queryString}`;
  }


  #roundBy(n, increment) {
    return Math.round(n / increment) * increment;
  }

  #getUniqueFeatures(lat, lng) {
    const projectedPoint = this.map.project([lng, lat]);
    const features = this.map.queryRenderedFeatures(projectedPoint, { layers: [CampaignMap.UNIT_CIRCLE_LAYER_ID] });
    const uniqueIds = new Set();
    const uniqueFeatures = [];
    for (const feature of features) {
      const unitId = feature.properties.id;
      if (!unitId) continue; // continue unless it's a unit "circle" (e.g. not a cluster)
      if (!uniqueIds.has(unitId)) {
        uniqueIds.add(unitId);
        uniqueFeatures.push(feature);
      }
    }
    return uniqueFeatures;
  }

  /*** POIS ***/

  #createPoiSource() {
    this.map.addSource(CampaignMap.POI_SOURCE_ID, {
      type: "geojson",
      promoteId: "id",
      data: { type: "FeatureCollection", features: [] },
    });
  }

  async updatePoisSource() {
    const url = `/api/v1/campaigns/${this.campaignId}/placemarkers?feature_collection=true`;
    const sourceData = await get(url);

    if (sourceData.features.length) {
      await this.#loadPoisIcons(sourceData.features);
      this.map.getSource(CampaignMap.POI_SOURCE_ID).setData(sourceData);
    }
  }

  async updateUnitFeatures(features) {
    const source = this.map.getSource(CampaignMap.UNIT_SOURCE_ID);
    const currentData = source.serialize().data;

    const existingFeatureMap = new Map(
      currentData.features.map(f => [f.properties.id, f])
    );

    features.forEach(newFeature => {
      existingFeatureMap.set(newFeature.properties.id, newFeature);
    });

    const updatedData = {
      type: "FeatureCollection",
      features: Array.from(existingFeatureMap.values())
    };

    source.setData(updatedData);
  }

  async #loadPoisIcons(features) {
    const properties = Array.from(new Set(features.map(f => f.properties)));
    const promises = properties.map(
      async properties => await this.#addIconToMap(properties.icon_id, properties.icon_custom_url),
    );
    await Promise.all(promises);
  }

  async #addIconToMap(iconId, iconCustomUrl) {
    let url;

    if (iconCustomUrl) {
      url = iconCustomUrl;
    } else {
      url = window.UNIT_TYPE_ICON_MAP[iconId] || window.UNIT_AVAILABILITY_MAP[iconId];
    }

    if (this.map.hasImage(iconId)) return;

    return new Promise(resolve => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        // To handle async issues where mapboxgl says that the image has already been loaded because the previous hasImage check returned false
        if (!this.map.hasImage(iconId)) {
          this.map.addImage(iconId, img);
        }
        resolve(img);
      };
      img.onerror = err => {
        console.error("error loading image to map", url, iconId, iconCustomUrl, err);
        resolve();
      };
      img.src = url;
    });
  }

  /*** CUSTOM BOUNDS ***/

  async #createCustomBoundsSource() {
    this.map.addSource(CampaignMap.CUSTOM_BOUNDS_SOURCE_ID, {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
    });
  }

  async updateCustomBoundsSource() {
    const url = `/api/v1/data_layers/highlighted_areas?campaign_id=${this.campaignId}`;
    const sourceData = await get(url);

    if (sourceData.features.length) {
      const source = this.map.getSource(CampaignMap.CUSTOM_BOUNDS_SOURCE_ID);
      source.setData(sourceData);
    }
  }

  /*** UNIT MODAL HISTORY EVENTS ***/

  #handleCloseUnitModal(event) {
    const element = event.detail.element;
    if (element.querySelector("#browse-unit-modal") == null) return false;
    if (hasParam("unit_id")) paramsRemove("unit_id", false);
  }

  #fitToCampaignUnits(features) {
    const bounds = new mapboxgl.LngLatBounds();
    features.forEach(feature => bounds.extend(feature.geometry.coordinates));
    this.map.fitBounds(bounds, { padding: { left: 620, right: 100, top: 50, bottom: 50 }, maxZoom: 7 });
  }

  #handleUnitFavorite(event) {
    this.updateUnitsSource(false);
  }

  #handleUnitRecommend(event) {
    this.updateUnitsSource(false);
  }
}

export default CampaignMap;
