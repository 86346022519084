import ApplicationController from "./application_controller";

export class ListSearchController extends ApplicationController {
  static targets = ["item", "input"];

  filter() {
    this.itemTargets.map(li => {
      const liValue = li.getAttribute("data-list-search-value").toLocaleLowerCase();
      const classes = li.classList;
      const found = liValue.startsWith(this.inputTarget.value.toLocaleLowerCase());

      if (!found) {
        classes.add("hidden");
      } else {
        classes.remove("hidden");
      }
    });
  }
}
