export default class MarketLayer {
  constructor(map, source, circleLayerId, symbolLayerId, maxzoom) {
    this.#addMarketCircleLayer(map, source, circleLayerId, maxzoom);
    this.#addMarketSymbolLayer(map, source, symbolLayerId, maxzoom);
  }

  #addMarketCircleLayer(map, source, id, maxzoom) {
    map.addLayer({
      id: id,
      type: "circle",
      source: source,
      maxzoom: maxzoom,
      paint: {
        "circle-opacity": ["step", ["get", "units_count"], 0.06, 10000, 0.1, 50000, 0.15],
        "circle-color": "#911884",
        "circle-radius": ["step", ["get", "units_count"], 12, 10000, 18, 50000, 28],
      },
      layout: {
        "circle-sort-key": ["to-number", ["get", "units_count"]],
      },
    });
  }

  #addMarketSymbolLayer(map, source, id, maxzoom) {
    map.addLayer({
      id: id,
      type: "symbol",
      source: source,
      maxzoom: maxzoom,
      layout: {
        "symbol-sort-key": ["to-number", ["-", ["get", "units_count"]]],
        "text-anchor": "bottom",
        "text-field": [
          "format",
          ["get", "units_count_abbreviated"],
          {},
          "\n",
          {},
          ["get", "name"],
          { "font-scale": 0.8 },
          "\n",
          {},
          "●",
          { "font-scale": 0.6, "text-color": "#911884" },
        ],
        "text-font": ["Inter Medium"],
        "text-size": ["step", ["get", "units_count"], 14, 10000, 15, 50000, 16],
      },
      paint: {
        "text-color": "#333333",
        "text-halo-width": 1,
        "text-halo-color": "#ffffff",
        "text-translate": [0, 6],
      },
    });
  }
}
