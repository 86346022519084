import ApplicationController from "../../application_controller";
import { delay } from "lodash";

export default class extends ApplicationController {
  static targets = ["option", "input", "form", "submit"];

  static values = {
    campaignToken: String,
    amount: Number,
    billingAddress: Object,
    stripeOnly: Boolean,
    achOnly: Boolean,
  };

  async connected() {
    this.stripe = Stripe(AppConfig.stripePublishableKey);
    this.elementMounted = false;
    if (this.stripeOnlyValue) {
      this.paymentIntent = await this.#createPaymentIntent();
      this.elements = this.stripe.elements({ clientSecret: this.paymentIntent.client_secret });
      this.openPaymentElement();
    }
    if (this.achOnlyValue) {
      document.getElementById("stripe_ach").click();
    }
  }

  async selectRadioOption(e) {
    const radioInput = e.currentTarget;
    switch (radioInput.value) {
      case "async_signature_request":
        this.closePaymentElement("payment-element-ach");
        this.closePaymentElement("payment-element");
        this.openContractEmailInput();
        break;
      case "stripe":
        this.closePaymentElement("payment-element-ach");
        this.paymentIntent = await this.#createPaymentIntent();
        this.elements = this.stripe.elements({ clientSecret: this.paymentIntent.client_secret });
        this.closeContractEmailInput();
        this.openPaymentElement("payment-element");
        break;
      case "stripe_ach":
        this.closePaymentElement("payment-element");
        this.setupIntent = await this.#createStripeSetupIntent();
        this.createStripeElement(this.setupIntent);
        if (!this.defaultPaymentPresent) {
          this.openPaymentElement("payment-element-ach");
        }
        break;
      default:
        this.closePaymentElement("payment-element-ach");
        this.closePaymentElement("payment-element");
        this.closeContractEmailInput();
    }

    this.optionTargets.forEach((el, i) => {
      el.classList.toggle("active", radioInput.parentElement === el);
    });
  }

  openPaymentElement(id) {
    const paymentElementDiv = document.getElementById(id);
    paymentElementDiv.classList.remove("hidden");

    if (!this.elementMounted) {
      const paymentElement = this.elements.create("payment");
      paymentElement.mount(paymentElementDiv);
      this.stripeElement = paymentElement;
      this.elementMounted = true;
    }
  }

  createStripeElement(response) {
    if (response.setup_intent) {
      if (this.elementCreated) {
        return;
      }
      this.stripeSetupIntent = response.setup_intent;
      this.stripe = Stripe(AppConfig.stripePublishableKey);
      this.elements = this.stripe.elements({ clientSecret: response.setup_intent.client_secret });
      this.elementCreated = true;
    }

    if (response.default_payment_method) {
      if (this.defaultPaymentPresent) {
        return;
      }
      this.stripeDefaultPaymentMethod = response.default_payment_method;
      const paymentElementDiv = document.getElementById("payment-element-ach");
      const span = this.#existingPaymentElement(
        response.default_payment_method.bank_name,
        response.default_payment_method.last4,
      );
      const removeButton = this.#removePaymentElement();
      paymentElementDiv.appendChild(span);
      paymentElementDiv.closest(".input-wrapper").appendChild(removeButton);
      this.defaultPaymentPresent = true;
      // paymentElementDiv.innerText = `Bank: ${response.default_payment_method.bank_name} - Account: ${response.default_payment_method.last4}`;
    }
  }

  closePaymentElement(id) {
    const paymentElement = document.getElementById(id);
    this.elementCreated = false;
    if (this.stripeElement) {
      this.stripeElement.unmount();
      this.stripeElement.destroy();
      this.elementMounted = false;
    }
    if (paymentElement) {
      paymentElement.classList.add("hidden");
    }
  }

  async removeUserPaymentMethod() {
    const response = await fetch(`/campaigns/${this.campaignTokenValue}/checkout/remove_default_payment`, {
      method: "POST",
    });

    if (response.ok) {
      return location.reload()
    }
  }

  openContractEmailInput() {
    const contractEmailInput = document.getElementById("contract-email");
    if (contractEmailInput) {
      contractEmailInput.classList.remove("hidden");
    }
  }

  closeContractEmailInput() {
    const contractEmailInput = document.getElementById("contract-email");
    if (contractEmailInput) {
      contractEmailInput.classList.add("hidden");
    }
  }

  async submitPayment(event) {
    const element = document.querySelector('input[name="payment_option"]:checked');
    if (!element) {
      this.addError("Please select a payment option");
      event.preventDefault();
      return;
    }
    const selectedPaymentOption = element.value;
    if (
      selectedPaymentOption === "stripe" ||
      selectedPaymentOption === "invoice" ||
      selectedPaymentOption == "stripe_ach"
    ) {
      event.preventDefault();
      if (selectedPaymentOption === "stripe_ach") {
        const setupResponse = await this.confirmSetup();
        if (setupResponse.error) {
          this.closeLoadingModal();
          alert("Error confirming setup intent");
          throw setupResponse.error;
        }
        await this.stripeFinish()
      } else {
        await this.handleContractSigning(selectedPaymentOption);
      }
    }
    if (selectedPaymentOption === "async_signature_request") {
      if (document.getElementById("contract-email").value === "") {
        event.preventDefault();
        this.addError("Please enter an email to send the contract to");
      }
    }
  }

  async handleCcPayment(paymentOption) {
    // Prevent multiple form submissions
    if (this.submitTarget.disabled) {
      return;
    }
    // Disable form submission while loading
    this.submitTarget.disabled = true;

    if (this.orderId == null) {
      const orderId = await this.#createPendingOrder(paymentOption);
      if (orderId) {
        this.orderId = orderId;
      } else {
        return;
      }
    }

    await this.#confirmPaymentIntent();
  }

  handleError(error) {
    this.addError(error.message);
    this.submitTarget.disabled = false;
  }

  addError(message) {
    const messageContainer = document.querySelector("#error-message");
    messageContainer.style.display = "block";
    messageContainer.textContent = message;
  }

  async handleContractSigning(paymentOption) {
    const payload = {
      campaign_token: this.campaignTokenValue,
      billing_address: this.billingAddressValue,
    };
    this.openLoadingModal();
    try {
      const response = await $.ajax({
        type: "POST",
        url: `/api/v1/contracts`,
        data: payload,
      });
      const { signature_request_url } = response;
      this.closeLoadingModal();
      this.openHelloSign(signature_request_url, paymentOption);
    } catch (error) {
      this.closeLoadingModal();
      alert("Error submitting contract");
      throw error;
    }
  }

  async openHelloSign(signature_request_url, paymentOption) {
    const form = this.formTarget;
    const submitStripe = this.handleCcPayment.bind(this);
    HelloSign.init(AppConfig.helloSignClientId);
    HelloSign.open({
      skipDomainVerification: AppConfig.helloSignTestMode,
      url: signature_request_url,
      allowCancel: true,
      container: this.helloSignRef,
      async messageListener(data) {
        if (data.event == "signature_request_signed") {
          HelloSign.close();
          if (paymentOption === "stripe") {
            await submitStripe(paymentOption);
          }
          if (paymentOption === "invoice") {
            form.submit();
          }
        }
      },
    });
  }

  openLoadingModal() {
    var element = document.getElementById("loading-modal");
    element.classList.remove("hide");
    element.classList.add("open");
  }

  closeLoadingModal() {
    var element = document.getElementById("loading-modal");
    element.classList.remove("open");
    element.classList.add("hide");
  }

  async #createPaymentIntent() {
    const paymentIntent = await fetch(`/campaigns/${this.campaignTokenValue}/checkout/payment_intent`, {
      method: "POST",
    }).then(r => r.json());
    if (paymentIntent.error) {
      this.handleError(paymentIntent.error);
      return null;
    }

    return paymentIntent;
  }

  async #createStripeSetupIntent() {
    const setupIntent = await fetch(`/campaigns/${this.campaignTokenValue}/checkout/setup_intent`, {
      method: "POST",
    }).then(r => r.json());
    if (setupIntent.error) {
      this.handleError(setupIntent.error);
      return null;
    }

    return setupIntent;
  }

  async stripeFinish() {
    const setupIntent = await fetch(`/campaigns/${this.campaignTokenValue}/checkout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.stripeResult),
    });
    if (setupIntent.ok) {
      window.location.replace(`${window.location.origin}/campaigns/${this.campaignTokenValue}/checkout/success`);
    }
  }

  async confirmSetup() {
    // event.preventDefault()
    // if no this.stripe user already have a payment method and no setup intent was created
    if (this.defaultPaymentPresent) {
      const setupIntent = await fetch(`/campaigns/${this.campaignTokenValue}/checkout/confirm_setup_intent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }).then(r => r.json());
      if (setupIntent.error) {
        this.handleError(setupIntent.error);
        return { error: setupIntent.error };
      }

      return setupIntent;
    }
    const confirmed = await this.stripe
      .confirmSetup({
        elements: this.elements,
        redirect: "if_required",
        confirmParams: {
          // Return URL where the customer should be redirected after the SetupIntent is confirmed.
          return_url: `${window.location.origin}/campaigns/${this.campaignTokenValue}/checkout/confirm_setup_intent`,
        },
      })
      .then(async result => {
        if (result.error) {
          console.error(result.error);
          return { error: result.error };
          // Inform the customer that there was an error.
        } else {
          console.log({ result });
          const setupIntent = await fetch(`/campaigns/${this.campaignTokenValue}/checkout/confirm_setup_intent`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              setup_intent_id: result.setupIntent.id,
            }),
          }).then(r => r.json());
          if (setupIntent.error) {
            this.handleError(setupIntent.error);
            return { error: setupIntent.error };
          }

          return setupIntent;
        }
      });

    return confirmed;
  }

  async #createPendingOrder(paymentOption) {
    const { error, order_id } = await fetch(`/campaigns/${this.campaignTokenValue}/checkout/pending_order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ payment_option: paymentOption, payment_intent_id: this.paymentIntent.id }),
    }).then(r => r.json());

    if (error) {
      this.handleError(error);
      return null;
    } else {
      return order_id;
    }
  }

  async #confirmPaymentIntent() {
    // Confirm the payment given the clientSecret
    // from the payment intent that was just created on connect()
    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: `${window.location.origin}/campaigns/${this.campaignTokenValue}/checkout/success`,
      },
    });

    if (error) {
      this.handleError(error);
      this.submitTarget.disabled = false;
    }
  }

  #existingPaymentElement(bankName, last4) {
    const span = document.createElement("span");
    span.innerText = `Bank: ${bankName} - Account: ${last4}`;
    return span;
  }

  #removePaymentElement() {
    const removeButton = document.createElement("i");
    removeButton.classList.add("fa-regular", "fa-x", "red-icon", "pointer-cursor");
    removeButton.setAttribute("data-action", "click->payment#removeUserPaymentMethod");
    // removeButton.innerText = "X";
    return (this.removeButton = removeButton);
  }
}
