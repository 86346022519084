import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static values = {
    supplierId: String,
    campaignToken: String,
    userId: String,
    reflexAction: String,
  };

  static targets = ["rowCheckbox", "globalCheckbox", "submitButton"];

  campaignUnitRowElements() {
    return this.rowCheckboxTargets.filter(el => el.checked);
  }

  checkedCampaignUnits() {
    const checkedElements = this.campaignUnitRowElements();
    return checkedElements.map(el => el.dataset.campaignUnitToken);
  }

  isThereAnyCheckedRows() {
    return this.campaignUnitRowElements().length > 0;
  }

  toggleGlobal(e) {
    const {
      target: { checked },
    } = e;
    this.toggleGlobalAction(checked);
    return this.toggleSubmit(!checked);
  }

  toggleGlobalAction(checked) {
    this.rowCheckboxTargets.filter(el => !el.disabled).forEach(el => (el.checked = checked));
  }

  toggleRow() {
    const disabled = !this.isThereAnyCheckedRows();
    return this.toggleSubmit(disabled);
  }

  toggleSubmit(disabled) {
    return (this.submitButtonTarget.disabled = disabled);
  }

  async submit() {
    const payload = {
      campaign_units: this.checkedCampaignUnits(),
      user_id: this.userIdValue,
      campaign_id: this.campaignTokenValue,
      supplier_id: this.supplierIdValue,
    };
    await this.stimulate(`Plus::Campaigns::VendorContractsReflex#${this.reflexActionValue}`, payload);
    window.modal.close();
  }
}
