import ApplicationController from "./application_controller";
import VendorSearchResults from "../utils/VendorSearchResult";
import debounce from "lodash/debounce";

export default class extends ApplicationController {
  static targets = ["filter", "contact", "sortBy", "sendRfpButton", "useApiCheckbox", "searchParams"];

  static values = {
    vendorsAddedSectionDisabled: Boolean,
  };

  connected() {
    this.onSearchInput = debounce(this.onSearchInput.bind(this), 500);
    const focusElement = this.element.querySelector("[autofocus]");
    if (focusElement) {
      focusElement.focus();
      const value = focusElement.value;
      focusElement.value = "";
      focusElement.value = value;
    }
  }

  campaignToken() {
    return this.element.dataset.campaignToken;
  }

  searchResult() {
    return JSON.parse(this.element.dataset.searchResult);
  }

  customLanguage() {
    return JSON.parse(this.data.get("customLanguage"));
  }

  activeFilters() {
    return this.filterTargets.filter(el => el.checked);
  }

  selectedContacts() {
    return this.contactTargets.filter(el => el.checked);
  }

  deactiveAllSorts() {
    this.sortByTargets.forEach(el => el.classList.remove("active"));
  }

  activateSendRfpButton() {
    this.sendRfpButtonTarget.classList.remove("disabled");
    this.sendRfpButtonTarget.classList.add("blue");
  }

  deactivateSendRfpButton() {
    this.sendRfpButtonTarget.classList.remove("blue");
    this.sendRfpButtonTarget.classList.add("disabled");
  }

  checkSendRfpButton() {
    const selectedContacts = this.selectedContacts();
    if (selectedContacts.length == 0) {
      return this.deactivateSendRfpButton();
    }
    this.activateSendRfpButton();
  }

  parentElementFromElement(e) {
    return e.parentElement;
  }

  filters() {
    return this.activeFilters().reduce((filter, input) => {
      const filterKey = input.name;
      const currentValues = filter[filterKey] || [];
      const filterValue = [...currentValues, this.parseInputValue(input)];
      filter[filterKey] = filterValue;
      return filter;
    }, {});
  }

  sortBy() {
    const sortTarget = this.sortByTargets.find(el => el.classList.contains("active"));
    if (!sortTarget) {
      return {};
    }
    return {
      [sortTarget.dataset.sortColumn]: sortTarget.dataset.sortBy,
    };
  }

  filterContactsByMarketId(marketId) {
    return this.contactTargets.filter(el => el.dataset.marketId == marketId);
  }

  filterContactsByContactIds(contactIds) {
    return this.contactTargets.filter(el => contactIds.includes(el.dataset.contactId));
  }

  getContactIdsFromContactElements(contactElements) {
    return contactElements.map(el => el.dataset.contactId);
  }

  toggleContactElements(contactElements, checked) {
    contactElements.forEach(el => (el.checked = checked));
  }

  parseInputValue(input) {
    const { value } = input;
    if (value == "true") {
      return true;
    }
    if (value == "false") {
      return false;
    }
    return value;
  }

  buildFilterSearchResultsPayload() {
    return {
      campaign_token: this.campaignToken(),
      search_result: this.searchResult(),
      filters: this.filters(),
      sort_by: this.sortBy(),
      custom_language: this.customLanguage(),
      search_params: this.searchParamsTarget.value,
    };
  }

  buildSendRfpPayload() {
    let use_api = false;
    if (this.hasUseApiCheckboxTarget) {
      use_api = !this.useApiCheckboxTarget.checked;
    }
    const user_ids = this.selectedContactIds();
    const custom_language_ids = Object.values(this.customLanguage());
    return {
      campaign_token: this.campaignToken(),
      user_ids,
      custom_language_ids,
      use_api,
    };
  }

  selectedContactIds() {
    const selectedContacts = this.selectedContacts();
    const selectedContactIds = this.getContactIdsFromContactElements(selectedContacts);
    return [...new Set(selectedContactIds)];
  }

  openCustomLanguageModal(e) {
    const { currentTarget } = e;
    const contactId = currentTarget.dataset.contactId;
    const currentUserId = currentTarget.dataset.currentUserId;
    const userCustomLanguageId = currentTarget.dataset.userCustomLanguageId;
    this.onOpenCustomLanguageModal(contactId, currentUserId, userCustomLanguageId);
  }

  async onOpenCustomLanguageModal(contactId, currentUserId, userCustomLanguageId) {
    await this.stimulateCustomLanguageModal(contactId, currentUserId, userCustomLanguageId);
    window.modal.open();
  }

  onColumnSort(e) {
    this.deactiveAllSorts();
    e.currentTarget.classList.toggle("active");
    this.stimulateFilterSearchResults();
  }

  onContactClick(e) {
    const {
      checked,
      dataset: { contactId, supplierId },
    } = e.currentTarget;
    e.stopPropagation();
    const contactElements = this.filterContactsByContactIds([contactId]);
    this.toggleContactElements(contactElements, checked);
    this.updateSearchResultWithCheckedContact(contactId, supplierId, checked);
    this.stimulateUpdateVendorsAdded();
    this.checkSendRfpButton();
  }

  updateSearchResultWithCheckedContact(contactId, supplierId, checked) {
    const helper = new VendorSearchResults(this.searchResult());
    const updatedSearchResult = helper.updateSearchResultWithCheckedContactBySupplier(contactId, supplierId, checked);
    return this.updateSearchResultWithChanges(updatedSearchResult);
  }

  updateSearchResultWithChanges(changedSearchResult) {
    this.element.dataset.searchResult = JSON.stringify(changedSearchResult);
  }

  onGlobalCheckChange(e) {
    const {
      checked,
      dataset: { marketId },
    } = e.currentTarget;
    const marketContacts = this.filterContactsByMarketId(marketId);
    const contactIds = this.getContactIdsFromContactElements(marketContacts);
    const contactElements = this.filterContactsByContactIds(contactIds);
    this.toggleContactElements(contactElements, checked);
    this.updateSearchResultWithCheckedContacts(contactIds, checked);
    this.stimulateUpdateVendorsAdded();
    this.checkSendRfpButton();
  }

  onSelectAllContacts(e) {
    const { checked } = e.currentTarget;
    const contactIds = this.contactTargets.map(el => el.dataset.contactId);
    const contactElements = this.contactTargets;
    this.toggleContactElements(contactElements, checked);
    this.updateSearchResultWithCheckedContacts(contactIds, checked);
    this.stimulateUpdateVendorsAdded();
    this.checkSendRfpButton();
  }

  updateSearchResultWithCheckedContacts(contactIds, checked) {
    const helper = new VendorSearchResults(this.searchResult());
    this.element.dataset.searchResult = JSON.stringify(
      helper.updateSearchResultWithCheckedContactIds(contactIds, checked),
    );
  }

  onFilterChange(e) {
    this.stimulateFilterSearchResults();
  }

  async sendRfp() {
    const selectedContacts = this.selectedContacts();
    if (selectedContacts.length == 0) {
      return;
    }
    try {
      this.deactivateSendRfpButton();
      await this.stimulateSendRfpForSelectedContacts();
      alert("The RFP has been sent to the selected vendors");
      this.activateSendRfpButton();
    } catch (error) {
      console.log(error);
      alert("Something happened, contact support");
      this.activateSendRfpButton();
    }
  }

  async stimulateFilterSearchResults() {
    const payload = this.buildFilterSearchResultsPayload();
    await this.stimulate(`Plus::Campaigns::OverviewReflex#filter_vendor_result`, payload);
  }

  async stimulateUpdateVendorsAdded() {
    if (this.vendorsAddedSectionDisabledValue) {
      return;
    }

    const payload = {
      campaign_token: this.campaignToken(),
      search_result: this.searchResult(),
    };
    await this.stimulate(`Plus::Campaigns::OverviewReflex#update_vendors_added`, payload);
  }

  async stimulateSendRfpForSelectedContacts() {
    const payload = this.buildSendRfpPayload();
    await this.stimulate(`Plus::Campaigns::OverviewReflex#send_rfp`, payload);
  }

  async stimulateCustomLanguageModal(contact_id, current_user_id, user_custom_language_id) {
    const payload = {
      component: "modal/custom_language_component",
      component_params: {
        contact_id,
        current_user_id,
        user_custom_language_id,
        search_results_payload: this.buildFilterSearchResultsPayload(),
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
  }

  async markAsFavoriteSupplier(e) {
    const { supplierId } = e.currentTarget.dataset;
    await this.stimulate("FavoriteSuppliersReflex#mark_as_favorite", { supplier_id: supplierId });
    this.updatedSearchResultWithFavoritedSupplierChange(supplierId, true);
    this.stimulateFilterSearchResults();
  }

  async removeFavoriteSupplier(e) {
    const { supplierId } = e.currentTarget.dataset;
    await this.stimulate("FavoriteSuppliersReflex#remove_favorite", { supplier_id: supplierId });
    this.updatedSearchResultWithFavoritedSupplierChange(supplierId, false);
    this.stimulateFilterSearchResults();
  }

  updatedSearchResultWithFavoritedSupplierChange(supplierId, favorited) {
    const helper = new VendorSearchResults(this.searchResult());
    const updatedSearchResult = helper.updatedSearchResultWithFavoritedSupplierChange(supplierId, favorited);
    return this.updateSearchResultWithChanges(updatedSearchResult);
  }

  onSearchInput(e) {
    this.stimulateFilterSearchResults();
  }
}
