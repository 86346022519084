import BaseController from "./base_controller";

export default class extends BaseController {
  static values = {
    campaignToken: String,
    allFilteredTokens: Array,
    nextPage: Number,
    previousPage: Number,
  };

  static targets = ["unit", "actions", "bulkListContainer", "globalCheckbox", "selectedCount", "selectAllFiltered"];

  isAllCurrentTokensSelected() {
    if (!this.base.hasSelectedTokens()) {
      return false;
    }
    return this.allCurrentTokens().every(token => this.base.selectedTokens.includes(token));
  }

  allCurrentTokens() {
    return this.unitTargets.map(el => el.dataset.campaignManageUnitCardCampaignUnitTokenValue);
  }

  afterConnected() {
    this.handleGlobalCheckboxState();
  }

  handleGlobalCheckboxState() {
    this.handleSelectedDetailsState();
    if (this.isAllCurrentTokensSelected()) {
      return (this.globalCheckboxTarget.checked = true);
    }
    return (this.globalCheckboxTarget.checked = false);
  }

  handleSelectedDetailsState() {
    if (!this.base.hasSelectedTokens()) {
      return this.hideSelectedDetails();
    }
    return this.showSelectedDetails();
  }

  hideSelectedDetails() {
    this.actionsTarget.classList.remove("show-selected");
  }

  showSelectedDetails() {
    this.actionsTarget.classList.add("show-selected");
    this.buildCurrentSelectedState();
  }

  buildCurrentSelectedState() {
    const content = `${this.base.selectedTokens.length} current units selected`;
    this.selectedCountTarget.innerHTML = content;
  }

  selectAllFiltered() {
    this.base.selectedTokens = this.allFilteredTokensValue;
    this.unitTargets.forEach(el => this.activateCard(el));
    this.handleGlobalCheckboxState();
  }

  clearSelection() {
    this.base.selectedTokens = [];
    this.unitTargets.forEach(el => this.deactivateCard(el));
    this.handleGlobalCheckboxState();
  }

  toggleAll(e) {
    const {
      target: { checked },
    } = e;
    if (checked) {
      return this.selectAll();
    }
    return this.unselectAll();
  }

  unselectAll() {
    this.unitTargets.forEach(el => this.deactivateCard(el));
    this.base.removeTokens(this.allCurrentTokens());
    this.handleSelectedDetailsState();
  }

  selectAll() {
    this.unitTargets.forEach(el => this.activateCard(el));
    this.base.addTokens(this.allCurrentTokens());
    this.handleSelectedDetailsState();
  }

  activateCard(card) {
    const cardController = this.getCardController(card);
    return cardController.activateCard();
  }

  deactivateCard(card) {
    const cardController = this.getCardController(card);
    return cardController.deactivateCard();
  }

  getCardController(card) {
    return this.getControllerForElement(card, "campaign-manage-unit-card");
  }

  toggleBulkList(e) {
    this.bulkListContainerTarget.classList.toggle("open");
  }

  async onGoToPage(e) {
    const {
      target: {
        dataset: { page },
      },
    } = e;
    if (page == "next" || page == "previous") {
      return this.handlePaginationButton(page);
    }
    this.paramsAdd("page", page);
    await this.stimulate("Plus::Campaigns::ManageUnitsReflex#index");
  }

  async handlePaginationButton(action) {
    const buttons = {
      next: this.nextPageValue,
      previous: this.previousPageValue,
    };
    const page = buttons[action];
    if (!page) {
      return;
    }
    this.paramsAdd("page", page);
    await this.stimulate("Plus::Campaigns::ManageUnitsReflex#index");
  }

  async stimulateModal(payload, skipToggle = false) {
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
    !skipToggle && this.toggleBulkList();
  }

  async stimulatecampaignUnitBulkDestroy() {
    const payload = { campaign_unit_tokens: this.base.selectedTokens };
    await this.stimulate("Plus::Campaigns::ManageUnitsReflex#bulk_destroy", payload);
  }

  openPriceModal() {
    const payload = {
      component: "modal/adjust_pricing_component",
      component_params: {
        campaign_unit_tokens: this.base.selectedTokens,
        campaign_token: this.campaignTokenValue,
      },
    };
    this.stimulateModal(payload);
  }

  openSupplierRequestModal(e) {
    const {
      target: { dataset },
    } = e;
    const skipToggle = Object.keys(dataset).includes("skipToggle");
    const payload = {
      component: "modal/supplier_request_component",
      component_params: {
        campaign_unit_tokens: this.base.selectedTokens,
        campaign_token: this.campaignTokenValue,
        request_type: dataset.modalType,
      },
    };
    this.stimulateModal(payload, skipToggle);
  }

  openUpdateDateModal(e) {
    const {
      target: { dataset },
    } = e;
    const skipToggle = Object.keys(dataset).includes("skipToggle");
    const payload = {
      component: "modal/date_update_component",
      component_params: {
        campaign_unit_tokens: this.base.selectedTokens,
        campaign_token: this.campaignTokenValue,
      },
    };
    this.stimulateModal(payload, skipToggle);
  }

  openUpdateSpecModal() {
    const payload = {
      component: "modal/manage_spec_sheet_component",
      component_params: {
        campaign_unit_tokens: this.base.selectedTokens,
        campaign_token: this.campaignTokenValue,
      },
    };
    this.stimulateModal(payload);
  }

  openProductionCostModal() {
    const payload = {
      component: "modal/normalize_production_cost_component",
      component_params: {
        campaign_unit_tokens: this.base.selectedTokens,
        campaign_token: this.campaignTokenValue,
      },
    };
    this.stimulateModal(payload);
  }

  openContractModal() {
    const payload = {
      component: "modal/contract_component",
      component_params: {
        campaign_unit_tokens: this.base.selectedTokens,
        campaign_token: this.campaignTokenValue,
      },
    };
    this.stimulateModal(payload);
  }

  async revertProductionCosts() {
    const payload = {
      unit_ids: this.base.selectedTokens,
      campaign_id: this.campaignTokenValue,
    };
    try {
      await fetch("/api/v1/campaign_units/revert_production_cost", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      window.location.reload();
    } catch (error) {
      alert(error);
    }
  }

  async availsApiRefresh(e) {
    if (e.target.disabled) {
      console.log("Request still runing");
      return;
    }
    e.target.disabled = true;
    const payload = {
      campaign_unit_tokens: this.base.selectedTokens,
      campaign_token: this.campaignTokenValue,
    };
    try {
      await fetch("/api/v1/avails_api/refreshes", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      e.target.disabled = undefined;
      alert("Avails refresh requests in progress. Selected units status will be updated.");
    } catch (error) {
      e.target.disabled = undefined;
      alert(error);
    }
  }

  bulkRemoveUnits() {
    const payload = {
      component: "modal/removal_reason_component",
      component_params: {
        campaign_id: this.campaignTokenValue,
        use_stimulate: true,
        campaign_unit_tokens: this.base.selectedTokens,
      },
    };
    this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  bulkRemoveUnitsWoReason() {
    if (confirm(`Are you sure you want to remove ${this.base.selectedTokens.length} units?`)) {
      return this.stimulatecampaignUnitBulkDestroy();
    }
  }

  goToAssignContact() {
    window.location.assign(`/admin/campaigns/${this.campaignTokenValue}/vendor_contacts`);
  }

  async markAsBooked() {
    const payload = { campaign_unit_tokens: this.base.selectedTokens };
    try {
      const request = await fetch(
        `/api/v1/campaigns/${this.campaignTokenValue}/campaign_units/batch_book_from_manage`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        },
      );
      const response = await request.json();
      if (request.status >= 400) {
        throw response.error;
      }
      window.location.reload();
    } catch (error) {
      alert(error);
    }
  }
}
