export default class AiPlannerCta {
  constructor(hasUnits) {
    this.hasUnits = hasUnits;
  }

  onAdd(map) {
    this._map = map;
    this._container = document.createElement("button");
    this._container.dataset.action = "browse#showAiPlannerModal";
    if (this.hasUnits) {
      this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-ai-planner-cta";
    } else {
      this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-ai-planner-cta empty";
    }
    this._container.innerText = "AI Planner";
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}
