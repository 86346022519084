import ApplicationController from "../../application_controller";
import { parsePriceInput } from "../../../utils/parsers";

export default class extends ApplicationController {
  static targets = ["form", "contractPdf", "submitButton", "contractNumber"];

  connected() {
    this.contractPdfTarget.addEventListener("input", () => this.checkSubmit());
    this.contractNumberTarget.addEventListener("input", () => this.checkSubmit());
  }

  disconnect() {
    this.contractPdfTarget.removeEventListener("input");
    this.contractNumberTarget.removeEventListener("input");
  }

  isContractFileSubmitted() {
    return this.contractPdfTarget.files.length > 0;
  }

  isContractNumberFilled() {
    if (this.hasContractNumberTarget) {
      return this.contractNumberTarget.value !== "";
    }
    return true;
  }

  checkSubmit() {
    // dont disable submit button for now
    return;
    const ready = this.isContractFileSubmitted() && this.isContractNumberFilled();
    if (ready) {
      return (this.submitButtonTarget.disabled = false);
    }
    this.submitButtonTarget.disabled = true;
  }

  submit(e) {
    e.preventDefault();
    this.formTarget.submit();
  }
}
