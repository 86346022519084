export class Units {
  constructor(map, dataTarget) {
    this.map = map;
    this.dataTarget = dataTarget;
  }

  async connect() {
    await this.loadCampaignUnits();
    this.initFilters();
  }

  disconnect() {
    this.dataObserver.disconnect();
  }

  async loadCampaignUnits() {
    await this.map.loadCampaignUnits();
  }

  initFilters() {
    this.setupFilters(this.dataTarget.querySelectorAll("#data input"));
    this.listenFilters();
  }

  setupFilters(inputs) {
    console.log("setup filters was called", inputs);
    inputs.forEach(input => {
      const name = this.filterName(input.dataset.ref);
      let filterData = null;

      if (input.value) {
        const data = JSON.parse(input.value);
        const numberValue = Number.parseFloat(data);

        if (Array.isArray(data)) {
          filterData = new Set(data);
        } else if (!Number.isNaN(numberValue)) {
          filterData = data;
        }
      }
      this.map.filter.filter(name, filterData);
    });
    console.log("gonna redrawLayers", this.map);
    this.map.redrawLayers("campaign units filter update");
  }

  listenFilters() {
    this.dataObserver = new MutationObserver(this.afterDataChange.bind(this));
    this.dataTarget.querySelectorAll("#data input").forEach(input => {
      this.dataObserver.observe(input, { attributes: true });
    });
  }

  afterDataChange(mutations) {
    const changes = mutations.filter(mutation => mutation.attributeName === "value");
    this.setupFilters(changes.map(mutation => mutation.target));
  }

  filterName(id) {
    return id.replace("-data", "");
  }
}
