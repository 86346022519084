import ApplicationController from "./application_controller";

/*
How to use:

<div data-controller="expandable-checkbox">
  <input type="checkbox" data-target="expandable-checkbox.checkbox" data-action="change->expandable-checkbox#change">
  <div data-target="expandable-checkbox.content">
    Content to display when checkbox is checked.
  </div>
</div>
*/
export default class extends ApplicationController {
  static targets = ["checkbox", "content"];

  connect() {
    this.change();
  }

  change() {
    if (this.checkboxTarget.checked) {
      this.contentTarget.classList.remove("hide");
    } else {
      this.contentTarget.classList.add("hide");
    }
  }
}
