import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  validateForm(e) {
    const dates = JSON.parse(e.target.dataset.value);
    const datePickerFormGroup = e.target.closest(".datepicker-form-group");
    const startDateElement = datePickerFormGroup.querySelector(".start-date");
    const endDateElement = datePickerFormGroup.querySelector(".end-date");

    if (startDateElement.value != '' && endDateElement.value != '') {
      this.#setFormToValidateOnly();
      const form = this.element;
      form.requestSubmit();
      this.#removeValidateOnlyConfig();
    }
  }

  async createAdvertiser(e) {
    e.preventDefault();
    document.getElementById("campaign_advertiser").querySelector("input[type='text']").value = "";
    const agencyId = this.agencyId();
    await this.stimulate("ModalReflex#load_modal_component", {
      component: "modal/create_advertiser_component",
      component_params: { agency_id: agencyId },
    });
    return window.modal.open();
  }

  agencyId() {
    if (this.hasAgencyIdValue) return this.agencyIdValue;
    const inputs = document.getElementsByName("campaign[agency_id]")
    if (inputs.length > 0) {
      return inputs[0].value;
    }
    return null;
  }

  #setFormToValidateOnly() {
    const form = this.element;
    const validateOnlyElement = document.createElement('input');
    validateOnlyElement.type = 'hidden';
    validateOnlyElement.id = 'new-campaign-component-validate-only-element';
    validateOnlyElement.name = 'validate_only';
    validateOnlyElement.value = 'true';
    form.append(validateOnlyElement);
  }

  #removeValidateOnlyConfig() {
    const form = this.element;
    form.querySelector('#new-campaign-component-validate-only-element').remove();
  }
}
