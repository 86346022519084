import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return ["truncatedContent", "fullContent", "moreButton", "lessButton"];
  }

  static get values() {
    return { fullContentSize: Number, truncatedContentSize: Number };
  }

  static get classes() {
    return ["hide"];
  }
  connect() {
    if (this.fullContentSizeValue !== this.truncatedContentSizeValue) {
      this.showLess();
    } else {
      this.show(this.fullContentTarget);
      this.hide(this.moreButtonTarget);
      this.hide(this.lessButtonTarget);
    }
  }

  showMore(event) {
    if (event) event.preventDefault();
    this.hide(this.truncatedContentTarget);
    this.hide(this.moreButtonTarget);
    this.show(this.fullContentTarget);
    this.show(this.lessButtonTarget);
  }

  showLess(event) {
    if (event) event.preventDefault();
    this.hide(this.fullContentTarget);
    this.hide(this.lessButtonTarget);
    this.show(this.truncatedContentTarget);
    this.show(this.moreButtonTarget);
  }

  show(target) {
    target.classList.remove(this.hideClass);
  }

  hide(target) {
    target.classList.add(this.hideClass);
  }
}
