import ApplicationController from "./../../application_controller";

export default class extends ApplicationController {
  static targets = ["uploadSection", "fileSection", "fileInput", "submitButton", "uploadForm"];

  openFileLibrary() {
    this.fileInputTarget.click();
  }

  validateUrl(e) {
    try {
      const parsedUrl = new URL(e.target.value);
      if (parsedUrl.protocol == "http:") {
        throw "Must be https";
      }
      return this.enableSubmit();
    } catch (error) {
      return this.disableSumit();
    }
  }

  getFileFromLibrary(e) {
    this.appendFileIcon();
  }

  appendFileIcon() {
    const icon = document.createElement("i");
    icon.classList.add("fal", "fa-file-check", "icon");
    this.fileSectionTarget.appendChild(icon);
    this.uploadSectionTarget.classList.remove("show-error");
    this.uploadSectionTarget.classList.add("show-images");
    this.enableSubmit();
  }

  buildErrorState() {
    this.uploadSectionTarget.classList.add("show-error");
  }

  enableSubmit() {
    this.submitButtonTarget.removeAttribute("disabled");
  }

  disableSumit() {
    this.submitButtonTarget.setAttribute("disabled", true);
  }

  onSubmit() {
    this.disableSumit();
    this.uploadFormTarget.submit();
  }
}
