import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "market",
    "zipCodes",
    "mediaType",
    "mediaSubType",
    "screenType",
    "managedVendorOption",
    "searchButton",
  ];

  campaignToken() {
    return this.element.dataset.campaignToken;
  }

  getParentElement(el) {
    return el.parentElement;
  }

  isOptionActive(el) {
    const container = this.getParentElement(el);
    return container.classList.contains("active");
  }

  isScreenTypeActive(screenType) {
    const el = this.screenTypeTargets.find(el => el.dataset.id == screenType);
    return this.isOptionActive(el);
  }

  findManagedVendorOption(id) {
    return this.managedVendorOptionTargets.find(el => el.dataset.id == id);
  }

  findOppositeManagerVendorOption(id) {
    return this.managedVendorOptionTargets.find(el => el.dataset.id != id);
  }

  toggleActiveOption(el) {
    const container = this.getParentElement(el);
    container.classList.toggle("active");
  }

  checkSearchButton() {
    // TODO fix async select target attributes
    // I think the issue started happening during stimulus upgrade but not 100% sure yet
    // The component is using deprecated target attributes
    const hasMarkets = document.querySelector("div[data-multi-select-async-target] .selected")
    const hasZipCodes = this.zipCodesTarget.value;
    const hasMedia = this.hasActiveMediaTypes();
    const hasScreen = this.hasActiveScreenTypes();
    if ((hasMarkets || hasZipCodes) && hasMedia && hasScreen) {
      return this.enableSearchButton();
    }
    return this.disableSearchButton();
  }

  hasActiveMediaTypes() {
    return !!this.mediaTypeTargets.find(el => this.isOptionActive(el));
  }

  hasActiveScreenTypes() {
    return !!this.screenTypeTargets.find(el => this.isOptionActive(el));
  }

  enableSearchButton() {
    this.searchButtonTarget.classList.remove("disabled");
    this.searchButtonTarget.classList.add("blue");
  }

  disableSearchButton() {
    this.searchButtonTarget.classList.remove("blue");
    this.searchButtonTarget.classList.add("disabled");
  }

  allowSearch() {
    return !this.searchButtonTarget.classList.contains("disabled");
  }

  buildMarketReflexPayload(market) {
    return {
      campaign_token: this.campaignToken(),
      market,
    };
  }

  buildZipCodeReflexPayload(zip_codes) {
    return {
      campaign_token: this.campaignToken(),
      zip_codes,
    };
  }

  buildMediaTypeReflexPayload(media_type_id) {
    return {
      media_type_id,
      campaign_token: this.campaignToken(),
    };
  }
  buildMediaSubTypeReflexPayload(media_subtype_id) {
    return {
      media_subtype_id,
      campaign_token: this.campaignToken(),
    };
  }

  buildScreenTypeReflexPayload(screen_type) {
    return {
      screen_type,
      campaign_token: this.campaignToken(),
    };
  }

  buildManagedInventoryOptionPayload(managed_inventory_option) {
    return {
      managed_inventory: managed_inventory_option,
      campaign_token: this.campaignToken(),
    };
  }

  parseDataseValue(dataset) {
    return JSON.parse(dataset.value);
  }

  getMarketDatasetFromSelectedOption(el) {
    const parent = el.parentElement;
    return this.parseDataseValue(parent.dataset);
  }

  onMediaChange(e) {
    const el = e.currentTarget;
    const isActive = this.isOptionActive(el);
    if (isActive) {
      return this.handleMediaTypeChange(el, "destroy");
    }
    this.handleMediaTypeChange(el, "create");
  }

  onMediaSubtypeChange(e) {
    const el = e.currentTarget;
    const isActive = this.isOptionActive(el);
    if (isActive) {
      return this.handleMediaSubTypeChange(el, "destroy");
    }
    this.handleMediaSubTypeChange(el, "create");
  }

  onMediaSubtypeDeselectAll(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.stimulateMediaSubTypeReflex({ campaign_token: this.campaignToken() }, "deselect_all");
  }

  async onZipCodeChange(e) {
    const zipCodes = e.currentTarget.value;
    const payload = this.buildZipCodeReflexPayload(zipCodes);
    await this.stimulate("Campaigns::ZipCodeReflex#create", payload);
    this.checkSearchButton();
  }

  onScreenChange(e) {
    const { id } = e.currentTarget.dataset;
    const handler = {
      static: this.handleStaticScreenType.bind(this),
      digital: this.handleDigitalScreenType.bind(this),
    };
    return handler[id](e.currentTarget);
  }

  onManagedVendorOptionChange(e) {
    const { id } = e.currentTarget.dataset;
    const current = this.findManagedVendorOption(id);
    const other = this.findOppositeManagerVendorOption(id);
    this.getParentElement(current).classList.add("active");
    this.getParentElement(other).classList.remove("active");
    this.stimulateCampaignReflex(this.buildManagedInventoryOptionPayload(id));
  }

  handleStaticScreenType(el) {
    const isActive = this.isOptionActive(el);
    const isDigitalActive = this.isScreenTypeActive("digital");
    if (isActive && isDigitalActive) {
      return this.handleScreenTypeChange(el, "digital");
    }
    if (!isActive && !isDigitalActive) {
      return this.handleScreenTypeChange(el, "static");
    }
    if (!isActive && isDigitalActive) {
      return this.handleScreenTypeChange(el, "both");
    }
    return this.handleScreenTypeChange(el, "");
  }

  handleDigitalScreenType(el) {
    const isActive = this.isOptionActive(el);
    const isStaticActive = this.isScreenTypeActive("static");
    if (isActive && isStaticActive) {
      return this.handleScreenTypeChange(el, "static");
    }
    if (!isActive && !isStaticActive) {
      return this.handleScreenTypeChange(el, "digital");
    }
    if (!isActive && isStaticActive) {
      return this.handleScreenTypeChange(el, "both");
    }
    return this.handleScreenTypeChange(el, "");
  }

  async handleScreenTypeChange(el, screenType) {
    const payload = this.buildScreenTypeReflexPayload(screenType);
    await this.stimulateScreenTypeReflex(payload);
    await this.stimulateOverviewReflex("morph_details");
    this.toggleActiveOption(el);
    this.checkSearchButton();
  }

  async handleMediaTypeChange(element, method) {
    const { id } = element.dataset;
    const payload = this.buildMediaTypeReflexPayload(id);
    await this.stimulateMediaTypeReflex(payload, method);
    await this.stimulateOverviewReflex("morph_details");
    this.toggleActiveOption(element);
    this.checkSearchButton();
  }

  async handleMediaSubTypeChange(element, method) {
    const { id } = element.dataset;
    const payload = this.buildMediaSubTypeReflexPayload(id);
    await this.stimulateMediaSubTypeReflex(payload, method);
    // await this.stimulateOverviewReflex('morph_details') // TODO put subtypes on overview panel
    this.toggleActiveOption(element);
    this.checkSearchButton();
  }

  async onMarketRemove(e) {
    const market = this.getMarketDatasetFromSelectedOption(e.currentTarget);
    const payload = this.buildMarketReflexPayload(market);
    await this.stimulateMarketReflex(payload, "destroy");
    await this.stimulateOverviewReflex("morph_details");
    this.checkSearchButton();
  }

  async onMarketAdd(e) {
    const market = this.parseDataseValue(e.currentTarget.dataset);
    const payload = this.buildMarketReflexPayload(market);
    await this.stimulateMarketReflex(payload, "create");
    await this.stimulateOverviewReflex("morph_details");
    this.checkSearchButton();
  }

  async searchVendor() {
    if (!this.allowSearch()) {
      return;
    }
    await this.stimulate(`Plus::Campaigns::OverviewReflex#vendor_search`, { campaign_token: this.campaignToken() });
  }

  async stimulateCampaignReflex(payload) {
    await this.stimulate("CampaignReflex#update", payload);
  }

  async stimulateMediaTypeReflex(payload, method) {
    await this.stimulate(`Campaigns::MediaTypeReflex#${method}`, payload);
  }

  async stimulateMediaSubTypeReflex(payload, method) {
    await this.stimulate(`Campaigns::MediaSubtypeReflex#${method}`, payload);
  }

  async stimulateScreenTypeReflex(payload) {
    await this.stimulate(`Campaigns::ScreenTypeReflex#update`, payload);
  }

  async stimulateMarketReflex(payload, method) {
    await this.stimulate(`Campaigns::MarketReflex#${method}`, payload);
  }

  async stimulateOverviewReflex(method) {
    await this.stimulate(`Plus::Campaigns::OverviewReflex#${method}`, { campaign_token: this.campaignToken() });
  }
}
