import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static values = { campaignToken: String, userId: String, filters: String };

  async openUnitModal(e) {
    const {
      target: { dataset },
    } = e;
    const { supplierId } = dataset;
    const payload = {
      component: "modal/vendor_contract_unit_modal_component",
      component_params: {
        supplier_id: supplierId,
        campaign_token: this.campaignTokenValue,
        current_user_id: this.userIdValue,
        filters: {},
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  destroy(e) {
    e.preventDefault();
    e.stopPropagation();
    const {
      currentTarget: { dataset },
    } = e;
    const { contractToken } = dataset;
    this.onDestroy(contractToken);
  }

  async onDestroy(contract_token) {
    const payload = {
      contract_token,
      filters: JSON.parse(this.element.dataset.vendorContractsFilters), //"this is not working, dunno why" this.filtersValue
    };
    await this.stimulate("Plus::Campaigns::VendorContractsReflex#destroy", payload);
  }

  openFile(e) {
    e.preventDefault();
    e.stopPropagation();
    const link = e.currentTarget.attributes.value.value;
    window.open(link, "_blank");
  }
}
