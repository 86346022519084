import ApplicationController from "../application_controller";
import Quill from "quill";

export default class extends ApplicationController {
  static targets = ["headerTemplate", "columnTemplate", "imagePreview", "imageInput", "customLanguageInput"];

  connected() {
    this.setupCustomLanguageWysiwyg();
  }

  addHeaderRow(event) {
    event.preventDefault();
    const isLeftSide = event.target.dataset.isLeftSide;
    var content = this.headerTemplateTarget.innerHTML
      .replace(/TEMPLATE_RECORD/g, new Date().valueOf())
      .replace(/IS_LEFT_SIDE/g, isLeftSide);
    event.target.parentElement.previousElementSibling.insertAdjacentHTML("beforeend", content);
  }

  addColumnRow(event) {
    event.preventDefault();
    var content = this.columnTemplateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf());
    document.getElementsByClassName("columns")[0].insertAdjacentHTML("beforeend", content);
  }

  markForRemoval(event) {
    event.preventDefault();
    const row = event.target.closest("li.column");
    row.classList.add("marked-for-deletion");
    row.getElementsByTagName("input")[0].value = true;
  }

  getFileFromLibrary(e) {
    const file = e.target.files[0];
    const blob = new Blob([file], { type: file.type });
    const url = URL.createObjectURL(blob);
    this.imagePreviewTarget.src = url;
    this.showImagePreview();
  }

  removeImage(event) {
    event.preventDefault();
    this.hideImagePreview();
  }

  openFileLibrary() {
    this.imageInputTarget.click();
  }

  showImagePreview() {
    this.imagePreviewTarget.parentElement.classList.add("show");
  }

  hideImagePreview() {
    this.imagePreviewTarget.parentElement.classList.remove("show");
  }

  setupCustomLanguageWysiwyg() {
    const editorElement = document.getElementById("custom-language-wysisyg");
    const quillSettings = {
      modules: {
        toolbar: [
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          ["link"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
        ],
      },
      scrollingContainer: "#custom-language-wysisyg-wrapper",
      theme: "snow",
    };

    this.editor = new Quill(editorElement, quillSettings);

    this.editor.on("text-change", (_delta, _oldDelta, _source) => {
      this.customLanguageInputTarget.value = this.editor.container.firstChild.innerHTML;
    });

    if (!editorElement.dataset.content) {
      return;
    }
    this.editor.clipboard.dangerouslyPasteHTML(editorElement.dataset.content, "api");
  }
}
