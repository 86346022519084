import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static values = { token: String, userId: Number };

  initialize() {
    this.calculatePrices = this.calculatePrices.bind(this);
  }

  connected() {
    // Wait 2 seconds before calculating prices if ActionCable isn't ready yet
    const delay = this.isActionCableConnectionOpen() ? 0 : 2000;
    _.delay(this.calculatePrices, delay);
  }

  async calculatePrices() {
    const payload = { campaign_token: this.tokenValue, user_id: this.userIdValue };
    this.stimulate("Plus::Campaigns::Dashboards::PriceLoaderReflex#calculate_prices", payload);
  }
}
