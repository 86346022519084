import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static values = { perPage: String, advertiserId: String };
  static targets = ["perPage"];

  connected() {}

  async loadMore(event) {
    const payload = { per_page: this.perPageTarget.attributes["value"].value, advertiser_id: this.advertiserIdValue };
    await this.stimulate("Dashboard::Reflex#stimulate_pop_gallery", payload);
  }
}
