import ApplicationController from "./../../application_controller";

export default class extends ApplicationController {
  static targets = ["noInventoryReason", "noInventoryNote"];

  onSubmit() {
    const reason = this.noInventoryReasonTarget.value;
    const note = this.noInventoryNoteTarget.value;

    const payload = { reason, note };

    this.stimulate("Plus::AdquickRequestsReflex#process_no_inventory_submit", payload);
  }
}
