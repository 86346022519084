import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static targets = ["form"];

  submit(e) {
    e.preventDefault();
    this.formTarget.submit();
  }
}
