import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["dropdown", "exportLink"];

  onFilterByChange(event) {
    const filterBy = event.target.value;

    this.exportLinkTargets.forEach((exportLink) => {
      const newUrl = new URL(exportLink.href);
      const params = new URLSearchParams();
      const fixedFilterName = exportLink.dataset.fixedFilterName;
      const fixedFilterValue = exportLink.dataset.fixedFilterValue;

      params.set("filter_by", filterBy);

      if (fixedFilterName !== undefined) {
        params.set(fixedFilterName, fixedFilterValue);
      }

      if (filterBy === 'with_filters') {
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.forEach((value, key) => {
          params.set(key, value);
        });
      }

      newUrl.search = params.toString();
      exportLink.href = newUrl.toString();
    });
  }

  closeDropdown() {
    this.dropdownTarget.classList.remove('open');
  }
}
