import ApplicationController from "./application_controller";
import morphdom from "morphdom";

export default class extends ApplicationController {
  connect() {
    this.element["controllerInstance"] = this;
  }

  async connected() {
    this.activeUnit = null;
    this.render({});
  }

  disconnect() {}

  setActiveUnit(unit) {
    this.activeUnit = unit;
  }

  hoverTooltip(point_count) {
    return `
      <div class="cluster-tooltip__content">
        <header>${point_count} Units</header>
        <section>Click for details</section>
      </div>
    `;
  }

  singleUnit(activeUnit) {
    return `
      <div class="cluster-tooltip__content_expanded cluster-tooltip__single">
        <div class="cluster-tooltip__right">
          <div class="cluster-tooltip__picture">
            <img src=${activeUnit.picture} />
          </div>
          <div class="cluster-tooltip__summary">
            <div class="cluster-tooltip__face_id">
              ${activeUnit.supplier_face_id}
            </div>
            <div class="cluster-tooltip__type_tag">
              ${activeUnit.screen_type || "static"}
            </div>
          </div>
          <div class="cluster-tooltip__name">${activeUnit.name}</div>
          <div class="cluster-tooltip__size">${activeUnit.size}</div>
          <div class="cluster-tooltip__price"></div>
        </div>
      </div>
    `;
  }

  unitsList(units, onListOver, onUnitClick) {
    const ul = document.createElement("ul");
    units.forEach(unit => {
      const onClick = unit => {
        onUnitClick({ object: unit });
      };
      const onHover = unit => {
        onListOver(unit);
      };

      const li = document.createElement("li");
      li.addEventListener("click", onClick);
      li.addEventListener("mouseover", onHover);
      li.innerHTML = `
        <strong>${unit.supplier_face_id}</strong>
        ${` · ${unit.screen_type} ${unit.media_type}`}
      `;
      li.appendChild(document.createTextNode("foo"));

      ul.appendChild(li);
    });

    return ul;
  }

  activeUnitPanel(activeUnit) {
    return `
      <div class="cluster-tooltip__picture">
        <img src=${activeUnit.picture} />
      </div>,
      <div class="cluster-tooltip__summary">
        <div class="cluster-tooltip__face_id">
          ${activeUnit.supplier_face_id}
        </div>
        <TypeIcon media_type=${activeUnit.media_type} />
        <div class="cluster-tooltip__type_tag">
          ${activeUnit.screen_type || "static"}
        </div>
      </div>
      <div class="cluster-tooltip__name">${activeUnit.name}</div>,
      <div class="cluster-tooltip__size">${activeUnit.size}</div>,
      <div class="cluster-tooltip__price"></div>
    `;
  }

  emptyState() {
    return `
      <div class="cluster-tooltip__blank_slate">
        Hover unit for info
      </div>
    `;
  }

  multipleUnits(point_count, onClose, activeUnit) {
    return `
      <div class="cluster-tooltip__content_expanded cluster-tooltip__multi">
        <div class="cluster-tooltip__left">
          <header>
            <span>${point_count} Units</span>
            <button class="cluster-tooltip__close" onClick=${onClose}>✕</button>
          </header>
          <ul id="ul-placeholder"></ul>
        </div>
        <div class="cluster-tooltip__right">
          ${activeUnit ? this.activeUnitPanel(activeUnit) : this.emptyState()}
        </div>
      </div>
    `;
  }

  render({ point_count, units, x, y, onClose, onUnitClick }) {
    const expanded = !!units?.length;
    const isSingleUnit = units?.length == 1;
    const onListOver = unit => {
      this.setActiveUnit(unit);
    };
    if (!point_count) return "<div></div>";

    let content = `
      <div>
        <div class="${expanded ? "cluster-tooltip expanded" : "cluster-tooltip"}" style="left: ${x}px; top: ${y}px;">
          <div class="cluster-tooltip__container">
            <div class="cluster-tooltip__arrow"></div>
            ${!expanded ? this.hoverTooltip(point_count) : ""}
            ${expanded && isSingleUnit && this.activeUnit ? this.singleUnit(this.activeUnit) : ""}
            ${expanded && !isSingleUnit ? this.multipleUnits(point_count, onClose, this.activeUnit) : ""}
            </div>
        </div>
      </div>
    `;

    this.element.innerHTML = content;

    const ulPlacholder = document.getElementById("ul-placeholder");
    if (ulPlacholder) {
      const parentNode = ulPlacholder.parentNode;
      const unitsList = this.unitsList(units, onListOver, onUnitClick);
      parentNode.replaceChild(unitsList, ulPlacholder);
    }
  }
}
