import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static values = { userId: String, advertiserId: String };

  static targets = ["form"];

  navigateToCampaignRequest() {
    const response = confirm("Would you like to navigate to the campaign request page?");
    if (!response) {
      return;
    }
    window.open("/campaigns/request-campaign", "_blank");
  }

  navigateToIntegrationsIndex() {
    window.location.href = "/integrations";
  }

  async stimulateSendDspMsaMailer(event) {
    const response = confirm("Would you like us to send you the our DSP MSA?");
    if (!response) {
      return;
    }

    const payload = { user_id: this.userIdValue, advertiser_id: this.advertiserIdValue };
    await this.stimulate("Dashboard::Reflex#send_dsp_msa_mailer", payload);
  }

  async stimulateSendAvailsApiRequestMailer(event) {
    const response = confirm("Would you like to submit a request to our Avails API?");
    if (!response) {
      return;
    }

    const payload = { user_id: this.userIdValue, advertiser_id: this.advertiserIdValue };
    await this.stimulate("Dashboard::Reflex#send_avails_api_request", payload);
  }

  async stimulateSendDspBillingMailer(event) {
    const response = confirm("Would you like us to send you the DSP billing form?");
    if (!response) {
      return;
    }

    const payload = { user_id: this.userIdValue, advertiser_id: this.advertiserIdValue };
    await this.stimulate(`Dashboard::Reflex#send_dsp_billing_form_mailer`, payload);
  }

  async openInviteTeamModal() {
    const payload = {
      component: "modal/dashboard/invite_team_modal_component",
      component_params: {
        user_id: this.userIdValue,
        advertiser_id: this.advertiserIdValue,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }

  async submitTeamInvite(event) {
    event.preventDefault();
    var formData = new FormData(this.formTarget);
    const response = await fetch(this.formTarget.action, {
      method: "POST",
      body: formData,
      headers: { Accept: "text/html,application/xhtml+xml,application/xml" },
    });
    if (response.status === 200) {
      await this.stimulate("Dashboard::Reflex#send_confirmation_toast");
      return window.modal.close();
    } else {
      const responseBody = await response.json();
      const message = responseBody.error;
      await this.stimulate("Dashboard::Reflex#send_error_toast", { message: message });
      return window.modal.close();
    }
  }

  navigateToMediaAuthorization(event) {
    const response = confirm("Would you like to navigate to the media auth page?");
    if (!response) {
      return;
    }
    window.open("/media-authorizations/sign", "_blank");
  }

  navigateToInviteUser(event) {
    const response = confirm("Would you like to navigate to our Invite Team Page?");
    if (!response) {
      return;
    }
    window.open("dashboard/invite", "_blank");
  }

  onArrowClick(event) {
    let box = document.getElementById("box-target"),
      x;
    const arrowTarget = event.currentTarget;
    if (arrowTarget.classList.contains("arrow-right")) {
      x = box.offsetWidth / 2 + box.scrollLeft;
      // box.animate({ scrollLeft: x })
      box.scroll({ left: x, top: 0, behavior: "smooth" });
    } else if (arrowTarget.classList.contains("arrow-left")) {
      x = box.offsetWidth / 2 - box.scrollLeft;
      // box.animate({ scrollLeft: -x })
      box.scroll({ left: -x, top: 0, behavior: "smooth" });
    }
  }
}
