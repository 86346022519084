import ApplicationController from "./application_controller";

export class QueryStringTrackController extends ApplicationController {
  static values = {
    campaignToken: String,
  };
  connect() {
    super.connect();
  }

  syncWithMapFilter() {
    this.stimulate("QueryStringSyncReflex#sync_with_map_filter", { campaign_token: this.campaignTokenValue });
  }

  syncWithFilterBar() {
    this.stimulate("QueryStringSyncReflex#sync_with_filter_bar", { campaign_token: this.campaignTokenValue });
  }
}
