import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  static targets = ["menu"];

  toggleMenu() {
    this.menuTarget.classList.toggle("open");
  }

  getGridData() {
    try {
      const supplierTaskId = this.data.get("supplierTaskId");
      const file = window.sessionStorage.getItem(`task_${supplierTaskId}_data`);
      return JSON.parse(file);
    } catch (error) {
      return [];
    }
  }

  async openPackageModal() {
    const payload = {
      component: "modal/rfp_package_component",
      component_params: {
        grid_data: this.getGridData(),
        supplier_task_id: this.data.get("supplierTaskId"),
        campaign_unit_id: this.data.get("campaignUnitId"),
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    return window.modal.open();
  }
}
