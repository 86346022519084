import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  static targets = ["inventoryDownload"];

  downloadInventoryData() {
    if (!this.data.has("inventoryFileId")) {
      return;
    }
    this.onDownloadInventory();
  }

  get gridCSVString() {
    const header = window.requestGrid.getColHeader();
    const content = window.requestGrid.getData();
    return [header, ...content].map(row => row.join(";")).join("\n");
  }

  get fallbackTemplateUrl() {
    return this.data.get("staticTemplateUrl");
  }

  downloadGrid() {
    let href = this.fallbackTemplateUrl;
    const fakeLink = document.createElement("a");

    if (window.requestGrid) {
      const content = encodeURI(`\uFEFF${this.gridCSVString}`);
      href = `data:text/csv;charset=utf-8,${content}`;

      fakeLink.download = `${this.data.get("campaignName")}-rfp-template.csv`;
    }

    fakeLink.href = href;
    fakeLink.target = "_blank";
    fakeLink.click();
    fakeLink.remove();
  }

  async onDownloadInventory() {
    const inventoryFileId = this.data.get("inventoryFileId");
    const url = `/api/v1/inventory_files/${inventoryFileId}/export_to_excel`;
    try {
      this.changeInventoryDownloadButtonText("Creating file...");
      const res = await fetch(url, { "Content-Type": "application/json" });
      const { file_link } = await res.json();
      this.changeInventoryDownloadButtonText();
      window.open(file_link, "_blank");
    } catch (error) {
      this.changeInventoryDownloadButtonText();
    }
  }

  changeInventoryDownloadButtonText(text) {
    if (!text) {
      return this.applyDefaultInventoryDownloadButtonText();
    }
    this.inventoryDownloadTarget.innerHTML = text;
  }

  applyDefaultInventoryDownloadButtonText() {
    this.inventoryDownloadTarget.innerHTML = `
      <i class="fal fa-file-export"></i>
      Download inventory data
    `;
  }
}
