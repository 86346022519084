import StimulusReflex from "stimulus_reflex";
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
    document.addEventListener("click", this.stateHandler.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.stateHandler);
  }

  stateHandler(e) {
    const { target } = e;
    const clickedWithin = this.element.contains(target);
    if (clickedWithin) {
      return;
    }
    this.element.classList.remove("open");
  }

  toggle() {
    this.element.classList.toggle("open");
  }

  selectOption(e) {
    this.toggle();
  }
}
