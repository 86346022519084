export default class PoiLayer {
  constructor(map, layerId, sourceId, minZoom, beforeLayerId) {
    const mapboxLayer = {
      id: layerId,
      type: "symbol",
      source: sourceId,
      minzoom: minZoom,
      layout: {
        "icon-image": "{icon_id}",
        "icon-size": 0.5,
        "icon-allow-overlap": true,
        "text-field": ["get", "name"],
        "text-font": ["Inter Semi Bold"],
        "text-size": 12,
        "text-justify": "center",
        "text-offset": [0.0, 0.8],
        "text-anchor": "top",
        "text-optional": true,
      },
      paint: {
        "text-color": "#000000",
        "text-halo-color": "#FFFFFF",
        "text-halo-width": 2,
        "text-halo-blur": 1,
      },
    };

    map.addLayer(mapboxLayer, beforeLayerId);
  }
}
