import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["fileInput", "fileDescription", "modalIcon", "modalFilename", "modalDescription", "uploadForm"];

  buildAttachmentDeletePayload(attachmentId) {
    return {
      campaign_token: this.element.dataset.campaignToken,
      attachment_id: attachmentId,
    };
  }

  clearIconEl() {
    const child = this.modalIconTarget.children[0];
    this.modalIconTarget.removeChild(child);
  }

  getFileFromInput() {
    return this.fileInputTarget.files[0];
  }

  createUrlFromFile(file) {
    const blob = new Blob([file], { type: file.type });
    return URL.createObjectURL(blob);
  }

  loadAppendedFileState() {
    const file = this.getFileFromInput();
    const fileIcon = this.createFileIcon();
    this.clearIconEl();
    this.modalIconTarget.appendChild(fileIcon);
    this.modalFilenameTarget.textContent = file.name;
    this.modalDescriptionTarget.classList.add("hide");
  }

  createFileIcon() {
    const el = document.createElement("i");
    el.classList.add(["fal"], ["fa-file"]);
    return el;
  }

  openUploadModal(e) {
    this.stimulateModalLoad();
  }

  openFileLibrary() {
    this.fileInputTarget.click();
  }

  getFileFromLibrary(e) {
    this.loadAppendedFileState();
  }

  delete(e) {
    const { attachmentId } = e.currentTarget.dataset;
    e.preventDefault();
    this.stimulateAttachmentDelete(attachmentId);
  }

  upload(e) {
    e.preventDefault();
    const file = this.getFileFromInput();
    if (!file) {
      return alert("Select a file before uploading");
    }
    this.uploadFormTarget.submit();
  }

  async stimulateModalLoad() {
    const payload = {
      component: "modal/supplier_attachment_component",
      component_params: {
        campaign_token: this.element.dataset.campaignToken,
      },
    };

    try {
      await this.stimulate("ModalReflex#load_modal_component", payload);
      window.modal.open();
    } catch (error) {
      console.log(error);
    }
  }

  async stimulateAttachmentDelete(attachmentId) {
    const payload = this.buildAttachmentDeletePayload(attachmentId);
    try {
      await this.stimulate("Campaigns::AttachmentReflex#destroy", payload);
      alert("Attachment successfully deleted.");
    } catch (error) {
      alert("Something unexpected happened.");
    }
  }
}
