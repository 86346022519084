import { FilterField, FilterFn, FilterName, FilterValue, PointOfInterest, Screen } from "../types";

type Filters = { [key in FilterName]: FilterValue | null } | {};

const filterScreen = (
  screens: Map<number, Screen>,
  dataset: Set<number>,
  filter: (_: Screen) => boolean,
): Set<number> => {
  const filtered = new Set<number>();
  dataset.forEach(id => {
    const screen = screens.get(id);
    if (screen && filter(screen)) filtered.add(screen.id);
  });
  return filtered;
};

const filterByIDSet =
  (value: Set<number>, field: FilterField) =>
  (screens: Map<number, Screen>, dataset: Set<number>): Set<number> => {
    return filterScreen(screens, dataset, (screen: Screen) => {
      const fields = screen[field] || [];
      return fields.some((l: number) => value.has(l));
    });
  };

const filterByID =
  (value: Set<number>, field: FilterField) =>
  (screens: Map<number, Screen>, dataset: Set<number>): Set<number> => {
    return filterScreen(screens, dataset, (screen: Screen) => {
      return value.has(screen[field]);
    });
  };

const filterOutByID =
  (value: Set<number>, field: FilterField) =>
  (screens: Map<number, Screen>, dataset: Set<number>): Set<number> => {
    return filterScreen(screens, dataset, (screen: Screen) => {
      return !value.has(screen[field]);
    });
  };

const filterByFlag =
  (active: boolean, field: FilterField) =>
  (screens: Map<number, Screen>, dataset: Set<number>): Set<number> => {
    return filterScreen(screens, dataset, (screen: Screen) => {
      return !active || screen[field];
    });
  };

const filterByValue =
  (value: number, field: FilterField) =>
  (screens: Map<number, Screen>, dataset: Set<number>): Set<number> => {
    return filterScreen(screens, dataset, (screen: Screen) => {
      const fieldValue: number = screen[field] || 0;
      return fieldValue <= value;
    });
  };

const filterMultipleOptions  =
  (value: string, field: FilterField,  possibleValue: string[]) =>
    (screens: Map<number, Screen>, dataset: Set<number>): Set<number> => {
      return filterScreen(screens, dataset, (screen: Screen) => {
        switch (value) {
          case possibleValue[0]:
            return true
          case possibleValue[1]:
            return screen[field]
          case possibleValue[2]:
            return !screen[field]
          default:
            console.error(`Unrecognized filter for ${value}`);
        }

      });
    };

const filterGreaterEqualThanValue =
  (value: number, field: FilterField) =>
    (screens: Map<number, Screen>, dataset: Set<number>): Set<number> => {
      return filterScreen(screens, dataset, (screen: Screen) => {
        const fieldValue: number = screen[field] || 0;
        return fieldValue >= value;
      });
    };

const filterByPointOfInterest =
  (_value: PointOfInterest[]) =>
  (_screens: Map<number, Screen>, dataset: Set<number>): Set<number> => {
    return dataset;
  };

const filterMapByKeyAndValue =
  (values: Map<string, number[]>, field: FilterField, secondaryField: FilterField) =>
  (screens: Map<number, Screen>, dataset: Set<number>): Set<number> => {
    return filterScreen(screens, dataset, (screen: Screen) => {
      if (!screen[field]) {
        return false;
      }
      let publisher = screen[field].toString();
      return !!(values.get(publisher) && values.get(publisher).includes(screen[secondaryField]));
    });
  };

const filterConditionalMapByKeyAndValue =
  (values: Map<string, number[]>, field: FilterField, secondaryField: FilterField, thirdField: FilterField) =>
    (screens: Map<number, Screen>, dataset: Set<number>): Set<number> => {
      return filterScreen(screens, dataset, (screen: Screen) => {
        if (!screen[field]) {
          return false;
        }
        let publisher = screen[field].toString();
        return !!(values.get(publisher) && values.get(publisher).includes(screen[secondaryField])) || !!(screen[thirdField]);
      });
    };

export class Filter {
  screens: Map<number, Screen>;
  filters: Filters = {};
  all: Set<number>;
  filtered: Set<number>;
  alwaysInclude: Set<number>;

  constructor(screens: Map<number, Screen>, alwaysInclude: Set<number> = new Set()) {
    this.screens = screens;
    this.all = new Set(screens.keys());
    this.filtered = this.all;
    this.alwaysInclude = alwaysInclude;
  }

  filter(name: FilterName, value: FilterValue | null): Set<number> | false {
    let filtered: Set<number> = this.all;
    if (value) {
      const filter = this.toFilter(name, value);
      if (filter) {
        this.filterDebug("before include", name, value, filtered);
        filtered = filter(this.screens, this.all);
        this.filterDebug("after include", name, value, filtered);
      }
    }
    Object.entries(this.filters)
      .filter(([n]) => (n as FilterName) !== name)
      .forEach(([name, value]: [FilterName, FilterValue]) => {
        if (!value) return;
        const filter = this.toFilter(name, value);
        if (filter) {
          this.filterDebug("before run", name, value, filtered);
          filtered = filter(this.screens, filtered);
          this.filterDebug("after run", name, value, filtered);
        }
      });

    this.filters[name] = value;
    this.filtered = new Set([...filtered, ...this.alwaysInclude]);
    return this.filtered;
  }

  filterDebug(when, name, value, filtered) {
    if (!window.DEBUG) return;
    console.log(when, "filter", name, value, "filtered", filtered.size, "total", this.screens.size);
    if (window.QUERY_PARAMS) {
      const debug_screen_id = window.QUERY_PARAMS.get("debug_screen_id");
      if (debug_screen_id) {
        console.log("filtered has", debug_screen_id, "?", filtered.has(parseInt(debug_screen_id, 10)));
      }
    }
  }

  filterOnly(name: FilterName, value: FilterValue | null): Set<number> | false {
    let filtered: Set<number> = this.all;
    if (value) {
      const filter = this.toFilter(name, value);
      if (filter) {
        filtered = filter(this.screens, this.all);
      }
    }

    const filter = this.toFilter(name, value || this.filters[name]);
    filtered = filter(this.screens, filtered);

    this.filters[name] = value;
    this.filtered = filtered;
    return this.filtered;
  }

  clear() {
    this.filters = {};
    this.filtered = this.all;
  }

  toFilter(name: FilterName, value: FilterValue): FilterFn | null {
    switch (name) {
      case "cpm":
        return filterByValue(value as number, "cpm");
      case "min_cpm":
        return filterGreaterEqualThanValue(value as number, "cpm");
      case "geography":
        return filterByIDSet(value as Set<number>, "loc");
      case "moving_media":
        return filterByID(value as Set<number>, "h3");
      case "publishers":
        return filterByID(value as Set<number>, "pub");
      case "venue_types":
        return filterByID(value as Set<number>, "vt");
      case "screen_type":
        return filterByID(value as Set<number>, "st");
      case "media_type":
        return filterByID(value as Set<number>, "mt");
      case "resolutions":
        return filterByID(value as Set<number>, "rs");
      case "deals":
        return filterByIDSet(value as Set<number>, "d");
      case "points_of_interest":
        return filterByID(value as Set<number>, "id");
      case "custom_geofences":
        return filterByID(value as Set<number>, "id");
      case "target":
        return filterByID(value as Set<number>, "id");
      case "exclude":
        return filterOutByID(value as Set<number>, "id");
      case "audience":
        return filterByID(value as Set<number>, "bg");
      case "active_screens":
        return filterByFlag(value as boolean, "actv");
      case "is_cannabis_friendly":
        return filterByFlag(value as boolean, "cf");
      case "is_instant_bookable":
        return filterByFlag(value as boolean, "ib");
      case "default_ssp_for_each_publisher":
        return filterConditionalMapByKeyAndValue(value as Map<string, number[]>, "pub", "ssp", 'only_direct_screen');
      case "screen_options_image":
        return filterByFlag(value as boolean, "b");
      case "screen_options_video":
        return filterByFlag(value as boolean, "v");
      case "screen_options_audio":
        return filterByFlag(value as boolean, "a");
      case "screen_options_min_duration":
        return filterGreaterEqualThanValue(value as number, "mind");
      case "screen_options_max_duration":
        return filterByValue(value as number, "maxd");
      case "display_screens_in_map":
        return filterMultipleOptions(value as string, "only_direct_screen", ['all', "direct", "programmatic"]);
      default:
        console.error(`Unrecognized filter for ${name}`);
        return null;
    }
  }
}
