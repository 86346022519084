import ApplicationController from "../application_controller";
import { get } from "@rails/request.js";
import { debounce } from "lodash";
export default class extends ApplicationController {

  static targets = [
    "tabContent",
    "tab",
    "subTab",
    "demographicsTab",
    "economicsTab",
    "educationTab",
    "flagUnitForm",
    "flagUnitButton",
    "flagUnitInput",
    "flagConfirmationMessage",
    "comments",
    "unitInfo",
    'commentBody',
    'recipientsCheveron',
    'recipients',
    'newTagName',
    'newTagButton',
    'tagInput',
    'dropDownMenu',
    'tagSuggestions'
  ];

  static values = {
    unitId: String, // unit's slug
    unitLat: Number,
    unitLng: Number,
    campaignId: String,
    campaignUnitId: String,
    tagSearch: String,
  };

  initialize() {
    this.campaignId = null;
    this.debouncedTagSearch = debounce(this.tagSearch, 300);
  }

  handleChangeTab(event) {
    const { currentTarget } = event;
    const {
      dataset: { tabId },
    } = currentTarget;
    if (currentTarget.classList.contains("active")) return;
    this.#markItemAsActive(currentTarget, this.tabTargets);
    this.stimulate("Pro::Campaigns::ModalActionsReflex#change_tab", {
      component_name: tabId,
      campaign_id: this.campaignIdValue,
      unit_id: this.unitIdValue,
    });
  }

  handleAudiencesSubTabChange(event) {
    const { currentTarget } = event;
    const {
      dataset: { target },
    } = currentTarget;
    if (currentTarget.classList.contains("active")) return;
    this.#markItemAsActive(currentTarget, this.subTabTargets);
    this.#markItemAsActive(this[target], [
      this.demographicsTabTarget,
      this.economicsTabTarget,
      this.educationTabTarget,
    ]);
  }

  handleFlagUnitFormToggle(_event) {
    this.flagUnitFormTarget.classList.toggle("hide");
    this.flagUnitButtonTarget.classList.toggle("active");

    if (this.flagUnitButtonTarget.classList.contains("active")) {
      this.flagUnitInputTarget.focus();
    } else {
      this.#resetFlagUnitForm();
    }
  }

  handleCancelFlaggingUnit() {
    this.#resetFlagUnitForm();
  }

  async handleFlaggingUnitSubmit() {
    await this.stimulate("Pro::Campaigns::ModalActionsReflex#flag_unit", {
      note: this.flagUnitInputTarget.value,
      unit_id: this.unitIdValue,
    });
    this.flagConfirmationMessageTarget.classList.remove("hide");
    this.#resetFlagUnitForm();
  }

  handleViewOnMap(e) {
    window.dispatchEvent(
      new CustomEvent("adquick:browseunitmodal:viewonmap", {
        detail: {
          lat: this.unitLatValue,
          lng: this.unitLngValue,
        },
      }),
    );
  }

  handleCommentSubmit(event) {
    const commentbody = this.commentBodyTarget.value;
    if (commentbody.trim() === "") return;
    this.stimulate("Pro::Campaigns::ModalActionsReflex#add_comment", {
      body: commentbody,
      campaign_unit_id: this.campaignUnitIdValue,
    });
  }

  toggleRecipients() {
    const recipientsCheveron = this.recipientsCheveronTarget;
    const recipients = this.recipientsTarget;

    if (recipientsCheveron.classList.contains('fa-chevron-down')) {
      recipientsCheveron.classList.remove('fa-chevron-down');
      recipientsCheveron.classList.add('fa-chevron-up');
    } else {
      recipientsCheveron.classList.remove('fa-chevron-up');
      recipientsCheveron.classList.add('fa-chevron-down');
    }

    this.recipientsTarget.classList.toggle('hidden');
  }

  newTag() {
    this.newTagButtonTarget.classList.add('hidden');
    this.tagInputTarget.classList.remove('hidden');
    this.newTagNameTarget.focus();
  }

  cancelTagCreation() {
    this.newTagButtonTarget.classList.remove('hidden');
    this.tagInputTarget.classList.add('hidden');
  }

  toggleMenu(event) {
    const dropDownMenu = this.dropDownMenuTarget;
    dropDownMenu.classList.toggle('hide');
  }

  removeTag(event) {
    const tagId = event.currentTarget.dataset.tag;
    this.stimulate("Pro::Campaigns::ModalActionsReflex#remove_tag", {
      tag_id: tagId,
      campaign_unit_id: this.campaignUnitIdValue,
    });
  }

  addTag(event) {
    if (event.key === "Esc" || event.key === "Escape") {
      this.cancelTagCreation();
      event.stopPropagation();
    }

    if (event.key === "Enter") {
      event.preventDefault();
      const tag = this.newTagNameTarget.value;
      if (!tag) return;
      this.stimulate("Pro::Campaigns::ModalActionsReflex#add_tag", {
        tag: tag,
        campaign_unit_id: this.campaignUnitIdValue,
      });

      this.newTagNameTarget.value = "";
    }

  }

  async tagSearch(event) {
    const tag = this.newTagNameTarget.value;
    if (!tag) {
      this.tagSuggestionsTarget.innerHTML = '';
      return;
    }

    const response = await get(this.tagSearchValue, {query: {search: tag}});
    const data = await response.json;

    let suggestionsHtml = '';
    if (data.tags.length > 0) {
      data.tags.forEach(tag => {
        suggestionsHtml += `
          <div class="px-4 py-2 cursor-pointer hover:bg-gray-50 my-2"
               data-action="click->campaigns-modal-actions#selectExistingTag"
               data-tag="${tag.tag}">
            ${tag.tag}
          </div>
        `;
      });
    }
    // if any of the tags match exactly, dont add suggestionsHtml
    if (!data.tags.some(t => t.tag === tag)) {
      suggestionsHtml += `
      <div class="px-4 py-2 cursor-pointer hover:bg-gray-50 my-2"
            data-action="click->campaigns-modal-actions#selectNewTag"
            data-tag="${tag}">
        Create "${tag}"
        </div>
      `;
    }
    this.tagSuggestionsTarget.innerHTML = suggestionsHtml;
  }

  selectExistingTag(event) {
    const tag = event.currentTarget.dataset.tag;
    this.stimulate("Pro::Campaigns::ModalActionsReflex#add_tag", {
      tag: tag,
      campaign_unit_id: this.campaignUnitIdValue,
    });
    this.newTagNameTarget.value = "";
    this.tagSuggestionsTarget.innerHTML = '';
    this.cancelTagCreation();
  }

  selectNewTag(event) {
    const tag = event.currentTarget.dataset.tag;
    this.stimulate("Pro::Campaigns::ModalActionsReflex#add_tag", {
      tag: tag,
      campaign_unit_id: this.campaignUnitIdValue,
      is_new: true
    });
    this.newTagNameTarget.value = "";
    this.tagSuggestionsTarget.innerHTML = '';
    this.cancelTagCreation();
  }

  closeModal(event) {
    event.preventDefault();
    window.modal.close();
  }

  favoriteUnit(event) {
    const cuToken = event.currentTarget.dataset.cuId;
    this.stimulate("Pro::Campaigns::ModalActionsReflex#favorite_flight_unit", {
      cu_token: cuToken,
    });
    this.handleFavoriteClick(event);
  }

  unFavoriteUnit(event) {
    const cuToken = event.currentTarget.dataset.cuId;
    this.stimulate("Pro::Campaigns::ModalActionsReflex#unfavorite_flight_unit", {
      cu_token: cuToken,
    });
    this.handleFavoriteClick(event);
  }

  handleFavoriteClick(event) {
    window.dispatchEvent(new CustomEvent("adquick:map:unit:favorite", {}));
  }

  recommendUnit(event) {
    const cuToken = event.currentTarget.dataset.cuId;
    this.stimulate("Pro::Campaigns::ModalActionsReflex#recommend_flight_unit", {
      cu_token: cuToken,
    });
    this.handleRecommendClick(event);
  }

  unRecommendUnit(event) {
    const cuToken = event.currentTarget.dataset.cuId;
    this.stimulate("Pro::Campaigns::ModalActionsReflex#unrecommend_flight_unit", {
      cu_token: cuToken,
    });
    this.handleRecommendClick(event);
  }

  handleRecommendClick(event) {
    window.dispatchEvent(new CustomEvent("adquick:map:unit:recommend", {}));
  }

  addToCart(event) {
    this.stimulate("Pro::Campaigns::ModalActionsReflex#add_to_cart", {
      campaign_id: this.campaignIdValue,
      cu_token: event.currentTarget.dataset.cuId,
      unit_id: this.unitIdValue
    });
  }

  removeFromCart(event) {
    this.stimulate("Pro::Campaigns::ModalActionsReflex#remove_from_cart", {
      campaign_id: this.campaignIdValue,
      cu_token: event.currentTarget.dataset.cuId,
      unit_id: this.unitIdValue,
    });
  }

  removeCampaignUnit(_event) {
    let response = confirm("Are you sure you want to remove this unit from the campaign?");
    if (!response) return;

    this.stimulate("Pro::Campaigns::ModalActionsReflex#remove_unit_from_campaign", {
      cu_token: this.campaignUnitIdValue
    });
    const options = { tab: 'units', campaign_id: this.campaignIdValue, page: 1 };
    this.stimulate("Pro::Campaigns::MapsReflex#update_sidebar", options)
    window.modal.close();
  }

  // StimulusReflex callbacks

  beforeChangeTab() {
    this.tabContentTarget.classList.add("wait");
  }

  afterChangeTab() {
    this.tabContentTarget.classList.remove("wait");
  }

  // Private

  #markItemAsActive(selected, allItems) {
    allItems.forEach(item => item.classList.remove("active"));
    selected.classList.add("active");
  }

  #resetFlagUnitForm() {
    this.flagUnitFormTarget.classList.add("hide");
    this.flagUnitButtonTarget.classList.remove("active");
    this.flagUnitInputTarget.value = "";
  }
}
