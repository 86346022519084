import ApplicationController from "../application_controller";
import { parsePriceInput } from "../../utils/parsers";

export default class extends ApplicationController {
  static values = { attributes: Object };

  static targets = ["hasProduction", "supplierProductionCost", "supplierInstallationCost", "mediaOffer"];

  itemAttributes() {
    const {
      dataset: { vendorNegotiationItemAttributes },
    } = this.element;
    return JSON.parse(vendorNegotiationItemAttributes);
  }

  itemToken() {
    const { token } = this.itemAttributes();
    return token;
  }

  isElementNotUpdatable(element) {
    const { dataset } = element;
    const datasetKeys = Object.keys(dataset);
    return datasetKeys.includes("notUpdatable");
  }

  allElementInputs() {
    return Array.from(this.element.querySelectorAll("input")).filter(
      el => el.name != "selector" && !this.isElementNotUpdatable(el),
    );
  }

  connected() {
    this.allElementInputs().forEach(el =>
      el.addEventListener("change", e => this.stimulateInputElementChange(e.target)),
    );
  }

  disconnect() {
    this.allElementInputs().forEach(el => el.removeEventListener("change", this.stimulateInputElementChange));
  }

  hasProductionChanged(e) {
    const {
      target: { checked },
    } = e;
    this.supplierProductionCostTarget.disabled = !checked;
  }

  stimulateInputElementChange(el) {
    const { name, value, checked, type } = el;
    const change = { [name]: type == "checkbox" ? checked : parsePriceInput(value) };
    this.onElementChange({ change, token: this.itemToken() });
  }

  async onElementChange(payload) {
    await this.stimulate("Plus::Campaigns::VendorContractsItemReflex#update", payload);
  }

  destroy(e) {
    this.onDestroy({ token: this.itemToken() });
  }

  async onDestroy(payload) {
    await this.stimulate("Plus::Campaigns::VendorContractsItemReflex#destroy", payload);
  }
}
