import ApplicationController from "../application_controller";
import debounce from "lodash/debounce";
import dragula from "dragula";

export default class extends ApplicationController {
  static targets = ["todoItemsList", "campaignProposalsList"];

  connected() {
    this.updateTodo = debounce(this.updateTodo.bind(this), 1000);
    this.updateCampaignProposal = debounce(this.updateCampaignProposal.bind(this), 1000);
    this.updateCampaignStrategy = debounce(this.updateCampaignStrategy.bind(this), 1000);
    this.updateCustomStrategyGoal = debounce(this.updateCustomStrategyGoal.bind(this), 1000);

    dragula([this.campaignProposalsListTarget], {
      moves: function(_el, _container, handle) {
        return handle.classList.contains("fa-grip-dots-vertical");
      },
      mirrorContainer: this.campaignProposalsListTarget,
    }).on("drop", this.reorderProposals.bind(this));
  }

  reorderProposals(el, _target, _source, sibling) {
    const movedCampaignProposalId = el.dataset.campaignProposalId;
    const replacedCampaignProposalId = sibling.dataset.campaignProposalId;

    this.stimulate(
      "CampaignOverviewSettings#reorder_campaign_proposals",
      movedCampaignProposalId,
      replacedCampaignProposalId,
    );
  }

  submit(e) {
    e.preventDefault();
  }

  createTodoItem(e) {
    e.preventDefault();
    this.stimulate("CampaignOverviewSettings#create_custom_todo", e.target).then(() => this.moveCreatedTodo());
  }

  createPreDefinedTodoItem(e) {
    e.preventDefault();
    this.stimulate("CampaignOverviewSettings#create_predefined_todo", e.target).then(() => this.moveCreatedTodo());
  }

  moveCreatedTodo() {
    const html = document.getElementById("js-temporary-todo-space").innerHTML;
    this.todoItemsListTarget.insertAdjacentHTML("afterbegin", html);
    this.todoItemsListTarget.querySelector(".js-description").focus();
  }

  deleteTodo(e) {
    e.preventDefault();
    this.stimulate("CampaignOverviewSettings#delete_todo", e.target).then(function() {
      e.target.closest(".campaign-todo-wrapper").remove();
    });
  }

  updateTodo(e) {
    this.stimulate("CampaignOverviewSettings#update_todo", e.target);
  }

  updateCampaignProposal(e) {
    if (e.target.dataset.fieldName === "name") {
      e.target.closest(".campaign-proposal-form").querySelector(".campaign-proposal-title").textContent =
        e.target.value;
    }
    this.stimulate("CampaignOverviewSettings#update_campaign_proposal", e.target);
  }

  updateCampaignStrategy(e) {
    this.stimulate("CampaignOverviewSettings#update_campaign_strategy", e.target);
  }

  toggleCampaignStrategyGoal(e) {
    const campaignStrategyGoalId = e.target.dataset["id"];
    const parentElement = e.target.parentElement;
    const selected = parentElement.classList.contains("active");

    if (selected) {
      parentElement.classList.remove("active");
    } else {
      parentElement.classList.add("active");
    }

    this.stimulate("CampaignOverviewSettings#toggle_campaign_strategy_goal", campaignStrategyGoalId, selected);
  }

  toggleCampaignStrategyType(e) {
    const campaignStrategyTypeId = e.target.dataset["id"];
    const parentElement = e.target.parentElement;
    const selected = parentElement.classList.contains("active");

    if (selected) {
      parentElement.classList.remove("active");
    } else {
      parentElement.classList.add("active");
    }

    this.stimulate("CampaignOverviewSettings#toggle_campaign_strategy_type", campaignStrategyTypeId, selected);
  }

  createCampaignProposal(e) {
    e.preventDefault();

    const campaignProposalsList = this.campaignProposalsListTarget;
    this.stimulate("CampaignOverviewSettings#create_campaign_proposal", e.target).then(function() {
      const html = document.getElementById("js-temporary-campaign-proposal-space").innerHTML;
      campaignProposalsList.insertAdjacentHTML("beforeEnd", html);
      campaignProposalsList.querySelector(".campaign-proposal-form:last-of-type .js-campaign-proposal-name").focus();

      document.querySelector(".js-blank-campaign-proposals-list").classList.add("hidden");
    });
  }

  toggleCampaignProposal(e) {
    e.preventDefault();

    const form = e.target.closest(".campaign-proposal-form");

    if (form.classList.contains("closed")) {
      form.classList.remove("closed");
    } else {
      form.classList.add("closed");
    }
  }

  deleteCampaignProposal(e) {
    e.preventDefault();

    this.stimulate("CampaignOverviewSettings#destroy_campaign_proposal", e.target).then(function() {
      e.target.closest(".campaign-proposal-form").remove();

      const campaignProposalsCount = document.querySelectorAll(".campaign-proposal-form").length;
      if (campaignProposalsCount === 0) {
        document.querySelector(".js-blank-campaign-proposals-list").classList.remove("hidden");
      }
    });
  }

  createCustomStrategyGoal(e) {
    e.preventDefault();
    const campaignStrategyId = e.target.dataset.campaignStrategyId;
    this.stimulate("CampaignOverviewSettings#create_custom_strategy_goal", e.target).then(() =>
      this.moveCreatedCustomStrategyGoal(campaignStrategyId),
    );
  }

  moveCreatedCustomStrategyGoal(campaignStrategyId) {
    const html = document.getElementById(`js-temporary-custom-campaign-strategy-${campaignStrategyId}-space`).innerHTML;
    const listId = `campaign-strategy-${campaignStrategyId}-custom-campaign-strategy-goals`;
    const list = document.getElementById(listId);

    list.insertAdjacentHTML("afterbegin", html);
    list.querySelector(`#${listId} .js-strategy-goal-name`).focus();
  }

  updateCustomStrategyGoal(e) {
    this.stimulate("CampaignOverviewSettings#update_custom_goal", e.target);
  }

  deleteCustomGoal(e) {
    e.preventDefault();
    this.stimulate("CampaignOverviewSettings#delete_custom_goal", e.target).then(function() {
      e.target.closest(".js-custom-goal").remove();
    });
  }

  createCustomStrategyType(e) {
    e.preventDefault();
    const campaignStrategyId = e.target.dataset.campaignStrategyId;
    this.stimulate("CampaignOverviewSettings#create_custom_strategy_type", e.target).then(() =>
      this.moveCreatedCustomStrategyType(campaignStrategyId),
    );
  }

  moveCreatedCustomStrategyType(campaignStrategyId) {
    const html = document.getElementById(`js-temporary-custom-campaign-strategy-${campaignStrategyId}-space`).innerHTML;
    const listId = `campaign-strategy-${campaignStrategyId}-custom-campaign-strategy-types`;
    const list = document.getElementById(listId);

    list.insertAdjacentHTML("afterbegin", html);
    list.querySelector(`#${listId} .js-strategy-type-title`).focus();
  }

  updateCustomStrategyType(e) {
    this.stimulate("CampaignOverviewSettings#update_custom_strategy_type", e.target);
  }

  deleteCustomStrategyType(e) {
    e.preventDefault();
    this.stimulate("CampaignOverviewSettings#delete_custom_strategy_type", e.target).then(function() {
      e.target.closest(".js-custom-strategy-type").remove();
    });
  }
}
