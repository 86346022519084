import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["editor"];

  vendorSearchResult() {
    return JSON.parse(this.element.dataset.vendorSearchResult);
  }

  getContentFromEditor() {
    return this.editorTarget.querySelector(".ql-editor").innerHTML;
  }

  buildCustomLanguagePayload() {
    return {
      user_custom_language_id: this.element.dataset.userCustomLanguageId,
      supplier_user_id: this.element.dataset.contactId,
      content: this.getContentFromEditor(),
      search_results_payload: this.vendorSearchResult(),
    };
  }

  close(e) {
    e.preventDefault();
    window.modal.close();
  }

  submit() {
    this.onSubmit();
  }

  async onSubmit() {
    try {
      const payload = this.buildCustomLanguagePayload();
      const method = this.element.dataset.userCustomLanguageId ? "update" : "create";
      await this.stimulate(`CustomLanguageReflex#${method}`, payload);
      alert("Your custom language was saved");
      window.modal.close();
    } catch (error) {
      alert("Something happened when saving your custom language");
    }
  }
}
