import accounting from "accounting";

export default class UnitTooltipController {
  constructor(map, showPrices, showUnitPrice, showSupplier) {
    this.map = map;
    this.showPrices = showPrices;
    this.showUnitPrice = showUnitPrice;
    this.showSupplier = showSupplier;

    this.showTooltip = true;

    map.on("draw.render", this.#handleDrawRender.bind(this));
  }

  // Avoid showing the tooltip while the user is drawing on the map.
  #handleDrawRender() {
    this.showTooltip = false;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.showTooltip = true;
    }, 1000);
  }

  showUnitTooltip({ unitId, coordinates, offset = 10 }) {
    if (unitId && coordinates && this.showTooltip) {
      fetch(`/api/v1/units/${unitId}`)
        .then(res => res.json())
        .then(json => {
          this.unitTooltip?.remove();
          this.unitTooltip = new mapboxgl.Popup({ closeButton: false, offset })
            .setLngLat(coordinates)
            .setHTML(this.#unitTooltipHtml(json))
            .addTo(this.map);
        });
    }
  }

  hideUnitTooltip() {
    this.unitTooltip?.remove();
  }

  // Template adapted from packages/adquick-classic/src/marketplace/components/NewMap/UnitPopups.js - unitDescription(unit)
  #unitTooltipHtml(properties) {
    return `
      <div class="new_map_unit_popup">
        <img class="picture" src="${properties.image_file_link}"></img>
        <div>
          ${`<div class="face-id-vendor">`}
            ${properties.faceId ? `<span class="face_id">${properties.faceId}</span>` : ""}
            ${
              properties.supplier_name && this.showSupplier
                ? `<div class="vendor">
                    <span class="vendor-name">${properties.supplier_name}</span>
                    ${properties.is_managed ? `<img src="${window.ASSETS.managed_vendor}">` : ""}
                    ${
                      !properties.is_managed && properties.is_fully_submitted
                        ? `<img src="${window.ASSETS.fully_submitted_vendor}">`
                        : ""
                    }
                    ${properties.is_broker ? `<img src="${window.ASSETS.broken_vendor}">` : ""}
                  </div>`
                : ""
            }
          ${"</div>"}
          ${properties.is_package ? '<span className="title">Package</span>' : ""}
          ${
            this.showPrices && this.showUnitPrice && properties.price
              ? `<div style="display:flex;">
                  <div class="price">${accounting.formatMoney(properties.price)}</div>
                </div>`
              : ""
          }
          ${
            this.showPrices && !this.showUnitPrice && properties.rate_card_price
              ? `<div style="display:flex;">
                  <div class="title">Rate Card</div>
                  <div class="data">${accounting.formatMoney(properties.rate_card_price)}</div>
                </div>`
              : ""
          }
          ${
            properties.size
              ? `<div style="display:flex;">
                  <div class="title">Size</div>
                  <div class="data">${properties.size}</div>
                </div>`
              : ""
          }
          ${
            this.showPrices && properties.cpm
              ? `<div style="display:flex;">
                  <div class="title">CPM</div>
                  <div class="data">${accounting.formatMoney(properties.cpm)}</div>
                </div>`
              : ""
          }
          ${
            properties.direction
              ? `<div style="display:flex;">
                  <div class="title">Direction</div>
                  <div class="data">${properties.direction}</div>
                </div>`
              : ""
          }
        </div>
      </div>
    `;
  }
}
