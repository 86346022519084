import ApplicationController from "./application_controller";
import Handsontable from "handsontable";
import { range, omitBy } from "lodash";
import {
  viewLink,
  removalReasonLink,
  removeLink,
  favoriteIcon,
  recommendIcon,
} from "../markup/new_campaign_grid/action_templates";
export class NewCampaignGridController extends ApplicationController {
  static values = {
    campaignId: String,
    campaignToken: String,
  };

  static targets = ["grid", "loading", "form", "submit"];

  async connect() {
    StimulusReflex.register(this);

    this.changes = [];
    this.gridData = [];
    this.originalData = [];
    this.campaignPermissions = {};
    this.currentColumns = [];
  }

  async initialize() {
    Handsontable.renderers.registerRenderer("custom.grid-actions", this.actionsRenderer);
    this.currentColumns = await this.fetchColumns();
    this.grid = new Handsontable(this.gridTarget, await this.gridDefaults(await this.columns()));
    this.formChanges();
    this.fetchAndLoadData();
    this.fetchPermissions();
  }

  enqueueGridChanges = async (grid, changed_data) => {
    const changes = changed_data
      .map(([row, column, old_value, new_value]) => {
        const unit_id = grid.getDataAtRowProp(row, "_id");
        const campaign_unit_token = grid.getDataAtRowProp(row, "campaign_unit_token");

        old_value = old_value == null ? "" : old_value;
        new_value = new_value == null ? "" : new_value;
        if (old_value == new_value) {
          return null;
        }
        return {
          row,
          unit_id: unit_id,
          campaign_unit_token: campaign_unit_token,
          attribute: column,
          value: new_value,
        };
      })
      .filter(obj => obj);
    this.changes = this.changes.concat(changes);

    if (this.changes.length > 0) {
      this.submitTarget.classList.remove("hidden");
    } else {
      this.submitTarget.classList.add("hidden");
    }
    return changes;
  };

  onGridChange = async (changes, source) => {
    const isEdition = source == "edit" || source == "CopyPaste.paste";
    if (!isEdition) {
      return;
    }

    const changesWithNoFavRec = changes.filter(c => !["is_favorited", "is_recommended"].includes(c[1]));
    const hasChanges = changesWithNoFavRec.map(c => c[2] != c[3]).includes(true);
    if (!hasChanges) {
      return;
    }
    await this.enqueueGridChanges(this.grid, changes);
  };

  applyGridChanges = async () => {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const response = await fetch("/api/v1/campaign_grid/changes", {
      method: "post",
      headers: headers,
      body: JSON.stringify({ campaign_id: this.campaignTokenValue, changes: this.changes }),
      credentials: "same-origin",
    });
    this.clearChanges();
  };

  cancelChanges = async e => {
    e.preventDefault();
    this.grid.loadData(this.originalData);
    this.clearChanges();
    this.grid.render();
  };

  clearChanges = () => {
    this.changes = [];
    this.submitTarget.classList.add("hidden");
  };

  onFormResponse(e) {
    const [jsonBody, status, event] = e.detail;
    this.grid.loadData(jsonBody);
    this.toggleLoading(true);
  }

  formChanges() {
    const queryString = new URLSearchParams(new FormData(this.formTarget));

    let keysToDelete = [];
    queryString.forEach((value, name) => {
      if (value === "") {
        keysToDelete.push(name);
      }
    });
    keysToDelete.map(k => queryString.delete(k));
    const newUrl = `${this.campaignTokenValue}?${queryString.toString()}`;

    const urlStateEvent = new CustomEvent("campaign-grid-url-push-state", { detail: { params: queryString } });
    window.history.replaceState(null, null, newUrl);
    setTimeout(() => window.dispatchEvent(urlStateEvent), 500);
  }

  async submit() {
    this.toggleLoading(false);
    await this.stimulate("Plus::Campaigns::FilterReflex#full_filterbar", { campaign_token: this.campaignTokenValue });
    this.setDefaultFocus();
  }

  async clearFilters(e) {
    e.preventDefault();
    const newUrl = `${this.campaignTokenValue}`;

    const urlStateEvent = new CustomEvent("campaign-grid-url-push-state", { detail: { params: {} } });
    window.history.replaceState(null, null, newUrl);
    setTimeout(() => window.dispatchEvent(urlStateEvent), 500);

    await this.fetchAndLoadData(true);
    await this.stimulate("Plus::Campaigns::FilterReflex#full_filterbar", { campaign_token: this.campaignTokenValue });
  }

  setDefaultFocus() {
    const defaultFocusInput = document.getElementById("default-focus");
    const length = defaultFocusInput.value.length;
    defaultFocusInput.focus();
    defaultFocusInput.setSelectionRange(length, length);
  }

  async fetchAndLoadData(clearFilters) {
    let params;
    if (clearFilters == true) {
      params = new URLSearchParams({});
    } else {
      params = new URLSearchParams(window.location.search);
    }
    const response = await fetch(
      `/api/v1/campaigns/${this.campaignTokenValue}/campaign_grid_data?${params.toString()}`,
    );
    const jsonBody = await response.json();
    this.gridData = jsonBody;
    this.originalData = JSON.parse(JSON.stringify(jsonBody));
    this.toggleLoading(true);
    this.grid.loadData(this.gridData);
  }

  async fetchPermissions() {
    const response = await fetch(`/api/v1/campaigns/${this.campaignTokenValue}/campaign_grid_data/permissions`);
    const jsonBody = await response.json();
    this.campaignPermissions = jsonBody;
  }

  async fetchColumns() {
    const response = await fetch(`/api/v1/campaign_grid/columns?campaign_id=${this.campaignTokenValue}`);
    const jsonBody = await response.json();
    const columns = jsonBody.columns;
    return omitBy(columns, (value, _key) => !value.visible || value.attribute == "old_actions_row"); // ugly way of not double rendering actions col
  }

  async toggleLoading(bool) {
    const cols = await this.columns(!bool);
    this.grid.updateSettings({ columns: cols });
  }

  actionsRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    const campaignToken = this.campaignTokenValue;
    const campaignUnitToken = instance.getDataAtRowProp(row, "campaign_unit_token");
    const unitSlug = instance.getDataAtRowProp(row, "id");
    const hasRequests = this.campaignPermissions.show_unit_removal_reason;
    let favIconStyle;
    let recSpanStyle = "";
    const isFavorited = instance.getDataAtRowProp(row, "is_favorited");
    const isRecommended = instance.getDataAtRowProp(row, "is_recommended");

    if (isFavorited) {
      favIconStyle = "color: var(--red)";
    } else {
      favIconStyle = "color: #E6E6E6";
    }

    if (!isRecommended) {
      recSpanStyle = "color: #CCCCCC; background-color: #F5F5F5";
    }
    let removeUnitLink;
    // if (hasRequests) {
    removeUnitLink = removalReasonLink(campaignToken, campaignUnitToken, unitSlug);
    // } else {
    removeUnitLink = removeLink();
    // }

    const template = /*html*/ `
    <div class='custom-grid-actions'>
      <span class='gray-action'>${viewLink(campaignToken, unitSlug)}</span>
      <span class='gray-action'>${removeUnitLink}</span>
      <span class="favorite-action">${favoriteIcon(favIconStyle)}</span>
      ${recommendIcon(recSpanStyle)}
    </div>
    `;
    td.innerHTML = "";
    td.insertAdjacentHTML("afterbegin", template);
    const favAction = td.querySelector(".favorite-action");
    const recAction = td.querySelector(".recommended-action");
    const viewUnitAction = td.querySelector(".view-unit-action");
    const removeAction = td.querySelector(".remove-units");
    favAction.addEventListener("click", () => {
      this.toggleFavorite(campaignToken, [unitSlug], isFavorited, row);
    });
    recAction.addEventListener("click", () => {
      this.toggleRecommend(campaignToken, [unitSlug], isRecommended, row);
    });
    viewUnitAction.addEventListener("click", e => {
      e.preventDefault();
      this.openUnitModal(unitSlug);
    });
    // if (!hasRequests) {
    removeAction.addEventListener("click", () => {
      this.removeUnit(campaignToken, unitSlug);
    });
    // }
    return td;
  };

  toggleFavorite = async (campaignToken, unitSlugs, isFavorited, row) => {
    this.grid.setDataAtRowProp(row, "is_favorited", !isFavorited);
    if (isFavorited) {
      return await this.unFavorite(campaignToken, unitSlugs);
    }

    return this.markAsFavorite(campaignToken, unitSlugs);
  };

  toggleRecommend = async (campaignToken, unitSlugs, isRecommended, row) => {
    this.grid.setDataAtRowProp(row, "is_recommended", !isRecommended);

    if (isRecommended) {
      return this.unRecommentUnit(campaignToken, unitSlugs);
    }

    return await this.recommendUnit(campaignToken, unitSlugs);
  };

  async openUnitModal(unitSlug) {
    const payload = {
      component: "modal/browse_unit_modal_component",
      component_params: {
        unit_id: unitSlug,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();
  }

  markAsFavorite = async (campaignToken, unitSlugs) => {
    const payload = {
      unit_ids: unitSlugs,
    };
    const url = `/api/v1/campaigns/${campaignToken}/campaign_units/batch_favorite`;
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
    if (response.ok) {
      this.stimulate("UI::ToastReflex#show", "success", "Unit Favorited");
    } else {
      this.stimulate("UI::ToastReflex#show", "error", "Error changing unit status, contact support");
    }
  };

  removeUnit = async (campaignToken, unitSlug) => {
    const confirmation = confirm("Are you sure you want to remove this unit?");
    if (confirmation) {
      const url = `/api/v1/campaigns/${campaignToken}/remove_unit/${unitSlug}`;
      const response = await fetch(url, { method: "DELETE", headers: { "Content-Type": "application/json" } });
      const body = await response.json();
      Rails.fire(this.formTarget, "submit");
      return body;
    }
  };

  unFavorite = async (campaignToken, unitSlugs) => {
    const payload = {
      unit_ids: unitSlugs,
    };
    const url = `/api/v1/campaigns/${campaignToken}/campaign_units/batch_favorite/unfavorite`;
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
    if (response.ok) {
      this.stimulate("UI::ToastReflex#show", "success", "Unit Unfavorited");
    } else {
      this.stimulate("UI::ToastReflex#show", "error", "Error changing unit status, contact support");
    }
  };

  recommendUnit = async (campaignToken, unitSlugs) => {
    const url = `/api/v1/campaigns/${campaignToken}/toggle_recommend_units`;
    const payload = {
      unit_ids: unitSlugs,
      flow: "recommend",
    };
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
    if (response.ok) {
      this.stimulate("UI::ToastReflex#show", "success", "Unit Recommended");
    } else {
      this.stimulate("UI::ToastReflex#show", "error", "Error changing unit status, contact support");
    }
  };

  unRecommentUnit = async (campaignToken, unitSlugs) => {
    const url = `/api/v1/campaigns/${campaignToken}/toggle_recommend_units`;
    const payload = {
      unit_ids: unitSlugs,
      flow: "unrecommend",
    };
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
    if (response.ok) {
      this.stimulate("UI::ToastReflex#show", "success", "Unit un recommended");
    } else {
      this.stimulate("UI::ToastReflex#show", "error", "Error changing unit status, contact support");
    }
  };

  async gridDefaults(column) {
    const token = this.campaignTokenValue;
    const markAsFavorite = this.markAsFavorite;
    const unFavorite = this.unFavorite;
    const unRecommentUnit = this.unRecommentUnit;
    const recommendUnit = this.recommendUnit;
    return {
      contextMenu: {
        items: {
          favorite: {
            name: "Mark as favorite",
            callback: async function(key, selection, clickEvent) {
              const ranges = selection.flatMap(s => range(s.start.row, s.end.row + 1));
              const slugs = ranges.map(row => this.getDataAtRowProp(row, "id"));
              return await markAsFavorite(token, slugs);
            },
          },
          un_favorite: {
            name: "Unfavorite",
            callback: async function(key, selection, clickEvent) {
              const ranges = selection.flatMap(s => range(s.start.row, s.end.row + 1));
              const slugs = ranges.map(row => this.getDataAtRowProp(row, "id"));
              return await unFavorite(token, slugs);
            },
          },
          recommend: {
            name: "Recommend",
            callback: async function(key, selection, clickEvent) {
              const ranges = selection.flatMap(s => range(s.start.row, s.end.row + 1));
              const slugs = ranges.map(row => this.getDataAtRowProp(row, "id"));
              return await recommendUnit(token, slugs);
            },
          },
          un_recommend: {
            name: "Unrecommend",
            callback: async function(key, selection, clickEvent) {
              const ranges = selection.flatMap(s => range(s.start.row, s.end.row + 1));
              const slugs = ranges.map(row => this.getDataAtRowProp(row, "id"));
              return await unRecommentUnit(token, slugs);
            },
          },
        },
      },
      comments: true,
      autoWrapRow: false,
      autoWrapCol: false,
      rowHeaders: true,
      renderAllRows: false,
      wordWrap: false,
      colHeaders: column.map(col => col.title),
      afterChange: this.onGridChange,
      columns: column,
      width: "100%",
      columnSorting: true,
      autoRowSize: true,
    };
  }

  async columns(forceReadOnly = false) {
    const cols = this.currentColumns;
    const formattedColumns = Object.entries(cols).map(row => {
      return {
        data: row[1].attribute,
        title: row[0],
        readOnly: forceReadOnly ? true : row[1].read_only,
        className: forceReadOnly ? "force-disable" : "",
        renderer: row[1].renderer,
        source: row[1].source,
        type: row[1].type,
        validator: row[1].validator,
        width: row[1].width,
      };
    });
    formattedColumns.map(row => {
      for (const key in row) {
        if (row[key] === undefined) {
          delete row[key];
        }
      }
    });

    return formattedColumns;
  }
}
