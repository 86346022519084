import ApplicationController from "../application_controller";
import debounce from "lodash/debounce";

export default class extends ApplicationController {
  static values = {
    reflex: String,
    supplierId: String,
    campaignToken: String,
    currentUserId: Number,
    contractToken: String,
    reflexAction: String,
  };

  static targets = ["filters"];

  connected() {
    this.filterFaceId = debounce(this.filterFaceId.bind(this), 500);
  }

  isFilterActive(filter) {
    const activeFilters = this.currentActiveFilters();
    return Object.keys(activeFilters).includes(filter);
  }

  currentActiveFiltersElements() {
    return this.filtersTargets.filter(el => Array.from(el.classList).includes("active"));
  }

  currentActiveFilters() {
    return this.currentActiveFiltersElements().reduce((acc, el) => {
      const {
        dataset: { filter, filterValue },
      } = el;
      acc[filter] = filterValue;
      return acc;
    }, {});
  }

  deactivateFilter(filter) {
    const activeFilters = this.currentActiveFilters();
    const filteredKeys = Object.keys(activeFilters).filter(af => af !== filter);
    return Object.assign({}, ...filteredKeys.map(af => ({ [af]: activeFilters[af] })));
  }

  toggleFilter(e) {
    const {
      target: { dataset },
    } = e;
    const { filter, filterValue } = dataset;
    if (this.isFilterActive(filter)) {
      return this.onFilter({ ...this.deactivateFilter(filter) });
    }
    return this.onFilter({ ...this.currentActiveFilters(), ...{ [filter]: filterValue } });
  }

  filterFaceId(e) {
    const {
      target: { value },
    } = e;
    if (!value) {
      return this.onFilter(this.currentActiveFilters());
    }
    return this.onFilter({ ...this.currentActiveFilters(), ...{ face_id: value } });
  }

  async onFilter(filters) {
    await this.stimulate(`${this.reflexValue}`, this.payload(filters));
  }

  payload(filters) {
    if (this.reflexValue == "ModalReflex#load_modal_component") {
      return this.modalPayload(filters);
    }
    return { filters };
  }

  modalPayload(filters) {
    return {
      component: "modal/vendor_contract_unit_modal_component",
      component_params: {
        supplier_id: this.supplierIdValue,
        campaign_token: this.campaignTokenValue,
        current_user_id: this.currentUserIdValue,
        contract_token: this.contractTokenValue,
        reflex_action: this.reflexActionValue,
        filters,
      },
    };
  }
}
