import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  async showFullAccessIntroModal(event) {
    event.stopPropagation();
    await this.stimulate("ModalReflex#load_modal_component", {
      component: "modal/browse/full_access/intro_modal_component",
    });
    return window.modal.open();
  }

  async showFullAccessInformationModal(event) {
    event.stopPropagation();
    await this.stimulate("ModalReflex#load_modal_component", {
      component: "modal/browse/full_access/information_modal_component",
    });
    return window.modal.open();
  }

  async showFullAccessSuccessModal(event) {
    event.stopPropagation();
    await this.stimulate("Pro::Browse::MapsReflex#request_full_access");
    await this.stimulate("ModalReflex#load_modal_component", {
      component: "modal/browse/full_access/success_modal_component",
    });
    window.dispatchEvent(new CustomEvent("adquick:browse:requestfullaccess"));
    return window.modal.open();
  }

  close() {
    window.modal.close();
  }
}
