import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "name",
    "dueDate",
    "campaignDueDate",
    "market",
    "agencyMarkup",
    "form",
    "brandLogoInput",
    "brandPreview",
    "productionCostContainer",
    "productionCostCheckbox",
    "enableCampaignTargetContainer",
    "enableCampaignTargetCheckbox",
    "enableAnalyticsCpmContainer",
    "enableAnalyticsCpmCheckbox",
    "skipLoginCheckbox",
    "favCheckbox",
  ];

  static values = {
    agencyId: Number
  }

  initialize() {
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  connected() {
    if (this.formTarget.id && this.formTarget.id === "request-form") {
      return this.addRequestSubmitListeners();
    }
    this.addSubmitListener();
    if (!!this.brandPreviewTarget.src) {
      this.showBrandLogoPreview();
    }
  }

  elementsNameMap(elementName) {
    const inputNames = {
      "campaign[name]": "Campaign Name",
      "campaign[advertiser_id]": "Advertiser",
      "campaign[start_date]": "Start Date",
      "campaign[end_date]": "End Date",
      "campaign[sales_user_id]": "Sales Executive",
      "campaign[fulfillment_user_id]": "Account Manager",
      "campaign[secondary_agency_id]": "Agency",
      "campaign[due_date]": "Due Date",
    };
    return inputNames[elementName];
  }

  requiredFields() {
    const fields = [...this.formTarget.elements];
    return fields.filter(el => el.required);
  }

  addRequestSubmitListeners() {
    this.formTarget.addEventListener("submit", e => {
      this.createMarketInputs();
    });
  }

  addSubmitListener() {
    this.formTarget.addEventListener("submit", this.handleSubmit, false);
  }

  async handleSubmit(e) {
    this.clearFormErrors();
    /* This has to be improved, maybe switch to rails model validation? */
    if (!this.verifyRequiredFields()) {
      this.showMissingRequiredInputsAlert();
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    if (!this.verifyOptionalFields()) {
      alert("Agency markup has to be less than 1");
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    this.createUncheckedBoxes();
    this.createMarketInputs();

    if (this.formTarget.dataset.async === "true") {
      // Async is used in the campaign form on browse (add units)
      e.stopPropagation();
      e.preventDefault();
      const response = await fetch(this.formTarget.action, {
        method: "POST",
        body: new FormData(this.formTarget),
      });
      const jsonData = await response.json();
      this.addSelectedUnitsToCampaign(jsonData.campaign.id);
    }
  }

  addSelectedUnitsToCampaign(campaignId) {
    const unitIds = window.adquick?.browse?.selected_unit_ids;

    if (campaignId && unitIds) {
      this.stimulate("Pro::Browse::AddUnitsReflex#submit_modal", campaignId, unitIds);

      // Clear the selected_unit_ids set from browse_controller.js to start clean on the next time the user selects some units
      window.adquick.browse.selected_unit_ids = null;
    }
  }

  clearFormErrors() {
    const errorElements = document.querySelectorAll(".has-error");
    Array.from(errorElements).forEach(el => el.classList.remove("has-error"));
  }

  verifyRequiredFields() {
    const campaignNameReady = this.checkCampaignName();
    const campaignDueDateReady = this.checkCampaignDueDate();
    const campaignAdvertiserReady = this.checkCampaignAdvertiser();
    const campaignSalesReady = this.checkCampaignSales();
    const campaignFulfillmentReady = this.checkCampaignFulfillment();
    const campaignSecondaryAgency = this.checkCampaignSecondaryAgency();
    return (
      campaignNameReady &&
      campaignDueDateReady &&
      campaignAdvertiserReady &&
      campaignSalesReady &&
      campaignFulfillmentReady &&
      campaignSecondaryAgency
    );
  }

  showMissingRequiredInputsAlert() {
    const missing = this.requiredFields().filter(el => !el.value);
    const missingInputNames = missing.map(el => el.name);
    const missingInputNamesMapped = missingInputNames.map(elName => this.elementsNameMap(elName)).join(", ");
    alert(`Required fields: ${missingInputNamesMapped} are not present.`);
  }

  verifyOptionalFields() {
    const campaignAgencyMarkup = this.checkCampaignAgencyMarkup();
    return campaignAgencyMarkup;
  }

  checkCampaignName() {
    if (!!this.nameTarget.value) {
      return true;
    }
    this.nameTarget.classList.add("has-error");
    return false;
  }

  checkCampaignDueDate() {
    if (!this.hasDueDateTarget) {
      return true;
    }
    if (!!this.dueDateTarget.value) {
      return true;
    }
    this.campaignDueDateTarget.classList.add("has-error");
    return false;
  }

  checkCampaignAdvertiser() {
    const advertiser = document.getElementById("campaign_advertiser_id").value;
    if (!!advertiser) {
      return true;
    }
    const advertiserField = document.getElementById("campaign_advertiser");
    advertiserField.classList.add("has-error");
    return false;
  }

  checkCampaignSales() {
    const sales = document.getElementById("campaign_sales_user_id").value;
    if (!!sales) {
      return true;
    }
    const salesField = document.getElementById("campaign_sales");
    salesField.classList.add("has-error");
    return false;
  }

  checkCampaignFulfillment() {
    const fulfillment = document.getElementById("campaign_fulfillment_user_id").value;
    if (!!fulfillment) {
      return true;
    }
    const fulfillmentField = document.getElementById("campaign_fulfillment");
    fulfillmentField.classList.add("has-error");
    return false;
  }

  checkCampaignSecondaryAgency() {
    // We first need to check if the element is present before we grab it's value
    // other wise users who are not talon associated will be unable to submit the form
    const fulfillment_element = document.getElementById("talon_secondary_agency_id");
    if (!fulfillment_element) {
      return true;
    }
    const fulfillment = fulfillment_element.value;
    if (!!fulfillment) {
      return true;
    }
    const fulfillmentField = document.getElementById("talon_secondary_agency");
    fulfillmentField.classList.add("has-error");
    return false;
  }

  checkCampaignAgencyMarkup() {
    if (!this.hasAgencyMarkupTarget) {
      return true;
    }
    const agencyMarkup = this.agencyMarkupTarget.value;
    if (!agencyMarkup) {
      return true;
    }
    if (Number(agencyMarkup) < 1) {
      return true;
    }
    this.agencyMarkupTarget.classList.add("has-error");
    return false;
  }

  async onClone(e) {
    const {
      currentTarget: { dataset },
    } = e;
    const { campaignToken } = dataset;
    if (window.confirm("Confirm campaign clone?")) {
      e.preventDefault();
      const response = await fetch(`/api/v1/campaigns/${campaignToken}/clone`, {
        method: "POST",
      });
      const result = await response.json();
      alert(
        "You are being redirected to your cloned campaign. Copying units and tags could take up to five minutes. Please refresh if you don't see all units.",
      );
      window.location.assign(`/campaigns/${result.campaign.token}`);
    }
  }

  onDueDateChange(e) {
    const {
      target: { value },
    } = e;
    this.dueDateTarget.value = !!value ? this.formatDate(value) : null;
  }

  formatDate(dateString) {
    return new Date(dateString).toUTCString();
  }

  removeBrandLogo(e) {
    e.preventDefault();
    const checkbox = this.createCheckbox("campaign[remove_branding_logo]", "true", true);
    this.formTarget.appendChild(checkbox);
    this.hideBrandLogoPreview();
  }

  openFileLibrary() {
    this.brandLogoInputTarget.click();
  }

  getFileFromLibrary(e) {
    const file = e.target.files[0];
    const blob = new Blob([file], { type: file.type });
    const url = URL.createObjectURL(blob);
    this.brandPreviewTarget.src = url;
    this.showBrandLogoPreview();
  }

  showBrandLogoPreview() {
    this.brandPreviewTarget.parentElement.classList.add("show");
  }

  hideBrandLogoPreview() {
    this.brandPreviewTarget.parentElement.classList.remove("show");
  }

  createUncheckedBoxes() {
    const uncheckedInputs = this.formTarget.querySelectorAll("input[type=checkbox]:not(:checked)");
    uncheckedInputs.forEach(el => {
      const name = el.name;
      const uncheckedBox = this.createCheckbox(name, "false", true);
      this.formTarget.appendChild(uncheckedBox);
    });
  }

  // This code is replicated in the campaign_markets_component_controller.js
  createMarketInputs() {
    const markets = Array.from(document.querySelectorAll("[data-multi-select-async-target].selected"));
    if (markets.length == 0) {
      return this.handleMarketsClear();
    }
    const geographies = markets.map(e => JSON.parse(e.dataset.value).name).join(";");
    this.createGeographyInput(geographies);
    markets.forEach(e => {
      const value = JSON.parse(e.dataset.value);
      this.createPlaceInput(value);
    });
  }

  handleMarketsClear() {
    const geographyInput = this.createInput("campaign[geography]", null);
    const placesInput = this.createInput("campaign[places][]", null);
    this.formTarget.append(geographyInput, placesInput);
  }

  createGeographyInput(geographies) {
    const el = this.createInput("campaign[geography]", geographies);
    this.formTarget.appendChild(el);
  }

  createPlaceInput(value) {
    const placeNameEl = this.createInput("campaign[places][][name]", value.name);
    const placeIdEl = this.createInput("campaign[places][][place_id]", value.place_id);
    const placeTypeEl = this.createInput("campaign[places][][place_type][]", value.place_type[0]);
    const placeCenterLatEl = this.createInput("campaign[places][][center][]", value.center[0]);
    const placeCenterLonEl = this.createInput("campaign[places][][center][]", value.center[1]);
    const placeGeometryTypeEl = this.createInput("campaign[places][][geometry][][type]", value.geometry.type);
    const placeGeometryCoordinatesEl = this.createInput(
      "campaign[places][][geometry][][coordinates][]",
      value.geometry.coordinates,
    );
    this.formTarget.appendChild(placeNameEl);
    this.formTarget.appendChild(placeIdEl);
    this.formTarget.appendChild(placeTypeEl);
    this.formTarget.appendChild(placeCenterLatEl);
    this.formTarget.appendChild(placeCenterLonEl);
    this.formTarget.appendChild(placeGeometryTypeEl);
    this.formTarget.appendChild(placeGeometryCoordinatesEl);
  }

  createInput(name, value) {
    const el = document.createElement("input");
    el.setAttribute("type", "hidden");
    el.setAttribute("name", name);
    el.value = value;
    return el;
  }

  createCheckbox(name, value, checked) {
    const el = document.createElement("input");
    el.setAttribute("type", "checkbox");
    el.setAttribute("name", name);
    el.setAttribute("value", value);
    el.setAttribute("checked", checked);
    el.classList.add("hide");
    return el;
  }

  async createAdvertiser(e) {
    e.preventDefault();
    document.getElementById("campaign_advertiser").querySelector("input[type='text']").value = "";
    const agencyId = this.agencyId();
    await this.stimulate("ModalReflex#load_modal_component", {
      component: "modal/create_advertiser_component",
      component_params: { agency_id: agencyId },
    });
    return window.modal.open();
  }

  onAgencyInputChange(e) {
    const {
      target: { value },
    } = e;
    return this.updateCampaignAgency(value);
  }

  agencyId() {
    if (this.hasAgencyIdValue) return this.agencyIdValue;

    const input = document.getElementsByName("campaign[agency_id]")[0];
    if (input) return input.value;
  }

  async updateCampaignAgency(agency_id) {
    await this.stimulate("CampaignReflex#update", { agency_id });
    return this.refreshAdQuickFeeComponent(agency_id, this.advertiserId());
  }

  onAdvertiserInputChange(e) {
    const {
      target: { value },
    } = e;
    return this.refreshAdQuickFeeComponent(this.agencyId(), value);
  }

  advertiserId() {
    const input = document.getElementsByName("campaign[advertiser_id]")[0];
    if (input) return input.value;
  }

  async refreshAdQuickFeeComponent(agencyId, advertiserId) {
    await this.stimulate("CampaignReflex#refresh_adquick_fee_component", {
      agency_id: agencyId,
      advertiser_id: advertiserId,
    });
  }
}
