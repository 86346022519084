import { Controller } from "stimulus";
import Pickr from "@simonwep/pickr/dist/pickr.es5.min.js";

export default class extends Controller {
  showBackgroundColorPicker(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const input = button.previousElementSibling;
    this.backgroundColorPicker(button, input).show();
  }

  backgroundColorPicker(button, input) {
    const pickr = Pickr.create({
      el: button,
      useAsButton: true,
      lockOpacity: true,
      theme: "nano",
      default: input.value,

      swatches: ["#ff6900", "#fcb900", "#7bdcb5", "#00d084", "#8ed1fc", "#0693e3", "#f78da7"],

      components: {
        preview: true,
        opacity: false,
        hue: true,

        interaction: {
          input: true,
          clear: true,
          save: true,
        },
      },
    }).on("save", (color, pickr) => {
      let hexColor;
      if (color === null) {
        // "clear" option
        hexColor = "#FFFFFF";
      } else {
        hexColor = color.toHEXA().toString(0);
      }
      button.style.borderColor = hexColor;
      input.value = hexColor;
      pickr.hide();
    });
    return pickr;
  }

  showTextColorPicker(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const input = button.previousElementSibling;
    this.textColorPicker(button, input).show();
  }

  textColorPicker(button, input) {
    const pickr = Pickr.create({
      el: button,
      useAsButton: true,
      lockOpacity: true,
      theme: "nano",
      default: input.value,
      appClass: "black_white_only",

      swatches: ["#000000", "#ffffff"],

      components: {
        preview: false,
        opacity: false,
        hue: false,
      },
    })
      .on("swatchselect", (color, pickr) => {
        pickr.applyColor();
      })
      .on("save", (color, pickr) => {
        const hexColor = color.toHEXA().toString(0);
        button.style.borderColor = hexColor;
        input.value = hexColor;
        pickr.hide();
      });
    return pickr;
  }
}
