import consumer from "./consumer";

const start = () => {
  const creatives_page_container = document.querySelector("#creatives-page");

  if (!creatives_page_container) {
    return setTimeout(() => start(), 300);
  }

  const {
    dataset: { userId, campaignToken },
  } = creatives_page_container;

  consumer.subscriptions.create(
    {
      channel: "CreativesChannel",
      user_id: userId,
      campaign_token: campaignToken,
    },
    {
      connected() {
        console.info("[ActionCable] Connected to CreativesChannel.");
      },
      received(data) {
        const parsed = JSON.parse(data);
        console.log(`redirecting to ${parsed["redirect_to"]}`);
        window.location.assign(parsed["redirect_to"]);
      },
    },
  );
};

const CreativesChannel = { start };
export default CreativesChannel;
