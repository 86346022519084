import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  openUploadModal() {
    const payload = {
      component: "modal/supplier_attachment_component",
      component_params: {
        campaign_token: this.data.get("campaignToken"),
        visibility: "private",
      },
    };
    this.stimulateModal(payload);
  }

  async stimulateModal(payload) {
    try {
      await this.stimulate("ModalReflex#load_modal_component", payload);
      window.modal.open();
    } catch (error) {
      console.log(error);
    }
  }

  async delete(event) {
    const element = event.currentTarget;
    const attachmentId = element.dataset.attachmentId;
    try {
      await this.stimulate("RfpAttachmentReflex#delete", attachmentId);
    } catch (error) {
      console.log("Error deleting attachment: " + error);
    }
  }
}
