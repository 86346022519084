import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  connected() {
    const form = this.#getFormElement();

    form.addEventListener('submit', e => {
      this.#createMarketInputs(e);
    });
  }

  #createMarketInputs(e) {
    const selectedMarkets = Array.from(e.target.querySelectorAll("[data-multi-select-async-target].selected"));

    if (selectedMarkets.length == 0) {
      return this.#clearMarketsField();
    }

    const geographies = selectedMarkets.map(e => JSON.parse(e.dataset.value).name).join(";");
    this.#createGeographyInput(geographies);

    selectedMarkets.forEach(e => {
      const placeData = JSON.parse(e.dataset.value);
      this.#createPlaceInput(placeData);
    });
  }

  #clearMarketsField() {
    const form = this.#getFormElement();
    const geographyInput = this.#createHiddenInput("campaign[geography]", null);
    const placesInput = this.#createHiddenInput("campaign[places][]", null);

    form.append(geographyInput, placesInput);
  }

  #getFormElement() {
    return this.element.closest('form');
  }

  #createHiddenInput(name, value) {
    const el = document.createElement("input");
    el.setAttribute("type", "hidden");
    el.setAttribute("name", name);
    el.value = value;

    return el;
  }

  #createGeographyInput(geographies) {
    const form = this.#getFormElement();
    const el = this.#createHiddenInput("campaign[geography]", geographies);
    form.appendChild(el);
  }

  #createPlaceInput(placeData) {
    const form = this.#getFormElement();
    const placeNameEl = this.#createHiddenInput("campaign[places][][name]", placeData.name);
    const placeIdEl = this.#createHiddenInput("campaign[places][][place_id]", placeData.place_id);
    const placeTypeEl = this.#createHiddenInput("campaign[places][][place_type][]", placeData.place_type[0]);
    const placeCenterLatEl = this.#createHiddenInput("campaign[places][][center][]", placeData.center[0]);
    const placeCenterLonEl = this.#createHiddenInput("campaign[places][][center][]", placeData.center[1]);
    const placeGeometryTypeEl = this.#createHiddenInput("campaign[places][][geometry][][type]", placeData.geometry.type);
    const placeGeometryCoordinatesEl = this.#createHiddenInput("campaign[places][][geometry][][coordinates][]", placeData.geometry.coordinates);

    form.appendChild(placeNameEl);
    form.appendChild(placeIdEl);
    form.appendChild(placeTypeEl);
    form.appendChild(placeCenterLatEl);
    form.appendChild(placeCenterLonEl);
    form.appendChild(placeGeometryTypeEl);
    form.appendChild(placeGeometryCoordinatesEl);
  }
}
