import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static values = {
    campaignStrategyId: Number,
  };

  async addMarket(e) {
    const { currentTarget } = e;
    if (currentTarget.classList.contains("added")) {
      return;
    }
    await this.stimulate(`Plus::Campaigns::StrategyReflex#add_strategy_market`, {
      dma_id: currentTarget.dataset.dmaId,
      campaign_strategy_id: this.campaignStrategyIdValue,
    });
    currentTarget.classList.toggle("added");
  }

  removeMarket(e) {
    if (confirm("Are you sure you want to remove this market from the list?"))
      this.stimulate(`Plus::Campaigns::StrategyReflex#remove_strategy_market`, {
        dma_id: e.currentTarget.dataset.dmaId,
      });
  }
}
