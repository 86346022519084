import ApplicationController from "./application_controller";
import debounce from "lodash/debounce";
export class CampaignPopFilterController extends ApplicationController {
  static values = {
    campaignToken: String,
  };

  initialize() {
    this.formChanges = debounce(this.formChanges, 500);
  }

  connect() {
    StimulusReflex.register(this);
  }

  handleFormChanges() {
    const queryString = new URLSearchParams(new FormData(this.element));
    let keysToDelete = [];
    queryString.forEach((value, name) => {
      if (value === "") {
        keysToDelete.push(name);
      }
    });
    keysToDelete.map(k => queryString.delete(k));
    const newUrl = `${this.campaignTokenValue}?${queryString.toString()}`;
    window.history.replaceState(null, null, newUrl);
  }
  async formChanges() {
    this.handleFormChanges();
    this.toggleLoading();
    this.element.requestSubmit();
    this.toggleLoading();
  }

  async overviewFormChanges() {
    this.handleFormChanges();
    await this.stimulate("Pro::Unit::PopCardReflex#render_overview_card_list", {});
  }

  toggleLoading() {
    // Not ideal, querying DOM instead of using a target but the container is not under the filter controller attached to the form
    const container = document.querySelector(".cards-container");
    container.classList.toggle("filter-loading");
  }
}
