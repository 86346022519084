import ApplicationController from "../application_controller";
import numeral from "numeral";
import Chart from "chart.js/auto";

const IMPRESSION_TRACK_ITEMS = {
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
        callback: function(value, index, array) {
          return numeral(value).format("0a");
        },
      },
    },
    x: {
      time: {
        // Luxon format string
        tooltipFormat: "DD T",
      },
    },
  },
  plugins: {
    title: {
      display: true,
      text: "History",
      align: "start",
      font: {
        size: 16,
      },
    },
    legend: {
      display: false,
    },
  },
  responsive: true,
};

export default class extends ApplicationController {
  static targets = ["chart"];
  static values = { dates: Array, impressions: Array };

  connect() {
    this.chart = new Chart(this.chartTarget.getContext("2d"), {
      type: "line",
      data: this.getData(),
      options: IMPRESSION_TRACK_ITEMS,
    });
  }

  getData() {
    const labels = this.datesValue;
    const impressions = this.impressionsValue;
    return {
      labels: labels,
      datasets: [
        {
          label: "Total Impressions",
          backgroundColor: "transparent",
          borderColor: "#3B82F6",
          data: impressions,
          legend: { display: false },
          tension: 0.4,
        },
      ],
    };
  }
}
